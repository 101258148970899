import { useState, useEffect, useRef } from 'react'
import { Spin } from 'antd'
import { ChevronLeft, ChevronRight } from "lucide-react"
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import { toast } from "sonner"

import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { MonthPicker } from '@/components/ui/date/month-picker'
import { WorkloadTable } from '@/components/ui/specialized/WorkloadTable'

import { TaskRecord } from '@/types/workload'

export default function TaskWorkload() {
  const [selectedDate, setSelectedDate] = useState(dayjs())
  const [loading, setLoading] = useState(false)
  const [taskData, setTaskData] = useState<TaskRecord[]>([])
  const mainTableScrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    fetchTaskData()
  }, [selectedDate])

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/user')
      return response.data
        .filter((user: any) => !user.is_test_user)
        .map((user: any) => user.user_id)
    } catch (error) {
      console.error('Error fetching users:', error)
      toast.error("Failed to fetch users. Please try again later.")
      return []
    }
  }

  const fetchTaskData = async () => {
    setLoading(true)
    try {
      const userIds = await fetchUsers()
      
      if (userIds.length === 0) {
        toast.error("No valid users found")
        return
      }

      console.log('Fetching data for users:', userIds)

      const response = await axios.get('/dashboard/tasks/workload', {
        params: {
          year: selectedDate.year(),
          month: selectedDate.month() + 1,
          user_ids: userIds.join(',')
        }
      })
      console.log('Received task data:', response.data)
      setTaskData(response.data)
    } catch (error) {
      console.error('Error fetching task data:', error)
      toast.error("Failed to fetch task data. Please try again later.")
    } finally {
      setLoading(false)
    }
  }

  const handleDateChange = (date: Date | undefined) => {
    if (date) {
      setSelectedDate(dayjs(date))
    }
  }

  return (
    <div className="space-y-4 pt-4">
      <Card>
        <CardContent className="pt-6">
          <div className="flex justify-center items-center gap-4 mb-6">
            <Button
              variant="outline"
              size="icon"
              onClick={() => setSelectedDate(prev => prev.subtract(1, 'month'))}
            >
              <ChevronLeft className="h-4 w-4" />
            </Button>
            
            <MonthPicker
              date={selectedDate.toDate()}
              onSelect={handleDateChange}
              className="w-[200px]"
            />
            
            <Button
              variant="outline"
              size="icon"
              onClick={() => setSelectedDate(prev => prev.add(1, 'month'))}
            >
              <ChevronRight className="h-4 w-4" />
            </Button>
          </div>

          <div className="relative min-h-[400px]">
            {loading ? (
              <div className="absolute inset-0 flex items-center justify-center">
                <Spin size="large" />
              </div>
            ) : (
              <WorkloadTable
                data={taskData}
                selectedDate={selectedDate}
                scrollRef={mainTableScrollRef}
                onScroll={() => {
                  // Handle scroll sync if needed
                }}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  )
}