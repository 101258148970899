import { create } from 'zustand';
import { Flow } from './flows';

interface FlowStore {
  flows: Flow[];
  activeFlow: Flow | null;
  setActiveFlow: (flow: Flow) => void;
  updateFlow: (flow: Flow) => void;
}

export const useOnboardingFlowStore = create<FlowStore>((set) => ({
  flows: [],
  activeFlow: null,
  setActiveFlow: (flow) => set({ activeFlow: flow }),
  updateFlow: (flow) => set((state) => ({
    flows: state.flows.map((f) => (f.id === flow.id ? flow : f)),
  })),
}));

export const useAutomationFlowStore = create<FlowStore>((set) => ({
  flows: [],
  activeFlow: null,
  setActiveFlow: (flow) => set({ activeFlow: flow }),
  updateFlow: (flow) => set((state) => ({
    flows: state.flows.map((f) => (f.id === flow.id ? flow : f)),
  })),
}));