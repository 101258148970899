import React, { useState } from 'react';
import { Form, Input, Button, Card, Switch, InputNumber, Select, message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import './AdminBackup.css';

function AdminBackup() {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Form values:', values);
    message.success('Backup configuration saved');
  };

  return (
    <div className="admin-backup">
      <h2>Database Backup Configuration</h2>
      <Card title="Backup Settings" className="backup-card">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            compression: true,
            retention_days: 30,
            storage_type: 'local',
          }}
        >
          <Form.Item
            name="backup_path"
            label="Backup Storage Path"
            rules={[{ required: true, message: 'Please input the backup storage path' }]}
          >
            <Input placeholder="/path/to/backup/storage" />
          </Form.Item>

          <Form.Item
            name="compression"
            valuePropName="checked"
            label="Enable Compression"
          >
            <Switch />
          </Form.Item>

          <Form.Item
            name="retention_days"
            label="Backup Retention (days)"
            rules={[{ required: true, message: 'Please input retention period' }]}
          >
            <InputNumber min={1} max={365} />
          </Form.Item>

          <Form.Item
            name="storage_type"
            label="Storage Type"
            rules={[{ required: true, message: 'Please select storage type' }]}
          >
            <Select>
              <Select.Option value="local">Local Storage</Select.Option>
              <Select.Option value="s3">Amazon S3</Select.Option>
              <Select.Option value="gcs">Google Cloud Storage</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" icon={<CloudUploadOutlined />}>
              Save Configuration
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default AdminBackup;

