import React from 'react';
import './SalesDash.css';

function SalesDash() {
  return (
    <div className="sales-dashboard">
      <h2>Sales Dashboard</h2>
      <p>Coming soon...</p>
    </div>
  );
}

export default SalesDash;