import translations from './common.json';

export const SUPPORTED_LANGUAGES = ['en', 'da'] as const;
export type Language = typeof SUPPORTED_LANGUAGES[number];

export const t = (key: string, lang: Language): string => {
  const keys = key.split('.');
  let value: any = translations[lang];
  
  for (const k of keys) {
    value = value?.[k];
    if (value === undefined) {
      console.warn(`Translation missing for key: ${key} in language: ${lang}`);
      // Fallback to English if translation is missing
      value = getValueFromKeys(['en', ...keys]);
      break;
    }
  }
  
  return value || key;
};

// Helper function to get nested value
const getValueFromKeys = (keys: string[]): string | undefined => {
  let value: any = translations;
  for (const key of keys) {
    value = value?.[key];
    if (value === undefined) return undefined;
  }
  return value;
};

// Type helper for autocompletion (optional)
type TranslationKeys = keyof typeof translations.en;
export const isValidTranslationKey = (key: string): key is TranslationKeys => {
  return key in translations.en;
};