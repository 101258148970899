import React, { memo, useMemo, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { hasRequiredPermission } from '../utils/permissionUtils';

interface ProtectedRouteProps {
  children: React.ReactElement;
  requiredPermission?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = memo(({ children, requiredPermission }) => {
  const userRef = useRef<any>(JSON.parse(localStorage.getItem('user') || 'null'));

  const accessGranted = useMemo(() => {
    const user = userRef.current;

    if (!user) {
      return false;
    }

    if (!user.is_registered && window.location.pathname === '/complete-registration') {
      return true;
    }

    if (requiredPermission) {
      return hasRequiredPermission(user.permissions, requiredPermission);
    }

    return true;
  }, [requiredPermission]);

  if (!userRef.current) {
    return <Navigate to="/login" replace />;
  }

  if (!accessGranted) {
    return <Navigate to="/main-menu" replace />;
  }

  return children;
});

ProtectedRoute.displayName = 'ProtectedRoute';
export default ProtectedRoute;