import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Loading } from "@/components/ui/loading"
import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"

// Icons
import { Users, Clock, CheckCircle, Plus } from 'lucide-react'

// Types
import type { ChurnStats, Churn, ChurnFormValues } from '@/types/offboarding'

// Components
import ChurnModal from './ChurnModal'

const OffboardingDashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [stats, setStats] = useState<ChurnStats>({ active: 0, pending: 0, completed: 0 })
  const [churns, setChurns] = useState<Churn[]>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    fetchStats()
    fetchChurns()
  }, [])

  const fetchStats = async () => {
    try {
      const response = await axios.get('/churn/stats')
      setStats(response.data)
    } catch (error) {
      console.error('Error:', error)
      toast.error('Failed to fetch churn statistics')
    }
  }

  const fetchChurns = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/churn/')
      setChurns(response.data)
    } catch (error) {
      console.error('Error:', error)
      toast.error('Failed to fetch churns')
    } finally {
      setLoading(false)
    }
  }

  const handleChurnSubmit = async (churnData: ChurnFormValues) => {
    try {
      await axios.post('/churn/', churnData)
      toast.success('Churn process initiated successfully')
      fetchStats()
      fetchChurns()
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error:', error)
      toast.error('Failed to initiate churn process')
    }
  }

  const columns: ColumnDef<Churn>[] = [
    {
      key: 'deal_name',
      title: 'Customer Name',
      dataIndex: 'deal_name',
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      cell: ({ row }) => {
        const statusMap = {
          new: { label: 'Pending', variant: 'secondary' },
          in_progress: { label: 'In Progress', variant: 'warning' },
          churned: { label: 'Completed', variant: 'success' },
          reverted: { label: 'Reverted', variant: 'destructive' }
        }
        const status = statusMap[row.original.status]
        return (
          <Badge variant={status.variant as any}>
            {status.label}
          </Badge>
        )
      }
    },
    {
      key: 'reason',
      title: 'Reason',
      dataIndex: 'reason',
    },
    {
      key: 'created_at',
      title: 'Created At',
      dataIndex: 'created_at',
      cell: ({ row }) => new Date(row.original.created_at).toLocaleDateString()
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <Button 
          variant="ghost"
          onClick={() => navigate(`/customers/offboarding/${row.original.id}`)}
        >
          View Details
        </Button>
      )
    }
  ]

  if (loading) {
    return <Loading message="Loading dashboard..." />
  }

  return (
    <div className="space-y-8 p-8">
      <div className="flex items-center justify-between">
        <h1 className="text-3xl font-bold text-foreground">Offboarding</h1>
      </div>

      <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <Users className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Active Offboardings</p>
              <p className="text-2xl font-bold">{stats.active}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <Clock className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Pending Offboardings</p>
              <p className="text-2xl font-bold">{stats.pending}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <CheckCircle className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Completed Offboardings</p>
              <p className="text-2xl font-bold">{stats.completed}</p>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="flex justify-end">
        <Button onClick={() => setIsModalOpen(true)}>
          <Plus className="mr-2 h-4 w-4" />
          Add Churned Customer
        </Button>
      </div>

      <div>
        <Table 
          data={churns}
          columns={columns}
          pageSize={10}
          onPageChange={(page) => console.log('Page changed:', page)}
          onPageSizeChange={(size) => console.log('Page size changed:', size)}
        />
      </div>

      <ChurnModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleChurnSubmit}
      />
    </div>
  )
}

export default OffboardingDashboard