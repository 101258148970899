import React from 'react';
import './CustomScrollbar.css';

const CustomScrollbar = ({ children, className = '', ...props }) => {
  return (
    <div className={`custom-scrollbar ${className}`} {...props}>
      {children}
    </div>
  );
};

export default CustomScrollbar;