import React, { useState, useEffect } from 'react';
import { Tooltip, Modal, Button, Input, Switch, List, Space, message } from 'antd';
import { EditOutlined, FolderOutlined, CalculatorOutlined, BankOutlined, CreditCardOutlined, RobotOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './DealNavBar.css';

const iconMap = {
  FolderOutlined: <FolderOutlined />,
  CalculatorOutlined: <CalculatorOutlined />,
  BankOutlined: <BankOutlined />,
  RobotOutlined: <RobotOutlined />,
  CreditCardOutlined: <CreditCardOutlined />,
};

const DealNavBar = ({ dealId }) => {
  const [navItems, setNavItems] = useState([]);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [selectedNav, setSelectedNav] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editedItems, setEditedItems] = useState({});

  useEffect(() => {
    if (dealId) {
      fetchNavigation();
    }
  }, [dealId]);

  const fetchNavigation = async () => {
    if (!dealId) {
      setNavItems([]);
      setLoading(false);
      return;
    }

    try {
      const response = await axios.get(`/deal/deals/${dealId}/navigation`);
      
      // Add safety checks
      if (response.data && Array.isArray(response.data.items)) {
        setNavItems(response.data.items);
        // Initialize editedItems with current values
        const initialEdits = {};
        response.data.items.forEach(item => {
          initialEdits[item.name] = { ...item };
        });
        setEditedItems(initialEdits);
      } else {
        console.warn('Navigation data is not in expected format:', response.data);
        setNavItems([]);
        setEditedItems({});
      }
      
      setLoading(false);
    } catch (error) {
      console.error('Error fetching navigation:', error);
      setNavItems([]);
      setEditedItems({});
      setLoading(false);
    }
  };

  const handleNavClick = (item) => {
    if (item.link) {
      window.open(item.link, '_blank', 'noopener,noreferrer');
    } else {
      setSelectedNav(item);
      setLinkModalVisible(true);
    }
  };

  const handleUpdateLink = async (item, newLink) => {
    try {
      console.log('Updating link with:', { item, newLink }); // Add debug logging
      
      const updatedItem = {
        ...item, // Spread the existing item properties
        link: newLink // Override the link
      };

      console.log('Sending updated item:', updatedItem); // Add debug logging
      
      const response = await axios.put(
        `/deal/deals/${dealId}/navigation/${item.name}`,
        updatedItem
      );
      
      if (response.status === 200) {
        message.success('Link updated successfully');
        await fetchNavigation();
        setLinkModalVisible(false);
        setSelectedNav(null);
      }
    } catch (error) {
      console.error('Error updating link:', error);
      message.error('Failed to update link');
    }
  };

  const handleEditInputChange = (itemName, field, value) => {
    setEditedItems(prev => ({
      ...prev,
      [itemName]: {
        ...prev[itemName],
        [field]: value
      }
    }));
  };

  const handleSaveAll = async () => {
    try {
      const updatedItems = Object.values(editedItems);
      await axios.put(`/deal/deals/${dealId}/navigation`, {
        items: updatedItems
      });
      message.success('Navigation settings saved');
      await fetchNavigation();
      setEditModalVisible(false);
    } catch (error) {
      console.error('Error saving navigation:', error);
      message.error('Failed to save navigation settings');
    }
  };

  // Function to reset edited items to original values
  const resetEditedItems = () => {
    const initialEdits = {};
    navItems.forEach(item => {
      initialEdits[item.name] = { ...item };
    });
    setEditedItems(initialEdits);
  };

  // Update the edit modal close handler
  const handleEditModalClose = () => {
    setEditModalVisible(false);
    resetEditedItems(); // Reset to original values
  };

  if (loading) return null;

  return (
    <div className="deal-nav-container">
      <Button
        icon={<EditOutlined />}
        className="nav-edit-button"
        onClick={() => setEditModalVisible(true)}
      />
      <div className="deal-nav-bar">
        {navItems.filter(item => item.enabled).map((item, index) => (
          <Tooltip key={index} title={item.name} placement="bottom">
            <div
              className="deal-nav-item"
              onClick={() => handleNavClick(item)}
            >
              {iconMap[item.icon]}
            </div>
          </Tooltip>
        ))}
      </div>

      {/* Edit Modal */}
      <Modal
        title="Edit Navigation Links"
        open={editModalVisible}
        onCancel={handleEditModalClose} // Use new close handler
        footer={[
          <Button key="cancel" onClick={handleEditModalClose}>
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveAll}>
            Save All
          </Button>
        ]}
      >
        <List
          dataSource={navItems}
          renderItem={item => (
            <List.Item>
              <Space className="nav-edit-item">
                {iconMap[item.icon]}
                <span>{item.name}</span>
                <Input
                  placeholder="Enter URL"
                  value={editedItems[item.name]?.link || ''}
                  onChange={(e) => handleEditInputChange(item.name, 'link', e.target.value)}
                  className="nav-link-input"
                />
                <Switch
                  checked={editedItems[item.name]?.enabled}
                  onChange={(checked) => handleEditInputChange(item.name, 'enabled', checked)}
                />
              </Space>
            </List.Item>
          )}
        />
      </Modal>

      {/* Quick Add Link Modal */}
      <Modal
        title={`Add Link for ${selectedNav?.name}`}
        open={linkModalVisible}
        onCancel={() => {
          setLinkModalVisible(false);
          setSelectedNav(null); // Reset selected nav when closing
        }}
        footer={[
          <Button 
            key="cancel" 
            onClick={() => {
              setLinkModalVisible(false);
              setSelectedNav(null);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={() => {
              if (selectedNav) {
                handleUpdateLink(selectedNav, selectedNav.link);
              }
            }}
          >
            Save
          </Button>
        ]}
      >
        <Input
          placeholder="Enter URL"
          value={selectedNav?.link || ''}
          onChange={(e) => {
            setSelectedNav(prev => ({
              ...prev,
              link: e.target.value
            }));
          }}
          onPressEnter={(e) => {
            if (selectedNav) {
              handleUpdateLink(selectedNav, e.target.value);
            }
          }}
        />
      </Modal>
    </div>
  );
};

export default DealNavBar;
