import React from 'react';
import { Button, message } from 'antd';
import axios from '../../api/axiosConfig';

function AdminTesting() {
  const generateTestDeal = async () => {
    try {
      const response = await axios.post('/admin/generate-test-deal');
      message.success(`Test deal created: ${response.data.deal_name}`);
    } catch (error) {
      message.error('Failed to create test deal');
    }
  };

  return (
    <div className="admin-testing">
      <h2>Testing</h2>
      <Button type="primary" onClick={generateTestDeal}>
        Generate Test Deal
      </Button>
    </div>
  );
}

export default AdminTesting;
