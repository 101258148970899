import React, { useState } from 'react';
import { Button, Card, Table, message, Space } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './AdminRevibot.css';
import dayjs from 'dayjs';

function AdminRevibot() {
  const [syncing, setSyncing] = useState(false);
  const [syncHistory, setSyncHistory] = useState([]);

  const handleSync = async () => {
    try {
      setSyncing(true);
      await axios.post('/revibot/sync');
      message.success('Sync completed successfully');
      // Fetch updated sync status
      fetchSyncStatus();
    } catch (error) {
      message.error('Failed to sync: ' + (error.response?.data?.error || error.message));
    } finally {
      setSyncing(false);
    }
  };

  const fetchSyncStatus = async () => {
    try {
      const response = await axios.get('/revibot/sync/status');
      // Transform the data into the format needed for the table
      const historyData = Object.entries(response.data).map(([key, value]) => ({
        key,
        file_name: getFileName(key),
        sync_status: value ? 'success' : 'not synced',
        last_sync: value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : '-',
        last_modified: value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : '-',
        file_size: '-' // This information isn't available in the current response
      }));
      setSyncHistory(historyData);
    } catch (error) {
      message.error('Failed to fetch sync status');
    }
  };

  // Helper function to get readable file names
  const getFileName = (key) => {
    const fileNames = {
      customer_access: 'Customer Access Data',
      moms: 'Moms Data',
      personlige: 'Private Årsopgørelser',
      selskaber: 'Erhvervs Selvangivelser',
      udloebsdato_cpr: 'CPR Udløbsdato',
      transfer_pricing: 'Transfer Pricing'
    };
    return fileNames[key] || key;
  };

  // Columns for the sync history table
  const columns = [
    {
      title: 'File Name',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: 'Status',
      dataIndex: 'sync_status',
      key: 'sync_status',
      render: (status) => (
        <span style={{ 
          color: status === 'success' ? '#52c41a' : 
                 status === 'error' ? '#f5222d' : 
                 status === 'not synced' ? '#faad14' : '#1890ff'
        }}>
          {status}
        </span>
      ),
    },
    {
      title: 'Last Sync',
      dataIndex: 'last_sync',
      key: 'last_sync',
    },
    {
      title: 'Last Modified',
      dataIndex: 'last_modified',
      key: 'last_modified',
    },
    {
      title: 'File Size',
      dataIndex: 'file_size',
      key: 'file_size',
    },
  ];

  // Fetch sync status when component mounts
  React.useEffect(() => {
    fetchSyncStatus();
  }, []);

  return (
    <div className="revibot-container">
      <Card title="Revibot Sync" className="revibot-card">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Button 
            type="primary" 
            icon={<SyncOutlined spin={syncing} />}
            onClick={handleSync}
            loading={syncing}
            style={{ marginBottom: 16 }}
          >
            Sync Revibot Files
          </Button>
          
          <Table 
            dataSource={syncHistory}
            columns={columns}
            rowKey="key"
            pagination={{ pageSize: 10 }}
          />
        </Space>
      </Card>
    </div>
  );
}

export default AdminRevibot;