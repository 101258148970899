import * as React from "react"
import { Check, ChevronsUpDown, Loader2 } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { useDebounce } from "@/hooks/use-debounce"
import axios from '@/api/axiosConfig'
import { toast } from "sonner"

interface Deal {
  id: string
  name: string
  cvr?: string
}

interface DealSelectorProps {
  value?: string
  onChange: (value: string) => void
  className?: string
}

export function DealSelector({ value, onChange, className }: DealSelectorProps) {
  const [open, setOpen] = React.useState(false)
  const [deals, setDeals] = React.useState<Deal[]>([])
  const [loading, setLoading] = React.useState(false)
  const [searchTerm, setSearchTerm] = React.useState("")
  
  const debouncedSearch = useDebounce(searchTerm, 500)

  const fetchDeals = React.useCallback(async (search: string) => {
    try {
      setLoading(true)
      const response = await axios.get('/custom-board/deals/search', {
        params: {
          search,
          limit: 10
        }
      })
      setDeals(response.data)
    } catch (error) {
      console.error('Error fetching deals:', error)
      toast.error('Failed to fetch deals')
    } finally {
      setLoading(false)
    }
  }, [])

  React.useEffect(() => {
    fetchDeals(debouncedSearch)
  }, [debouncedSearch, fetchDeals])

  // Initial fetch
  React.useEffect(() => {
    fetchDeals('')
  }, [fetchDeals])

  const selectedDeal = deals.find((deal) => deal.id === value)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between", className)}
        >
          {value ? (
            selectedDeal ? 
              `${selectedDeal.name} ${selectedDeal.cvr ? `(${selectedDeal.cvr})` : ''}` 
              : "Select a deal..."
          ) : "Select a deal..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput 
            placeholder="Search deals..." 
            onValueChange={setSearchTerm}
          />
          {loading && (
            <div className="flex items-center justify-center py-6">
              <Loader2 className="h-4 w-4 animate-spin" />
            </div>
          )}
          {!loading && (
            <>
              <CommandEmpty>No deals found.</CommandEmpty>
              <CommandGroup>
                {deals.map((deal) => (
                  <CommandItem
                    key={deal.id}
                    value={deal.id}
                    onSelect={() => {
                      onChange(deal.id)
                      setOpen(false)
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value === deal.id ? "opacity-100" : "opacity-0"
                      )}
                    />
                    {deal.name} {deal.cvr ? `(${deal.cvr})` : ''}
                  </CommandItem>
                ))}
              </CommandGroup>
            </>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  )
}