import { useEffect, useState } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { ArrowRight, Calendar, Clock } from 'lucide-react'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { UserAvatar } from '@/components/user-avatar'
import { getBoardStatus, BoardConfigKey, getBoardStatusColor } from '@/taskboards/config/board-status'
import { Task } from '@/types/task'
import { StoredUser } from '@/types/user'
import { cn } from '@/lib/utils'
import { useUsers } from '@/contexts/UserContext'

dayjs.extend(relativeTime)

interface ActivityChange {
  from: string
  to: string
}

interface Activity {
  type: 'board_status' | 'next_work_date' | 'next_due_date'
  timestamp: string
  user_id: string | number
  deal_name: string
  task_description: string
  changes: ActivityChange
}

interface BoardActivityProps {
  open: boolean
  onClose: () => void
  taskType: BoardConfigKey
}

export function BoardActivity({ open, onClose, taskType }: BoardActivityProps) {
  const { users } = useUsers()
  const [activities, setActivities] = useState<Activity[]>([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (open) {
      fetchActivities()
    }
  }, [open, taskType])

  const fetchActivities = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`/taskboard/tasks/${taskType}/activity`)
      setActivities(response.data)
    } catch (error) {
      console.error('Error fetching activities:', error)
    } finally {
      setLoading(false)
    }
  }

  const getStatusColor = (status: string) => {
    return getBoardStatusColor(status, taskType)
  }

  const renderActivityContent = (activity: Activity) => {
    const changes = activity.changes
    switch (activity.type) {
      case 'board_status':
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">Status Changed</span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(changes.from) }}
              >
                {changes.from || 'None'}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span
                className="min-w-20 rounded px-2 py-0.5 text-center text-xs font-medium text-white shadow-sm"
                style={{ backgroundColor: getStatusColor(changes.to) }}
              >
                {changes.to || 'None'}
              </span>
            </div>
          </div>
        )
      case 'next_work_date':
      case 'next_due_date':
        const isWorkDate = activity.type === 'next_work_date'
        return (
          <div className="space-y-2 rounded-md bg-muted p-3">
            <div className="flex items-center justify-between">
              <span className="font-medium">
                {isWorkDate ? 'Work Date Updated' : 'Due Date Updated'}
              </span>
              <span className="text-sm text-muted-foreground">
                {dayjs(activity.timestamp).fromNow()}
              </span>
            </div>
            <div className="flex items-center gap-2">
              <span className="line-through text-muted-foreground">
                {changes.from ? dayjs(changes.from).format('DD/MM/YYYY') : 'None'}
              </span>
              <ArrowRight className="h-4 w-4 text-muted-foreground" />
              <span>
                {changes.to ? dayjs(changes.to).format('DD/MM/YYYY') : 'None'}
              </span>
            </div>
          </div>
        )
      default:
        return null
    }
  }

  const isValidUser = (user: StoredUser | undefined): user is StoredUser => {
    return user !== undefined && 'user_id' in user
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[90vh] max-w-2xl">
        <DialogHeader>
          <DialogTitle>Recent Activities</DialogTitle>
          <p className="text-sm text-muted-foreground">
            {activities.length} updates in the last 30 days
          </p>
        </DialogHeader>

        <ScrollArea className="h-[calc(90vh-160px)] px-4">
          {activities.length > 0 ? (
            <div className="space-y-8 py-4">
              {activities.map((activity, index) => {
                const user = users.find(u => u.user_id === activity.user_id)
                return (
                  <div key={index} className="relative pl-8">
                    <div className="absolute left-0 top-0">
                      {isValidUser(user) && <UserAvatar user={user} className="h-6 w-6" />}
                    </div>
                    <div className="space-y-1">
                      <div className="flex items-center justify-between gap-2">
                        <span className="font-medium">{activity.deal_name}</span>
                        {user && (
                          <span className="text-sm text-muted-foreground">
                            {user.name} {user.surname}
                          </span>
                        )}
                      </div>
                      <p className="text-sm text-muted-foreground">
                        {activity.task_description}
                      </p>
                      {renderActivityContent(activity)}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="flex h-[400px] items-center justify-center">
              <p className="text-muted-foreground">No recent activities found</p>
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}