import { useState, useEffect } from 'react'
import { Plus } from 'lucide-react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Card, CardContent } from "@/components/ui/card"
import axios from '../api/axiosConfig'
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { toast } from "sonner"

interface TaskConfig {
  id: number
  task_type: string
  frequency: string
  window_days: number
  workload_minutes: number
}

interface TableRow {
  original: TaskConfig;
}

const taskTypes = ['Bogføring', 'Løn', 'Moms']
const frequencies = ['Monthly', 'Quarterly', 'Semi-annually', 'Yearly']

const formSchema = z.object({
  task_type: z.string().min(1, "Task type is required"),
  frequency: z.string().min(1, "Frequency is required"),
  window_days: z.number().min(1).max(364),
  workload_hours: z.number().min(0).max(40),
  workload_minutes: z.number().min(0).max(59),
})

export default function TaskConfiguration() {
  const [configs, setConfigs] = useState<TaskConfig[]>([])
  const [loading, setLoading] = useState(false)
  const [changes, setChanges] = useState<Record<number, Partial<TaskConfig>>>({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      window_days: 14,
      workload_hours: 2,
      workload_minutes: 0,
    },
  })

  useEffect(() => {
    fetchConfigs()
  }, [])

  const fetchConfigs = async () => {
    try {
      const response = await axios.get('/task-config/')
      setConfigs(response.data)
      setChanges({})
    } catch (error) {
      toast.error('Failed to fetch task configurations')
    }
  }

  const handleChange = (configId: number, field: keyof TaskConfig, value: number) => {
    setChanges(prev => ({
      ...prev,
      [configId]: {
        ...prev[configId],
        [field]: value
      }
    }))
  }

  const handleSave = async () => {
    try {
      setLoading(true)
      await axios.put('/task-config/batch', { changes })
      toast.success('Configurations updated successfully')
      await fetchConfigs()
    } catch (error) {
      toast.error('Failed to update configurations')
    } finally {
      setLoading(false)
    }
  }

  const handleAdd = async (values: z.infer<typeof formSchema>) => {
    try {
      const totalMinutes = (values.workload_hours * 60) + values.workload_minutes
      
      await axios.post('/task-config/', {
        task_type: values.task_type,
        frequency: values.frequency,
        window_days: values.window_days,
        workload_minutes: totalMinutes
      })
      
      toast.success('Task configuration added successfully')
      setIsModalOpen(false)
      form.reset()
      fetchConfigs()
    } catch (error) {
      toast.error('Failed to add task configuration')
    }
  }

  const columns = [
    {
      header: "Task Type",
      accessorKey: "task_type",
    },
    {
      header: "Frequency",
      accessorKey: "frequency",
    },
    {
      header: "Window (Days)",
      accessorKey: "window_days",
      cell: ({ row }: { row: TableRow }) => {
        const config = row.original as TaskConfig
        return (
          <Input
            type="number"
            min={1}
            max={364}
            value={changes[config.id]?.window_days ?? config.window_days}
            onChange={(e) => handleChange(config.id, 'window_days', parseInt(e.target.value))}
            className="w-20"
          />
        )
      },
    },
    {
      header: "Workload",
      accessorKey: "workload_minutes",
      cell: ({ row }: { row: TableRow }) => {
        const config = row.original as TaskConfig
        const currentValue = changes[config.id]?.workload_minutes ?? config.workload_minutes
        const hours = Math.floor(currentValue / 60)
        const minutes = currentValue % 60
        
        return (
          <div className="flex items-center gap-2">
            <Input
              type="number"
              min={0}
              max={40}
              value={hours}
              onChange={(e) => {
                const totalMinutes = (parseInt(e.target.value) * 60) + minutes
                handleChange(config.id, 'workload_minutes', totalMinutes)
              }}
              className="w-20"
            />
            <span>hours</span>
            <Input
              type="number"
              min={0}
              max={59}
              value={minutes}
              onChange={(e) => {
                const totalMinutes = (hours * 60) + parseInt(e.target.value)
                handleChange(config.id, 'workload_minutes', totalMinutes)
              }}
              className="w-20"
            />
            <span>min</span>
          </div>
        )
      },
    },
  ]

  return (
    <div className="space-y-6 w-full">
      <h2 className="text-2xl font-semibold">Task Configuration</h2>
      
      <Card className="w-full">
        <CardContent className="p-6">
          <div className="flex justify-end mb-4">
            <Button onClick={() => setIsModalOpen(true)}>
              <Plus className="h-4 w-4 mr-2" />
              Add Configuration
            </Button>
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column, index) => (
                  <TableHead key={index}>{column.header}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {configs.map((config) => (
                <TableRow key={config.id}>
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      {column.cell ? 
                        column.cell({ row: { original: config } }) : 
                        config[column.accessorKey as keyof TaskConfig]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <div className="flex justify-end mt-4">
            <Button
              onClick={handleSave}
              disabled={Object.keys(changes).length === 0 || loading}
            >
              Save Changes
            </Button>
          </div>
        </CardContent>
      </Card>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Task Configuration</DialogTitle>
          </DialogHeader>
          
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleAdd)} className="space-y-4">
              <FormField
                control={form.control}
                name="task_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Task Type</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select task type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {taskTypes.map(type => (
                          <SelectItem key={type} value={type}>{type}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="frequency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Frequency</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select frequency" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {frequencies.map(freq => (
                          <SelectItem key={freq} value={freq}>{freq}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="window_days"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Window (Days)</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        min={1}
                        max={364}
                        {...field}
                        onChange={e => field.onChange(parseInt(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex gap-4">
                <FormField
                  control={form.control}
                  name="workload_hours"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Hours</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min={0}
                          max={40}
                          {...field}
                          onChange={e => field.onChange(parseInt(e.target.value))}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="workload_minutes"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Minutes</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min={0}
                          max={59}
                          {...field}
                          onChange={e => field.onChange(parseInt(e.target.value))}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex justify-end gap-2">
                <Button type="submit">Add Configuration</Button>
                <Button 
                  type="button" 
                  variant="outline" 
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  )
}