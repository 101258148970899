import React, { useState, useEffect } from 'react';
import { Layout, Tabs, DatePicker, Button, Radio, Dropdown } from 'antd';
import { BsArrowRepeat } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from '../api/axiosConfig';
import ErpHeader from '../components/ErpHeader';
import './Digi-Dashboard.css';
import InvoiceDash from './InvoiceDash';
import SalesDash from './SalesDash';
import CustomerDash from './CustomerDash';
import { useMessage } from '../contexts/MessageContext';

// Import logos
import digidashboardLight from '../assets/logos/dashboard_x500_b.png';
import digidashboardDark from '../assets/logos/dashboard_x500_w.png';

const { Content } = Layout;
const { RangePicker } = DatePicker;

function DigiDashboard({ theme, toggleTheme }) {
  const navigate = useNavigate();
  const [dateType, setDateType] = useState('month');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const [activeTab, setActiveTab] = useState('1');
  const [salesData, setSalesData] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);
  const [aiInvoiceAnalysis, setAiInvoiceAnalysis] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const showMessage = useMessage();

  const logo = theme === 'dark' ? digidashboardDark : digidashboardLight;

  const user = JSON.parse(localStorage.getItem('user')) || { name: 'User' };
  const initials = user.name.split(' ').map(n => n[0]).join('').toUpperCase();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const userMenu = {
    items: [
      { key: 'logout', label: 'Logout' },
    ],
    onClick: ({ key }) => {
      if (key === 'logout') {
        handleLogout();
      }
    },
  };

  const fetchAllData = async () => {
    try {
      const [salesResponse, invoiceResponse, customerResponse, aiAnalysisResponse] = await Promise.all([
        axios.get('/dashboard/sales'),
        axios.get('/dashboard/invoices'),
        axios.get('/dashboard/customers'),
        axios.get('/ai/analyze_invoices')
      ]);
      setSalesData(salesResponse.data);
      setInvoiceData(invoiceResponse.data);
      setCustomerData(customerResponse.data);
      setAiInvoiceAnalysis(aiAnalysisResponse.data.analysis);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      showMessage('error', 'Failed to fetch dashboard data');
    }
  };

  const handleRefresh = async () => {
    setIsRefreshing(true);
    try {
      await axios.post('/dashboard/refresh');
      await fetchAllData();
      showMessage('success', 'Data refreshed successfully');
    } catch (error) {
      console.error('Error refreshing data:', error);
      showMessage('error', 'Failed to refresh data');
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  const areDatesEqual = (date1, date2) => {
    if (Array.isArray(date1) && Array.isArray(date2)) {
      return dayjs(date1[0]).isSame(date2[0], 'day') && dayjs(date1[1]).isSame(date2[1], 'day');
    }
    if (date1 && date2 && date1.date && date2.date) {
      return dayjs(date1.date).isSame(dayjs(date2.date), date1.format || 'day');
    }
    return false;
  };

  const getSelectedDateOrRange = () => {
    if (dateType === 'custom') return dateRange.map(date => dayjs(date));
    return {
      date: dayjs(selectedDate),
      format: dateType === 'year' ? 'YYYY' : 'MMMM YYYY'
    };
  };

  const handleDateTypeChange = (e) => {
    setDateType(e.target.value);
    if (e.target.value === 'month') {
      setSelectedDate(dayjs());
    } else if (e.target.value === 'year') {
      setSelectedDate(dayjs().startOf('year'));
    } else {
      setDateRange([dayjs().startOf('month'), dayjs().endOf('month')]);
    }
  };

  const handleDateChange = (date, dateString) => {
    if (dateType === 'custom') {
      setDateRange(date);
    } else {
      setSelectedDate(date);
    }
  };

  const handlePrevious = () => {
    setSelectedDate(selectedDate.subtract(1, dateType));
  };

  const handleNext = () => {
    setSelectedDate(selectedDate.add(1, dateType));
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleMainMenuRedirect = () => {
    navigate('/main-menu');
  };

  const renderDatePicker = () => {
    if (dateType === 'custom') {
      return (
        <RangePicker
          value={dateRange}
          onChange={handleDateChange}
          allowClear={false}
        />
      );
    }

    return (
      <>
        <Button onClick={handlePrevious}>Previous {dateType}</Button>
        <DatePicker
          picker={dateType}
          value={selectedDate}
          onChange={handleDateChange}
          allowClear={false}
        />
        <Button onClick={handleNext}>Next {dateType}</Button>
      </>
    );
  };

  const items = [
    {
      key: '1',
      label: 'Sales',
      children: salesData ? (
        <SalesDash 
          selectedDate={getSelectedDateOrRange()} 
          dateType={dateType} 
          salesData={salesData.datasets && salesData.labels ? salesData : null} 
        />
      ) : (
        <div>Loading sales data...</div>
      ),
    },
    {
      key: '2',
      label: 'Invoices',
      children: invoiceData ? (
        <InvoiceDash 
          selectedDate={getSelectedDateOrRange()} 
          dateType={dateType} 
          invoiceData={invoiceData} 
          aiInvoiceAnalysis={aiInvoiceAnalysis}
        />
      ) : (
        <div>Loading invoice data...</div>
      ),
    },
    {
      key: '3',
      label: 'Customers',
      children: customerData ? (
        <CustomerDash selectedDate={getSelectedDateOrRange()} dateType={dateType} customerData={customerData} />
      ) : (
        <div>Loading customer data...</div>
      ),
    },
  ];

  return (
    <Layout className="dashboard-layout">
      <ErpHeader theme={theme} toggleTheme={toggleTheme} logo={logo} />
      <Content className="dashboard-content">
        <div className="dashboard-controls">
          <Radio.Group value={dateType} onChange={handleDateTypeChange} className="date-type-selector">
            <Radio.Button value="month">Month</Radio.Button>
            <Radio.Button value="year">Year</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
          <div className="date-picker-container">
            {renderDatePicker()}
          </div>
          <Button
            icon={<BsArrowRepeat />}
            onClick={handleRefresh}
            loading={isRefreshing}
            className="refresh-button"
          >
            Refresh Data
          </Button>
        </div>
        <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
      </Content>
    </Layout>
  );
}

export default DigiDashboard;