import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation, useParams, useOutletContext } from 'react-router-dom'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { TaskNotes } from '@/taskboards/modals/TaskNotes'
import { CustomBoardActivity } from '@/custom-boards/modals/CustomBoardActivity'
import { TaskDateEditor } from '@/components/TaskDateEditor'
import CustomTaskStatusSelector from '../components/CustomTaskStatusSelector'
import { useDebounce } from '@/hooks/use-debounce'

// Lucide Icons
import { X } from 'lucide-react'

// Types
import { Board } from '@/types/custom-board'
import { CustomTask, TaskNotesProps, BoardsActivityProps } from '@/types/custom-task'

// Shadcn Components
import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Textarea } from '@/components/ui/textarea'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'

interface OutletContext {
  board: Board
  onTaskUpdate: (task: CustomTask) => void
}

const CustomTaskView = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { boardId, taskId } = useParams()
  const { board, onTaskUpdate } = useOutletContext<OutletContext>()
  const [task, setTask] = useState<CustomTask | null>(null)
  const [loading, setLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('overview')
  const [description, setDescription] = useState('')
  const debouncedDescription = useDebounce(description, 1000)

  useEffect(() => {
    const fetchTask = async () => {
      try {
        const response = await axios.get(`/custom-board/tasks/${taskId}`)
        setTask(response.data)
        setDescription(response.data.description)
      } catch (error) {
        console.error('Error fetching task:', error)
        toast.error('Failed to load task')
        navigate(`/custom-boards/${boardId}`)
      } finally {
        setLoading(false)
      }
    }
    fetchTask()
  }, [taskId, boardId, navigate])

  useEffect(() => {
    const updateDescription = async () => {
      if (!task || description === task.description) return

      try {
        await axios.put(`/custom-board/tasks/${task.id}`, {
          description: debouncedDescription
        })
      } catch (error) {
        console.error('Error saving description:', error)
        toast.error('Failed to save description')
      }
    }

    updateDescription()
  }, [debouncedDescription, task])

  const handleClose = () => {
    navigate(`/custom-boards/${boardId}`)
  }

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription)
    setTask(prev => prev ? ({
      ...prev,
      description: newDescription
    }) : null)
  }

  if (loading || !task || !board) {
    return null
  }

  const items = [
    {
      key: 'overview',
      label: 'Overview',
    },
    {
      key: 'notes',
      label: 'Notes & Comments',
      children: <TaskNotes 
        task={task as unknown as CustomTask}
        visible={true}
        onClose={() => {}}
        taskType="custom"
        users={[]}
      />
    },
    {
      key: 'activity',
      label: 'Activity Log',
      children: <CustomBoardActivity 
        open={true}
        onClose={() => {}}
        task={task}
        users={[]}
      />
    }
  ]

  if (task.deal_id) {
    items.splice(1, 0, {
      key: 'communication',
      label: 'Communication',
      children: <div>Communication content</div>
    })
  }

  return (
    <Dialog open={true} onOpenChange={(open) => !open && handleClose()}>
      <DialogContent className="w-11/12 max-w-4xl">
        <DialogHeader className="bg-primary text-white rounded-t-lg -m-6 p-4 mb-0">
          <DialogTitle>{task.title}</DialogTitle>
        </DialogHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="mt-4">
          <TabsList>
            {items.map(item => (
              <TabsTrigger key={item.key} value={item.key}>
                {item.label}
              </TabsTrigger>
            ))}
          </TabsList>
          <TabsContent value="overview">
            <div className="p-4">
              <h3 className="text-lg font-semibold">Description</h3>
              <Textarea
                value={description}
                onChange={(e) => handleDescriptionChange(e.target.value)}
                placeholder="Add a description..."
                className="mt-2"
              />
            </div>
          </TabsContent>
          {items.map(item => (
            item.key !== 'overview' && (
              <TabsContent key={item.key} value={item.key}>
                {item.children}
              </TabsContent>
            )
          ))}
        </Tabs>
      </DialogContent>
    </Dialog>
  )
}

export default CustomTaskView