import { Bar, BarChart, XAxis, YAxis } from "recharts"
import { LucideIcon } from "lucide-react"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui"

interface BarChartProps {
  data: Array<{
    browser: string
    visitors: number
    fill: string
  }>
  config: ChartConfig
  title?: string
  description?: string
  footerContent?: React.ReactNode
  trendIcon?: LucideIcon
  trendText?: string
  trendDescription?: string
  className?: string
}

function formatTickLabel(value: any, config: ChartConfig): string {
  const label = config[value as keyof typeof config]?.label
  return typeof label === 'string' ? label : String(label)
}

export function BarChartHorizontalMix({
  data,
  config,
  title,
  description,
  footerContent,
  trendIcon: TrendIcon,
  trendText,
  trendDescription,
  className
}: BarChartProps) {
  return (
    <Card className={className}>
      {(title || description) && (
        <CardHeader>
          {title && <CardTitle>{title}</CardTitle>}
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
      )}
      <CardContent className="h-[calc(100%-4rem)]">
        <ChartContainer config={config}>
          <BarChart
            accessibilityLayer
            data={data}
            layout="vertical"
            margin={{
              left: 80,
              right: 20,
              top: 10,
              bottom: 20
            }}
          >
            <YAxis
              dataKey="browser"
              type="category"
              tickLine={false}
              tickMargin={8}
              axisLine={false}
              tickFormatter={(value) => formatTickLabel(value, config)}
              width={75}
            />
            <XAxis dataKey="visitors" type="number" hide />
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="visitors" layout="vertical" radius={5} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      {(trendText || trendDescription || footerContent) && (
        <CardFooter className="flex-col items-start gap-2 text-sm">
          {trendText && (
            <div className="flex gap-2 font-medium leading-none">
              {trendText} {TrendIcon && <TrendIcon className="h-4 w-4" />}
            </div>
          )}
          {trendDescription && (
            <div className="leading-none text-muted-foreground">
              {trendDescription}
            </div>
          )}
          {footerContent}
        </CardFooter>
      )}
    </Card>
  )
}
