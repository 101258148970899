import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DndContext, DragEndEvent, DragStartEvent, closestCenter, useDroppable, useDraggable, DragOverlay, DragOverEvent, UniqueIdentifier } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import { cn } from "@/lib/utils"
import { CSS } from "@dnd-kit/utilities"
import { createPortal } from 'react-dom'
import { useUsers } from '@/contexts/UserContext'

// Lucide Icons
import { 
  Eye, 
  MessageSquare, 
  MoreHorizontal, 
  Clock,
  Calendar,
  Briefcase,
  Pencil
} from 'lucide-react'

// Shadcn Components
import { Card } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

// Custom Components
import { CustomSortableTaskCard } from '../components/CustomSortableTaskCard'
import { TaskDateEditor } from '@/components/TaskDateEditor'
import { AvatarGroup } from '@/components/ui/avatars/avatar-group'
import { TaskNotes } from '@/taskboards/modals/TaskNotes'
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { DealDrawer } from '@/drawers/DealDrawer'
import EditCustomTaskModal from '../modals/EditCustomTaskModal'
import { KanbanCard, KanbanCardHeader, KanbanCardContent, KANBAN_COLUMN_WIDTH } from "@/components/ui/specialized/kanban-card"

// Types
import { CustomTask } from '@/types/custom-task'
import { Board, BoardStatusOption } from '@/types/custom-board'
import { StoredUser } from '@/types/user'
import { FrontendTaskType } from '@/types/task'
import { 
  useSensors, 
  useSensor, 
  PointerSensor, 
  TouchSensor 
} from '@dnd-kit/core'

interface CustomBoardKanbanProps {
  tasks: CustomTask[]
  statusOptions: BoardStatusOption[]
  onTaskUpdate: (taskId: number, updatedData: Partial<CustomTask> | null) => void
  kanbanSource: string
  board: Board
}

interface DroppableColumnProps {
  id: string;
  children: React.ReactNode;
  activeTask?: CustomTask | null;
  renderTaskContent: (task: CustomTask) => React.ReactNode;
}

function DroppableColumn({ id, children, activeTask, renderTaskContent }: DroppableColumnProps) {
  const { isOver, setNodeRef } = useDroppable({ 
    id,
  });

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "min-h-[100px] p-2 rounded-md transition-colors",
        isOver && "bg-secondary/20"
      )}
    >
      <div className="space-y-3">
        {children}
      </div>

      {isOver && activeTask && (
        <div className="mt-3 opacity-50 pointer-events-none">
          {renderTaskContent(activeTask)}
        </div>
      )}
    </div>
  );
}

export function CustomBoardKanban({ 
  tasks = [], 
  statusOptions = [], 
  onTaskUpdate,
  kanbanSource = 'progress',
  board
}: CustomBoardKanbanProps) {
  const navigate = useNavigate();
  const { boardId } = useParams();
  const { users } = useUsers();
  
  // State
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeTask, setActiveTask] = useState<CustomTask | null>(null);
  const [selectedTask, setSelectedTask] = useState<CustomTask | null>(null);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<CustomTask | null>(null);
  const [isNotesModalVisible, setIsNotesModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null);
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null);

  // Sensors for drag and drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  const getTasksForStatus = (statusValue: string | undefined) => {
    if (!statusValue) return []
    return tasks.filter(task => 
      kanbanSource === 'progress' 
        ? task.progress_status === statusValue
        : task.board_status === statusValue
    )
  }

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    console.log('Drag start:', active);
    const draggedTask = tasks.find(task => task.id.toString() === active.id.toString());
    
    if (draggedTask) {
      setActiveId(active.id);
      setActiveTask(draggedTask);
    }
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) {
      setOverId(null);
      return;
    }
    setOverId(over.id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    console.log('Drag end:', event);
    const { active, over } = event;
    
    // Reset states
    setActiveId(null);
    setActiveTask(null);
    setOverId(null);
    
    if (!over) return;

    const taskId = Number(active.id);
    const newStatus = over.id.toString();
    
    const task = tasks.find(t => t.id === taskId);
    const statusOption = statusOptions.find(option => option.value === newStatus);
    
    if (!task || !statusOption) return;

    const updateField = kanbanSource === 'progress' ? 'progress_status' : 'board_status';
    const oldStatus = task[updateField];

    if (oldStatus === newStatus) return;

    try {
      // Optimistic update
      onTaskUpdate(taskId, {
        [updateField]: newStatus
      });

      await axios.put(`/custom-board/tasks/${taskId}`, {
        [updateField]: newStatus
      });
    } catch (error) {
      console.error('Error updating task status:', error);
      onTaskUpdate(taskId, {
        [updateField]: oldStatus
      });
      toast.error('Failed to update task status');
    }
  };

  const getColumnColor = (statusValue: string | undefined) => {
    if (!statusValue) return 'gray'
    const option = statusOptions.find(opt => opt.value === statusValue)
    return option?.color || 'gray'
  }

  const handleAssigneeUpdate = (assignees: string[]) => {
    if (selectedTaskForAssignee) {
      onTaskUpdate(selectedTaskForAssignee.id, { assigned_users: assignees });
    }
  };

  const getTaskClasses = (task: CustomTask) => {
    const classes = ['custom-board-task'];
    
    if (task.deal_id) {
      classes.push('has-deal');
    }
    if (task.work_date && task.due_date) {
      classes.push('has-both-dates');
    }
    if (task.work_date || task.due_date) {
      classes.push('has-dates');
    }

    // Single class for missing required data
    if (
      (board?.board_config?.requires_deal && !task.deal_id) ||
      (board?.board_config?.requires_work_date && !task.work_date) ||
      (board?.board_config?.requires_due_date && !task.due_date)
    ) {
      classes.push('missing-required-data');
    }
    
    return classes.join(' ');
  };

  const handleOpenTask = (taskId: number) => {
    const task = tasks.find(t => t.id === taskId);
    
    navigate(`${taskId}`, {
      state: {
        task,
        board,
        statusOptions: board.board_config?.kanban_source === 'progress'
          ? board.board_config?.progress_status_options
          : board.board_config?.board_status_options
      }
    });
  };

  const handleDateChange = async (taskId: number, dateType: 'work_date' | 'due_date', value: dayjs.Dayjs | null) => {
    try {
      const response = await axios.put(`/custom-board/tasks/${taskId}/dates`, {
        [dateType]: value ? value.format('YYYY-MM-DD') : null
      });

      if (response.data) {
        onTaskUpdate(taskId, {
          [dateType]: response.data[dateType]
        });
      }
    } catch (error) {
      console.error('Error updating date:', error);
      toast.error('Failed to update date');
    }
  };

  const getDateStatus = (date: string | undefined) => {
    if (!date) return '';
    
    const today = dayjs().startOf('day');
    const dateValue = dayjs(date);
    
    if (dateValue.isSame(today, 'day')) {
      return 'due-today';
    } else if (dateValue.isBefore(today)) {
      return 'overdue';
    }
    return '';
  };

  const handleDealClick = (e: React.MouseEvent<HTMLElement>, dealId: number) => {
    e.stopPropagation();
    setSelectedDealId(dealId);
    setDealDrawerVisible(true);
  };

  const handleTaskDelete = (taskId: number) => {
    const updatedTasks = tasks.filter(t => t.id !== taskId);
    onTaskUpdate(taskId, null);
  };

  const kanbanStyles = {
    board: "flex gap-4 overflow-x-auto h-[calc(100vh-100px)] p-6",
    columnContent: "flex-1 overflow-y-auto min-h-0 pt-2",
  };

  const renderTaskContent = (task: CustomTask) => {
    return (
      <Card 
        className="w-full p-4 hover:shadow-md transition-shadow relative group cursor-grab active:cursor-grabbing"
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <div className="space-y-4">
          {/* Header Section - Centered Content */}
          <div className="flex flex-col items-center text-center">
            {task.deal_name && (
              <Badge 
                variant="secondary"
                className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
                onClick={(e) => {
                  e.stopPropagation();
                  task.deal_id && handleDealClick(e, task.deal_id);
                }}
              >
                <Briefcase className="w-3 h-3 flex-shrink-0 mr-1" />
                <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {task.deal_name}
                </span>
              </Badge>
            )}
            <p className="text-sm text-primary font-medium mt-2 line-clamp-2">
              {task.title}
            </p>
          </div>

          {/* Dates Section */}
          <div className="grid grid-cols-2 gap-2">
            <TaskDateEditor
              date={task.work_date || null}
              dateType="work"
              taskId={task.id}
              taskType="custom"
              onSuccess={(updatedTask) => onTaskUpdate(task.id, updatedTask)}
              variant="compact"
              className="w-full"
              icon={<Clock className="h-4 w-4 text-muted-foreground" />}
            />
            <TaskDateEditor
              date={task.due_date || null}
              dateType="due"
              taskId={task.id}
              taskType="custom"
              onSuccess={(updatedTask) => onTaskUpdate(task.id, updatedTask)}
              variant="compact"
              className="w-full"
              icon={<Calendar className="h-4 w-4 text-muted-foreground" />}
            />
          </div>

          {/* Footer Section */}
          <div className="flex justify-between items-center">
            {/* Assignees on the left */}
            {(task.assigned_users && task.assigned_users.length > 0) && (
              <div 
                className="flex items-center gap-2 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTaskForAssignee(task);
                  setAssigneeModalVisible(true);
                }}
              >
                <AvatarGroup
                  users={users.filter(u => task.assigned_users?.includes(u.user_id.toString()))}
                  max={3}
                  className="hover:opacity-80 transition-opacity"
                />
              </div>
            )}
            
            {/* Task view button centered */}
            <Button
              variant="ghost"
              size="icon"
              className="opacity-0 group-hover:opacity-100 transition-opacity mx-auto"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenTask(task.id);
              }}
            >
              <Eye className="h-4 w-4" />
            </Button>

            {/* Actions on the right */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem onClick={() => {
                  setSelectedTask(task);
                  setIsNotesModalVisible(true);
                }}>
                  <MessageSquare className="mr-2 h-4 w-4" />
                  Notes
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => {
                  setSelectedTask(task);
                  setIsEditModalVisible(true);
                }}>
                  <Pencil className="mr-2 h-4 w-4" />
                  Edit
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </Card>
    )
  }

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
    >
      <div className={kanbanStyles.board}>
        {statusOptions.map((statusOption) => {
          const value = statusOption.value || '';
          const tasksInStatus = getTasksForStatus(value);
          
          return (
            <KanbanCard key={value} className="min-w-[320px]">
              <KanbanCardHeader 
                className="sticky top-0 z-10"
                color={getColumnColor(value)}
              >
                <div className="flex items-center gap-2">
                  {statusOption.label}
                  <Badge variant="secondary" className="text-xs">
                    {tasksInStatus.length}
                  </Badge>
                </div>
              </KanbanCardHeader>
              <DroppableColumn 
                id={value} 
                activeTask={activeTask}
                renderTaskContent={renderTaskContent}
              >
                <SortableContext 
                  items={tasksInStatus.map(task => task.id.toString())}
                  strategy={verticalListSortingStrategy}
                >
                  <div className="space-y-3">
                    {tasksInStatus.map((task) => (
                      <CustomSortableTaskCard
                        key={task.id}
                        task={task}
                        renderContent={renderTaskContent}
                        onTaskClick={() => handleOpenTask(task.id)}
                      />
                    ))}
                  </div>
                </SortableContext>
              </DroppableColumn>
            </KanbanCard>
          );
        })}
      </div>
      {createPortal(
        <DragOverlay 
          dropAnimation={{
            duration: 200,
            easing: 'cubic-bezier(0.18, 0.67, 0.6, 1.22)',
          }}
          modifiers={[]}
          zIndex={50}
        >
          {activeTask ? (
            <div style={{ 
              transform: 'rotate(2deg)',
              cursor: 'grabbing',
            }}>
              <CustomSortableTaskCard
                task={activeTask}
                renderContent={renderTaskContent}
                onTaskClick={() => {}}
                isDragOverlay={true}
              />
            </div>
          ) : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );
}

export default CustomBoardKanban