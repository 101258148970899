import React from 'react';
import { Link } from 'react-router-dom';
import { Command } from 'lucide-react';
import {
  Sidebar,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  SidebarRail,
} from '@/components/ui/sidebar';
import { NavMain } from './nav-main';
import { NavUser } from './nav-user';
import { cn } from '@/lib/utils';
import { useSidebar } from "@/contexts/sidebar-contexts";
import { useTheme } from "@/contexts/ThemeContext";

interface AppSidebarProps {
  user: any;
  onUpdateUsers: (allUsers: any[], onlineUsers: any[]) => void;
}

export function AppSidebar({ user, onUpdateUsers }: AppSidebarProps) {
  const { collapsed, setCollapsed } = useSidebar();
  const { theme } = useTheme();

  const logo = theme === 'dark' 
    ? 'https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/logos/tuesday_x200_w.png'
    : 'https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/logos/tuesday_x200_b.png';

  return (
    <Sidebar 
      variant="inset"
      collapsible="icon"
      className={cn(
        "group/sidebar z-50 flex h-screen sticky top-0 flex-col overflow-hidden border-r border-border bg-background transition-all duration-300 ease-in-out",
        collapsed ? "w-[70px]" : "w-[230px]"
      )}
      data-collapsed={collapsed}
    >
      <div className="flex h-full flex-col">
        <SidebarHeader className="border-b border-border px-3 py-3">
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton size="lg" asChild>
                <Link to="/main-menu" className="flex items-center gap-2">
                  <div className="flex aspect-square h-8 w-8 items-center justify-center rounded-lg bg-primary">
                    <Command className="h-4 w-4 text-primary-foreground" />
                  </div>
                  {!collapsed && <img src={logo} alt="Tuesday" className="h-8" />}
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        
        <div className="flex-1 overflow-y-auto">
          <NavMain collapsed={collapsed} />
        </div>

        <div className="mt-auto border-t border-border mb-4">
          <NavUser 
            user={user} 
            onUpdateUsers={onUpdateUsers}
            collapsed={collapsed}
          />
        </div>
      </div>
      
      <SidebarRail 
        className="cursor-pointer" 
        onClick={() => setCollapsed(!collapsed)} 
      />
    </Sidebar>
  );
}
