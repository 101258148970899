import { Button } from "@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { Share2, Users } from "lucide-react"

export default function FlowNav() {
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <div className="w-full space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold tracking-tight">Flow Management</h2>
          <p className="text-muted-foreground">
            Manage automation and onboarding flows for your organization
          </p>
        </div>
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <Card className="hover:shadow-lg transition-shadow">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Share2 className="h-5 w-5" />
              Automation Flows
            </CardTitle>
            <CardDescription>
              Create and manage automation flows for various business processes
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Button 
              variant="default" 
              onClick={() => openInNewTab('/flows/automation')}
              className="w-full"
            >
              Open Automation Flows
            </Button>
          </CardContent>
        </Card>

        <Card className="hover:shadow-lg transition-shadow">
          <CardHeader>
            <CardTitle className="flex items-center gap-2">
              <Users className="h-5 w-5" />
              Onboarding Flows
            </CardTitle>
            <CardDescription>
              Manage customer onboarding processes and workflows
            </CardDescription>
          </CardHeader>
          <CardContent>
            <Button 
              variant="default" 
              onClick={() => openInNewTab('/flows/onboarding')}
              className="w-full"
            >
              Open Onboarding Flows
            </Button>
          </CardContent>
        </Card>
      </div>
    </div>
  )
}