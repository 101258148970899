import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useFieldArray } from 'react-hook-form'
import * as z from 'zod'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { Plus, Minus, ArrowUp, ArrowDown, Trash2 } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs'
import { Separator } from '@/components/ui/separator'
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { ANT_COLORS } from '@/configs/colors'

import { StoredUser } from '@/types/user'
import { Board } from '@/types/custom-board'
import { useUsers } from '@/contexts/UserContext'

interface StatusOption {
  label: string
  color: string
  value?: string
  order?: number
}

interface BoardSettingsModalProps {
  open: boolean
  onClose: () => void
  board: Board
  onBoardUpdate: (board: Board) => void
}

const formSchema = z.object({
  name: z.string().min(1, 'Please enter a board name'),
  description: z.string().optional(),
  is_public: z.boolean(),
  board_config: z.object({
    requires_deal: z.boolean(),
    requires_work_date: z.boolean(),
    requires_due_date: z.boolean(),
    show_in_my_work: z.boolean(),
    enable_time_tracking: z.boolean(),
    progress_status_options: z.array(z.object({
      label: z.string(),
      color: z.string(),
      value: z.string().optional(),
      order: z.number().optional()
    })),
    board_status_options: z.array(z.object({
      label: z.string(),
      color: z.string(),
      value: z.string().optional(),
      order: z.number().optional()
    })).optional()
  })
})

const moveItem = (array: any[], index: number, direction: 'up' | 'down') => {
  if (
    (direction === 'up' && index === 0) || 
    (direction === 'down' && index === array.length - 1)
  ) {
    return array
  }

  const newArray = [...array]
  const newIndex = direction === 'up' ? index - 1 : index + 1
  ;[newArray[index], newArray[newIndex]] = [newArray[newIndex], newArray[index]]
  return newArray
}

const BoardSettingsModal = ({
  open,
  onClose,
  board,
  onBoardUpdate
}: BoardSettingsModalProps) => {
  const navigate = useNavigate()
  const [isPublic, setIsPublic] = useState(board?.is_public || false)
  const [kanbanSource, setKanbanSource] = useState(board?.board_config?.kanban_source || 'progress')
  const [showAssigneeModal, setShowAssigneeModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>(board?.user_access || [])
  const [useBoardStatus, setUseBoardStatus] = useState(
    Boolean(board?.board_config?.board_status_options?.length)
  )
  const { users } = useUsers()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: board?.name,
      description: board?.description,
      is_public: board?.is_public,
      board_config: {
        requires_deal: board?.board_config?.requires_deal || false,
        requires_work_date: board?.board_config?.requires_work_date || false,
        requires_due_date: board?.board_config?.requires_due_date || false,
        show_in_my_work: board?.board_config?.show_in_my_work ?? true,
        enable_time_tracking: board?.board_config?.enable_time_tracking ?? true,
        progress_status_options: board?.board_config?.progress_status_options || [],
        board_status_options: board?.board_config?.board_status_options || []
      }
    }
  })

  const progressStatusArray = useFieldArray({
    control: form.control,
    name: "board_config.progress_status_options"
  })

  const boardStatusArray = useFieldArray({
    control: form.control,
    name: "board_config.board_status_options"
  })

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (!values.is_public && selectedUsers.length === 0) {
        toast.error('Private boards must have at least one user with access')
        return
      }

      const boardData: Board = {
        id: board.id,
        name: values.name,
        description: values.description,
        is_public: isPublic,
        user_access: selectedUsers,
        board_config: {
          ...values.board_config,
          kanban_source: kanbanSource,
          progress_status_options: values.board_config.progress_status_options.map((status, index) => ({
            ...status,
            value: status.label.toLowerCase().replace(/\s+/g, '_'),
            order: index
          })),
          board_status_options: useBoardStatus 
            ? values.board_config.board_status_options?.map((status, index) => ({
                ...status,
                value: status.label.toLowerCase().replace(/\s+/g, '_'),
                order: index
              }))
            : []
        }
      }

      const response = await axios.put(`/custom-board/boards/${board.id}`, boardData)
      
      if (response.status === 200) {
        toast.success('Board settings updated successfully')
        onBoardUpdate(boardData)
        onClose()
      }
    } catch (error) {
      console.error('Error updating board:', error)
      toast.error('Failed to update board settings')
    }
  }

  const handleDelete = async () => {
    try {
      const response = await axios.delete(`/custom-board/boards/${board.id}`)
      
      if (response.status === 200) {
        toast.success('Board deleted successfully')
        onBoardUpdate(board)
        onClose()
        navigate('/boards')
      }
    } catch (error) {
      console.error('Error deleting board:', error)
      toast.error('Failed to delete board')
    }
  }

  const renderStatusOptionsList = (type: 'progress' | 'board') => {
    const { fields, append, remove } = type === 'progress' ? progressStatusArray : boardStatusArray
    const fieldName = `board_config.${type === 'progress' ? 'progress_status_options' : 'board_status_options'}` as const;

    return (
      <FormField
        control={form.control}
        name={fieldName}
        render={({ field }) => (
          <div className="space-y-4">
            {(field.value as StatusOption[] || []).map((status, index) => (
              <div key={index} className="flex items-center space-x-4 p-4 bg-muted rounded-md">
                <FormItem>
                  <FormLabel>{index === 0 ? `${type === 'progress' ? 'Progress' : 'Board'} Status Options` : ''}</FormLabel>
                  <FormControl>
                    <Input
                      value={status.label}
                      onChange={(e) => {
                        const newStatus = [...(field.value as StatusOption[])]
                        newStatus[index].label = e.target.value
                        field.onChange(newStatus)
                      }}
                      placeholder="Label (e.g., Not Started)"
                      className="w-1/2"
                    />
                  </FormControl>
                </FormItem>
                <FormItem>
                  <FormControl>
                    <Select
                      value={status.color}
                      onValueChange={(value) => {
                        const newStatus = [...(field.value as StatusOption[])]
                        newStatus[index].color = value
                        field.onChange(newStatus)
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select color" />
                      </SelectTrigger>
                      <SelectContent>
                        {ANT_COLORS.map((color) => (
                          <SelectItem key={color.value} value={color.value}>
                            <div
                              className="flex items-center px-2 rounded"
                              style={{
                                backgroundColor: color.value,
                                color: ['#ffec3d', '#fff566', '#ffd666', '#fff1b8'].includes(color.value) ? '#000000' : '#ffffff'
                              }}
                            >
                              {color.name}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </FormControl>
                </FormItem>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    const newStatus = (field.value as StatusOption[]).filter((_, i) => i !== index)
                    field.onChange(newStatus)
                  }}
                >
                  <Trash2 className="h-4 w-4 text-destructive" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    const newStatus = moveItem(field.value as StatusOption[], index, 'up')
                    field.onChange(newStatus)
                  }}
                  disabled={index === 0}
                >
                  <ArrowUp className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => {
                    const newStatus = moveItem(field.value as StatusOption[], index, 'down')
                    field.onChange(newStatus)
                  }}
                  disabled={index === (field.value as StatusOption[]).length - 1}
                >
                  <ArrowDown className="h-4 w-4" />
                </Button>
              </div>
            ))}
            <Button
              variant="outline"
              onClick={() => field.onChange([...(field.value as StatusOption[] || []), { label: '', color: '' }])}
              className="w-full"
            >
              <Plus className="mr-2 h-4 w-4" />
              Add Status
            </Button>
          </div>
        )}
      />
    )
  }

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Edit Board Settings</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
            <Tabs defaultValue="general" className="w-full">
              <TabsList>
                <TabsTrigger value="general">General</TabsTrigger>
                <TabsTrigger value="status">Status Options</TabsTrigger>
              </TabsList>

              <TabsContent value="general" className="space-y-4">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Board Name</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter board name" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Description</FormLabel>
                      <FormControl>
                        <Input {...field} placeholder="Enter board description" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="is_public"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Public Board</FormLabel>
                      <FormControl>
                        <Switch
                          checked={field.value}
                          onCheckedChange={(checked) => {
                            field.onChange(checked)
                            setIsPublic(checked)
                            if (!checked && board) {
                              setSelectedUsers([board.user_access[0]])
                            } else {
                              setSelectedUsers([])
                            }
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {/* Additional form fields for general settings */}
              </TabsContent>

              <TabsContent value="status" className="space-y-6">
                <div className="p-6 bg-muted rounded-lg space-y-4">
                  <h3 className="text-lg font-semibold">
                    Progress Status Options (Required)
                  </h3>
                  {renderStatusOptionsList('progress')}
                </div>

                <Separator />

                <div className="p-6 bg-muted rounded-lg space-y-4">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-semibold">
                      Additional Board Status
                    </h3>
                    <div className="flex items-center gap-4">
                      <Switch
                        checked={useBoardStatus}
                        onCheckedChange={(checked) => {
                          setUseBoardStatus(checked)
                          if (checked) {
                            setKanbanSource('board')
                          } else {
                            setKanbanSource('progress')
                            form.setValue('board_config.board_status_options', [])
                          }
                        }}
                      />
                      {useBoardStatus && (
                        <div className="flex gap-2">
                          <Button
                            variant={kanbanSource === 'progress' ? 'default' : 'outline'}
                            onClick={() => setKanbanSource('progress')}
                            size="sm"
                          >
                            Progress Status
                          </Button>
                          <Button
                            variant={kanbanSource === 'board' ? 'default' : 'outline'}
                            onClick={() => setKanbanSource('board')}
                            size="sm"
                          >
                            Board Status
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                  {useBoardStatus && renderStatusOptionsList('board')}
                </div>
              </TabsContent>
            </Tabs>

            <DialogFooter>
              <Button variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save Changes</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={(users) => {
          setSelectedUsers(users)
          setShowAssigneeModal(false)
        }}
        initialAssignees={selectedUsers}
      />
    </Dialog>
  )
}

export default BoardSettingsModal