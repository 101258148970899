import * as React from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"
import { DayPicker, DayPickerSingleProps } from "react-day-picker"
import { cn } from "@/lib/utils"
import { buttonVariants } from "@/components/ui/button"
import dayjs from "dayjs"

export interface CapacityData {
  default_daily_capacity: number;
  default_work_days: {
    [key: string]: boolean;
  };
  daily_overrides: {
    [key: string]: number;
  };
}

interface CapacityCalendarProps extends Omit<DayPickerSingleProps, 'mode' | 'selected' | 'onSelect'> {
  capacityData?: CapacityData;
  selected?: Date;
  onSelect?: (date: Date | undefined) => void;
}

const CapacityCalendar = ({
  className,
  classNames,
  showOutsideDays = true,
  capacityData,
  selected,
  onSelect,
  ...props
}: CapacityCalendarProps) => {
  // Function to format capacity display
  const formatCapacity = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  const DayContent = ({ date, ...contentProps }: { date: Date }) => {
    if (!capacityData) return <div className="text-sm">{date.getDate()}</div>;

    const dateStr = dayjs(date).format('YYYY-MM-DD');
    const dayOfWeek = dayjs(date).format('dddd').toLowerCase();
    const override = capacityData.daily_overrides?.[dateStr];
    const isWorkDay = capacityData.default_work_days?.[dayOfWeek];
    const defaultCapacity = capacityData.default_daily_capacity;

    let capacity = 0;
    if (override !== undefined) {
      capacity = override;
    } else if (isWorkDay) {
      capacity = defaultCapacity;
    }

    let capacityColor = "text-primary";
    if (capacity > defaultCapacity) {
      capacityColor = "text-destructive font-medium";
    } else if (capacity === defaultCapacity && capacity > 0) {
      capacityColor = "text-blue-500 dark:text-blue-400";
    } else if (capacity < defaultCapacity && capacity > 0) {
      capacityColor = "text-green-500 dark:text-green-400";
    }

    const isSelected = selected && dayjs(selected).format('YYYY-MM-DD') === dateStr;

    return (
      <div 
        className={cn(
          "w-full h-full flex flex-col items-center cursor-pointer transition-colors",
          "hover:bg-muted/50",
          isSelected && "bg-accent"
        )}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSelect?.(date);
        }}
      >
        <div className="text-sm">{date.getDate()}</div>
        {capacity > 0 && (
          <div className={cn("text-xs mt-1", capacityColor)}>
            {formatCapacity(capacity)}
          </div>
        )}
      </div>
    );
  };

  return (
    <DayPicker
      mode="single"
      selected={selected}
      onSelect={onSelect}
      showOutsideDays={showOutsideDays}
      className={cn("w-full p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 w-full",
        month: "space-y-4 w-full",
        caption: "flex justify-center pt-1 relative items-center mb-4",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center absolute w-full justify-between px-1",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "",
        nav_button_next: "",
        table: "w-full border-collapse space-y-1",
        head_row: "flex w-full",
        head_cell: "text-muted-foreground rounded-md w-full font-normal text-[0.8rem] h-9 flex items-center justify-center",
        row: "flex w-full mt-2",
        cell: "h-12 w-full relative p-1 border-[1px] border-border focus-within:relative focus-within:z-20",
        day: cn(
          "h-full w-full p-0 font-normal aria-selected:opacity-100"
        ),
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
        Day: DayContent
      }}
      {...props}
    />
  );
};

CapacityCalendar.displayName = "CapacityCalendar";

export { CapacityCalendar };