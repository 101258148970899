import React from 'react';
import { Calendar } from "@/components/ui/calendar";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";
import { Calendar as CalendarIcon } from "lucide-react";
import { Task } from "@/types/task";
import dayjs from 'dayjs';

interface TaskCalendarProps {
  tasks: Task[];
}

export function TaskCalendar({ tasks }: TaskCalendarProps) {
  const [date, setDate] = React.useState<Date | undefined>(new Date());

  const getDayTasks = (day: Date) => {
    return tasks.filter(task => 
      dayjs(task.next_work_date).isSame(dayjs(day), 'day')
    );
  };

  return (
    <Card className="p-4">
      <div className="grid gap-4 md:grid-cols-[300px_1fr]">
        <div>
          <Calendar
            mode="single"
            selected={date}
            onSelect={setDate}
            className="rounded-md border"
            modifiers={{
              hasTasks: (date) => getDayTasks(date).length > 0,
            }}
            modifiersClassNames={{
              hasTasks: "bg-primary/10 font-bold text-primary",
            }}
          />
        </div>
        <div>
          {date && (
            <div className="space-y-4">
              <div className="flex items-center gap-2">
                <CalendarIcon className="h-5 w-5 text-muted-foreground" />
                <h2 className="font-semibold">
                  Tasks for {dayjs(date).format('MMMM D, YYYY')}
                </h2>
              </div>
              <ScrollArea className="h-[calc(100vh-300px)]">
                <div className="space-y-2 pr-4">
                  {getDayTasks(date).length > 0 ? (
                    getDayTasks(date).map((task) => (
                      <div
                        key={task.id}
                        className="flex items-center gap-2 rounded-lg border p-3 text-sm"
                      >
                        <Badge variant="outline" className="whitespace-nowrap">
                          {dayjs(task.next_work_date).format('HH:mm')}
                        </Badge>
                        <span className="line-clamp-1">{task.description}</span>
                      </div>
                    ))
                  ) : (
                    <div className="flex h-[100px] items-center justify-center text-sm text-muted-foreground">
                      No tasks for this day
                    </div>
                  )}
                </div>
              </ScrollArea>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
}