import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route, useParams } from 'react-router-dom';
import { Search, Filter, History, Calendar as CalendarIcon, MoreHorizontal, Eye, Link2, Inbox, Trash2, Star, X } from 'lucide-react';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { Table, ColumnDef } from '@/components/ui/specialized/mod-table';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { toast } from 'sonner';
import { Loading } from "@/components/ui/loading";
import { Badge } from "@/components/ui/badge";

import { TaskSchedule } from './modals/TaskSchedule';
import { BoardActivity } from './modals/BoardActivity';
import { TaskKanban } from './views/TaskKanban';
import { TaskCalendar } from './views/TaskCalendar';
import { useRealTimeUpdates } from '../hooks/useRealTimeUpdates';
import { Task, TaskData, FrontendTaskType } from '@/types/task';
import { TaskBoardProps } from '@/types/taskboard';
import { StoredUser as User } from '@/types/user';
import { cn } from "@/lib/utils";
import TaskView from './views/TaskView';
import TaskTable from './views/TaskTable';
import { BoardConfigKey } from '@/types/task';
import { DealConfigItem, CardConfig } from '@/types/taskboard';
import { TaskFilter, TaskFilters } from './components/TaskFilter'

dayjs.extend(utc);
dayjs.extend(timezone);

const ENABLE_REALTIME = false; // Toggle this to enable/disable real-time updates

interface TaskActionsProps {
  task: Task;
}

const TaskBoards: React.FC<TaskBoardProps> = ({
  title,
  fetchUrl,
  columns,
  boardStatusOptions,
  cardConfig,
  dealDataConfig,
  dealSoftwareConfig,
  taskType,
  defaultView,
  users: externalUsers,
  tasks: externalTasks,
  setTasks: setExternalTasks,
  dealName = 'Default Deal Name',
}) => {
  const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState<'table' | 'kanban' | 'calendar'>(defaultView || 'table');
  const [searchText, setSearchText] = useState('');
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);
  const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
  const navigate = useNavigate();
  const { taskId } = useParams();
  const [users, setUsers] = useState<User[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
  const [activeFilters, setActiveFilters] = useState<TaskFilters>({
    status: [],
    assignedUsers: [],
    recurringType: [],
  })

  useEffect(() => {
    const storedUsersData = localStorage.getItem('usersData');
    if (storedUsersData) {
      const usersObject = JSON.parse(storedUsersData);
      const usersArray = Object.values(usersObject) as User[];
      setUsers(usersArray);
    }
  }, []);

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get(fetchUrl);
      setExternalTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!externalTasks.length) {
      fetchTasks();
    }
  }, [fetchUrl]);

  useEffect(() => {
    filterTasks()
  }, [externalTasks, searchText, activeFilters])

  const filterTasks = () => {
    let filtered = externalTasks.filter((task: Task) => {
      // Text search
      const matchesSearch = searchText === '' || Object.values(task).some(value => 
        String(value).toLowerCase().includes(searchText.toLowerCase())
      )
      
      // Status filter
      const matchesStatus = activeFilters.status.length === 0 || 
        (task.status && activeFilters.status.includes(task.status))
      
      // Assigned users filter
      const matchesAssignees = activeFilters.assignedUsers.length === 0 ||
        (task.assigned_users?.some(userId => 
          activeFilters.assignedUsers.includes(String(userId))
        ))
      
      // Recurring type filter
      const matchesRecurringType = activeFilters.recurringType.length === 0 ||
        (task.recurring_type && activeFilters.recurringType.includes(task.recurring_type))

      return matchesSearch && 
             matchesStatus && 
             matchesAssignees && 
             matchesRecurringType
    })

    setFilteredTasks(filtered)
  }

  const handleOpenTask = (task: Task) => {
    navigate(`${task.id}`, { state: { tasks: filteredTasks, taskType } });
  };

  const handleCopyTaskLink = (task: Task) => {
    const taskLink = `${window.location.origin}${window.location.pathname}/${task.id}`;
    navigator.clipboard.writeText(taskLink).then(() => {
      toast.success('Task link copied to clipboard');
    }).catch((err) => {
      console.error('Failed to copy task link: ', err);
      toast.error('Failed to copy task link');
    });
  };

  const TaskActions: React.FC<TaskActionsProps> = ({ task }) => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="h-8 w-8 p-0">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => handleOpenTask(task)}>
          <Eye className="mr-2 h-4 w-4" />
          Open task
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleCopyTaskLink(task)}>
          <Link2 className="mr-2 h-4 w-4" />
          Copy task link
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <Inbox className="mr-2 h-4 w-4" />
          Archive
        </DropdownMenuItem>
        <DropdownMenuItem disabled>
          <Trash2 className="mr-2 h-4 w-4" />
          Delete task
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );

  const mapFrequencyToLabel = (frequency: Task['recurring_type']) => {
    if (!frequency) return 'N/A';
    const mapping: Record<string, string> = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      BIWEEKLY: 'Every 2 weeks',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency] || frequency;
  };

  const handleTaskUpdate = async (taskId: string, updatedFields: Partial<Task>) => {
    try {
      const response = await axios.put<Task>(`${fetchUrl}/${taskId}`, updatedFields);
      setExternalTasks((prevTasks: Task[]) => 
        prevTasks.map(task => 
          task.id.toString() === taskId ? { ...task, ...response.data } : task
        )
      );
    } catch (error) {
      console.error('Error updating task:', error);
    }
  };

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY-MM-DD');
  };

  const handleAssigneeUpdate = async (taskId: number, newAssignees: string[]) => {
    try {
      // Map frontend taskType to backend task_type
      let backendTaskType: string;
      if (['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType)) {
        backendTaskType = 'recurring';
      } else if (taskType === 'andre-opgaver') {
        backendTaskType = 'other';
      } else if (taskType === 'activities') {
        backendTaskType = 'activity';
      } else {
        backendTaskType = taskType;
      }

      const response = await axios.put(`/work/update_assignees`, {
        task_id: taskId,
        task_type: backendTaskType,
        assignees: newAssignees
      });
      
      if (response.status === 200) {
        toast.success('Assignees updated successfully');
        setExternalTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === taskId 
              ? { ...task, assigned_users: newAssignees }
              : task
          )
        );
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  const renderTableView = () => {
    if (loading) {
      return <Loading message="Loading tasks..." size="default" />;
    }

    if (!filteredTasks?.length) {
      return (
        <div className="flex h-[50vh] items-center justify-center text-muted-foreground">
          {searchText ? 'No tasks found matching your search' : 'No tasks found'}
        </div>
      );
    }

    const dataSource = filteredTasks.map(task => ({
      ...task,
      key: task.id.toString(),
      next_work_date: formatDate(task.next_work_date || ''),
      next_due_date: formatDate(task.next_due_date || ''),
    }));

    return (
      <TaskTable
        dataSource={dataSource}
        columns={columns}
        handleOpenTask={handleOpenTask}
        loading={loading}
        onAssigneeUpdate={handleAssigneeUpdate}
      />
    );
  };

  const renderKanbanView = () => {
    console.log('Rendering Kanban view with:', {
      taskType,
      tasksCount: filteredTasks.length,
      sampleTaskTypes: filteredTasks.slice(0, 3).map(t => t.task_type)
    });
    
    if (!Array.isArray(filteredTasks)) {
      console.error('Tasks is not an array:', filteredTasks);
      return <div>Loading...</div>;
    }
    
    return (
      <TaskKanban
        tasks={filteredTasks}
        onTaskUpdate={handleTaskUpdate}
        onTaskClick={handleOpenTask}
        onAssigneeUpdate={handleAssigneeUpdate}
        boardStatusOptions={boardStatusOptions}
        cardConfig={cardConfig}
        dealDataConfig={dealDataConfig}
        dealSoftwareConfig={dealSoftwareConfig || []}
        taskType={taskType as FrontendTaskType}
        setTasks={setExternalTasks}
        loading={loading}
      />
    );
  };

  const renderCalendarView = () => (
    <TaskCalendar tasks={filteredTasks} />
  );

  const showScheduleButton = ['bogforing', 'lon', 'moms', 'arsafslutning'].includes(taskType);

  useRealTimeUpdates(taskType, null, (updatedTask: Task) => {
    if (!ENABLE_REALTIME) return;
    
    console.log('Received task update in TaskBoard:', updatedTask);
    if (updatedTask && updatedTask.id) {
      setExternalTasks((prevTasks: Task[]) => 
        prevTasks.map(task => 
          task.id === updatedTask.id ? updatedTask : task
        )
      );
    }
  });

  const enhancedColumns: ColumnDef<Task>[] = [
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions' as keyof Task,
      cell: ({ row }: { row: { original: Task } }) => <TaskActions task={row.original} />,
    },
    ...columns.map((col: ColumnDef<Task>) => ({
      ...col,
      cell: ({ row }: { row: { original: Task } }) => {
        const isClickable = col.dataIndex === 'deal_name' || col.dataIndex === 'description';
        const value = row.original[col.dataIndex as keyof Task];
        
        if (col.dataIndex === 'recurring_type') {
          return mapFrequencyToLabel(value as string);
        }

        const displayValue = (() => {
          if (value === null || value === undefined) {
            return '';
          }
          if (typeof value === 'object') {
            if ('Task' in value) {
              return (value as TaskData).Task || '';
            }
            return JSON.stringify(value);
          }
          return String(value);
        })();

        return (
          <div
            className={isClickable ? 'cursor-pointer' : undefined}
            onClick={isClickable ? () => handleOpenTask(row.original) : undefined}
          >
            {col.render ? col.render(value) : displayValue}
          </div>
        );
      }
    }))
  ];

  return (
    <div className="p-4 pt-0 min-h-screen">
      <h1 className="text-2xl text-primary mb-6">Taskboard - {title}</h1>
      
      <Tabs 
        defaultValue={defaultView || 'table'} 
        onValueChange={(value) => setViewMode(value as 'table' | 'kanban' | 'calendar')} 
        className="mb-6"
      >
        <TabsList>
          <TabsTrigger value="table">
            Table
            {defaultView === 'table' && <Star className="ml-1 h-3 w-3 text-primary" />}
          </TabsTrigger>
          <TabsTrigger value="kanban">
            Kanban
            {defaultView === 'kanban' && <Star className="ml-1 h-3 w-3 text-primary" />}
          </TabsTrigger>
          <TabsTrigger value="calendar" disabled>
            Calendar
            {defaultView === 'calendar' && <Star className="ml-1 h-3 w-3 text-primary" />}
          </TabsTrigger>
        </TabsList>
      </Tabs>

      <div className="flex justify-between items-center mb-6">
        <div className="flex-1 flex gap-2">
          <div className="relative w-[300px]">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
            <Input
              placeholder="Search by company name..."
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="pl-8"
            />
          </div>
          <div className="flex flex-wrap gap-2 items-center">
            {activeFilters.status.length > 0 && (
              <div className="flex flex-wrap gap-1">
                {activeFilters.status.map(status => (
                  <Badge 
                    key={status} 
                    variant="secondary"
                    className="flex items-center gap-1"
                  >
                    Status: {boardStatusOptions.find(opt => opt.value === status)?.label}
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-4 w-4 p-0"
                      onClick={() => setActiveFilters(prev => ({
                        ...prev,
                        status: prev.status.filter(s => s !== status)
                      }))}
                    >
                      <X className="h-3 w-3" />
                    </Button>
                  </Badge>
                ))}
              </div>
            )}
            
            {activeFilters.assignedUsers.length > 0 && (
              <div className="flex flex-wrap gap-1">
                {activeFilters.assignedUsers.map(userId => (
                  <Badge 
                    key={userId} 
                    variant="secondary"
                    className="flex items-center gap-1"
                  >
                    Assigned: {users.find(u => String(u.user_id) === userId)?.name}
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-4 w-4 p-0"
                      onClick={() => setActiveFilters(prev => ({
                        ...prev,
                        assignedUsers: prev.assignedUsers.filter(id => id !== userId)
                      }))}
                    >
                      <X className="h-3 w-3" />
                    </Button>
                  </Badge>
                ))}
              </div>
            )}
            
            {(activeFilters.status.length > 0 || 
              activeFilters.assignedUsers.length > 0 || 
              activeFilters.recurringType.length > 0) && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setActiveFilters({
                  status: [],
                  assignedUsers: [],
                  recurringType: [],
                })}
              >
                Clear all filters
              </Button>
            )}
          </div>
          <Button 
            variant="outline" 
            size="icon"
            onClick={() => setIsFilterModalOpen(true)}
          >
            <Filter className="h-4 w-4" />
          </Button>
        </div>
        <div className="flex gap-2">
          <Button variant="outline" onClick={() => setIsActivityModalVisible(true)}>
            <History className="mr-2 h-4 w-4" />
            Activity
          </Button>
          {showScheduleButton && (
            <Button variant="outline" onClick={() => setIsScheduleModalVisible(true)}>
              <CalendarIcon className="mr-2 h-4 w-4" />
              Schedule
            </Button>
          )}
        </div>
      </div>

      {!taskId && (
        <>
          {viewMode === 'table' && renderTableView()}
          {viewMode === 'kanban' && renderKanbanView()}
          {viewMode === 'calendar' && renderCalendarView()}
        </>
      )}

      <Dialog open={isScheduleModalVisible} onOpenChange={setIsScheduleModalVisible}>
        <DialogContent className="max-w-4xl">
          <TaskSchedule onClose={() => setIsScheduleModalVisible(false)} />
        </DialogContent>
      </Dialog>

      <BoardActivity 
        open={isActivityModalVisible}
        onClose={() => setIsActivityModalVisible(false)} 
        taskType={taskType as FrontendTaskType}
      />

      <Routes>
        <Route 
          path=":taskId" 
          element={
            <TaskView 
              onClose={() => navigate(`/boards/${taskType}`, { replace: true })}
              tasks={filteredTasks}
              task={filteredTasks.find(t => t.id.toString() === taskId) || filteredTasks[0]}
              taskType={taskType}
              boardStatusOptions={boardStatusOptions}
              fromWork={false}
              dealName={dealName || 'Default Deal Name'}
              getBoardName={() => taskType}
            />
          } 
        />
      </Routes>

      <TaskFilter
        open={isFilterModalOpen}
        onOpenChange={setIsFilterModalOpen}
        onFiltersChange={setActiveFilters}
        statusOptions={boardStatusOptions}
        currentFilters={activeFilters}
      />
    </div>
  );
};

export default TaskBoards;