import React from 'react'
import { Routes, Route } from 'react-router-dom'
import CustomBoardList from './components/CustomBoardList'
import { CustomBoard } from './CustomBoard'
import CustomTaskView from './views/CustomTaskView'

export function CustomBoards() {
  return (
    <div className="flex-1 space-y-4 p-4 md:p-8 pt-6">
      <Routes>
        <Route index element={<CustomBoardList />} />
        <Route path=":boardId" element={<CustomBoard />}>
          <Route path="task/:taskId" element={<CustomTaskView />} />
        </Route>
      </Routes>
    </div>
  )
}

export default CustomBoards;