import { Link, Outlet, useLocation } from 'react-router-dom'
import { ClipboardList, Users, LineChart, LayoutDashboard } from 'lucide-react'
import { Button } from "@/components/ui/button"

export default function Dashboard() {
  const location = useLocation()
  const currentPath = location.pathname.split('/').pop() || 'dashboard'
  
  // Get display title based on current path
  const displayTitle = currentPath === 'dashboard' ? 'Overview' 
    : currentPath.charAt(0).toUpperCase() + currentPath.slice(1)

  return (
    <div className="min-h-screen bg-background p-6">
      <div className="rounded-lg border border-border bg-card text-card-foreground shadow-sm">
        <header className="border-b border-border">
          <div className="flex h-16 items-center px-4">
            <nav className="flex items-center space-x-4 mx-6">
              <Button 
                variant={currentPath === 'dashboard' ? 'outline' : 'ghost'} 
                className={currentPath === 'dashboard' ? 'border-primary' : ''}
                asChild
              >
                <Link to="/dashboard" className="flex items-center gap-2">
                  <LayoutDashboard className="h-4 w-4" />
                  Overview
                </Link>
              </Button>
              <Button 
                variant={currentPath === 'tasks' ? 'outline' : 'ghost'} 
                className={currentPath === 'tasks' ? 'border-primary' : ''}
                asChild
              >
                <Link to="/dashboard/tasks" className="flex items-center gap-2">
                  <ClipboardList className="h-4 w-4" />
                  Tasks
                </Link>
              </Button>
              <Button 
                variant={currentPath === 'customers' ? 'outline' : 'ghost'}
                className={currentPath === 'customers' ? 'border-primary' : ''}
                asChild
              >
                <Link to="/dashboard/customers" className="flex items-center gap-2">
                  <Users className="h-4 w-4" />
                  Customers
                </Link>
              </Button>
              <Button 
                variant={currentPath === 'sales' ? 'outline' : 'ghost'}
                className={currentPath === 'sales' ? 'border-primary' : ''}
                asChild
              >
                <Link to="/dashboard/sales" className="flex items-center gap-2">
                  <LineChart className="h-4 w-4" />
                  Sales
                </Link>
              </Button>
            </nav>
          </div>
        </header>

        <main>
          <div className="flex justify-between items-center p-6">
            <h1 className="text-3xl font-bold text-primary">
              Dashboard - {displayTitle}
            </h1>
            <Button>Download</Button>
          </div>
          
          <div className="p-6">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  )
}