import React, { useState } from 'react';
import { Table, ColumnDef } from '@/components/ui/specialized/mod-table';
import { cn } from '@/lib/utils';
import { Task } from '@/types/task';
import { Loading } from '@/components/ui/loading';
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { StoredUser } from "@/types/user";
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { toast } from 'sonner';
import axios from '@/api/axiosConfig';
import { useUsers } from '@/contexts/UserContext';

interface TaskTableProps {
  dataSource: Task[];
  columns: ColumnDef<Task>[];
  handleOpenTask: (task: Task) => void;
  loading: boolean;
  onAssigneeUpdate: (taskId: number, newAssignees: string[]) => Promise<void>;
}

const TaskTable: React.FC<TaskTableProps> = ({ dataSource, columns, handleOpenTask, loading, onAssigneeUpdate }) => {
  const { users } = useUsers();
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<Task | null>(null);

  const getAssignedUsers = (assignedUserIds: string[]): StoredUser[] => {
    if (!assignedUserIds?.length) return [];
    return assignedUserIds
      .map(userId => users.find(u => u.user_id.toString() === userId.toString()))
      .filter((user): user is StoredUser => user !== undefined);
  };

  const renderCellValue = (value: any, column: ColumnDef<Task>, task: Task): React.ReactNode => {
    if (value === null || value === undefined) {
      return '';
    }

    if (column.dataIndex === 'assigned_users') {
      const assignedUsers = getAssignedUsers(value);
      return (
        <div 
          className="cursor-pointer hover:opacity-80 transition-opacity"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedTaskForAssignee(task);
            setIsAssigneeModalOpen(true);
          }}
        >
          <AvatarGroup users={assignedUsers} max={3} />
        </div>
      );
    }

    if (typeof value === 'object') {
      if ('Task' in value) {
        return (value as any).Task || '';
      }
      if ('name' in value) {
        return value.name;
      }
      return JSON.stringify(value);
    }
    return String(value);
  };

  if (loading) {
    return <Loading message="Loading tasks..." size="default" />;
  }

  return (
    <>
      <Table
        data={dataSource}
        columns={columns.map(col => ({
          ...col,
          cell: ({ row }: { row: { original: Task } }) => {
            if (!row?.original) return null;

            const isClickable = col.dataIndex === 'deal_name' || col.dataIndex === 'description';
            const value = row.original[col.dataIndex as keyof Task];

            return (
              <div
                className={cn(
                  isClickable && "cursor-pointer",
                  "w-full"
                )}
                onClick={isClickable ? () => handleOpenTask(row.original) : undefined}
              >
                {col.render ? col.render(value) : renderCellValue(value, col, row.original)}
              </div>
            );
          }
        }))}
        className="w-full"
      />

      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => {
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={(newAssignees) => {
          if (selectedTaskForAssignee) {
            onAssigneeUpdate(selectedTaskForAssignee.id, newAssignees);
          }
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        initialAssignees={selectedTaskForAssignee?.assigned_users?.map(id => id.toString()) || []}
        maxAssignees={10}
      />
    </>
  );
};

export default TaskTable;