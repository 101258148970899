import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { 
  Home, 
  Users, 
  UserPlus, 
  UserMinus,
  Bot,
  LayoutDashboard,
  ClipboardList,
  Calculator,
  Receipt,
  FileSpreadsheet,
  ListTodo,
  Activity,
  FolderKanban,
  Plus,
  Wrench,
  Briefcase,
  ChevronRight,
  HandshakeIcon,
  Building2,
  UserRound,
  Package,
  Mail,
} from 'lucide-react';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from '@/components/ui/collapsible';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuAction,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from '@/components/ui/sidebar';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useLanguage } from '@/contexts/LanguageContext';
import { t } from '@/i18n/language';
import axios from '@/api/axiosConfig';

interface CustomBoard {
  id: number;
  name: string;
  board_type: string;
  is_public: boolean;
}

export function NavMain({ collapsed }: { collapsed?: boolean }) {
  const [customBoards, setCustomBoards] = useState<CustomBoard[]>([]);
  const location = useLocation();
  const { language } = useLanguage();
  const [openMenus, setOpenMenus] = React.useState<{ [key: string]: boolean }>({
    "taskBoards": true,
    "tools": true,
    "projects": false,
  });

  // Fetch custom boards on component mount
  useEffect(() => {
    const fetchCustomBoards = async () => {
      try {
        const response = await axios.get('/custom-board/boards');
        setCustomBoards(response.data);
      } catch (error) {
        console.error('Error fetching custom boards:', error);
      }
    };

    fetchCustomBoards();
  }, []);

  // Separate boards by type
  const taskBoards = customBoards.filter(board => board.board_type === 'taskboard');
  const projectBoards = customBoards.filter(board => board.board_type === 'project');

  const handleMenuClick = (item: any, event: React.MouseEvent) => {
    if (item.items?.length && !collapsed) {
      event.preventDefault();
      setOpenMenus(prev => ({
        ...prev,
        [item.id]: !prev[item.id]
      }));
    }
  };

  const isMenuItemActive = (item: any): boolean => {
    if (item.url === location.pathname) return true;
    if (item.items?.length) {
      return item.items.some((subItem: any) => 
        location.pathname.startsWith(subItem.url)
      );
    }
    return location.pathname.startsWith(item.url);
  };

  const isSubMenuItemActive = (url: string): boolean => {
    return location.pathname.startsWith(url);
  };

  const defaultNavItems = [
    {
      id: "home",
      title: t('nav-main.home', language),
      url: "/home",
      icon: Home,
      isActive: location.pathname === '/home',
    },
    {
      id: "myWork",
      title: t('nav-main.myWork', language),
      url: "/work",
      icon: Briefcase,
      isActive: location.pathname === '/my-work',
    },
    {
      id: "email",
      title: t('nav-main.email', language),
      url: "/email",
      icon: Mail,
      isActive: location.pathname.startsWith('/email'),
    },
    {
      id: "dashboard",
      title: t('nav-main.dashboard', language),
      url: "/dashboard",
      icon: LayoutDashboard,
      isActive: location.pathname === '/dashboard',
    },
    {
      id: "customers",
      title: t('nav-main.customers.title', language),
      url: "/customers",
      icon: Users,
      items: [
        { title: t('nav-main.customers.deals', language), url: "/customers/deals", icon: HandshakeIcon },
        { title: t('nav-main.customers.accounts', language), url: "/customers/accounts", icon: Building2 },
        { title: t('nav-main.customers.contacts', language), url: "/customers/contacts", icon: UserRound },
        { title: t('nav-main.customers.products', language), url: "/customers/products", icon: Package },
        { title: t('nav-main.customers.onboarding', language), url: "/customers/onboarding", icon: UserPlus },
        { title: t('nav-main.customers.offboarding', language), url: "/customers/offboarding", icon: UserMinus },
      ],
    },
    {
      id: "tools",
      title: t('nav-main.tools.title', language),
      url: "/tools",
      icon: Wrench,
      items: [
        { title: t('nav-main.tools.revibot', language), url: "/revibot", icon: Bot },
      ],
    },
    {
      id: "taskBoards",
      title: t('nav-main.taskBoards.title', language),
      url: "/boards",
      icon: ClipboardList,
      isActive: location.pathname.startsWith('/boards'),
      items: [
        {
          title: t('nav-main.taskBoards.bookkeeping', language),
          url: "/boards/bogforing",
          icon: Calculator,
        },
        {
          title: t('nav-main.taskBoards.payroll', language),
          url: "/boards/lon",
          icon: Receipt,
        },
        {
          title: t('nav-main.taskBoards.vat', language),
          url: "/boards/moms",
          icon: FileSpreadsheet,
        },
        {
          title: t('nav-main.taskBoards.annualReport', language),
          url: "/boards/arsafslutning",
          icon: ListTodo,
        },
        {
          title: t('nav-main.taskBoards.otherTasks', language),
          url: "/boards/andre-opgaver",
          icon: Activity,
        },
        {
          title: t('nav-main.taskBoards.activities', language),
          url: "/boards/activities",
          icon: FolderKanban,
        },
        // Dynamic custom taskboards
        ...taskBoards.map(board => ({
          title: board.name,
          url: `/custom-boards/${board.id}`,
          icon: FolderKanban,
        })),
        {
          title: t('nav-main.taskBoards.addBoard', language),
          url: "/custom-boards/new",
          icon: Plus,
        },
      ],
    },
    {
      id: "projects",
      title: t('nav-main.projects.title', language),
      url: "/projects",
      icon: FolderKanban,
      items: [
        // Dynamic project boards
        ...projectBoards.map(board => ({
          title: board.name,
          url: `/projects/${board.id}`,
          icon: FolderKanban,
        })),
        {
          title: t('nav-main.projects.addBoard', language),
          url: "/projects/new",
          icon: Plus,
        },
      ],
    },
  ];

  return (
    <SidebarGroup>
      <SidebarMenu className="space-y-0.1">
        {defaultNavItems.map((item) => (
          collapsed && item.items?.length ? (
            <DropdownMenu key={item.id}>
              <SidebarMenuItem>
                <DropdownMenuTrigger asChild>
                  <SidebarMenuButton>
                    <div className="flex justify-center w-full">
                      <item.icon className="h-4 w-4 shrink-0" />
                    </div>
                  </SidebarMenuButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent side="right" align="start" className="min-w-48">
                  {item.items.map((subItem) => (
                    <DropdownMenuItem key={subItem.title} asChild>
                      <Link to={subItem.url} className="flex items-center gap-2">
                        {subItem.icon && <subItem.icon className="h-4 w-4 shrink-0" />}
                        <span>{subItem.title}</span>
                      </Link>
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </SidebarMenuItem>
            </DropdownMenu>
          ) : (
            <Collapsible 
              key={item.id} 
              open={openMenus[item.id]}
              onOpenChange={(isOpen) => setOpenMenus(prev => ({ ...prev, [item.id]: isOpen }))}
            >
              <SidebarMenuItem>
                <SidebarMenuButton 
                  asChild={!item.items?.length}
                  onClick={(e) => item.items?.length && handleMenuClick(item, e)}
                  className={cn(
                    isMenuItemActive(item) && "text-primary"
                  )}
                >
                  {item.items?.length ? (
                    <div className={cn(
                      "flex items-center gap-2 transition-all duration-300 ease-in-out",
                      collapsed ? "justify-center w-full" : "w-full",
                      isMenuItemActive(item) && "text-primary"
                    )}>
                      <item.icon className="h-4 w-4 shrink-0" />
                      {!collapsed && <span>{item.title}</span>}
                      {!collapsed && (
                        <ChevronRight className={cn(
                          "ml-auto h-4 w-4 shrink-0 transition-transform",
                          openMenus[item.id] && "rotate-90"
                        )} />
                      )}
                    </div>
                  ) : (
                    <Link 
                      to={item.url}
                      className={cn(
                        "flex items-center gap-2 transition-all duration-300 ease-in-out",
                        collapsed ? "justify-center w-full" : "",
                        isMenuItemActive(item) && "text-primary"
                      )}
                    >
                      <item.icon className="h-4 w-4 shrink-0" />
                      {!collapsed && <span>{item.title}</span>}
                    </Link>
                  )}
                </SidebarMenuButton>
                {!collapsed && item.items?.length && (
                  <>
                    <CollapsibleTrigger asChild>
                      <SidebarMenuAction />
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      <SidebarMenuSub>
                        {item.items?.map((subItem) => (
                          <SidebarMenuSubItem key={subItem.title}>
                            <SidebarMenuSubButton 
                              asChild
                              className={cn(
                                isSubMenuItemActive(subItem.url) && "text-primary [&_svg]:text-primary"
                              )}
                            >
                              <Link 
                                to={subItem.url}
                                className={cn(
                                  "flex items-center gap-2",
                                  isSubMenuItemActive(subItem.url) && "text-primary"
                                )}
                              >
                                {subItem.icon && <subItem.icon className="h-4 w-4 shrink-0" />}
                                <span>{subItem.title}</span>
                              </Link>
                            </SidebarMenuSubButton>
                          </SidebarMenuSubItem>
                        ))}
                      </SidebarMenuSub>
                    </CollapsibleContent>
                  </>
                )}
              </SidebarMenuItem>
            </Collapsible>
          )
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
