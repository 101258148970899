import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, message, Layout } from 'antd';
import axios from '../../api/axiosConfig';
import OnboardingForm from './OnboardingForm';

const { Content } = Layout;

const OnboardingFormPage = () => {
  const { dealId, onboardingId } = useParams();
  const [deal, setDeal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [initialStep, setInitialStep] = useState(0);

  useEffect(() => {
    fetchDealData();
  }, [dealId]);

  const fetchDealData = async () => {
    try {
      const response = await axios.get(`/onboarding/deal/${dealId}`);
      setDeal(response.data);
      
      // Set initial step based on last_completed_step
      if (response.data.onboarding?.last_completed_step === 'form_completed') {
        setInitialStep(1); // E-conomic setup
      }
      // Add more conditions for other steps as needed
      
    } catch (error) {
      message.error('Failed to fetch deal data');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Layout style={{ minHeight: '100vh', background: 'var(--gray-100)' }}>
        <Content style={{ 
          padding: 'var(--spacing-large)',
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center' 
        }}>
          <Spin size="large" />
        </Content>
      </Layout>
    );
  }

  return (
    <Layout style={{ minHeight: '100vh', background: 'var(--gray-100)' }}>
      <Content style={{ padding: 'var(--spacing-large)' }}>
        <OnboardingForm 
          deal={deal}
          visible={true}
          onClose={() => window.close()}
          standalone={true}
          initialStep={initialStep}
        />
      </Content>
    </Layout>
  );
};

export default OnboardingFormPage;