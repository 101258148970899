import React, { useEffect, useRef } from 'react';
import { GlobeInstance } from '@/types/globe';

interface GlobeProps {
  width: number;
  height: number;
  onGlobeReady: (globe: GlobeInstance) => void;
}

export function Globe({ width, height, onGlobeReady }: GlobeProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const globeRef = useRef<GlobeInstance | null>(null);

  useEffect(() => {
    // Silence the specific Three.js warning
    const originalConsoleWarn = console.warn;
    console.warn = function(...args) {
      if (typeof args[0] === 'string' && args[0].includes('onBeforeRender() has been removed')) {
        return;
      }
      originalConsoleWarn.apply(console, args);
    };

    let mounted = true;

    const initGlobe = async () => {
      // Prevent double initialization
      if (globeRef.current || !containerRef.current) return;

      const Globe = (await import('globe.gl')).default;
      const globe = Globe() as GlobeInstance;

      // Configure globe
      globe
        .globeImageUrl('//unpkg.com/three-globe/example/img/earth-blue-marble.jpg')
        .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
        .backgroundColor('rgba(0,0,0,0)')
        .width(width)
        .height(height)
        .pointOfView({ lat: 20, lng: 45, altitude: 2.5 })
        .atmosphereColor('lightskyblue')
        .atmosphereAltitude(0.1);

      // Apply to DOM
      globe(containerRef.current);

      if (mounted) {
        globeRef.current = globe;
        onGlobeReady(globe);
      }
    };

    initGlobe();

    return () => {
      mounted = false;
      if (globeRef.current) {
        const globe = globeRef.current;
        
        // Cleanup
        if (globe.scene()) {
          globe.scene().traverse((object: any) => {
            if (object.material) {
              if (Array.isArray(object.material)) {
                object.material.forEach((m: any) => m.dispose?.());
              } else {
                object.material.dispose?.();
              }
            }
            if (object.geometry) {
              object.geometry.dispose?.();
            }
          });
        }
        
        if (globe.controls()) globe.controls().dispose();
        if (globe.renderer()) globe.renderer().dispose();
        globeRef.current = null;
      }
      
      // Restore original console.warn
      console.warn = originalConsoleWarn;
    };
  }, [width, height, onGlobeReady]);

  return (
    <div className="flex justify-center items-center w-full">
      <div 
        ref={containerRef} 
        style={{ 
          width, 
          height,
          position: 'relative',
          overflow: 'hidden',
          background: 'transparent'
        }} 
      />
    </div>
  );
}