import React, { useState, useEffect } from 'react';
import { Card, Table, Tag, Space, Typography, Spin, Form, Input, Button, message } from 'antd';
import axios from '../../api/axiosConfig';

const { Title } = Typography;

const TasksSetupStep = ({ deal, setCurrentStep }) => {
  const [loading, setLoading] = useState(true);
  const [recommendedTasks, setRecommendedTasks] = useState([]);
  const [products, setProducts] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (deal) {
      fetchRecommendedTasks();
      fetchProducts();
    }
  }, [deal]);

  const fetchRecommendedTasks = async () => {
    try {
      const response = await axios.get(`/onboarding/recommended-tasks/${deal.id}`);
      setRecommendedTasks(response.data);
    } catch (error) {
      console.error('Error fetching recommended tasks:', error);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`/deals/${deal.id}/products`);
      setProducts(response.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  const taskColumns = [
    {
      title: 'Task Type',
      dataIndex: 'task_type',
      key: 'task_type',
      render: (type) => <Tag color="blue">{type}</Tag>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      render: (freq) => <Tag color="green">{freq}</Tag>
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date'
    }
  ];

  const productColumns = [
    {
      title: 'Product',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => <Tag color="purple">{type}</Tag>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <Tag color={status === 'active' ? 'green' : 'orange'}>{status}</Tag>
    }
  ];

  if (loading) {
    return <Spin size="large" />;
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Card title="Products">
        <Table 
          columns={productColumns}
          dataSource={products}
          pagination={false}
          rowKey="id"
        />
      </Card>

      <Card title="Recommended Tasks">
        <Table 
          columns={taskColumns}
          dataSource={recommendedTasks}
          pagination={false}
          rowKey={(record) => record.task_type}
        />
      </Card>

      <Card>
        <div className="tasks-setup">
          <Form form={form}>
            <Form.Item
              name="data.onboarding_opgaver"
              label="Onboarding Tasks"
              rules={[{ required: true, message: 'Please input onboarding tasks!' }]}
            >
              <Input.TextArea 
                rows={4} 
                placeholder="Enter any additional onboarding tasks or notes..."
              />
            </Form.Item>
          </Form>
          <div style={{ textAlign: 'right', marginTop: 16 }}>
            <Button 
              type="primary" 
              onClick={() => {
                form.validateFields()
                  .then(() => setCurrentStep(prev => prev + 1))
                  .catch(() => message.error('Please fill in the required fields'));
              }}
            >
              Continue to Invoice
            </Button>
          </div>
        </div>
      </Card>
    </Space>
  );
};

export default TasksSetupStep;