import { useState, useEffect } from 'react'
import { Users, FileText, Mail, BanknoteIcon, Settings as SettingsIcon, Share2, BookOpen } from 'lucide-react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { cn } from "@/lib/utils"
import BillingSettings from './BillingSettings'
import TeamManagement from './TeamManagement'
import OverdueAccounts from './OverdueAccounts'
import TaskConfiguration from './TaskConfiguration'
import FlowNav from './FlowNav'
import Guides from './Guides'
import axios from '../api/axiosConfig'
import { hasRequiredPermission } from '../utils/permissionUtils'

interface SettingsProps {
  visible: boolean
  onClose: () => void
}

export default function Settings({ visible, onClose }: SettingsProps) {
  const [allUsers, setAllUsers] = useState<any[]>([])
  const [currentUser] = useState(JSON.parse(localStorage.getItem('user') || '{}'))

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get('/user')
      setAllUsers(response.data)
    } catch (error) {
      console.error('Error fetching users:', error)
    }
  }

  useEffect(() => {
    if (visible) {
      fetchAllUsers()
    }
  }, [visible])

  const canAccessBilling = () => {
    return hasRequiredPermission(currentUser?.permissions, 'admin') || 
           currentUser?.permissions === 'payroll'
  }

  const canAccessOverdueAccounts = () => {
    return hasRequiredPermission(currentUser?.permissions, 'admin') || 
           currentUser?.permissions === 'payroll'
  }

  const canAccessTaskConfiguration = () => {
    return hasRequiredPermission(currentUser?.permissions, 'admin')
  }

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="flex flex-col max-w-[1200px] max-h-[90vh] h-[800px] p-0">
        <DialogHeader className="shrink-0 px-6 py-4">
          <DialogTitle>Settings</DialogTitle>
        </DialogHeader>
        
        <Tabs defaultValue="teams" className="flex flex-1 min-h-0">
          <TabsList className="h-full w-[200px] flex-col justify-start items-stretch space-y-1 bg-muted/50 p-2 border-r border-border">
            <TabsTrigger 
              value="teams"
              className="justify-start gap-2 px-4 py-2 h-9"
            >
              <Users className="h-4 w-4" />
              Teams & Permissions
            </TabsTrigger>

            <TabsTrigger 
              value="billing"
              className={cn(
                "justify-start gap-2 px-4 py-2 h-9",
                !canAccessBilling() && "opacity-50 cursor-not-allowed"
              )}
              disabled={!canAccessBilling()}
            >
              <FileText className="h-4 w-4" />
              Billing
            </TabsTrigger>

            <TabsTrigger 
              value="overdue"
              className={cn(
                "justify-start gap-2 px-4 py-2 h-9",
                !canAccessOverdueAccounts() && "opacity-50 cursor-not-allowed"
              )}
              disabled={!canAccessOverdueAccounts()}
            >
              <BanknoteIcon className="h-4 w-4" />
              Overdue Accounts
            </TabsTrigger>

            <TabsTrigger 
              value="email"
              className="justify-start gap-2 px-4 py-2 h-9 opacity-50 cursor-not-allowed"
              disabled
            >
              <Mail className="h-4 w-4" />
              Email Templates
            </TabsTrigger>

            <TabsTrigger 
              value="tasks"
              className={cn(
                "justify-start gap-2 px-4 py-2 h-9",
                !canAccessTaskConfiguration() && "opacity-50 cursor-not-allowed"
              )}
              disabled={!canAccessTaskConfiguration()}
            >
              <SettingsIcon className="h-4 w-4" />
              Task Configuration
            </TabsTrigger>

            <TabsTrigger 
              value="flows"
              className={cn(
                "justify-start gap-2 px-4 py-2 h-9",
                !hasRequiredPermission(currentUser?.permissions, 'admin') && "opacity-50 cursor-not-allowed"
              )}
              disabled={!hasRequiredPermission(currentUser?.permissions, 'admin')}
            >
              <Share2 className="h-4 w-4" />
              Flows
            </TabsTrigger>

            <TabsTrigger 
              value="guides"
              className="justify-start gap-2 px-4 py-2 h-9"
            >
              <BookOpen className="h-4 w-4" />
              Guides
            </TabsTrigger>
          </TabsList>

          <div className="flex-1 min-h-0 overflow-auto">
            <TabsContent value="teams" className="h-full p-6 m-0 data-[state=active]:flex">
              <TeamManagement 
                allUsers={allUsers}
                currentUser={currentUser}
                onUpdateUsers={fetchAllUsers}
              />
            </TabsContent>

            <TabsContent value="billing" className="h-full p-6 m-0 data-[state=active]:flex">
              <BillingSettings />
            </TabsContent>

            <TabsContent value="overdue" className="h-full p-6 m-0 data-[state=active]:flex">
              <OverdueAccounts 
                allUsers={allUsers}
                currentUser={currentUser}
              />
            </TabsContent>

            <TabsContent value="email" className="h-full p-6 m-0 data-[state=active]:flex">
              <div>Coming soon</div>
            </TabsContent>

            <TabsContent value="tasks" className="h-full p-6 m-0 data-[state=active]:flex">
              <TaskConfiguration />
            </TabsContent>

            <TabsContent value="flows" className="h-full p-6 m-0 data-[state=active]:flex">
              <FlowNav />
            </TabsContent>

            <TabsContent value="guides" className="h-full p-6 m-0 data-[state=active]:flex">
              <Guides />
            </TabsContent>
          </div>
        </Tabs>
      </DialogContent>
    </Dialog>
  )
}