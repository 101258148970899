import { useState, useEffect } from 'react'
import { Plus } from 'lucide-react'
import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch"
import { Checkbox } from "@/components/ui/checkbox"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Form, FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form"
import { 
  Table, 
  type ColumnDef 
} from "@/components/ui/specialized/mod-table"
import { TimePickerInput } from "@/components/ui/date/time-picker"
import { toast } from "sonner"
import { cn } from "@/lib/utils"
import { useForm, type FieldValues } from 'react-hook-form'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import axios from '../../api/axiosConfig'
import * as React from "react"

dayjs.extend(customParseFormat)
dayjs.extend(utc)

interface ScheduleConfig {
  job_name: string
  is_active: boolean
  days_of_week: number[]
  times_of_day: string[]
  secondary_status?: 'draft' | 'book'
}

interface ScheduleConfigs {
  [key: string]: ScheduleConfig
}

interface FormValues {
  [key: string]: {
    is_active: boolean;
    days_of_week: number[];
    times_of_day: string[];
    secondary_status?: 'draft' | 'book';
    morning_time?: string;
    afternoon_time?: string;
  }
}

const DAYS_OF_WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

interface AdminScheduleProps {
  activeTab?: string;
}

export default function AdminSchedule({ activeTab = "overview" }: AdminScheduleProps) {
  const form = useForm<FormValues>()
  const [scheduleConfigs, setScheduleConfigs] = useState<ScheduleConfigs>({})
  const [maintenanceMode, setMaintenanceMode] = useState(false)
  const [isMaintenanceModeChanged, setIsMaintenanceModeChanged] = useState(false)

  useEffect(() => {
    fetchScheduleConfigs()
    fetchMaintenanceMode()
  }, [])

  const fetchScheduleConfigs = async () => {
    try {
      const response = await axios.get('/admin/schedule-config')
      const configs = response.data.reduce((acc: ScheduleConfigs, config: ScheduleConfig) => {
        acc[config.job_name] = config
        return acc
      }, {})
      setScheduleConfigs(configs)
      form.reset(formatConfigsForForm(configs))
    } catch (error) {
      console.error('Error fetching schedule configs:', error)
      toast.error('Failed to fetch schedule configurations')
    }
  }

  const fetchMaintenanceMode = async () => {
    try {
      const response = await axios.get('/admin/system-flags')
      const maintenanceFlag = response.data.find((flag: { flag_name: string }) => 
        flag.flag_name === 'maintenance_mode'
      )
      setMaintenanceMode(maintenanceFlag?.is_enabled || false)
      setIsMaintenanceModeChanged(false)
    } catch (error) {
      console.error('Error fetching maintenance mode:', error)
      toast.error('Failed to fetch maintenance mode status')
    }
  }

  const handleMaintenanceModeChange = (checked: boolean) => {
    setMaintenanceMode(checked)
    setIsMaintenanceModeChanged(true)
  }

  const saveMaintenanceMode = async () => {
    try {
      await axios.put('/admin/system-flags/maintenance_mode', {
        is_enabled: maintenanceMode,
        description: 'Run scheduled jobs in development environment only'
      })
      toast.success('Maintenance mode updated successfully')
      setIsMaintenanceModeChanged(false)
    } catch (error) {
      console.error('Error updating maintenance mode:', error)
      toast.error('Failed to update maintenance mode')
    }
  }

  const formatConfigsForForm = (configs: ScheduleConfigs): FormValues => {
    const formattedConfigs: FormValues = {}
    Object.entries(configs).forEach(([jobName, config]) => {
      if (jobName === 'team_briefings') {
        formattedConfigs[jobName] = {
          is_active: config.is_active,
          days_of_week: config.days_of_week,
          times_of_day: config.times_of_day,
          morning_time: config.times_of_day[0],
          afternoon_time: config.times_of_day[1],
        }
      } else {
        formattedConfigs[jobName] = {
          is_active: config.is_active,
          days_of_week: config.days_of_week,
          times_of_day: config.times_of_day,
          secondary_status: config.secondary_status,
        }
      }
    })
    return formattedConfigs
  }

  const onFinish = async (jobName: string) => {
    try {
      const values = form.getValues()
      const formData = values[jobName]
      
      const formattedValues = {
        job_name: jobName,
        is_active: formData.is_active,
        days_of_week: formData.days_of_week || [],
        times_of_day: jobName === 'team_briefings' 
          ? [formData.morning_time, formData.afternoon_time]
          : formData.times_of_day,
        secondary_status: formData.secondary_status
      }

      await axios.post('/admin/schedule-config', formattedValues)
      toast.success('Schedule configuration saved')
    } catch (error) {
      toast.error('Failed to save configuration')
    }
  }

  const OverviewContent = () => {
    const columns: ColumnDef<ScheduleConfig>[] = [
      {
        key: 'job',
        title: 'Job',
        dataIndex: 'job_name',
        cell: ({ row }) => (
          <span className="font-medium">
            {row.original.job_name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
          </span>
        )
      },
      {
        key: 'days',
        title: 'Days',
        dataIndex: 'days_of_week',
        cell: ({ row }) => (
          <span>{row.original.days_of_week.map(day => DAYS_OF_WEEK[day]).join(', ')}</span>
        )
      },
      {
        key: 'times',
        title: 'Times (UTC)',
        dataIndex: 'times_of_day',
        cell: ({ row }) => <span>{row.original.times_of_day.join(', ')}</span>
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'is_active',
        cell: ({ row }) => (
          <span className={cn(
            "font-semibold",
            row.original.is_active ? "text-green-600" : "text-red-600"
          )}>
            {row.original.is_active ? 'Active' : 'Disabled'}
          </span>
        )
      }
    ]

    return (
      <div className="space-y-6">
        <h3 className="text-xl font-semibold text-foreground">Schedule Overview</h3>
        <Table
          data={Object.values(scheduleConfigs)}
          columns={columns}
        />

        <div className="flex items-center gap-4">
          <Switch
            checked={maintenanceMode}
            onCheckedChange={handleMaintenanceModeChange}
          />
          <span className="text-foreground">Maintenance Mode (Run jobs in development only)</span>
          {isMaintenanceModeChanged && (
            <Button onClick={saveMaintenanceMode}>
              Save Changes
            </Button>
          )}
        </div>
      </div>
    )
  }

  const TeamBriefingsContent = () => (
    <div className="space-y-6">
      <h3 className="text-xl font-semibold text-foreground">Team Briefings Schedule</h3>
      <FormField
        name={`team_briefings.is_active`}
        render={({ field }) => (
          <FormItem className="text-foreground flex flex-row items-center justify-between rounded-lg border p-4">
            <div className="space-y-0.5">
              <FormLabel className="text-base">Activate/Disable Automation</FormLabel>
            </div>
            <FormControl>
              <Switch checked={field.value} onCheckedChange={field.onChange} />
            </FormControl>
          </FormItem>
        )}
      />

      <FormField
        name={`team_briefings.days_of_week`}
        render={({ field }) => (
          <FormItem className="text-foreground">
            <FormLabel>Days of the Week</FormLabel>
            <FormControl>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {DAYS_OF_WEEK.map((day, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <Checkbox 
                      checked={field.value?.includes(index)}
                      onCheckedChange={(checked) => {
                        const newValue = checked 
                          ? [...(field.value || []), index]
                          : (field.value || []).filter((v: number) => v !== index)
                        field.onChange(newValue)
                      }}
                    />
                    <span className="text-foreground">{day}</span>
                  </div>
                ))}
              </div>
            </FormControl>
          </FormItem>
        )}
      />

      <div className="space-y-4">
        <FormField
          name={`team_briefings.morning_time`}
          render={({ field }) => (
            <FormItem className="text-foreground">
              <FormLabel>Morning Briefing Time (UTC)</FormLabel>
              <FormControl>
                <div className="flex items-center space-x-2">
                  <TimePickerInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <span className="text-foreground">UTC</span>
                </div>
              </FormControl>
            </FormItem>
          )}
        />

        <FormField
          name={`team_briefings.afternoon_time`}
          render={({ field }) => (
            <FormItem className="text-foreground">
              <FormLabel>Afternoon Briefing Time (UTC)</FormLabel>
              <FormControl>
                <div className="flex items-center space-x-2">
                  <TimePickerInput
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <span className="text-foreground">UTC</span>
                </div>
              </FormControl>
            </FormItem>
          )}
        />
      </div>

      <Button onClick={() => onFinish("team_briefings")}>
        Save Configuration
      </Button>
    </div>
  );

  const ScheduleContent = ({ jobName, title }: { jobName: string; title: string }) => {
    const { getValues, setValue } = form;
    const [timesList, setTimesList] = React.useState<string[]>(getValues(`${jobName}.times_of_day`) || []);

    React.useEffect(() => {
      const formTimes = getValues(`${jobName}.times_of_day`) || [];
      setTimesList(formTimes);
    }, [getValues, jobName]);

    const handleAddTime = () => {
      const newTimes = [...timesList, "00:00"];
      setTimesList(newTimes);
      setValue(`${jobName}.times_of_day`, newTimes);
    };

    const handleTimeChange = (index: number, newTime: string) => {
      const newTimes = [...timesList];
      newTimes[index] = newTime;
      setTimesList(newTimes);
      setValue(`${jobName}.times_of_day`, newTimes);
    };

    const handleDeleteTime = (index: number) => {
      const newTimes = timesList.filter((_, i) => i !== index);
      setTimesList(newTimes);
      setValue(`${jobName}.times_of_day`, newTimes);
    };

    return (
      <div className="space-y-6">
        <h3 className="text-xl font-semibold text-foreground">{title}</h3>
        <FormField
          name={`${jobName}.is_active`}
          render={({ field }) => (
            <FormItem className="text-foreground flex flex-row items-center justify-between rounded-lg border p-4">
              <div className="space-y-0.5">
                <FormLabel className="text-base">Activate/Disable Automation</FormLabel>
              </div>
              <FormControl>
                <Switch checked={field.value} onCheckedChange={field.onChange} />
              </FormControl>
            </FormItem>
          )}
        />

        {jobName === 'invoice_processing' && (
          <FormField
            name={`${jobName}.secondary_status`}
            render={({ field }) => (
              <FormItem className="text-foreground">
                <FormLabel>Invoice Processing Mode</FormLabel>
                <Select value={field.value} onValueChange={field.onChange}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select processing mode" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="draft">Draft Only</SelectItem>
                    <SelectItem value="book">Automatically Book</SelectItem>
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
        )}

        <FormField
          name={`${jobName}.days_of_week`}
          render={({ field }) => (
            <FormItem className="text-foreground">
              <FormLabel>Days of the Week</FormLabel>
              <FormControl>
                <div className="grid grid-cols-4 gap-4">
                  {DAYS_OF_WEEK.map((day, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <Checkbox 
                        checked={field.value?.includes(index)}
                        onCheckedChange={(checked) => {
                          const newValue = checked 
                            ? [...(field.value || []), index]
                            : (field.value || []).filter((v: number) => v !== index)
                          field.onChange(newValue)
                        }}
                      />
                      <span className="text-foreground">{day}</span>
                    </div>
                  ))}
                </div>
              </FormControl>
            </FormItem>
          )}
        />

        <div className="space-y-4">
          {timesList.map((time: string, index: number) => (
            <div key={index} className="flex items-center space-x-4">
              <TimePickerInput
                value={time}
                onChange={(newTime) => handleTimeChange(index, newTime)}
              />
              <span className="text-foreground">UTC</span>
              {index > 0 && (
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleDeleteTime(index)}
                >
                  Delete
                </Button>
              )}
            </div>
          ))}
          <Button
            type="button"
            variant="outline"
            size="sm"
            onClick={handleAddTime}
            className="mt-2"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Time
          </Button>
        </div>

        <div className="flex justify-end">
          <Button onClick={() => onFinish(jobName)}>
            Save Configuration
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl font-bold tracking-tight mb-6 text-foreground">
        Admin Schedule Configuration
      </h2>
      <Form {...form}>
        {activeTab === "overview" && (
          <OverviewContent />
        )}

        {activeTab === "fetch_dashboard_data" && (
          <ScheduleContent 
            jobName="fetch_dashboard_data" 
            title="Dashboard Data Fetch & Processing Schedule" 
          />
        )}

        {activeTab === "recurring_tasks_processing" && (
          <ScheduleContent 
            jobName="recurring_tasks_processing" 
            title="Recurring Tasks Processing Schedule" 
          />
        )}

        {activeTab === "invoice_processing" && (
          <ScheduleContent 
            jobName="invoice_processing" 
            title="Invoice Processing Schedule" 
          />
        )}

        {activeTab === "team_briefings" && (
          <TeamBriefingsContent />
        )}

        {activeTab === "db_backups" && (
          <ScheduleContent 
            jobName="db_backups" 
            title="Database Backup Schedule" 
          />
        )}

        {activeTab === "revibot_sync" && (
          <ScheduleContent 
            jobName="revibot_sync" 
            title="Revibot OneDrive to S3 Sync Schedule" 
          />
        )}
      </Form>
    </div>
  )
}
