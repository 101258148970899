import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent } from "@/components/ui/card";
import { Pencil } from 'lucide-react';
import axios from '../api/axiosConfig';
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { toast } from "sonner";

interface User {
  user_id: number;
  name: string;
  surname: string;
  email: string;
  permissions: string;
  is_test_user: boolean;
}

interface TeamManagementProps {
  allUsers: User[];
  currentUser: User;
  onUpdateUsers: () => Promise<void>;
}

interface TableRow {
  original: User;
}

const formSchema = z.object({
  user_id: z.number().min(1, "User ID is required"),
  permissions: z.string().min(1, "Permissions are required"),
});

function TeamManagement({ allUsers, currentUser, onUpdateUsers }: TeamManagementProps) {
  const [editingUser, setEditingUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      user_id: 0,
      permissions: "",
    },
  });

  const displayUsers = allUsers.filter(user => !user.is_test_user);

  const handleEdit = async (values: z.infer<typeof formSchema>) => {
    try {
      setLoading(true);
      await axios.put(`/user/${editingUser?.user_id}/update`, {
        new_user_id: values.user_id,
        permissions: values.permissions
      });
      
      toast.success("User updated successfully");
      
      if (onUpdateUsers) await onUpdateUsers();
      setEditingUser(null);
    } catch (error) {
      toast.error("Failed to update user");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    ...(currentUser?.permissions === 'owner' ? [{
      header: "User ID",
      accessorKey: "user_id",
    }] : []),
    {
      header: "Name",
      accessorKey: "name",
      cell: ({ row }: { row: TableRow }) => {
        const firstName = row.original.name || '';
        const surname = row.original.surname || '';
        return firstName || surname ? `${firstName} ${surname}`.trim() : "Not Registered";
      },
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Permissions",
      accessorKey: "permissions",
    },
    ...(currentUser?.permissions === 'owner' ? [{
      header: "Actions",
      cell: ({ row }: { row: TableRow }) => (
        <Button
          variant="ghost"
          size="icon"
          onClick={() => {
            setEditingUser(row.original);
            form.setValue("user_id", row.original.user_id);
            form.setValue("permissions", row.original.permissions);
          }}
        >
          <Pencil className="h-4 w-4" />
        </Button>
      ),
    }] : []),
  ];

  return (
    <div className="space-y-6 w-full">
      <h2 className="text-2xl font-semibold">Team Management</h2>
      
      <Card className="w-full">
        <CardContent className="p-0 w-full">
          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column, index) => (
                  <TableHead key={index}>{column.header}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {displayUsers.map((user) => (
                <TableRow key={user.user_id}>
                  {columns.map((column, index) => (
                    <TableCell key={index}>
                      {column.cell ? 
                        column.cell({ row: { original: user } }) : 
                        user[column.accessorKey as keyof User]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Dialog open={!!editingUser} onOpenChange={() => setEditingUser(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit User</DialogTitle>
          </DialogHeader>
          
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleEdit)} className="space-y-4">
              <FormField
                control={form.control}
                name="user_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>User ID</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        {...field} 
                        onChange={e => field.onChange(parseInt(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="permissions"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Permissions</FormLabel>
                    <Select 
                      onValueChange={field.onChange} 
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select permissions" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="user">User</SelectItem>
                        <SelectItem value="admin">Admin</SelectItem>
                        <SelectItem value="owner">Owner</SelectItem>
                        <SelectItem value="accounting">Accounting</SelectItem>
                        <SelectItem value="payroll">Payroll</SelectItem>
                        <SelectItem value="sales">Sales</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit" disabled={loading}>
                Save Changes
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TeamManagement;