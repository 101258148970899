import { io } from 'socket.io-client';

class SocketService {
    constructor() {
        this.socket = null;
        this.enableWebsockets = process.env.REACT_APP_ENABLE_WEBSOCKETS === 'true';
        this.connectionAttempts = 0;
        this.maxAttempts = 3;
        this.connectPromise = null;
        this.pendingCallbacks = new Set();
        this.isConnecting = false;
    }

    async connect() {
        if (this.socket?.connected) {
            return this.socket;
        }

        if (this.isConnecting) {
            return this.connectPromise;
        }

        this.isConnecting = true;
        
        try {
            const socket = await this._connect();
            this.isConnecting = false;
            return socket;
        } catch (error) {
            this.isConnecting = false;
            throw error;
        }
    }

    async _connect() {
        return new Promise((resolve, reject) => {
            if (this.connectPromise) {
                return this.connectPromise;
            }

            this.connectPromise = new Promise(async (resolveConnect, rejectConnect) => {
                try {
                    if (!this.enableWebsockets) {
                        console.log('WebSockets are disabled in this environment');
                        resolveConnect();
                        return;
                    }

                    const token = localStorage.getItem('token');
                    if (!token) {
                        throw new Error('No authentication token available');
                    }

                    const SOCKET_URL = 'wss://erp.digi-tal.dk';
                    const socketOptions = {
                        transports: ['websocket'],
                        path: '/socket.io/socket.io',
                        auth: {
                            token: token
                        },
                        reconnection: true,
                        reconnectionDelay: 1000,
                        reconnectionDelayMax: 5000,
                        reconnectionAttempts: 5
                    };

                    this.socket = io(SOCKET_URL, socketOptions);

                    this.socket.on('connect', () => {
                        console.log('Socket connected successfully');
                        this.pendingCallbacks.forEach(callback => callback());
                        this.pendingCallbacks.clear();
                        resolveConnect(this.socket);
                    });

                    this.socket.on('connect_error', (error) => {
                        console.error('Socket connect_error:', error);
                        rejectConnect(error);
                    });
                } catch (error) {
                    rejectConnect(error);
                }
            });

            this.connectPromise.finally(() => {
                this.connectPromise = null;
            });

            return this.connectPromise;
        });
    }

    onRoomUpdate(callback) {
        if (this.socket) {
            this.socket.on('task_update', callback);
        }
    }

    offRoomUpdate(callback) {
        if (this.socket) {
            this.socket.off('task_update', callback);
        }
    }

    joinRoom(room) {
        if (!this.socket?.connected) {
            console.warn('Socket not connected, queueing room join for:', room);
            this.pendingCallbacks.add(() => this.joinRoom(room));
            return;
        }

        console.log('Joining room:', room);
        this.socket.emit('join', { room });
    }

    leaveRoom(room) {
        if (this.socket?.connected) {
            console.log('Leaving room:', room);
            this.socket.emit('leave', { room });
        }
    }
}

export const socketService = new SocketService();