import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import { toast } from 'sonner';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { StoredUser } from '@/types/user';
import { UserAvatar } from "@/components/user-avatar";
import { useUsers } from '@/contexts/UserContext';

interface AssigneeSelectionProps {
  visible: boolean;
  onClose: () => void;
  onSave: (assignees: string[]) => void;
  initialAssignees?: string[];
  maxAssignees?: number;
}

interface ExtendedStoredUser extends StoredUser {
  is_test_user?: boolean;
}

const AssigneeSelection: React.FC<AssigneeSelectionProps> = ({
  visible,
  onClose,
  onSave,
  initialAssignees = [],
  maxAssignees = Infinity
}) => {
  const { users } = useUsers();
  const [selectedAssignees, setSelectedAssignees] = useState<string[]>([]);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSelectedAssignees(initialAssignees);
  }, [initialAssignees]);

  const handleSave = () => {
    if (selectedAssignees.length === 0) {
      toast.error('Please select at least one assignee');
      return;
    }
    onSave(selectedAssignees);
    onClose();
  };

  const handleRemoveAssignee = (userId: string) => {
    setSelectedAssignees(prev => prev.filter(id => id !== userId));
  };

  const handleSelectAssignee = (userId: string | number) => {
    const stringUserId = userId.toString();
    if (!selectedAssignees.includes(stringUserId)) {
      if (maxAssignees === 1) {
        setSelectedAssignees([stringUserId]);
      } else if (selectedAssignees.length < maxAssignees) {
        setSelectedAssignees(prev => [...prev, stringUserId]);
      } else {
        toast.warning(`Maximum of ${maxAssignees} assignees allowed`);
      }
    }
  };

  const getAvatarUrl = (user: StoredUser): string => {
    return user.avatar_urls?.medium || user.avatar_urls?.small || '';
  };

  const getAvatarFallback = (user: StoredUser): string => {
    const firstInitial = user.name?.[0] || '';
    const lastInitial = user.surname?.[0] || '';
    return `${firstInitial}${lastInitial}`;
  };

  const getDisplayName = (user: StoredUser): string => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || 'Unknown User';
  };

  const filteredUsers = users.filter((user: ExtendedStoredUser) => {
    if (!user || user.is_test_user) return false;
    const fullName = getDisplayName(user).toLowerCase();
    return fullName.includes(searchText.toLowerCase());
  });

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Select Assignees</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col gap-4">
          <div className="flex flex-wrap gap-2 min-h-[32px]">
            {selectedAssignees.map(userId => {
              const user = users.find(u => u?.user_id?.toString() === userId);
              if (!user) return null;
              
              return (
                <Badge 
                  key={userId}
                  variant="secondary"
                  className="flex items-center gap-2 py-1 px-2"
                >
                  <UserAvatar 
                    user={user}
                    size={24}
                    disableTooltip
                  />
                  <span>{getDisplayName(user)}</span>
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-4 w-4 p-0 hover:bg-transparent"
                    onClick={() => handleRemoveAssignee(userId)}
                  >
                    <X className="h-3 w-3" />
                  </Button>
                </Badge>
              );
            })}
          </div>

          <div className="relative">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-muted-foreground" />
            <Input
              placeholder="Search users..."
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              className="pl-9"
            />
          </div>

          <ScrollArea className="h-[300px] rounded-md border">
            <div className="p-1">
              {filteredUsers.map(user => (
                <Button
                  key={user.user_id}
                  variant="ghost"
                  className={cn(
                    "w-full justify-start gap-2 px-2",
                    selectedAssignees.includes(user.user_id?.toString()) && "bg-accent"
                  )}
                  onClick={() => handleSelectAssignee(user.user_id)}
                >
                  <UserAvatar 
                    user={user}
                    size={32}
                    disableTooltip
                  />
                  <span>{getDisplayName(user)}</span>
                </Button>
              ))}
            </div>
          </ScrollArea>

          <div className="flex justify-end gap-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={handleSave}>
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export { AssigneeSelection };