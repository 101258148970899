import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import type { Account, Contact, Deal, Product } from '@/types/customers';

interface AccountTreeProps {
  visible: boolean;
  onClose: () => void;
  account: Account | null;
}

const AccountTree: React.FC<AccountTreeProps> = ({ visible, onClose, account }) => {
  const [expandedProducts, setExpandedProducts] = useState<{ [key: string]: boolean }>({});

  const toggleProductExpansion = (dealId: number, productId: number) => {
    setExpandedProducts(prev => ({
      ...prev,
      [`${dealId}-${productId}`]: !prev[`${dealId}-${productId}`]
    }));
  };

  if (!account) return null;

  // Get primary contact (first contact in the list)
  const primaryContact = account.contacts?.[0];

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="max-w-4xl max-h-[90vh]">
        <DialogHeader>
          <DialogTitle>Account Structure</DialogTitle>
        </DialogHeader>
        <ScrollArea className="h-[80vh] pr-4">
          <div className="flex flex-col items-center space-y-4">
            {/* Account Card */}
            <Card className="w-[90%] md:w-2/3">
              <CardHeader>
                <h3 className="text-lg font-semibold">Account</h3>
              </CardHeader>
              <CardContent>
                <p className="font-medium">{account.company_name}</p>
                <p className="text-muted-foreground">CVR: {account.cvr}</p>
              </CardContent>
            </Card>

            {/* Vertical Line */}
            <div className="w-1 h-8 bg-primary/60" />

            {/* Primary Contact Card */}
            {primaryContact && (
              <>
                <Card className="w-[90%] md:w-2/3">
                  <CardHeader>
                    <h3 className="text-lg font-semibold">Primary Contact</h3>
                  </CardHeader>
                  <CardContent>
                    <p className="font-medium">{primaryContact.name}</p>
                    <p className="text-sm text-muted-foreground">{primaryContact.email}</p>
                    <p className="text-sm text-muted-foreground">{primaryContact.phone}</p>
                  </CardContent>
                </Card>

                {/* Vertical Line */}
                <div className="w-1 h-8 bg-primary/60" />
              </>
            )}

            {/* Deals Section with Branching */}
            {account.deals && account.deals.length > 0 && (
              <div className="w-full grid grid-cols-1 gap-8">
                {/* Horizontal Line for Branching */}
                {account.deals.length > 1 && (
                  <div className="relative h-1">
                    <div className="absolute left-1/2 w-[80%] h-1 bg-primary/60 -translate-x-1/2" />
                  </div>
                )}

                {/* Deals Grid */}
                <div className={`grid grid-cols-${Math.min(account.deals.length, 3)} gap-4`}>
                  {account.deals.map((deal, index) => {
                    // Use the contact relationship directly from the deal
                    const dealContact = deal.contact;
                    return (
                      <div key={deal.id} className="flex flex-col items-center">
                        {/* Vertical Line from Branch */}
                        {account.deals.length > 1 && <div className="w-1 h-8 bg-primary/60" />}

                        {/* Deal Card */}
                        <Card className="w-full">
                          <CardHeader>
                            <h3 className="text-lg font-semibold">Deal: {deal.name || 'N/A'}</h3>
                          </CardHeader>
                          <CardContent>
                            <p className="text-muted-foreground">CVR: {deal.cvr || 'N/A'}</p>
                            <p className="text-muted-foreground">
                              Value: {deal.data?.value || 'N/A'} {deal.data?.currency || ''}
                            </p>
                            {dealContact && (
                              <p className="mt-2 text-sm font-medium">
                                Contact: {dealContact.name.split(' ')[0]}
                              </p>
                            )}
                          </CardContent>
                        </Card>

                        {/* Vertical Line to Products */}
                        <div className="w-1 h-8 bg-primary/60" />

                        {/* Products Card */}
                        <Card className="w-full">
                          <CardHeader>
                            <h3 className="text-lg font-semibold">Products</h3>
                          </CardHeader>
                          <CardContent className="space-y-2">
                            {deal.products?.map(product => (
                              <Card key={product.id} className="border border-border/50">
                                <CardContent className="p-4">
                                  <div className="flex justify-between items-center">
                                    <h4 className="font-medium">{product.name}</h4>
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={() => toggleProductExpansion(deal.id, product.id)}
                                    >
                                      {expandedProducts[`${deal.id}-${product.id}`] ? 
                                        <ChevronUp className="h-4 w-4" /> : 
                                        <ChevronDown className="h-4 w-4" />
                                      }
                                    </Button>
                                  </div>
                                  {expandedProducts[`${deal.id}-${product.id}`] && (
                                    <div className="mt-2 space-y-1 text-sm text-muted-foreground">
                                      <p>Code: {product.code}</p>
                                      <p>Quantity: {product.quantity}</p>
                                      <p>Price: {product.item_price}</p>
                                      <p>Sum: {product.sum}</p>
                                      <p>Billing Frequency: {product.billing_frequency}</p>
                                    </div>
                                  )}
                                </CardContent>
                              </Card>
                            ))}
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default AccountTree; 