import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'sonner'
import axios from '@/api/axiosConfig'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Loading } from "@/components/ui/loading"
import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import {
  RadioGroup,
  RadioGroupItem
} from "@/components/ui/radio-group"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"

// Icons
import { Trash2 } from 'lucide-react'

// Types
interface ChurnTask {
  id: number
  recurring_type: string
  description: string
  progress_status: string
  assignees: Array<{
    id: number
    full_name: string
  }>
}

interface ChurnDetail {
  deal_name: string
  reason: string
  reason_detail: string
  status: string
  created_at: string
  is_winback_possible: boolean
}

interface TasksData {
  onboarding_tasks: ChurnTask[]
  recurring_tasks: ChurnTask[]
}

const ChurnDetails = () => {
  const { id } = useParams<{ id: string }>()
  const [churn, setChurn] = useState<ChurnDetail | null>(null)
  const [tasks, setTasks] = useState<TasksData>({ onboarding_tasks: [], recurring_tasks: [] })
  const [loading, setLoading] = useState(true)

  const fetchChurnDetails = async () => {
    try {
      const response = await axios.get(`/churn/${id}`)
      setChurn(response.data)
    } catch (error) {
      toast.error('Failed to fetch churn details')
      console.error('Error:', error)
    }
  }

  const fetchAssociatedTasks = async () => {
    try {
      const response = await axios.get(`/churn/${id}/tasks`)
      setTasks(response.data)
    } catch (error) {
      toast.error('Failed to fetch tasks')
      console.error('Error:', error)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        await Promise.all([
          fetchChurnDetails(),
          fetchAssociatedTasks()
        ])
      } catch (error) {
        toast.error('Failed to fetch data')
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [id])

  const handleWinbackStatusChange = async (value: string) => {
    try {
      await axios.put(`/churn/${id}/winback`, {
        isWinbackPossible: value === 'true'
      })
      toast.success('Winback status updated successfully')
      await fetchChurnDetails()
    } catch (error) {
      toast.error('Failed to update winback status')
      console.error('Error:', error)
    }
  }

  const handleDeleteTask = async (task: ChurnTask) => {
    try {
      await axios.delete(`/churn/delete-task/${task.recurring_type}/${task.id}`)
      toast.success('Task deleted successfully')
      await fetchAssociatedTasks()
    } catch (error) {
      toast.error('Failed to delete task')
      console.error('Error:', error)
    }
  }

  const taskColumns: ColumnDef<ChurnTask>[] = [
    {
      key: 'recurring_type',
      title: 'Task Type',
      dataIndex: 'recurring_type',
      cell: ({ row }) => row.original.recurring_type.charAt(0).toUpperCase() + row.original.recurring_type.slice(1)
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description'
    },
    {
      key: 'progress_status',
      title: 'Status',
      dataIndex: 'progress_status'
    },
    {
      key: 'assignees',
      title: 'Assignees',
      dataIndex: 'assignees',
      cell: ({ row }) => (
        <div className="flex gap-2">
          {row.original.assignees?.map(assignee => (
            <Badge key={assignee.id} variant="secondary">
              {assignee.full_name}
            </Badge>
          ))}
        </div>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <Button 
          variant="ghost" 
          size="icon"
          onClick={() => handleDeleteTask(row.original)}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      )
    }
  ]

  if (loading) {
    return <Loading message="Loading details..." />
  }

  return (
    <div className="space-y-8 p-8">
      <Card>
        <CardHeader>
          <CardTitle>Churn Details</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Customer: {churn?.deal_name}</h3>
            <p className="text-sm text-muted-foreground">Reason: {churn?.reason}</p>
            <p className="text-sm text-muted-foreground">Details: {churn?.reason_detail}</p>
            <p className="text-sm text-muted-foreground">Status: {churn?.status}</p>
            <p className="text-sm text-muted-foreground">
              Created: {churn?.created_at ? new Date(churn.created_at).toLocaleDateString() : ''}
            </p>
          </div>

          <Separator />

          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Winback Possibility</h3>
            <RadioGroup
              defaultValue={churn?.is_winback_possible?.toString()}
              onValueChange={handleWinbackStatusChange}
              className="flex gap-4"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="true" id="winback-possible" />
                <Label htmlFor="winback-possible">Possible Winback</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="false" id="winback-not-possible" />
                <Label htmlFor="winback-not-possible">Not Possible</Label>
              </div>
            </RadioGroup>
          </div>

          {!churn?.is_winback_possible && (
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-semibold mb-4">Onboarding Tasks</h3>
                <Table
                  data={tasks.onboarding_tasks}
                  columns={taskColumns}
                  pageSize={5}
                />
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-4">Recurring Tasks</h3>
                <Table
                  data={tasks.recurring_tasks}
                  columns={taskColumns}
                  pageSize={5}
                />
              </div>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default ChurnDetails