import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, DatePicker, Input, message, Tag, Tooltip, Typography, Space } from 'antd';
import { PhoneOutlined, CheckCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import OnboardingForm from './forms/OnboardingForm';
import BookOnboardingMeeting from './modals/BookOnboardingMeeting';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const OnboardingNew = () => {
  const navigate = useNavigate();
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const [meetingModalVisible, setMeetingModalVisible] = useState(false);
  const [accessData, setAccessData] = useState(null);
  const [accessLoading, setAccessLoading] = useState(false);
  const [formVisible, setFormVisible] = useState(false);

  useEffect(() => {
    fetchDeals();
  }, []);

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/onboarding/deals/not-onboarded');
      setDeals(response.data);
    } catch (error) {
      message.error('Failed to fetch deals');
    } finally {
      setLoading(false);
    }
  };

  const checkAccess = async (deal) => {
    setSelectedDeal(deal);
    setAccessModalVisible(true);
    setAccessLoading(true);
    
    try {
      const response = await axios.get(`/onboarding/check-access/${deal.cvr}`);
      setAccessData(response.data);
    } catch (error) {
      message.error('Failed to check access');
    } finally {
      setAccessLoading(false);
    }
  };

  const startOnboarding = async (values) => {
    try {
      await axios.post('/onboarding/start', {
        deal_id: selectedDeal.id,
        meeting_date: values.meeting_date.toISOString(),
        meeting_notes: values.notes
      });
      
      message.success('Meeting booked successfully');
      setMeetingModalVisible(false);
      fetchDeals(); // Refresh list
      
    } catch (error) {
      message.error('Failed to book meeting');
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone) => (
        <Button 
          icon={<PhoneOutlined />} 
          type="link"
          onClick={() => window.open(`tel:${phone}`)}
        >
          {phone}
        </Button>
      )
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button 
            onClick={() => checkAccess(record)}
            style={{ marginRight: 8 }}
          >
            Check Access
          </Button>
          <Button 
            type="primary"
            onClick={() => {
              setSelectedDeal(record);
              setMeetingModalVisible(true);
            }}
          >
            Book Meeting
          </Button>
        </>
      )
    }
  ];

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%', marginBottom: 24 }}>
        <Button 
          icon={<ArrowLeftOutlined />} 
          onClick={() => navigate('/onboarding')}
        />
        <Space>
          <Title level={2} style={{ margin: 0 }}>New Deals</Title>
        </Space>
        <Typography.Text type="secondary">
          Start onboarding process for new customers
        </Typography.Text>
      </Space>
      
      <Table 
        columns={columns} 
        dataSource={deals}
        loading={loading}
        rowKey="id"
        pagination={false}
      />

      <Modal
        title="Customer Access"
        open={accessModalVisible}
        onCancel={() => setAccessModalVisible(false)}
        footer={null}
      >
        {accessLoading ? (
          <div>Checking access...</div>
        ) : (
          <div>
            {accessData?.access_types.map(type => (
              <Tag key={type} color="green">
                <CheckCircleOutlined /> {type}
              </Tag>
            ))}
            {accessData?.latest_date && (
              <div style={{ marginTop: 16 }}>
                Last updated: {dayjs(accessData.latest_date).format('YYYY-MM-DD')}
              </div>
            )}
          </div>
        )}
      </Modal>

      <BookOnboardingMeeting 
        visible={meetingModalVisible}
        onCancel={() => setMeetingModalVisible(false)}
        deal={selectedDeal}
        onSubmit={startOnboarding}
      />

      {formVisible && selectedDeal && (
        <OnboardingForm 
          deal={selectedDeal}
          visible={formVisible}
          onClose={() => setFormVisible(false)}
        />
      )}
    </div>
  );
};

export default OnboardingNew;