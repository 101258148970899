import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm, useFieldArray } from 'react-hook-form'
import * as z from 'zod'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { Plus, Minus, X } from 'lucide-react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui/tabs'
import { Separator } from '@/components/ui/separator'
import { AssigneeSelection } from '@/modals/AssigneeSelection'
import { ANT_COLORS } from '@/configs/colors'

import { useUsers } from '@/contexts/UserContext'

interface CreateBoardModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  currentUser: any;
}

const formSchema = z.object({
  name: z.string().min(1, 'Please enter a board name'),
  description: z.string().optional(),
  is_public: z.boolean().default(true),
  board_type: z.enum(['taskboard', 'project']).default('taskboard'),
  requires_deal: z.boolean().default(false),
  requires_work_date: z.boolean().default(false),
  requires_due_date: z.boolean().default(false),
  show_in_my_work: z.boolean().default(true),
  enable_time_tracking: z.boolean().default(true),
  kanban_source: z.string().default('progress'),
  progress_status_options: z.array(z.object({
    label: z.string(),
    color: z.string(),
  })),
  board_status_options: z.array(z.object({
    label: z.string(),
    color: z.string(),
  })).optional(),
})

interface FieldArrayItem {
  id: string;
  label: string;
  color: string;
}

const CreateBoardModal = ({
  open,
  onClose,
  onSuccess,
  currentUser,
}: CreateBoardModalProps) => {
  const navigate = useNavigate()
  const [isPublic, setIsPublic] = useState(true)
  const [kanbanSource, setKanbanSource] = useState('progress')
  const [showAssigneeModal, setShowAssigneeModal] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [useBoardStatus, setUseBoardStatus] = useState(false)

  const { users } = useUsers();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      is_public: true,
      show_in_my_work: true,
      enable_time_tracking: true,
      requires_deal: false,
      requires_work_date: false,
      requires_due_date: false,
      kanban_source: 'progress',
      progress_status_options: [
        { label: 'Not Started', color: '#ff4d4f' },
        { label: 'In Progress', color: '#ffa940' },
        { label: 'Completed', color: '#52c41a' }
      ],
      board_status_options: []
    }
  })

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'progress_status_options'
  })

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (!values.is_public && selectedUsers.length === 0) {
        toast.error('Private boards must have at least one user with access')
        return
      }

      const boardData = {
        name: values.name,
        description: values.description,
        is_public: isPublic,
        board_type: values.board_type,
        requires_deal: values.requires_deal,
        requires_work_date: values.requires_work_date,
        requires_due_date: values.requires_due_date,
        kanban_source: kanbanSource,
        progress_status_options: values.progress_status_options.map((status, index) => ({
          label: status.label,
          color: status.color || '#1890ff',
          order: index
        })),
        board_status_options: values.board_status_options?.map((status, index) => ({
          label: status.label,
          color: status.color || '#1890ff',
          order: index
        })) || [],
        user_access: selectedUsers
      }

      const response = await axios.post('/custom-board/boards', boardData)
      
      if (response.status === 200) {
        toast.success('Board created successfully')
        form.reset()
        setSelectedUsers([])
        setIsPublic(true)
        onSuccess()
        onClose()
        
        navigate(`/boards/custom/${response.data.id}`)
      }
    } catch (error) {
      console.error('Error creating board:', error)
      toast.error('Failed to create board')
    }
  }

  const handlePublicChange = (checked: boolean) => {
    setIsPublic(checked)
    if (!checked && currentUser) {
      setSelectedUsers([currentUser.user_id.toString()])
    } else {
      setSelectedUsers([])
    }
  }

  const renderStatusOptionsList = (type: 'progress' | 'board') => {
    const fieldName = type === 'progress' ? 'progress_status_options' : 'board_status_options'
    
    return (
      <div className="space-y-4">
        {fields.map((field: FieldArrayItem, index: number) => (
          <div key={field.id} className="flex items-center gap-4 p-4 bg-muted rounded-md">
            <FormField
              control={form.control}
              name={`${fieldName}.${index}.label`}
              render={({ field }) => (
                <FormItem className="flex-1">
                  <FormLabel className={index === 0 ? 'block' : 'sr-only'}>
                    {type === 'progress' ? 'Progress' : 'Board'} Status Options
                  </FormLabel>
                  <FormControl>
                    <div className="flex gap-2">
                      <Input
                        {...field}
                        placeholder="Label (e.g., Not Started)"
                        className="flex-1"
                      />
                      <Select
                        onValueChange={(value) => 
                          form.setValue(`${fieldName}.${index}.color`, value)
                        }
                        value={form.getValues(`${fieldName}.${index}.color`)}
                      >
                        <SelectTrigger className="w-[180px]">
                          <SelectValue placeholder="Select color" />
                        </SelectTrigger>
                        <SelectContent>
                          {ANT_COLORS.map(color => (
                            <SelectItem
                              key={color.value}
                              value={color.value}
                            >
                              <div 
                                className="w-full h-5 flex items-center px-2 rounded"
                                style={{ 
                                  backgroundColor: color.value,
                                  color: ['#ffec3d', '#fff566', '#ffd666', '#fff1b8'].includes(color.value) ? '#000000' : '#ffffff'
                                }}
                              >
                                {color.name}
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            {(fields.length > 1 || type === 'board') && (
              <Button
                variant="ghost"
                size="icon"
                onClick={() => remove(index)}
                className="text-destructive"
              >
                <Minus className="h-4 w-4" />
              </Button>
            )}
          </div>
        ))}
        <Button
          type="button"
          variant="outline"
          onClick={() => append({ label: '', color: '#1890ff' })}
          className="w-full"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add {type === 'progress' ? 'Progress' : 'Board'} Status
        </Button>
      </div>
    )
  }

  const resetModal = () => {
    form.reset()
    setIsPublic(true)
    setKanbanSource('progress')
    setShowAssigneeModal(false)
    setSelectedUsers([])
    setUseBoardStatus(false)
  }

  useEffect(() => {
    if (open) {
      resetModal()
    }
  }, [open])

  useEffect(() => {
    if (!isPublic && selectedUsers.length === 0 && currentUser) {
      setSelectedUsers([currentUser.user_id.toString()])
    }
  }, [isPublic])

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Create New Board</DialogTitle>
          </DialogHeader>

          <Tabs defaultValue="general" className="w-full">
            <TabsList>
              <TabsTrigger value="general">General</TabsTrigger>
              <TabsTrigger value="status">Status Options</TabsTrigger>
            </TabsList>

            <Form {...form}>
              <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
                <TabsContent value="general" className="space-y-4">
                  {/* General tab content */}
                  <FormField
                    control={form.control}
                    name="board_type"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Board Type</FormLabel>
                        <Select
                          onValueChange={field.onChange}
                          value={field.value}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder="Select board type" />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="taskboard">Taskboard</SelectItem>
                            <SelectItem value="project">Project</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormItem>
                    )}
                  />
                  {/* ... other form fields ... */}
                </TabsContent>

                <TabsContent value="status" className="space-y-6">
                  <div className="p-6 bg-muted rounded-lg space-y-4">
                    <h3 className="text-lg font-semibold">
                      Progress Status Options (Required)
                    </h3>
                    {renderStatusOptionsList('progress')}
                  </div>

                  <Separator />

                  <div className="p-6 bg-muted rounded-lg space-y-4">
                    <div className="flex items-center justify-between">
                      <h3 className="text-lg font-semibold">
                        Additional Board Status
                      </h3>
                      <div className="flex items-center gap-4">
                        <Switch
                          checked={useBoardStatus}
                          onCheckedChange={(checked) => {
                            setUseBoardStatus(checked)
                            if (checked) {
                              setKanbanSource('board_status')
                            } else {
                              setKanbanSource('progress')
                              form.setValue('board_status_options', [])
                            }
                          }}
                        />
                        {useBoardStatus && (
                          <div className="flex gap-2">
                            <Button
                              variant={kanbanSource === 'progress' ? 'default' : 'outline'}
                              onClick={() => setKanbanSource('progress')}
                              size="sm"
                            >
                              Progress Status
                            </Button>
                            <Button
                              variant={kanbanSource === 'board_status' ? 'default' : 'outline'}
                              onClick={() => setKanbanSource('board_status')}
                              size="sm"
                            >
                              Board Status
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                    {useBoardStatus && renderStatusOptionsList('board')}
                  </div>
                </TabsContent>

                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit">Create Board</Button>
                </div>
              </form>
            </Form>
          </Tabs>
        </DialogContent>
      </Dialog>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={(users) => {
          setSelectedUsers(users)
          setShowAssigneeModal(false)
        }}
        initialAssignees={selectedUsers}
      />
    </>
  )
}

export default CreateBoardModal