import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import { Bot, LayoutDashboard, Settings, TestTube, PenToolIcon as Tool } from 'lucide-react';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { hasRequiredPermission } from '../utils/permissionUtils';
import { useTheme } from '@/contexts/ThemeContext';

// Image imports
const tuesdayLight = 'https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/logos/tuesday_x200_b.png';
const tuesdayDark = 'https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/logos/tuesday_x200_w.png';

type ThemeType = 'light' | 'dark';

interface MainMenuProps {
  user: {
    permissions: string[];
    [key: string]: any;
  };
}

export default function MainMenu({ user }: MainMenuProps) {
  const navigate = useNavigate();
  const { theme } = useTheme();
  const tuesdayLogo = theme === 'dark' ? tuesdayDark : tuesdayLight;

  if (!user) {
    return <div>Loading...</div>;
  }

  const handleAppSelection = (app: string) => {
    if (app === 'crm' && hasRequiredPermission(user.permissions, 'user')) {
      navigate('/home');
    } else if (app === 'dashboard' && hasRequiredPermission(user.permissions, 'admin')) {
      navigate('/digi-dashboard');
    } else if (app === 'bot' && hasRequiredPermission(user.permissions, 'user')) {
      navigate('/bot');
    } else if (app === 'officebot' && hasRequiredPermission(user.permissions, 'user')) {
      navigate('/officebot');
    } else if (app === 'custom-gpts' && hasRequiredPermission(user.permissions, 'admin')) {
      navigate('/custom-gpts');
    } else if (app === 'admin' && hasRequiredPermission(user.permissions, 'owner')) {
      navigate('/admin');
    } else {
      message.error('You do not have permission to access this application');
    }
  };

  return (
    <div className="min-h-screen bg-background p-6 flex items-center">
      <div className="mx-auto max-w-6xl space-y-8 w-full">
        <div className="text-center">
          <h1 className="text-3xl font-bold tracking-tight text-foreground">Welcome to Digi-Tal ERP</h1>
        </div>

        {/* Apps Section */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-muted-foreground border-b border-border pb-2">Apps</h2>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            {/* CRM Card */}
            <Card 
              className={cn(
                "transition-all hover:-translate-y-1 cursor-pointer",
                !hasRequiredPermission(user.permissions, 'user') && "opacity-60 cursor-not-allowed"
              )}
              onClick={() => handleAppSelection('crm')}
            >
              <CardHeader>
                <CardTitle className="text-2xl font-semibold">
                  <div className="flex items-center gap-2">
                    <img src={tuesdayLogo} alt="Tuesday Logo" className="h-8 object-contain" />
                  </div>
                </CardTitle>
                <CardDescription className="text-left text-muted-foreground">
                  CRM & Task Management
                </CardDescription>
                {!hasRequiredPermission(user.permissions, 'user') && 
                  <p className="text-sm text-destructive">No Access</p>
                }
              </CardHeader>
            </Card>

            {/* Dashboard Card */}
            <Card 
              className={cn(
                "transition-all hover:-translate-y-1 cursor-pointer",
                !hasRequiredPermission(user.permissions, 'admin') && "opacity-60 cursor-not-allowed"
              )}
              onClick={() => handleAppSelection('dashboard')}
            >
              <CardHeader>
                <CardTitle className="text-2xl font-semibold">
                  <div className="flex items-center gap-2">
                    <LayoutDashboard className="h-6 w-6 text-primary" />
                    <span>digi-dashboard</span>
                  </div>
                </CardTitle>
                <CardDescription className="text-left text-muted-foreground">
                  View and analyze business data
                </CardDescription>
                {!hasRequiredPermission(user.permissions, 'admin') && 
                  <p className="text-sm text-destructive">No Access</p>
                }
              </CardHeader>
            </Card>

            {/* Bot Card */}
            <Card 
              className={cn(
                "transition-all hover:-translate-y-1 cursor-pointer",
                !hasRequiredPermission(user.permissions, 'user') && "opacity-60 cursor-not-allowed"
              )}
              onClick={() => handleAppSelection('bot')}
            >
              <CardHeader>
                <CardTitle className="text-2xl font-semibold">
                  <div className="flex items-center gap-2">
                    <Bot className="h-6 w-6 text-primary" />
                    <span>Digi-Regnskabsbot</span>
                  </div>
                </CardTitle>
                <CardDescription className="text-left text-muted-foreground">
                  AI Chatbot for all your accounting questions
                </CardDescription>
                {!hasRequiredPermission(user.permissions, 'user') && 
                  <p className="text-sm text-destructive">No Access</p>
                }
              </CardHeader>
            </Card>
          </div>
        </div>

        {/* Tools Section */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-muted-foreground border-b border-border pb-2">Tools</h2>
          <div className="grid gap-4 md:grid-cols-2">
            {/* Officebot Card */}
            <Card 
              className={cn(
                "transition-all hover:-translate-y-1 cursor-pointer",
                !hasRequiredPermission(user.permissions, 'user') && "opacity-60 cursor-not-allowed"
              )}
              onClick={() => handleAppSelection('officebot')}
            >
              <CardHeader>
                <CardTitle className="text-2xl font-semibold">
                  <div className="flex items-center gap-2">
                    <Tool className="h-6 w-6 text-primary" />
                    <span>Officebot Tools</span>
                  </div>
                </CardTitle>
                <CardDescription className="text-left text-muted-foreground">
                  Automations and tools for Officebot
                </CardDescription>
                {!hasRequiredPermission(user.permissions, 'user') && 
                  <p className="text-sm text-destructive">No Access</p>
                }
              </CardHeader>
            </Card>

            {/* Custom GPTs Card */}
            <Card 
              className={cn(
                "transition-all hover:-translate-y-1 cursor-pointer",
                !hasRequiredPermission(user.permissions, 'admin') && "opacity-60 cursor-not-allowed"
              )}
              onClick={() => handleAppSelection('custom-gpts')}
            >
              <CardHeader>
                <CardTitle className="text-2xl font-semibold">
                  <div className="flex items-center gap-2">
                    <TestTube className="h-6 w-6 text-primary" />
                    <span>Custom GPTs</span>
                  </div>
                </CardTitle>
                <CardDescription className="text-left text-muted-foreground">
                  GPT models with custom data
                </CardDescription>
                {!hasRequiredPermission(user.permissions, 'admin') && 
                  <p className="text-sm text-destructive">No Access</p>
                }
              </CardHeader>
            </Card>
          </div>
        </div>

        {/* Admin Section */}
        <div className="space-y-4">
          <h2 className="text-xl font-semibold text-muted-foreground border-b border-border pb-2">Admin</h2>
          <div className="grid gap-4">
            <Card 
              className={cn(
                "transition-all hover:-translate-y-1 cursor-pointer",
                !hasRequiredPermission(user.permissions, 'owner') && "opacity-60 cursor-not-allowed"
              )}
              onClick={() => handleAppSelection('admin')}
            >
              <CardHeader>
                <CardTitle className="text-2xl font-semibold">
                  <div className="flex items-center gap-2">
                    <Settings className="h-6 w-6 text-primary" />
                    <span>Admin</span>
                  </div>
                </CardTitle>
                <CardDescription className="text-left text-muted-foreground">
                  Admin control panel
                </CardDescription>
                {!hasRequiredPermission(user.permissions, 'owner') && 
                  <p className="text-sm text-destructive">No Access</p>
                }
              </CardHeader>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
