import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { DatePicker } from "@/components/ui/date/date-picker"
import { Checkbox } from "@/components/ui/checkbox"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Plus, X } from "lucide-react"
import axios from '../api/axiosConfig'
import { Contact, Deal } from '../types/customers'
import { dealDataConfig } from '@/configs/DealDataConfig'
import { Card, CardContent } from "@/components/ui/card"
import { dealProductConfig } from '@/configs/DealProductConfig'
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig'
import { Badge } from "@/components/ui/badge"

interface EmailRecipientSelectorProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onAddRecipients: (recipients: Array<{ email: string; name: string }>) => void
}

// Get the relevant config items
const companyTypeConfig = dealDataConfig.find(item => item.key === 'data.virksomhedsform')
const accountingSystemConfig = dealDataConfig.find(item => item.key === 'data.regnskabsprogram')
const accountManagerConfig = dealDataConfig.find(item => item.key === 'data.ansvarlig_bogholder')
const bookkeepingFreqConfig = dealDataConfig.find(item => item.key === 'data.bogføringsfrekvens')

type FilterType = 'products' | 'companyType' | 'dealType' | 'createdAfter' | 
                 'accountingSystem' | 'accountManager' | 'bookkeepingFreq' | 'lønprogram';

interface FilterOption {
  id: FilterType
  label: string
  component: React.ReactNode
}

export function EmailRecipientSelector({ open, onOpenChange, onAddRecipients }: EmailRecipientSelectorProps) {
  const [deals, setDeals] = useState<Deal[]>([])
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([])
  const [selectedContacts, setSelectedContacts] = useState<Set<string>>(new Set())
  const [activeFilters, setActiveFilters] = useState<Set<FilterType>>(new Set())
  
  // Filter states
  const [productFilter, setProductFilter] = useState<string[]>([])
  const [companyTypeFilter, setCompanyTypeFilter] = useState<string>("")
  const [dealTypeFilter, setDealTypeFilter] = useState<string>("")
  const [createdAfter, setCreatedAfter] = useState<Date | undefined>(undefined)
  const [accountingSystem, setAccountingSystem] = useState<string>("")
  const [accountManager, setAccountManager] = useState<string>("")
  const [bookkeepingFreq, setBookkeepingFreq] = useState<string>("")
  const [lønprogram, setLønprogram] = useState("")

  // Reset all filters when dialog opens
  useEffect(() => {
    if (open) {
      setActiveFilters(new Set())
      setProductFilter([])
      setCompanyTypeFilter("")
      setDealTypeFilter("")
      setCreatedAfter(undefined)
      setAccountingSystem("")
      setAccountManager("")
      setBookkeepingFreq("")
      setSelectedContacts(new Set())
    }
  }, [open])

  const filterOptions: FilterOption[] = [
    {
      id: 'products',
      label: 'Products',
      component: (
        <Select
          value=""
          onValueChange={(value) => {
            if (!productFilter.includes(value)) {
              setProductFilter([...productFilter, value])
            }
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select products" />
          </SelectTrigger>
          <SelectContent>
            {dealProductConfig.options
              .filter(option => !productFilter.includes(option.value))
              .map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'companyType',
      label: 'Company Type',
      component: (
        <Select
          value={companyTypeFilter}
          onValueChange={setCompanyTypeFilter}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select company type" />
          </SelectTrigger>
          <SelectContent>
            {companyTypeConfig?.options?.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'dealType',
      label: 'Deal Type',
      component: (
        <Select
          value={dealTypeFilter}
          onValueChange={setDealTypeFilter}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select deal type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="drift">Drift</SelectItem>
            <SelectItem value="holding">Holding</SelectItem>
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'createdAfter',
      label: 'Created After',
      component: (
        <DatePicker
          date={createdAfter}
          onSelect={(date) => setCreatedAfter(date)}
        />
      )
    },
    {
      id: 'accountingSystem',
      label: 'Accounting System',
      component: (
        <Select
          value={accountingSystem}
          onValueChange={setAccountingSystem}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select system" />
          </SelectTrigger>
          <SelectContent>
            {accountingSystemConfig?.options?.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'accountManager',
      label: 'Account Manager',
      component: (
        <Select
          value={accountManager}
          onValueChange={setAccountManager}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select manager" />
          </SelectTrigger>
          <SelectContent>
            {accountManagerConfig?.options?.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'bookkeepingFreq',
      label: 'Bookkeeping Frequency',
      component: (
        <Select
          value={bookkeepingFreq}
          onValueChange={setBookkeepingFreq}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select frequency" />
          </SelectTrigger>
          <SelectContent>
            {bookkeepingFreqConfig?.options?.map(option => (
              <SelectItem key={option.value} value={option.value}>
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'lønprogram',
      label: 'Lønprogram',
      component: (
        <Select
          value={lønprogram}
          onValueChange={setLønprogram}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select lønprogram" />
          </SelectTrigger>
          <SelectContent>
            {dealSoftwareConfig
              .find(config => config.key === 'lønprogram')
              ?.options?.map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      )
    }
  ]

  const addFilter = (filterId: FilterType) => {
    setActiveFilters(prev => new Set(Array.from(prev).concat(filterId)))
  }

  const removeFilter = (filterId: FilterType, value?: string) => {
    if (filterId === 'products' && value) {
      setProductFilter(productFilter.filter(p => p !== value))
      if (productFilter.length === 1) {
        activeFilters.delete(filterId)
        setActiveFilters(new Set(activeFilters))
      }
      return
    }
    
    activeFilters.delete(filterId)
    setActiveFilters(new Set(activeFilters))
    switch (filterId) {
      case 'companyType':
        setCompanyTypeFilter("")
        break
      case 'dealType':
        setDealTypeFilter("")
        break
      case 'createdAfter':
        setCreatedAfter(undefined)
        break
      case 'accountingSystem':
        setAccountingSystem("")
        break
      case 'accountManager':
        setAccountManager("")
        break
      case 'bookkeepingFreq':
        setBookkeepingFreq("")
        break
      case 'lønprogram':
        setLønprogram("")
        break
    }
  }

  const availableFilters = filterOptions.filter(filter => !activeFilters.has(filter.id))

  useEffect(() => {
    fetchDeals()
  }, [])

  useEffect(() => {
    applyFilters()
  }, [deals, productFilter, companyTypeFilter, dealTypeFilter, createdAfter, 
      accountingSystem, accountManager, bookkeepingFreq, lønprogram])

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals')
      setDeals(response.data)
    } catch (error) {
      console.error('Error fetching deals:', error)
    }
  }

  const applyFilters = () => {
    let filtered = deals.filter(deal => {
      // Apply all filters
      if (productFilter.length && !deal.data?.products?.some(p => productFilter.includes(p.name))) return false
      if (companyTypeFilter && deal.data?.virksomhedsform !== companyTypeFilter) return false
      if (dealTypeFilter && deal.type !== dealTypeFilter) return false
      if (createdAfter && new Date(deal.created_at) < createdAfter) return false
      if (accountingSystem && deal.data?.regnskabsprogram !== accountingSystem) return false
      if (accountManager && deal.data?.ansvarlig_bogholder !== accountManager) return false
      if (bookkeepingFreq && deal.data?.bogføringsfrekvens !== bookkeepingFreq) return false
      if (lønprogram && deal.software?.lønprogram !== lønprogram) return false
      return true
    })

    // Extract unique contacts from filtered deals
    const contacts = filtered.map(deal => deal.contact).filter((contact): contact is Contact => !!contact)
    setFilteredContacts(contacts)
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Select Recipients</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          {/* Add Filter Button */}
          {availableFilters.length > 0 && (
            <div className="flex items-center gap-2">
              <Select 
                value=""
                onValueChange={(value) => {
                  addFilter(value as FilterType)
                }}
              >
                <SelectTrigger className="w-[200px]">
                  <SelectValue placeholder={activeFilters.size > 0 ? "Add new filter..." : "Add filter..."} />
                </SelectTrigger>
                <SelectContent>
                  {availableFilters.map(filter => (
                    <SelectItem key={filter.id} value={filter.id}>
                      {filter.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <span className="text-sm text-muted-foreground">
                {availableFilters.length} available filter{availableFilters.length !== 1 ? 's' : ''}
              </span>
            </div>
          )}

          {/* Active Filters Card */}
          {activeFilters.size > 0 && (
            <Card>
              <CardContent className="pt-6 space-y-4">
                {filterOptions
                  .filter(filter => activeFilters.has(filter.id))
                  .map(filter => (
                    <div key={filter.id} className="flex items-start gap-2">
                      <div className="flex-1 space-y-2">
                        <div className="flex items-center justify-between">
                          <label className="text-sm font-medium">{filter.label}</label>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => removeFilter(filter.id)}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                        {filter.id === 'products' ? (
                          <div className="flex flex-wrap gap-2">
                            {productFilter.map(product => (
                              <Badge
                                key={product}
                                variant="secondary"
                                className="flex items-center gap-1"
                              >
                                {product}
                                <Button
                                  variant="ghost"
                                  size="icon"
                                  className="h-4 w-4 p-0"
                                  onClick={() => {
                                    setProductFilter(productFilter.filter(p => p !== product))
                                    if (productFilter.length === 1) {
                                      removeFilter('products')
                                    }
                                  }}
                                >
                                  <X className="h-3 w-3" />
                                </Button>
                              </Badge>
                            ))}
                          </div>
                        ) : (
                          filter.component
                        )}
                      </div>
                    </div>
                  ))}
              </CardContent>
            </Card>
          )}

          {/* Recipients list */}
          <ScrollArea className="h-[300px] border rounded-md p-4">
            <div className="space-y-2">
              {filteredContacts.map(contact => (
                <div key={contact.email} className="flex items-center space-x-2">
                  <Checkbox
                    checked={selectedContacts.has(contact.email)}
                    onCheckedChange={(checked) => {
                      const newSelected = new Set(selectedContacts)
                      if (checked) {
                        newSelected.add(contact.email)
                      } else {
                        newSelected.delete(contact.email)
                      }
                      setSelectedContacts(newSelected)
                    }}
                  />
                  <span>{contact.name} ({contact.email})</span>
                </div>
              ))}
            </div>
          </ScrollArea>

          <DialogFooter className="space-x-2">
            <Button variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button onClick={() => {
              const selectedRecipients = filteredContacts
                .filter(contact => selectedContacts.has(contact.email))
                .map(contact => ({
                  email: contact.email,
                  name: contact.name
                }))
              onAddRecipients(selectedRecipients)
              onOpenChange(false)
            }}>
              Add Selected ({selectedContacts.size})
            </Button>
            <Button onClick={() => {
              onAddRecipients(filteredContacts.map(contact => ({
                email: contact.email,
                name: contact.name
              })))
              onOpenChange(false)
            }}>
              Add All ({filteredContacts.length})
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  )
}