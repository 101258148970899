import * as React from "react"
import { Clock } from "lucide-react"
import { Input } from "@/components/ui/input"
import { cn } from "@/lib/utils"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Button } from "@/components/ui/button"

interface TimePickerInputProps {
  value?: string
  onChange?: (value: string) => void
  disabled?: boolean
  className?: string
}

export function TimePickerInput({ 
  value, 
  onChange, 
  disabled,
  className 
}: TimePickerInputProps) {
  const [time, setTime] = React.useState(value || '')
  const [isOpen, setIsOpen] = React.useState(false)
  const [tempHours, setTempHours] = React.useState(value ? value.split(':')[0] : '00')
  const [tempMinutes, setTempMinutes] = React.useState(value ? value.split(':')[1] : '00')
  
  const hours = time ? parseInt(time.split(':')[0]) : 0
  const minutes = time ? parseInt(time.split(':')[1]) : 0

  React.useEffect(() => {
    if (value !== time) {
      setTime(value || '')
      setTempHours(value ? value.split(':')[0] : '00')
      setTempMinutes(value ? value.split(':')[1] : '00')
    }
  }, [value])

  const handleTimeChange = (newTime: string) => {
    setTime(newTime)
    setTempHours(newTime.split(':')[0])
    setTempMinutes(newTime.split(':')[1])
    onChange?.(newTime)
  }

  const handleHourChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^\d]/g, '') // Only allow digits
    if (val === '') {
      setTempHours('')
      return
    }
    
    const numVal = parseInt(val)
    setTempHours(val)

    if (val.length === 2) {
      if (numVal >= 0 && numVal < 24) {
        handleTimeChange(`${numVal.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`)
      } else {
        // Reset to valid value if final number is invalid
        setTempHours(hours.toString().padStart(2, '0'))
      }
    }
  }

  const handleMinuteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(/[^\d]/g, '') // Only allow digits
    if (val === '') {
      setTempMinutes('')
      return
    }
    
    const numVal = parseInt(val)
    setTempMinutes(val)

    if (val.length === 2) {
      if (numVal >= 0 && numVal < 60) {
        handleTimeChange(`${hours.toString().padStart(2, '0')}:${numVal.toString().padStart(2, '0')}`)
      } else {
        // Reset to valid value if final number is invalid
        setTempMinutes(minutes.toString().padStart(2, '0'))
      }
    }
  }

  // Handle input blur to ensure valid values
  const handleHourBlur = () => {
    const numVal = parseInt(tempHours)
    if (isNaN(numVal) || numVal >= 24 || tempHours === '') {
      setTempHours(hours.toString().padStart(2, '0'))
    } else {
      handleTimeChange(`${numVal.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`)
    }
  }

  const handleMinuteBlur = () => {
    const numVal = parseInt(tempMinutes)
    if (isNaN(numVal) || numVal >= 60 || tempMinutes === '') {
      setTempMinutes(minutes.toString().padStart(2, '0'))
    } else {
      handleTimeChange(`${hours.toString().padStart(2, '0')}:${numVal.toString().padStart(2, '0')}`)
    }
  }

  const incrementHour = () => {
    const newHours = (hours + 1) % 24
    handleTimeChange(`${newHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`)
  }

  const decrementHour = () => {
    const newHours = (hours - 1 + 24) % 24
    handleTimeChange(`${newHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`)
  }

  const incrementMinute = () => {
    const newMinutes = (minutes + 1) % 60
    handleTimeChange(`${hours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`)
  }

  const decrementMinute = () => {
    const newMinutes = (minutes - 1 + 60) % 60
    handleTimeChange(`${hours.toString().padStart(2, '0')}:${newMinutes.toString().padStart(2, '0')}`)
  }

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          disabled={disabled}
          className={cn(
            "w-[140px] justify-start text-left font-normal",
            !time && "text-muted-foreground",
            className
          )}
        >
          <Clock className="mr-2 h-4 w-4 text-foreground" />
          {time || "Select time"}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="flex items-center p-2 gap-2">
          <div className="flex flex-col items-center">
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={incrementHour}
            >
              ▲
            </Button>
            <Input
              value={tempHours}
              onChange={handleHourChange}
              onBlur={handleHourBlur}
              className="w-12 h-9 text-center text-xl tabular-nums p-0"
              maxLength={2}
              placeholder="00"
            />
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={decrementHour}
            >
              ▼
            </Button>
          </div>

          <div className="text-2xl">:</div>

          <div className="flex flex-col items-center">
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={incrementMinute}
            >
              ▲
            </Button>
            <Input
              value={tempMinutes}
              onChange={handleMinuteChange}
              onBlur={handleMinuteBlur}
              className="w-12 h-9 text-center text-xl tabular-nums p-0"
              maxLength={2}
              placeholder="00"
            />
            <Button
              variant="ghost"
              size="sm"
              className="h-8 w-8 p-0"
              onClick={decrementMinute}
            >
              ▼
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}