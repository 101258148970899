import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, InputNumber, Switch, Button, Steps, message, Progress } from 'antd';
import { dealDataConfig } from '../../configs/DealDataConfig';
import { dealSoftwareConfig } from '../../configs/DealSoftwareConfig';
import axios from '../../api/axiosConfig';
import dayjs from 'dayjs';
import TasksSetupStep from './TasksSetupStep';
import './OnboardingForm.css';

const { Step } = Steps;

const OnboardingForm = ({ deal, visible, onClose, standalone = false, initialStep = 0 }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [loading, setLoading] = useState(false);
  const [economicLoading, setEconomicLoading] = useState(false);
  const [onboardingId, setOnboardingId] = useState(null);
  const [formFields, setFormFields] = useState(dealDataConfig);
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (deal) {
      const initialValues = {};
      const transformedValues = {
        data: {},
        software: {}
      };

      dealDataConfig.forEach(field => {
        const value = getNestedValue(deal, field.key);
        if (value !== undefined) {
          if (field.type === 'date' && value) {
            initialValues[field.key] = dayjs(value);
          } else {
            initialValues[field.key] = value;
          }

          if (field.key.startsWith('data.')) {
            transformedValues.data[field.key.split('.')[1]] = value;
          } else if (field.key.startsWith('software.')) {
            transformedValues.software[field.key.split('.')[1]] = value;
          } else {
            transformedValues[field.key] = value;
          }
        }
      });

      form.setFieldsValue(initialValues);
      
      const fields = dealDataConfig.filter(field => {
        const dependency = fieldDependencies[field.key];
        if (dependency) {
          const dependentValue = getNestedValue(transformedValues, dependency.dependsOn);
          return dependency.showWhen(dependentValue);
        }
        return true;
      });

      setFormFields(fields);
      setIsInitialized(true);

      if (deal?.onboarding?.id) {
        setOnboardingId(deal.onboarding.id);
      }
    }
  }, [deal]);

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const fieldDependencies = {
    'data.antal_medarbejdere_til_lønhandtering': {
      dependsOn: 'data.lønansvarlig',
      showWhen: value => value === 'Digi-Tal'
    },
    'data.lønfrekvens': {
      dependsOn: 'data.lønansvarlig',
      showWhen: value => value === 'Digi-Tal'
    },
    'data.ansvarlig_lønbogholder': {
      dependsOn: 'data.lønansvarlig',
      showWhen: value => value === 'Digi-Tal'
    },
    'software.lønprogram': {
      dependsOn: 'data.lønansvarlig',
      showWhen: value => value === 'Digi-Tal'
    },
    'data.ansvarlig_revisor_for_årsregnskab': {
      dependsOn: 'data.hvem_er_ansvarlig_for_årsregnskab',
      showWhen: value => value === 'Digi-Tal'
    }
  };

  const updateFormFields = (values) => {
    if (!isInitialized) return;
    
    const fields = dealDataConfig.filter(field => {
      const dependency = fieldDependencies[field.key];
      if (dependency) {
        const dependentValue = getNestedValue(values, dependency.dependsOn);
        return dependency.showWhen(dependentValue);
      }
      return true;
    });

    setFormFields(fields);
  };

  const handleValuesChange = (changedValues, allValues) => {
    // Transform all values to match the nested structure
    const transformedValues = {
      data: {},
      software: {}
    };

    // Transform flat form values into nested structure
    Object.entries(allValues).forEach(([key, value]) => {
      if (key.startsWith('data.')) {
        transformedValues.data[key.split('.')[1]] = value;
      } else if (key.startsWith('software.')) {
        transformedValues.software[key.split('.')[1]] = value;
      } else {
        transformedValues[key] = value;
      }
    });

    // Update form fields based on the new values
    updateFormFields(transformedValues);

    // No need to clear fields manually as they will be filtered out by updateFormFields
  };

  const renderFormItem = (field) => {
    // Special case for mailbox fields
    if (field.key === 'main_mailbox' || field.key === 'corpay_mailbox') {
      return (
        <div className="mailbox-field-container">
          <Button 
            type="primary" 
            className="create-mailbox-button"
            onClick={() => console.log(`Create ${field.label}`)}
          >
            Create mailbox
          </Button>
          <Input disabled />
        </div>
      );
    }

    // Handle software fields
    if (field.key.startsWith('software.')) {
      const softwareField = dealSoftwareConfig.find(
        config => config.key === field.key.split('.')[1]
      );

      if (softwareField?.options) {
        return (
          <div className="software-field-container">
            <Select
              className="editable-field-wrapper"
              popupClassName="deal-drawer-select-dropdown"
              allowClear
            >
              {softwareField.options.map(option => (
                <Select.Option 
                  key={option.value} 
                  value={option.value}
                  className="custom-select-option"
                  data-color={option.color}
                >
                  <span style={{ 
                    backgroundColor: option.color,
                    color: option.color === '#d9d9d9' ? 'var(--text-color)' : '#fff',
                    padding: '2px 8px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    width: '100%'
                  }}>
                    {option.label}
                  </span>
                </Select.Option>
              ))}
            </Select>
          </div>
        );
      } else {
        // For text-based software fields (like andre_softwares)
        return (
          <div className="software-field-container">
            <Input allowClear />
          </div>
        );
      }
    }

    // Regular cases from dealDataConfig
    switch (field.type) {
      case 'text':
        return <Input />;
      case 'number':
        return <InputNumber style={{ width: '100%' }} />;
      case 'date':
        return <DatePicker style={{ width: '100%' }} />;
      case 'boolean':
        return <Switch />;
      case 'dropdown':
        return (
          <Select
            className="editable-field-wrapper"
            popupClassName="deal-drawer-select-dropdown"
          >
            {field.options?.map(option => (
              <Select.Option 
                key={option.value} 
                value={option.value}
                className="custom-select-option"
                data-color={option.color}
              >
                <span style={{ 
                  backgroundColor: option.color,
                  color: option.color === '#d9d9d9' ? 'var(--text-color)' : '#fff',
                  padding: '2px 8px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  width: '100%'
                }}>
                  {option.label}
                </span>
              </Select.Option>
            ))}
          </Select>
        );
      default:
        return <Input />;
    }
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      setLoading(true);

      // Transform values to match the deal structure
      const dealData = {
        main_mailbox: values.main_mailbox || null,
        corpay_mailbox: values.corpay_mailbox || null,
        description: values.description,
        data: {},
        software: {}
      };

      // Split form values into appropriate objects
      Object.entries(values).forEach(([key, value]) => {
        if (key.startsWith('data.')) {
          dealData.data[key.replace('data.', '')] = value;
        } else if (key.startsWith('software.')) {
          dealData.software[key.replace('software.', '')] = value;
        }
      });

      // Save form data
      const response = await axios.post(`/onboarding/update-form-data/${onboardingId}`, {
        form_data: values,
        last_completed_step: 'form_completed',
        deal_data: dealData
      });

      setCurrentStep(1);
      message.success('Form data saved successfully');
    } catch (error) {
      console.error('Form submission error:', error);
      message.error('Failed to save form data');
    } finally {
      setLoading(false);
    }
  };

  const createEconomicCustomer = async () => {
    try {
      setEconomicLoading(true);
      const response = await axios.post(`/onboarding/create-economic-customer/${onboardingId}`);
      message.success('Customer created in E-conomic successfully');
      setCurrentStep(2);
    } catch (error) {
      message.error('Failed to create customer in E-conomic');
    } finally {
      setEconomicLoading(false);
    }
  };

  const formSections = [
    {
      title: 'Basic Information',
      fields: [
        'data.virksomhedsform',
        'data.onboarding_dato',
        'description'
      ]
    },
    {
      title: 'Accounting Setup',
      fields: [
        'data.regnskabsprogram',
        'data.bogføringsfrekvens',
        'data.momsfrekvens',
        'data.ansvarlig_bogholder',
        'data.hvem_er_ansvarlig_for_årsregnskab',
        'data.ansvarlig_revisor_for_årsregnskab',
        'data.hvornår_slutter_næste_regnskabsår',
        'data.kan_bankkonto_integreres_i_bogføringssystem',
        'data.hvidvask_compliant'
      ]
    },
    {
      title: 'Payroll & Staff',
      fields: [
        'data.lønansvarlig',
        'data.antal_medarbejdere_til_lønhandtering',
        'data.lønfrekvens',
        'data.ansvarlig_lønbogholder',
        'software.lønprogram'
      ]
    },
    {
      title: 'Software & Integration',
      fields: [
        'software.lagerstyring',
        'software.kreditorstyring',
        'software.betalingsgateway',
        'software.abonnementsstyring',
        'software.debitorstyring_kredit',
        'software.debitorstyring_kontant',
        'software.projekt_og_tidsstyring',
        'software.kreditorstyring_kvitteringer',
        'software.andre_softwares'
      ]
    },
    {
      title: 'Email Setup',
      fields: [
        'main_mailbox',
        'corpay_mailbox'
    ]
    },
  ];

  const CustomerInformationStep = () => {
    const currentSection = formSections[currentSubStep];
    const totalSections = formSections.length;

    const getFieldRules = (field) => {
      // Make mailbox fields optional
      if (field.key === 'main_mailbox' || field.key === 'corpay_mailbox') {
        return [];
      }
      return [{ required: true, message: `Please input ${field.label}!` }];
    };

    return (
      <div>
        <Steps 
          size="small" 
          current={currentSubStep}
          onChange={setCurrentSubStep}
          style={{ marginBottom: 'var(--spacing-large)' }}
        >
          {formSections.map(section => (
            <Step key={section.title} title={section.title} />
          ))}
        </Steps>

        <Form
          form={form}
          layout="vertical"
          onValuesChange={handleValuesChange}
          className="onboarding-form"
        >
          <div className="form-fields-container">
            {currentSection.fields
              .map(fieldKey => formFields.find(field => field.key === fieldKey))
              .filter(field => field && formFields.some(f => f.key === field.key))
              .map(field => (
                <Form.Item
                  key={field.key}
                  name={field.key}
                  label={field.label}
                  rules={getFieldRules(field)}
                >
                  {renderFormItem(field)}
                </Form.Item>
              ))}
          </div>

          <div className="form-buttons">
            {currentSubStep > 0 && (
              <Button onClick={() => setCurrentSubStep(prev => prev - 1)}>
                Previous
              </Button>
            )}
            {currentSubStep < totalSections - 1 ? (
              <Button type="primary" onClick={() => setCurrentSubStep(prev => prev + 1)}>
                Next
              </Button>
            ) : (
              <Button type="primary" onClick={handleSubmit} loading={loading}>
                Save and Continue
              </Button>
            )}
          </div>
        </Form>
      </div>
    );
  };

  const steps = [
    {
      title: 'Customer Information',
      content: <CustomerInformationStep />
    },
    {
      title: 'E-conomic Setup',
      content: (
        <div className="economic-setup">
          <h3>Create Customer in E-conomic</h3>
          {economicLoading ? (
            <div className="progress-container">
              <Progress type="circle" percent={75} status="active" />
              <p>Creating customer in E-conomic...</p>
            </div>
          ) : (
            <Button type="primary" onClick={createEconomicCustomer}>
              Create Customer
            </Button>
          )}
        </div>
      )
    },
    {
      title: 'Tasks Setup',
      content: <TasksSetupStep deal={deal} setCurrentStep={setCurrentStep} />
    },
    {
      title: 'Generate Invoice',
      content: (
        <div className="invoice-generation">
          <h3>Generate Onboarding Invoice</h3>
          <Button 
            type="primary" 
            style={{ marginRight: 16 }}
            onClick={() => {
              // Add invoice generation logic here
              console.log('Generating invoice...');
            }}
          >
            Generate Invoice
          </Button>
          <Button onClick={onClose}>
            Skip for Now
          </Button>
        </div>
      )
    }
  ];

  const FormContent = () => (
    <>
      <Steps current={currentStep}>
        {steps.map(step => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <div className="steps-content">
        {steps[currentStep].content}
      </div>
      <div className="steps-navigation" style={{ marginTop: 24, textAlign: 'right' }}>
        {currentStep > 0 && (
          <Button 
            style={{ marginRight: 8 }} 
            onClick={() => setCurrentStep(prev => prev - 1)}
          >
            Previous Stage
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button 
            type="primary" 
            onClick={() => setCurrentStep(prev => prev + 1)}
          >
            Skip to Next Stage
          </Button>
        )}
      </div>
    </>
  );

  if (standalone) {
    return (
      <div style={{ 
        background: 'var(--background-color)',
        padding: 'var(--spacing-large)',
        borderRadius: 'var(--border-radius-medium)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
        maxWidth: '1200px',
        margin: '0 auto'
      }}>
        <FormContent />
      </div>
    );
  }

  return (
    <Modal
      title="Customer Onboarding"
      open={visible}
      onCancel={onClose}
      width={800}
      footer={null}
    >
      <FormContent />
    </Modal>
  );
};

export default OnboardingForm;