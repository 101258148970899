import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Task } from '@/types/task';
import { cn } from "@/lib/utils";

interface SortableTaskCardProps {
  id: string;
  task: Task;
  onTaskClick: (task: Task) => void;
  renderContent: (task: Task) => React.ReactNode;
}

export function SortableTaskCard({ id, task, onTaskClick, renderContent }: SortableTaskCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    over,
  } = useSortable({
    id: id,
    data: {
      type: 'task',
      task: task
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={cn(
        "mb-2",
        isDragging && "cursor-grabbing",
        over && "relative before:absolute before:w-full before:h-1 before:bg-primary/50 before:-top-1"
      )}
      onClick={(e) => {
        e.stopPropagation();
        onTaskClick(task);
      }}
    >
      {renderContent(task)}
    </div>
  );
}