import { Card } from "@/components/ui/card"
import { 
  ClipboardList, 
  Users, 
  LineChart, 
  ArrowUpRight,
  ArrowDownRight,
  ArrowRight
} from "lucide-react"
import { Button } from "@/components/ui/button"
import { Link } from "react-router-dom"

interface StatCardProps {
  title: string
  value: string
  trend?: "up" | "down" | "neutral"
  trendValue?: string
  icon: React.ReactNode
  link: string
}

function StatCard({ title, value, trend, trendValue, icon, link }: StatCardProps) {
  const trendIcon = trend === "up" ? <ArrowUpRight className="text-green-500" /> 
                  : trend === "down" ? <ArrowDownRight className="text-red-500" />
                  : <ArrowRight className="text-gray-500" />

  return (
    <Card className="p-6">
      <div className="flex items-start justify-between">
        <div className="space-y-2">
          <div className="flex items-center gap-2 text-muted-foreground">
            {icon}
            <span className="text-sm font-medium">{title}</span>
          </div>
          <div className="flex items-baseline gap-2">
            <span className="text-2xl font-bold">{value}</span>
            {trendValue && (
              <span className="flex items-center text-sm">
                {trendIcon}
                {trendValue}
              </span>
            )}
          </div>
        </div>
        <Button variant="ghost" size="icon" asChild>
          <Link to={link}>
            <ArrowRight className="h-4 w-4" />
          </Link>
        </Button>
      </div>
    </Card>
  )
}

export default function OverviewDashboard() {
  return (
    <div className="space-y-8">
      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
        <StatCard
          title="Tasks"
          value="xx Active"
          trend="up"
          trendValue="+xx% this week"
          icon={<ClipboardList className="h-4 w-4" />}
          link="/dashboard/tasks"
        />
        <StatCard
          title="Customers"
          value="x,xxx"
          trend="neutral"
          trendValue="Same as last month"
          icon={<Users className="h-4 w-4" />}
          link="/dashboard/customers"
        />
        <StatCard
          title="Sales"
          value="$xx,xxx"
          trend="down"
          trendValue="-x% this month"
          icon={<LineChart className="h-4 w-4" />}
          link="/dashboard/sales"
        />
      </div>

      {/* Add more dashboard widgets here */}
    </div>
  )
}