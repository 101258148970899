import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, Moon, Sun, LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import UserAvatar from '@/components/user-avatar';
import { useTheme } from '@/contexts/ThemeContext';
import axios from '../api/axiosConfig';

interface ErpHeaderProps {
  logo?: string;
  icon?: React.ComponentType<any>;
  title?: string;
}

function ErpHeader({ logo, icon: Icon, title }: ErpHeaderProps) {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const { theme, toggleTheme } = useTheme();

  const handleLogout = async () => {
    try {
      await axios.post('/auth/logout');
    } catch (error) {
      console.error('Logout failed:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('user');
      navigate('/login');
    }
  };

  const handleThemeToggle = () => {
    toggleTheme();
    
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);
      user.settings = {
        ...user.settings,
        theme: theme === 'dark' ? 'light' : 'dark'
      };
      localStorage.setItem('user', JSON.stringify(user));
    }
  };

  return (
    <header className="sticky top-0 z-40 flex h-16 shrink-0 items-center border-b border-border bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60">
      <div className="flex h-16 w-full items-center justify-between px-5">
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => navigate('/main-menu')}
          >
            <Menu className="h-5 w-5 text-foreground" />
          </Button>
          
          <Button
            variant="ghost"
            size="icon"
            onClick={handleThemeToggle}
          >
            {theme === 'dark' ? (
              <Sun className="h-5 w-5 text-foreground" />
            ) : (
              <Moon className="h-5 w-5 text-foreground" />
            )}
          </Button>
        </div>

        <div className="flex items-center justify-center">
          {logo ? (
            <img src={logo} alt="App Logo" className="h-8 rounded-lg" />
          ) : (
            <div className="flex items-center gap-2">
              {Icon && <Icon className="h-5 w-5 text-foreground" />}
              <span className="font-medium text-foreground">{title}</span>
            </div>
          )}
        </div>

        <div className="flex items-center">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div>
                <UserAvatar
                  user={user}
                  size={35}
                  className="cursor-pointer hover:opacity-80"
                  disableTooltip={true}
                  tooltipPlacement="bottom"
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={handleLogout}>
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
}

export default ErpHeader;