import * as React from "react"
import { useIsMobile } from "@/hooks/use-mobile"

interface SidebarContextValue {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  isMobile: boolean;
  toggleSidebar: () => void;
  state: 'expanded' | 'collapsed';
}

const SidebarContext = React.createContext<SidebarContextValue>({
  collapsed: false,
  setCollapsed: () => {},
  isMobile: false,
  toggleSidebar: () => {},
  state: 'expanded'
});

export function SidebarProvider({ children }: { children: React.ReactNode }) {
  const [collapsed, setCollapsed] = React.useState(false);
  const isMobile = useIsMobile();

  const toggleSidebar = React.useCallback(() => {
    setCollapsed(prev => !prev);
  }, []);

  const value = React.useMemo(
    () => ({
      collapsed,
      setCollapsed,
      isMobile,
      toggleSidebar,
      state: collapsed ? 'collapsed' as const : 'expanded' as const
    }),
    [collapsed, isMobile, toggleSidebar]
  );

  return (
    <SidebarContext.Provider value={value}>
      {children}
    </SidebarContext.Provider>
  );
}

export function useSidebar() {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
}