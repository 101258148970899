import React, { useState, useEffect } from 'react';
import { Drawer, Form, Select, InputNumber, Button, message, Tag } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import CustomScrollbar from '../components/CustomScrollbar';
import './PayrollDrawer.css';
import { dealSoftwareConfig } from '../configs/DealSoftwareConfig';

const PayrollDrawer = ({ visible, onClose, dealId, onSave }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [salaryType, setSalaryType] = useState(null);

  useEffect(() => {
    if (dealId && visible) {
      fetchConfig();
    }
  }, [dealId, visible]);

  const fetchConfig = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/lon/lon-config/${dealId}`);
      const config = response.data;
      setSalaryType(config.salary_type);
      form.setFieldsValue({
        lønprogram: config.deal_software?.lønprogram,
        salary_type: config.salary_type,
        salary_period_hourly_start: config.salary_period_hourly?.start,
        salary_period_hourly_end: config.salary_period_hourly?.end,
        salary_period_monthly_start: config.salary_period_monthly?.start,
        salary_period_monthly_end: config.salary_period_monthly?.end,
        mail_reminder_date: config.mail_reminder_date
      });
    } catch (error) {
      console.error('Error fetching payroll config:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSalaryTypeChange = (value) => {
    setSalaryType(value);
    // Clear irrelevant period values when switching types
    if (value === 'Monthly') {
      form.setFieldsValue({
        salary_period_hourly_start: null,
        salary_period_hourly_end: null
      });
    } else if (value === 'Hourly') {
      form.setFieldsValue({
        salary_period_monthly_start: null,
        salary_period_monthly_end: null
      });
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        lønprogram: values.lønprogram,
        salary_type: values.salary_type,
        salary_period_hourly: {
          start: ['Mix', 'Hourly'].includes(values.salary_type) ? values.salary_period_hourly_start : null,
          end: ['Mix', 'Hourly'].includes(values.salary_type) ? values.salary_period_hourly_end : null
        },
        salary_period_monthly: {
          start: ['Mix', 'Monthly'].includes(values.salary_type) ? values.salary_period_monthly_start : null,
          end: ['Mix', 'Monthly'].includes(values.salary_type) ? values.salary_period_monthly_end : null
        },
        mail_reminder_date: values.mail_reminder_date
      };

      await axios.put(`/lon/lon-config/${dealId}`, payload);
      message.success('Payroll configuration saved successfully');
      if (onSave) onSave();
      onClose();
    } catch (error) {
      console.error('Error saving payroll config:', error);
      message.error('Failed to save payroll configuration');
    }
  };

  return (
    <Drawer
      title="Edit Payroll Configuration"
      placement="right"
      onClose={onClose}
      open={visible}
      width={550}
      className="payroll-drawer-component payroll-drawer"
    >
      <CustomScrollbar className="payroll-drawer-content">
        <div className="payroll-drawer-inner">
          <Form
            form={form}
            layout="vertical"
            className="payroll-form"
          >
            <Form.Item
              name="lønprogram"
              label="Lønprogram"
              rules={[{ required: true }]}
            >
              <Select>
                {dealSoftwareConfig
                  .find(config => config.key === 'lønprogram')
                  ?.options.map(option => (
                    <Select.Option key={option.value} value={option.value}>
                      <Tag color={option.color}>{option.label}</Tag>
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>

            <Form.Item
              name="salary_type"
              label="Type"
              rules={[{ required: true }]}
            >
              <Select onChange={handleSalaryTypeChange}>
                <Select.Option value="Monthly">Monthly</Select.Option>
                <Select.Option value="Hourly">Hourly</Select.Option>
                <Select.Option value="Mix">Mix</Select.Option>
              </Select>
            </Form.Item>

            {(salaryType === 'Mix' || salaryType === 'Hourly') && (
              <div className="period-section">
                <h3>Period (hourly)</h3>
                <Form.Item
                  name="salary_period_hourly_start"
                  label="Start Day"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={-1} max={31} />
                </Form.Item>
                <Form.Item
                  name="salary_period_hourly_end"
                  label="End Day"
                  extra={<span className="helper-text">-1 = last day of month</span>}
                  rules={[{ required: true }]}
                >
                  <InputNumber min={-1} max={31} />
                </Form.Item>
              </div>
            )}

            {(salaryType === 'Mix' || salaryType === 'Monthly') && (
              <div className="period-section">
                <h3>Period (monthly)</h3>
                <Form.Item
                  name="salary_period_monthly_start"
                  label="Start Day"
                  rules={[{ required: true }]}
                >
                  <InputNumber min={-1} max={31} />
                </Form.Item>
                <Form.Item
                  name="salary_period_monthly_end"
                  label="End Day"
                  extra={<span className="helper-text">-1 = last day of month</span>}
                  rules={[{ required: true }]}
                >
                  <InputNumber min={-1} max={31} />
                </Form.Item>
              </div>
            )}

            <Form.Item
              name="mail_reminder_date"
              label="Mail Reminder Day"
              rules={[{ required: true }]}
            >
              <InputNumber min={1} max={31} />
            </Form.Item>

            <Button 
              type="primary" 
              onClick={handleSave}
              className="save-changes-button"
            >
              Save Changes
            </Button>
            <Button 
              onClick={onClose}
              className="close-drawer-button"
            >
              Close
            </Button>
          </Form>
        </div>
      </CustomScrollbar>
    </Drawer>
  );
};

export default PayrollDrawer;