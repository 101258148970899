import * as React from "react";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";

interface TaskBadgeProps {
  items: {
    key: string;
    value: string;
    color?: string;
    label: string;
  }[];
}

export function TaskBadge({ items }: TaskBadgeProps) {
  const getColorClasses = (hexColor?: string) => {
    if (!hexColor) return '';
    
    const color = hexColor.replace('#', '').toLowerCase();
    
    return `bg-[#${color}] hover:bg-[#${color}]/80 text-foreground border-transparent`;
  };

  return (
    <div className="flex flex-wrap gap-1">
      {items.map((item) => (
        <Badge
          key={item.key}
          variant={item.color ? "custom" : "secondary"}
          className={cn(
            "text-xs whitespace-nowrap",
            item.color ? getColorClasses(item.color) : ""
          )}
          title={`${item.label}: ${item.value}`}
        >
          {item.value}
        </Badge>
      ))}
    </div>
  );
}