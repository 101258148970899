interface Color {
  name: string;
  value: string;
}

// Ant Design's default colors
export const ANT_COLORS: Color[] = [
  { name: 'Red', value: '#ff4d4f' },
  { name: 'Volcano', value: '#ff7a45' },
  { name: 'Orange', value: '#ffa940' },
  { name: 'Gold', value: '#ffc53d' },
  { name: 'Yellow', value: '#ffec3d' },
  { name: 'Lime', value: '#a0d911' },
  { name: 'Green', value: '#52c41a' },
  { name: 'Cyan', value: '#13c2c2' },
  { name: 'Blue', value: '#1890ff' },
  { name: 'Geekblue', value: '#2f54eb' },
  { name: 'Purple', value: '#722ed1' },
  { name: 'Magenta', value: '#eb2f96' },
  { name: 'Grey', value: '#8c8c8c' },
  { name: 'Dark Blue', value: '#096dd9' },
  { name: 'Dark Green', value: '#389e0d' },
  { name: 'Dark Cyan', value: '#08979c' },
  { name: 'Dark Red', value: '#cf1322' },
  { name: 'Dark Purple', value: '#531dab' },
  { name: 'Dark Magenta', value: '#c41d7f' },
  { name: 'Dark Grey', value: '#595959' }
];

// Export the type for use in other files if needed
export type AntColor = typeof ANT_COLORS[number];
export type AntColorName = AntColor['name'];
export type AntColorValue = AntColor['value'];