import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { CustomTask } from '@/types/custom-task';
import { cn } from '@/lib/utils';

interface CustomSortableTaskCardProps {
  task: CustomTask;
  onTaskClick: (task: CustomTask) => void;
  renderContent: (task: CustomTask) => React.ReactNode;
  isDragOverlay?: boolean;
  isPreview?: boolean;
}

export function CustomSortableTaskCard({ 
  task, 
  onTaskClick, 
  renderContent,
  isDragOverlay = false,
  isPreview = false
}: CustomSortableTaskCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    isDragging,
  } = useDraggable({ 
    id: task.id.toString(),
    data: {
      type: 'task',
      task: task
    }
  });

  const style = !isDragOverlay && transform ? {
    transform: CSS.Translate.toString(transform),
  } : undefined;

  return (
    <div
      ref={!isDragOverlay ? setNodeRef : undefined}
      style={style}
      {...(!isDragOverlay ? { ...attributes, ...listeners } : {})}
      className={cn(
        "touch-none transition-opacity duration-200",
        isDragging && "opacity-30",
        isDragOverlay && "shadow-md cursor-grabbing",
        isPreview && "opacity-30 pointer-events-none"
      )}
      onClick={(e) => {
        e.stopPropagation();
        onTaskClick(task);
      }}
    >
      {renderContent(task)}
    </div>
  );
}