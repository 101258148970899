import React, { useState, useEffect, useContext } from 'react'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardContent } from '@/components/ui/card'
import { X as CloseIcon, Play, Pause, Square, Clock } from 'lucide-react'
import { cn } from '@/lib/utils'
import axios from '../api/axiosConfig'
import { TimeTrackerContext } from '../contexts/TimeTrackerContext'
import { Check, ChevronsUpDown } from "lucide-react"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"

interface Deal {
  id: string
  name: string
}

interface Task {
  id: string
  deal_id: string
  description?: string
  text?: string
  task_type: string
  recurring_type: string
}

export interface TimeTrackerProps {
  isVisible: boolean
  isMaximized?: boolean
  isRunning?: boolean
  isLiveTimer?: boolean
  isLiveTimerVisible?: boolean
  onOpen: () => void
  onClose: () => void
  onStatusChange?: (isRunning: boolean, account: string, task: string) => void
  displayAccountProp?: string
  displayTaskProp?: string
}

export const TimeTracker: React.FC<TimeTrackerProps> = ({
  isVisible,
  isMaximized = false,
  isRunning = false,
  isLiveTimer = false,
  onOpen,
  onClose,
  onStatusChange,
  displayAccountProp = '',
  displayTaskProp = ''
}) => {
  const { 
    isRunning: contextIsRunning, 
    isPaused, 
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer 
  } = useContext(TimeTrackerContext);
  
  const [selectedDeal, setSelectedDeal] = useState<string | null>(null);
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const [deals, setDeals] = useState<Deal[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [dealTasks, setDealTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDeal, setOpenDeal] = useState(false)
  const [openTask, setOpenTask] = useState(false)

  useEffect(() => {
    fetchDeals();
    fetchTasks();
  }, []);

  useEffect(() => {
    if (selectedDeal) {
      filterDealTasks(selectedDeal);
    } else {
      setDealTasks([]);
    }
  }, [selectedDeal, tasks]);

  const fetchDeals = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/account/deals');
      setDeals(response.data);
    } catch (error) {
      console.error('Error fetching deals:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTasks = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/work/assigned_tasks');
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    } finally {
      setLoading(false);
    }
  };

  const filterDealTasks = (dealId: string) => {
    const filtered = tasks.filter(task => task.deal_id === dealId);
    setDealTasks(filtered);
  };

  const handleStartTimer = () => {
    if (!selectedDeal || !selectedTask) return;
    
    const task = dealTasks.find(t => t.id === selectedTask);
    const deal = deals.find(d => d.id === selectedDeal);
    
    if (!task) return;

    startTracking({
      taskId: selectedTask,
      taskType: task.task_type,
      dealId: selectedDeal,
      recurringType: task.recurring_type,
      dealName: deal?.name,
      taskDescription: task.description || task.text || ''
    });

    onClose();
    if (onStatusChange) {
      onStatusChange(true, deal?.name || '', task.description || task.text || '');
    }
  };

  const handleStopTimer = () => {
    stopTracking();
    setSelectedDeal(null);
    setSelectedTask(null);
  };

  if (!isMaximized) {
    return (
      <div className={cn(
        'opacity-0 pointer-events-none transition-all duration-300',
        isVisible && 'opacity-100 pointer-events-auto'
      )}>
        <TooltipProvider delayDuration={100}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="ghost"
                size="icon"
                className={cn(
                  '!w-12 !h-12 !rounded-full !bg-gradient-to-br from-[hsl(var(--timetracker-gradient-from))] to-[hsl(var(--timetracker-gradient-to))]',
                  'hover:translate-y-[-2px] hover:scale-105 transition-all duration-300',
                  'shadow-lg hover:shadow-blue-500/20'
                )}
                onClick={onOpen}
              >
                <Clock size={32} className="text-white transform scale-150" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="left">
              <p>Time Tracker</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    );
  }

  return (
    <div className={cn(
      'fixed z-50 transition-all duration-300',
      isVisible ? 'opacity-100' : 'opacity-0 pointer-events-none',
      isMaximized && 'bottom-6 right-6'
    )}>
      <Card className="w-80 shadow-2xl flex flex-col">
        <CardHeader className="flex-row items-center justify-between space-y-0 p-4">
          <div className="flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-destructive" />
            <h3 className="text-sm font-medium">
              <span className="bg-gradient-to-r from-[hsl(var(--timetracker-gradient-from))] to-[hsl(var(--timetracker-gradient-to))] text-transparent bg-clip-text">
                Time Tracker
              </span>
            </h3>
          </div>
          <Button 
            variant="ghost" 
            size="icon"
            onClick={onClose}
          >
            <CloseIcon className="h-4 w-4" />
          </Button>
        </CardHeader>

        <CardContent className="flex flex-col space-y-4">
          {!contextIsRunning && (
            <>
              <Popover open={openDeal} onOpenChange={setOpenDeal}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={openDeal}
                    className="w-full justify-between bg-background text-foreground border-input"
                  >
                    {selectedDeal && deals.find((deal) => deal.id === selectedDeal)?.name
                      ? deals.find((deal) => deal.id === selectedDeal)?.name
                      : "Select Deal..."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command>
                    <CommandInput placeholder="Search deals..." className="h-9" />
                    <CommandList>
                      <CommandEmpty>No deals found.</CommandEmpty>
                      <CommandGroup>
                        {deals.map((deal) => (
                          <CommandItem
                            key={deal.id}
                            value={deal.name}
                            onSelect={(currentValue) => {
                              setSelectedDeal(deal.id)
                              setOpenDeal(false)
                            }}
                          >
                            {deal.name}
                            <Check
                              className={cn(
                                "ml-auto h-4 w-4",
                                selectedDeal === deal.id ? "opacity-100" : "opacity-0"
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>

              <Popover open={openTask} onOpenChange={setOpenTask}>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={openTask}
                    className="w-full justify-between bg-background text-foreground border-input"
                    disabled={!selectedDeal}
                  >
                    {selectedTask
                      ? dealTasks.find((task) => task.id === selectedTask)?.description || 
                        dealTasks.find((task) => task.id === selectedTask)?.text
                      : "Select Task..."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-full p-0">
                  <Command>
                    <CommandInput placeholder="Search tasks..." className="h-9" />
                    <CommandList>
                      <CommandEmpty>No tasks found.</CommandEmpty>
                      <CommandGroup>
                        {dealTasks.map((task) => (
                          <CommandItem
                            key={task.id}
                            value={task.id}
                            onSelect={(currentValue) => {
                              setSelectedTask(currentValue === selectedTask ? "" : currentValue)
                              setOpenTask(false)
                            }}
                          >
                            {task.description || task.text}
                            <Check
                              className={cn(
                                "ml-auto h-4 w-4",
                                selectedTask === task.id ? "opacity-100" : "opacity-0"
                              )}
                            />
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    </CommandList>
                  </Command>
                </PopoverContent>
              </Popover>
            </>
          )}

          <div className="flex justify-center gap-2">
            {contextIsRunning ? (
              <div className="flex gap-2">
                <Button
                  variant="default"
                  className={cn(
                    isPaused ? 'bg-success hover:bg-success/90' : 'bg-warning hover:bg-warning/90',
                    'border-none'
                  )}
                  onClick={isPaused ? resumeTimer : pauseTracking}
                >
                  {isPaused ? <Play className="h-4 w-4 mr-2" /> : <Pause className="h-4 w-4 mr-2" />}
                  {isPaused ? 'Resume' : 'Pause'}
                </Button>
                <Button
                  variant="destructive"
                  onClick={handleStopTimer}
                  className="bg-destructive hover:bg-destructive/90 border-none"
                >
                  <Square className="h-4 w-4 mr-2" />
                  Stop
                </Button>
              </div>
            ) : (
              <Button
                className="w-full h-10 border border-background hover:border-primary disabled:opacity-70 disabled:bg-gradient-to-br disabled:from-gray-400 disabled:to-gray-600"
                onClick={handleStartTimer}
                disabled={!selectedDeal || !selectedTask}
              >
                <Play className="h-4 w-4 mr-2" />
                Start Timer
              </Button>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TimeTracker;