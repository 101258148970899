import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Spin, Tabs, Progress } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './AdminMonitor.css';

const { TabPane } = Tabs;

function AdminMonitor() {
  const [metrics, setMetrics] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchMetrics = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/admin/cache-metrics');
      setMetrics(response.data);
    } catch (error) {
      console.error('Error fetching cache metrics:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics();
    const interval = setInterval(fetchMetrics, 30000); // Update every 30 seconds
    return () => clearInterval(interval);
  }, []);

  if (loading && !metrics) return <Spin size="large" />;

  return (
    <div className="admin-monitor">
      <Tabs defaultActiveKey="redis">
        <TabPane tab="Redis Cache" key="redis">
          <div className="redis-metrics">
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} lg={8}>
                <Card title="Memory Usage">
                  <Statistic title="Used" value={metrics?.memory?.used} />
                  <Statistic title="Peak" value={metrics?.memory?.peak} />
                  <Progress 
                    percent={Math.round((metrics?.memory?.used_bytes / metrics?.memory?.max_bytes) * 100)} 
                    status="active"
                  />
                </Card>
              </Col>
              
              <Col xs={24} sm={12} lg={8}>
                <Card title="Cache Performance">
                  <Statistic 
                    title="Hit Rate" 
                    value={metrics?.stats?.hit_rate} 
                    suffix="%" 
                  />
                  <Row gutter={16}>
                    <Col span={12}>
                      <Statistic title="Hits" value={metrics?.stats?.hits} />
                    </Col>
                    <Col span={12}>
                      <Statistic title="Misses" value={metrics?.stats?.misses} />
                    </Col>
                  </Row>
                </Card>
              </Col>
              
              <Col xs={24} sm={12} lg={8}>
                <Card title="Keys">
                  <Statistic title="Total Keys" value={metrics?.keys?.total} />
                  <Row gutter={16}>
                    <Col span={12}>
                      <Statistic title="Expired" value={metrics?.keys?.expired} />
                    </Col>
                    <Col span={12}>
                      <Statistic title="Evicted" value={metrics?.keys?.evicted} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AdminMonitor;