import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import axios from '../api/axiosConfig';

const PresenceContext = createContext();

export function PresenceProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const heartbeatIntervalRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const manualLogout = sessionStorage.getItem('manualLogout');
    setIsAuthenticated(!!token && !manualLogout);

    const handleStorageChange = () => {
      const newToken = localStorage.getItem('token');
      const manualLogout = sessionStorage.getItem('manualLogout');
      setIsAuthenticated(!!newToken && !manualLogout);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    if (!isAuthenticated && heartbeatIntervalRef.current) {
      clearTimeout(heartbeatIntervalRef.current);
      heartbeatIntervalRef.current = null;
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let heartbeatInterval;

    const sendHeartbeat = async () => {
      try {
        const response = await axios.post('/auth/heartbeat');
        scheduleNextHeartbeat();
      } catch (error) {
        if (error.response?.status === 401) {
          // If unauthorized, stop sending heartbeats
          clearTimeout(heartbeatInterval);
        } else {
          console.error('Heartbeat failed:', error);
        }
      }
    };

    const getRandomInterval = () => {
      // Random interval between 4-6 minutes (240000-360000 ms)
      return Math.floor(Math.random() * (360000 - 240000) + 240000);
    };

    const scheduleNextHeartbeat = () => {
      if (heartbeatInterval) {
        clearTimeout(heartbeatInterval);
      }
      heartbeatInterval = setTimeout(sendHeartbeat, getRandomInterval());
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // Clear timeout when tab is hidden
        if (heartbeatInterval) {
          clearTimeout(heartbeatInterval);
        }
      } else if (isAuthenticated) {
        // Only send heartbeat if authenticated
        sendHeartbeat();
      }
    };

    const handleBeforeUnload = async () => {
      if (isAuthenticated) {
        try {
          const token = localStorage.getItem('token');
          // Only send logout beacon if it wasn't a manual logout
          if (!sessionStorage.getItem('manualLogout')) {
            navigator.sendBeacon(
              `${process.env.REACT_APP_API_URL}/auth/logout`,
              JSON.stringify({ token })
            );
          }
        } catch (error) {
          console.error('Error during logout:', error);
        }
      }
    };

    // Only set up heartbeat if authenticated
    if (isAuthenticated) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      window.addEventListener('beforeunload', handleBeforeUnload);
      sendHeartbeat(); // Start initial heartbeat
    }

    return () => {
      if (heartbeatInterval) clearTimeout(heartbeatInterval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isAuthenticated]); // Add isAuthenticated as dependency

  return (
    <PresenceContext.Provider value={{ isAuthenticated }}>
      {children}
    </PresenceContext.Provider>
  );
}

export const usePresence = () => useContext(PresenceContext);
