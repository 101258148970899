import React, { useState, useEffect } from 'react';
import { Table, Button, Space, message, Typography, Tag } from 'antd';
import { ArrowLeftOutlined, FormOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';

const { Title } = Typography;

const OnboardingCurrent = () => {
  const navigate = useNavigate();
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchCurrentOnboardings();
  }, []);

  const fetchCurrentOnboardings = async () => {
    try {
      const response = await axios.get('/onboarding/current');
      setDeals(response.data);
    } catch (error) {
      message.error('Failed to fetch current onboardings');
    } finally {
      setLoading(false);
    }
  };

  const startOnboardingFlow = (deal) => {
    window.open(
      `/onboarding/form/${deal.id}/${deal.onboarding.id}`, 
      '_blank',
      'width=1200,height=800'
    );
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Meeting Date',
      dataIndex: ['onboarding', 'meeting_date'],
      key: 'meeting_date',
      render: (date) => date ? dayjs(date).format('YYYY-MM-DD HH:mm') : '-'
    },
    {
      title: 'Status',
      dataIndex: ['onboarding', 'last_completed_step'],
      key: 'status',
      render: (step) => (
        <Tag color={step ? 'blue' : 'orange'}>
          {step || 'Not Started'}
        </Tag>
      )
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="primary"
          icon={<FormOutlined />}
          onClick={() => startOnboardingFlow(record)}
        >
          Start Onboarding
        </Button>
      )
    }
  ];

  return (
    <div>
      <Space direction="vertical" style={{ width: '100%', marginBottom: 24 }}>
        <Button 
            icon={<ArrowLeftOutlined />} 
            onClick={() => navigate('/onboarding')}
        />
        <Space>
          <Title level={2} style={{ margin: 0 }}>Current Onboardings</Title>
        </Space>
        <Typography.Text type="secondary">
          Manage ongoing customer onboarding processes
        </Typography.Text>
      </Space>
      
      <Table 
        columns={columns} 
        dataSource={deals}
        loading={loading}
        rowKey="id"
        pagination={false}
      />
    </div>
  );
};

export default OnboardingCurrent;