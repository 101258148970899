import { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Switch } from "@/components/ui/switch"
import { Input } from "@/components/ui/input"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Alert, AlertDescription } from "@/components/ui/alert"
import { Calendar } from "@/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { ScrollArea } from "@/components/ui/scroll-area"
import { cn } from "@/lib/utils"
import { format } from "date-fns"
import { CalendarIcon, AlertCircle, InfoIcon } from "lucide-react"
import { toast } from "sonner"
import { dealDataConfig } from '../../configs/DealDataConfig'
import { fetchUsersByPermission, getUserOptions } from '../../utils/permissionUtils'
import axios from '../../api/axiosConfig'
import { Deal, DealDataConfigItem } from '@/types/customers'
import { dealSoftwareConfig } from '../../configs/DealSoftwareConfig'

interface DealDetailsProps {
  visible: boolean
  onClose: () => void
  deal: Deal | null
  onUpdateSuccess: (dealId: string) => void
}

interface UserOptions {
  accounting: { value: string; label: string }[]
  payroll: { value: string; label: string }[]
}

const isSoftwareField = (key: string): boolean => {
  return dealSoftwareConfig.some(config => config.key === key)
}

const DealDetails = ({ visible, onClose, deal, onUpdateSuccess }: DealDetailsProps) => {
  const [editedDeal, setEditedDeal] = useState<Deal | null>(null)
  const [userOptions, setUserOptions] = useState<UserOptions>({ accounting: [], payroll: [] })

  useEffect(() => {
    if (visible && deal) {
      setEditedDeal(JSON.parse(JSON.stringify(deal)))
      fetchUserOptions()
    }
  }, [visible, deal])

  const fetchUserOptions = async () => {
    const users = await fetchUsersByPermission()
    setUserOptions({
      accounting: getUserOptions(users.accounting),
      payroll: getUserOptions(users.payroll),
    })
  }

  const handleEditDeal = (key: string, value: any) => {
    if (!editedDeal) return

    setEditedDeal(prevState => {
      if (!prevState) return prevState

      // Create a deep copy of the current state
      const newState = { ...prevState }
      
      // Update the data field
      newState.data = {
        ...newState.data,
        [key]: value
      }

      return newState
    })
  }

  const getConfig = (key: string): DealDataConfigItem => {
    const foundConfig = dealDataConfig.find(item => item.key === `data.${key}`)
    if (foundConfig) {
      return foundConfig
    }
    return {
      key: key,
      label: key,
      type: 'text',
      editable: false
    }
  }

  const renderEditableField = (key: string, value: any, config: DealDataConfigItem) => {
    switch (config.type) {
      case 'number':
        return (
          <Input
            type="number"
            value={value}
            onChange={(e) => handleEditDeal(key, parseFloat(e.target.value))}
            className="w-full"
          />
        )
      case 'boolean':
        return (
          <Switch
            checked={value}
            onCheckedChange={(checked) => handleEditDeal(key, checked)}
          />
        )
      case 'dropdown':
        const options = config.options || []
        return (
          <Select
            value={value}
            onValueChange={(selectedValue) => handleEditDeal(key, selectedValue)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select..." />
            </SelectTrigger>
            <SelectContent>
              {options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        )
      case 'date':
        return (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !value && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {value ? format(new Date(value), "PPP") : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={value ? new Date(value) : undefined}
                onSelect={(date) => handleEditDeal(key, date ? format(date, 'yyyy-MM-dd') : null)}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        )
      default:
        return (
          <Input
            value={value}
            onChange={(e) => handleEditDeal(key, e.target.value)}
            className="w-full"
          />
        )
    }
  }

  const saveDealChanges = async () => {
    if (!editedDeal) return

    try {
      await axios.patch(`/account/pending-deals/${editedDeal.id}`, {
        data: editedDeal.data
      })
      
      toast.success('Deal updated successfully')
      onClose()
      onUpdateSuccess(String(editedDeal.id))
    } catch (error) {
      console.error('Error updating deal:', error)
      toast.error('Failed to update deal')
    }
  }

  if (!editedDeal) return null

  return (
    <Dialog open={visible} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle className="text-xl font-semibold">
            Deal Details - {editedDeal.name}
          </DialogTitle>
        </DialogHeader>

        <ScrollArea className="max-h-[calc(100vh-200px)]">
          <div className="space-y-6 pr-4">
            {/* Basic Info Section */}
            <div className="grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="text-sm font-medium">Name</label>
                  <p>{editedDeal.name}</p>
                </div>
                <div>
                  <label className="text-sm font-medium">CVR</label>
                  <p>{editedDeal.cvr}</p>
                </div>
              </div>

              {/* Phone with recommendation */}
              <div>
                <label className="text-sm font-medium">Phone</label>
                <p>{editedDeal.phone}</p>
                {editedDeal.recommendations?.recommendations['phone'] && (
                  <Alert variant="default" className="mt-2">
                    <InfoIcon className="h-4 w-4" />
                    <AlertDescription>
                      Automatic correction: {editedDeal.recommendations.recommendations['phone']}
                    </AlertDescription>
                  </Alert>
                )}
              </div>

              {/* Editable Fields Section */}
              <div className="space-y-4">
                {Object.entries(editedDeal.data)
                  .filter(([key]) => !['status', 'title', 'value', 'currency', 'is_new_deal', 'contact_email', 'contact_phone', 'contact_name', 'source', 'products'].includes(key) && !isSoftwareField(key))
                  .map(([key, value]) => {
                    const config = getConfig(key)
                    return (
                      <div key={key} className="space-y-2">
                        <label className="text-sm font-medium">{config.label}</label>
                        <div className="space-y-2">
                          {config.editable ? renderEditableField(key, value, config) : <p>{value}</p>}
                          {editedDeal.recommendations?.recommendations[key] && (
                            <Alert variant="default">
                              <InfoIcon className="h-4 w-4" />
                              <AlertDescription>
                                Automatic correction: {editedDeal.recommendations.recommendations[key]}
                              </AlertDescription>
                            </Alert>
                          )}
                          {editedDeal.recommendations?.alerts[key] && (
                            <Alert variant="destructive">
                              <AlertCircle className="h-4 w-4" />
                              <AlertDescription>
                                Alert: {editedDeal.recommendations.alerts[key]}
                              </AlertDescription>
                            </Alert>
                          )}
                        </div>
                      </div>
                    )
                  })}
              </div>

              {/* Products Section */}
              <div className="space-y-2">
                <h3 className="text-lg font-medium">Products</h3>
                <div className="space-y-2">
                  {editedDeal.data.products?.map((product, index) => (
                    <div key={index} className="p-4 border rounded-lg space-y-1">
                      <p><span className="font-medium">Name:</span> {product.name}</p>
                      <p><span className="font-medium">Code:</span> {product.code}</p>
                      <p><span className="font-medium">Price:</span> {product.item_price}</p>
                      <p><span className="font-medium">Quantity:</span> {product.quantity}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </ScrollArea>

        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={saveDealChanges}>
            Save Changes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default DealDetails