import React, { createContext, useContext, useState, useEffect } from 'react';

export type ThemeType = 'light' | 'dark';
export type ColorScheme = 'blue' | 'orange' | 'green' | 'rose' | 'zinc';

interface ThemeColors {
  background: string;
  foreground: string;
  card: string;
  cardForeground: string;
  popover: string;
  popoverForeground: string;
  primary: string;
  primaryForeground: string;
  secondary: string;
  secondaryForeground: string;
  muted: string;
  mutedForeground: string;
  accent: string;
  accentForeground: string;
  destructive: string;
  destructiveForeground: string;
  border: string;
  input: string;
  ring: string;
}

interface ColorSchemeConfig {
  light: ThemeColors;
  dark: ThemeColors;
}

const colorSchemes: Record<ColorScheme, ColorSchemeConfig> = {
  blue: {
    light: {
      background: "0 0% 100%",
      foreground: "20 14.3% 4.1%",
      card: "0 0% 100%",
      cardForeground: "20 14.3% 4.1%",
      popover: "0 0% 100%",
      popoverForeground: "20 14.3% 4.1%",
      primary: "214 100% 50%",
      primaryForeground: "0 0% 100%",
      secondary: "60 4.8% 95.9%",
      secondaryForeground: "24 9.8% 10%",
      muted: "60 4.8% 95.9%",
      mutedForeground: "25 5.3% 44.7%",
      accent: "60 4.8% 95.9%",
      accentForeground: "24 9.8% 10%",
      destructive: "0 84.2% 60.2%",
      destructiveForeground: "60 9.1% 97.8%",
      border: "20 5.9% 90%",
      input: "20 5.9% 90%",
      ring: "24.6 95% 53.1%",
    },
    dark: {
      background: "214 45% 12%",
      foreground: "210 20% 98%",
      card: "214 40% 16%",
      cardForeground: "210 20% 98%",
      popover: "214 35% 18%",
      popoverForeground: "210 20% 98%",
      primary: "214 100% 50%",
      primaryForeground: "210 20% 98%",
      secondary: "214 25% 20%",
      secondaryForeground: "210 20% 98%",
      muted: "214 25% 22%",
      mutedForeground: "214 20% 75%",
      accent: "214 35% 22%",
      accentForeground: "210 20% 98%",
      destructive: "0 72.2% 50.6%",
      destructiveForeground: "210 20% 98%",
      border: "214 35% 20%",
      input: "12 6.5% 15.1%",
      ring: "214 100% 50%",
    },
  },
  zinc: {
    light: {
      background: "0 0% 100%",
      foreground: "240 10% 3.9%",
      card: "0 0% 100%",
      cardForeground: "240 10% 3.9%",
      popover: "0 0% 100%",
      popoverForeground: "240 10% 3.9%",
      primary: "240 5.9% 10%",
      primaryForeground: "0 0% 98%",
      secondary: "240 4.8% 95.9%",
      secondaryForeground: "240 5.9% 10%",
      muted: "240 4.8% 95.9%",
      mutedForeground: "240 3.8% 46.1%",
      accent: "240 4.8% 95.9%",
      accentForeground: "240 5.9% 10%",
      destructive: "0 84.2% 60.2%",
      destructiveForeground: "0 0% 98%",
      border: "240 5.9% 90%",
      input: "240 5.9% 90%",
      ring: "240 5.9% 10%",
    },
    dark: {
      background: "240 10% 3.9%",
      foreground: "0 0% 98%",
      card: "240 10% 3.9%",
      cardForeground: "0 0% 98%",
      popover: "240 10% 3.9%",
      popoverForeground: "0 0% 98%",
      primary: "0 0% 98%",
      primaryForeground: "240 5.9% 10%",
      secondary: "240 3.7% 15.9%",
      secondaryForeground: "0 0% 98%",
      muted: "240 3.7% 15.9%",
      mutedForeground: "240 5% 64.9%",
      accent: "240 3.7% 15.9%",
      accentForeground: "0 0% 98%",
      destructive: "0 62.8% 30.6%",
      destructiveForeground: "0 0% 98%",
      border: "240 3.7% 15.9%",
      input: "240 3.7% 15.9%",
      ring: "240 4.9% 83.9%",
    },
  },
  rose: {
    light: {
      background: "0 0% 100%",
      foreground: "240 10% 3.9%",
      card: "0 0% 100%",
      cardForeground: "240 10% 3.9%",
      popover: "0 0% 100%",
      popoverForeground: "240 10% 3.9%",
      primary: "349.7 89.2% 60.2%",
      primaryForeground: "355.7 100% 97.3%",
      secondary: "240 4.8% 95.9%",
      secondaryForeground: "240 5.9% 10%",
      muted: "240 4.8% 95.9%",
      mutedForeground: "240 3.8% 46.1%",
      accent: "240 4.8% 95.9%",
      accentForeground: "240 5.9% 10%",
      destructive: "0 84.2% 60.2%",
      destructiveForeground: "0 0% 98%",
      border: "240 5.9% 90%",
      input: "240 5.9% 90%",
      ring: "346.8 77.2% 49.8%",
    },
    dark: {
      background: "346 15% 6%",
      foreground: "0 0% 95%",
      card: "346 15% 8%",
      cardForeground: "0 0% 95%",
      popover: "346 15% 9%",
      popoverForeground: "0 0% 95%",
      primary: "349.7 89.2% 60.2%",
      primaryForeground: "355.7 100% 97.3%",
      secondary: "240 3.7% 15.9%",
      secondaryForeground: "0 0% 98%",
      muted: "0 0% 15%",
      mutedForeground: "240 5% 64.9%",
      accent: "12 6.5% 15.1%",
      accentForeground: "0 0% 98%",
      destructive: "0 62.8% 30.6%",
      destructiveForeground: "0 85.7% 97.3%",
      border: "240 3.7% 15.9%",
      input: "240 3.7% 15.9%",
      ring: "346.8 77.2% 49.8%",
    },
  },
  orange: {
    light: {
      background: "0 0% 100%",
      foreground: "20 14.3% 4.1%",
      card: "0 0% 100%",
      cardForeground: "20 14.3% 4.1%",
      popover: "0 0% 100%",
      popoverForeground: "20 14.3% 4.1%",
      primary: "24.6 95% 53.1%",
      primaryForeground: "60 9.1% 97.8%",
      secondary: "60 4.8% 95.9%",
      secondaryForeground: "24 9.8% 10%",
      muted: "60 4.8% 95.9%",
      mutedForeground: "25 5.3% 44.7%",
      accent: "60 4.8% 95.9%",
      accentForeground: "24 9.8% 10%",
      destructive: "0 84.2% 60.2%",
      destructiveForeground: "60 9.1% 97.8%",
      border: "20 5.9% 90%",
      input: "20 5.9% 90%",
      ring: "24.6 95% 53.1%",
    },
    dark: {
      background: "24 15% 6%",
      foreground: "60 9.1% 97.8%",
      card: "24 15% 8%",
      cardForeground: "60 9.1% 97.8%",
      popover: "24 15% 9%",
      popoverForeground: "60 9.1% 97.8%",
      primary: "20.5 90.2% 48.2%",
      primaryForeground: "60 9.1% 97.8%",
      secondary: "12 6.5% 15.1%",
      secondaryForeground: "60 9.1% 97.8%",
      muted: "12 6.5% 15.1%",
      mutedForeground: "24 5.4% 63.9%",
      accent: "12 6.5% 15.1%",
      accentForeground: "60 9.1% 97.8%",
      destructive: "0 72.2% 50.6%",
      destructiveForeground: "60 9.1% 97.8%",
      border: "12 6.5% 15.1%",
      input: "12 6.5% 15.1%",
      ring: "20.5 90.2% 48.2%",
    },
  },
  green: {
    light: {
      background: "0 0% 100%",
      foreground: "240 10% 3.9%",
      card: "0 0% 100%",
      cardForeground: "240 10% 3.9%",
      popover: "0 0% 100%",
      popoverForeground: "240 10% 3.9%",
      primary: "142.1 76.2% 36.3%",
      primaryForeground: "355.7 100% 97.3%",
      secondary: "240 4.8% 95.9%",
      secondaryForeground: "240 5.9% 10%",
      muted: "240 4.8% 95.9%",
      mutedForeground: "240 3.8% 46.1%",
      accent: "240 4.8% 95.9%",
      accentForeground: "240 5.9% 10%",
      destructive: "0 84.2% 60.2%",
      destructiveForeground: "0 0% 98%",
      border: "240 5.9% 90%",
      input: "240 5.9% 90%",
      ring: "142.1 76.2% 36.3%",
    },
    dark: {
      background: "142 15% 6%",
      foreground: "0 0% 95%",
      card: "142 15% 8%",
      cardForeground: "0 0% 95%",
      popover: "142 15% 9%",
      popoverForeground: "0 0% 95%",
      primary: "142.1 70.6% 45.3%",
      primaryForeground: "144.9 80.4% 10%",
      secondary: "240 3.7% 15.9%",
      secondaryForeground: "0 0% 98%",
      muted: "0 0% 15%",
      mutedForeground: "240 5% 64.9%",
      accent: "12 6.5% 15.1%",
      accentForeground: "0 0% 98%",
      destructive: "0 62.8% 30.6%",
      destructiveForeground: "0 85.7% 97.3%",
      border: "240 3.7% 15.9%",
      input: "240 3.7% 15.9%",
      ring: "142.4 71.8% 29.2%",
    },
  },
};

const applyThemeColors = (colors: ThemeColors) => {
  const root = document.documentElement;
  Object.entries(colors).forEach(([key, value]) => {
    const cssVar = `--${key.replace(/[A-Z]/g, m => `-${m.toLowerCase()}`)}`;
    root.style.setProperty(cssVar, value);
  });
};

interface ThemeContextType {
  theme: ThemeType;
  colorScheme: ColorScheme;
  toggleTheme: () => void;
  setColorScheme: (scheme: ColorScheme) => void;
  applyThemeSettings: (newTheme: ThemeType, newColorScheme: ColorScheme) => void;
}

const ThemeContext = createContext<ThemeContextType>({
  theme: 'light',
  colorScheme: 'blue',
  toggleTheme: () => {},
  setColorScheme: () => {},
  applyThemeSettings: () => {},
});

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<ThemeType>(() => {
    const savedTheme = localStorage.getItem('theme') as ThemeType;
    if (savedTheme) {
      return savedTheme;
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  });

  const [colorScheme, setColorScheme] = useState<ColorScheme>(() => {
    return (localStorage.getItem('colorScheme') as ColorScheme) || 'blue';
  });

  const applyThemeSettings = (newTheme: ThemeType, newColorScheme: ColorScheme) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
    document.documentElement.classList.toggle('dark', newTheme === 'dark');

    setColorScheme(newColorScheme);
    localStorage.setItem('colorScheme', newColorScheme);
    
    applyThemeColors(colorSchemes[newColorScheme][newTheme]);
  };

  // Apply initial theme settings on mount
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') as ThemeType;
    const savedColorScheme = localStorage.getItem('colorScheme') as ColorScheme;
    
    if (savedTheme && savedColorScheme) {
      applyThemeSettings(savedTheme, savedColorScheme);
    }
  }, []);

  const toggleTheme = () => {
    const newTheme: ThemeType = theme === 'light' ? 'dark' : 'light';
    applyThemeSettings(newTheme, colorScheme);
  };

  const handleColorSchemeChange = (scheme: ColorScheme) => {
    applyThemeSettings(theme, scheme);
  };

  // Export the applyThemeSettings function through context
  return (
    <ThemeContext.Provider value={{ 
      theme, 
      colorScheme, 
      toggleTheme, 
      setColorScheme: handleColorSchemeChange,
      applyThemeSettings,
    }}>
      {children}
    </ThemeContext.Provider>
  );
}

export const useTheme = () => useContext(ThemeContext);