import { useState } from "react"
import { BarChart, Clock, PieChart } from "lucide-react"
import TaskWorkload from "./TaskWorkload"
import TaskTimetracking from "./TaskTimetracking"
import TaskData from "./TaskData"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"

type TabType = "data" | "timetracking" | "workload"

interface TabConfig {
  key: TabType
  label: string
  icon: React.ElementType
  component: React.ComponentType
}

const tabs: TabConfig[] = [
  {
    key: "data",
    label: "Data",
    icon: PieChart,
    component: TaskData
  },
  {
    key: "timetracking",
    label: "Timetracking",
    icon: Clock,
    component: TaskTimetracking
  },
  {
    key: "workload",
    label: "Workload",
    icon: BarChart,
    component: TaskWorkload
  }
]

export default function TaskDash() {
  const [activeTab, setActiveTab] = useState<TabType>("data")

  return (
    <div className="space-y-4">
      <Tabs defaultValue="data" onValueChange={(value) => setActiveTab(value as TabType)}>
        <TabsList>
          {tabs.map((tab) => (
            <TabsTrigger key={tab.key} value={tab.key} className="flex items-center gap-2">
              <tab.icon className="h-4 w-4" />
              {tab.label}
            </TabsTrigger>
          ))}
        </TabsList>

        {tabs.map((tab) => (
          <TabsContent key={tab.key} value={tab.key}>
            <tab.component />
          </TabsContent>
        ))}
      </Tabs>
    </div>
  )
}