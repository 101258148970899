import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Switch } from '@/components/ui/switch';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { useForm } from 'react-hook-form';
import axios from '@/api/axiosConfig';
import { toast } from "sonner";
import { StoredUser, UserSettings as UserSettingsType } from '@/types/user';
import { cn } from '@/lib/utils';
import UserCapacitySettings from './UserCapacitySettings';
import { TooltipProvider } from '@/components/ui/tooltip';
import { useTheme, ColorScheme, ThemeType } from '@/contexts/ThemeContext';
import { GB, DK } from 'country-flag-icons/react/3x2'
import { ColorButton } from "@/components/ui/buttons/color-button"
import { useLanguage } from '@/contexts/LanguageContext';
import { t, Language, SUPPORTED_LANGUAGES } from '@/i18n/language';

interface UserSettingsProps {
  open: boolean;
  onClose: () => void;
}

interface PasswordFormValues {
  currentPassword?: string;
  newPassword?: string;
  confirmPassword?: string;
}

const preSpecifiedColors = [
  '#1890ff', '#52c41a', '#fa8c16', '#722ed1', '#eb2f96', '#f5222d', '#13c2c2', '#faad14',
  '#2f54eb', '#fa541c', '#237804', '#0050b3', '#391085', '#cf1322', '#006d75', '#ad6800',
  '#1d39c4', '#a8071a', '#5c0011', '#003a8c'
];

const getStoredUsers = (): Record<string, StoredUser> => {
  try {
    const storedUsers = localStorage.getItem('users');
    return storedUsers ? JSON.parse(storedUsers) : {};
  } catch (error) {
    console.error('Error parsing stored users:', error);
    return {};
  }
};

const getUserInitials = (color: string): string => {
  const users = getStoredUsers();
  const user = Object.values(users).find((u: StoredUser) => 
    u.settings?.avatar_color === color
  );
  return user?.initials || '';
};

export function UserSettings({ open, onClose }: UserSettingsProps) {
  const { theme, colorScheme, applyThemeSettings } = useTheme();
  const form = useForm<UserSettingsType>();
  const passwordForm = useForm<PasswordFormValues>();
  const [avatarColor, setAvatarColor] = useState('');
  const [availableColors, setAvailableColors] = useState<string[]>([]);
  const [showPasswordReset, setShowPasswordReset] = useState(false);
  const [currentPasswordVerified, setCurrentPasswordVerified] = useState(false);
  const [activeTab, setActiveTab] = useState('appearance');
  const { language, setLanguage } = useLanguage();
  const [languageKey, setLanguageKey] = useState(language);

  useEffect(() => {
    console.log('Language changed:', language);
    console.log('LanguageKey changed:', languageKey);
  }, [language, languageKey]);

  useEffect(() => {
    if (open) {
      fetchUserSettings();
      fetchAvailableColors();
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setShowPasswordReset(false);
      setCurrentPasswordVerified(false);
      passwordForm.reset();
      form.reset();
      setActiveTab('appearance');
    }
  }, [open]);

  useEffect(() => {
    setLanguageKey(language);
  }, [language]);

  useEffect(() => {
    console.log('Current language:', language);
  }, [language]);

  const fetchUserSettings = async () => {
    try {
      const response = await axios.get('/user/settings');
      form.reset(response.data);
      setAvatarColor(response.data.avatar_color);
    } catch (error) {
      console.error('Error fetching user settings:', error);
      toast.error("Failed to load user settings");
      form.reset({
        theme: 'dark',
        language: 'en',
        desktop_notifications: false,
        email_notifications: false,
        auto_login: true,
        avatar_color: preSpecifiedColors[0]
      });
      setAvatarColor(preSpecifiedColors[0]);
    }
  };

  const fetchAvailableColors = async () => {
    try {
      const response = await axios.get('/user/available-colors');
      setAvailableColors(response.data);
    } catch (error) {
      console.error('Error fetching available colors:', error);
      toast.error("Failed to load available colors");
    }
  };

  const verifyCurrentPassword = async (values: PasswordFormValues) => {
    try {
      await axios.post('/auth/verify-password', { 
        password: values.currentPassword 
      });
      setCurrentPasswordVerified(true);
      toast.success("Current password verified");
    } catch (error) {
      toast.error("Current password is incorrect");
    }
  };

  const handlePasswordReset = async (values: PasswordFormValues) => {
    try {
      await axios.post('/auth/reset-password', {
        new_password: values.newPassword
      });
      toast.success("Password updated successfully");
      setShowPasswordReset(false);
      setCurrentPasswordVerified(false);
      passwordForm.reset();
    } catch (error) {
      toast.error("Failed to update password");
    }
  };

  const handleSubmit = async (values: any) => {
    try {
      await axios.put('/user/settings', { 
        ...values, 
        avatar_color: avatarColor,
        theme: theme,
        color_scheme: colorScheme
      });
      
      const storedUser = localStorage.getItem('user');
      if (storedUser) {
        const user = JSON.parse(storedUser);
        user.settings = {
          ...user.settings,
          theme,
          color_scheme: colorScheme
        };
        localStorage.setItem('user', JSON.stringify(user));
      }
      
      toast.success("Settings updated successfully");
      onClose();
    } catch (error) {
      toast.error("Failed to update settings");
    }
  };

  const handleThemeChange = async (newTheme: ThemeType) => {
    // First apply the theme locally
    applyThemeSettings(newTheme, colorScheme);
    
    try {
      // Then persist to backend with full settings structure
      await axios.put('/user/settings', {
        theme: newTheme,
        color_scheme: colorScheme
      });
    } catch (error) {
      console.error('Failed to save theme to backend:', error);
    }
  };

  const handleColorSchemeChange = async (newColorScheme: ColorScheme) => {
    // First apply the color scheme locally
    applyThemeSettings(theme, newColorScheme);
    
    try {
      // Then persist to backend with full settings structure
      await axios.put('/user/settings', {
        theme: theme,
        color_scheme: newColorScheme
      });
    } catch (error) {
      console.error('Failed to save color scheme to backend:', error);
    }
  };

  const handleLanguageChange = async (newLanguage: Language) => {
    setLanguageKey(newLanguage);
    setLanguage(newLanguage);
    
    try {
      await axios.put('/user/settings', {
        language: newLanguage,
      });
      toast.success(t('settings.language.updated', newLanguage));
    } catch (error) {
      setLanguageKey(language);
      setLanguage(language);
      console.error('Error updating language:', error);
      toast.error(t('settings.language.updateFailed', language));
    }
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent 
        className={cn(
          "max-w-3xl min-h-[400px] flex flex-col p-0",
          activeTab === "capacity" ? "max-h-[90vh]" : ""
        )}
        aria-describedby="settings-dialog-description"
      >
        <div id="settings-dialog-description" className="sr-only">
          User settings dialog for managing appearance, notifications, account, and capacity settings
        </div>
        <DialogHeader className="p-6 pb-2">
          <DialogTitle>{t('settings.title', language)}</DialogTitle>
        </DialogHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab} className="flex-1 flex flex-col">
          <div className="px-6 mb-4">
            <TabsList className="grid w-full grid-cols-4">
              <TabsTrigger value="appearance">
                {t('settings.tabs.appearance', language)}
              </TabsTrigger>
              <TabsTrigger value="notifications">
                {t('settings.tabs.notifications', language)}
              </TabsTrigger>
              <TabsTrigger value="account">
                {t('settings.tabs.account', language)}
              </TabsTrigger>
              <TabsTrigger value="capacity">
                {t('settings.tabs.capacity', language)}
              </TabsTrigger>
            </TabsList>
          </div>

          <div className="flex-1 flex flex-col">
            <div className="flex-1 p-6">
              <TabsContent value="appearance" className="mt-0 border-0">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6 h-full flex flex-col">
                    <div className="space-y-6 flex-1">
                      <FormItem>
                        <FormLabel>{t('settings.language.label', language)}</FormLabel>
                        <div className="flex gap-2">
                          <Button
                            type="button"
                            variant={languageKey === 'en' ? 'default' : 'outline'}
                            className="flex items-center gap-2 w-24"
                            onClick={() => handleLanguageChange('en')}
                          >
                            <GB className="w-4 h-4" />
                            {t('settings.language.english', language)}
                          </Button>
                          <Button
                            type="button"
                            variant={languageKey === 'da' ? 'default' : 'outline'}
                            className="flex items-center gap-2 w-24"
                            onClick={() => handleLanguageChange('da')}
                          >
                            <DK className="w-4 h-4" />
                            {t('settings.language.danish', language)}
                          </Button>
                        </div>
                      </FormItem>

                      <FormItem>
                        <FormLabel>{t('settings.appearance.mode', language)}</FormLabel>
                        <div className="flex gap-2">
                          <Button
                            type="button"
                            variant={theme === 'light' ? 'default' : 'outline'}
                            onClick={() => handleThemeChange('light')}
                            className="flex items-center gap-2 w-24"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <circle cx="12" cy="12" r="5"/>
                              <line x1="12" y1="1" x2="12" y2="3"/>
                              <line x1="12" y1="21" x2="12" y2="23"/>
                              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"/>
                              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"/>
                              <line x1="1" y1="12" x2="3" y2="12"/>
                              <line x1="21" y1="12" x2="23" y2="12"/>
                              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"/>
                              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"/>
                            </svg>
                            {t('settings.appearance.light', language)}
                          </Button>
                          <Button
                            type="button"
                            variant={theme === 'dark' ? 'default' : 'outline'}
                            onClick={() => handleThemeChange('dark')}
                            className="flex items-center gap-2 w-24"
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"/>
                            </svg>
                            {t('settings.appearance.dark', language)}
                          </Button>
                        </div>
                      </FormItem>

                      <FormItem>
                        <FormLabel>{t('settings.appearance.themeColor', language)}</FormLabel>
                        <div className="flex flex-wrap gap-2">
                          {[
                            { name: 'blue', color: '#3b82f6' },
                            { name: 'zinc', color: '#71717a' },
                            { name: 'rose', color: '#f43f5e' },
                            { name: 'orange', color: '#f97316' },
                            { name: 'green', color: '#22c55e' },
                          ].map((scheme) => (
                            <ColorButton
                              key={scheme.name}
                              color={scheme.color}
                              selected={colorScheme === scheme.name}
                              onClick={() => handleColorSchemeChange(scheme.name as ColorScheme)}
                            />
                          ))}
                        </div>
                      </FormItem>

                      <FormItem>
                        <FormLabel>{t('settings.appearance.avatarColor', language)}</FormLabel>
                        <div className="flex flex-wrap gap-2">
                          {preSpecifiedColors.map(color => (
                            <TooltipProvider key={color}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <ColorButton
                                    key={color}
                                    color={color}
                                    selected={avatarColor === color}
                                    className={cn(
                                      !availableColors.includes(color) && 'opacity-50 cursor-not-allowed'
                                    )}
                                    onClick={() => availableColors.includes(color) && setAvatarColor(color)}
                                  >
                                    {!availableColors.includes(color) && (
                                      <span className="absolute inset-0 flex items-center justify-center text-sm font-medium text-foreground">
                                        {getUserInitials(color)}
                                      </span>
                                    )}
                                  </ColorButton>
                                </TooltipTrigger>
                                <TooltipContent>
                                  {availableColors.includes(color) ? 'Available' : `In use by ${getUserInitials(color)}`}
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          ))}
                        </div>
                      </FormItem>
                    </div>

                    <div className="flex justify-end pt-6 border-t">
                      <Button type="submit">{t('settings.appearance.saveButton', language)}</Button>
                    </div>
                  </form>
                </Form>
              </TabsContent>

              <TabsContent value="notifications" className="mt-0 border-0">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6 h-full flex flex-col">
                    <div className="space-y-6 flex-1">
                      <div className="space-y-4">
                        <FormField
                          control={form.control}
                          name="desktop_notifications"
                          render={({ field }) => (
                            <FormItem className="flex flex-row items-start justify-between space-y-0 rounded-lg border p-4">
                              <div className="space-y-1">
                                <FormLabel className="text-base font-semibold text-muted-foreground">
                                  {t('settings.notifications.desktop.title', language)}
                                </FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  {t('settings.notifications.desktop.description', language)}
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="email_notifications"
                          render={({ field }) => (
                            <FormItem className="flex flex-row items-start justify-between space-y-0 rounded-lg border p-4">
                              <div className="space-y-1">
                                <FormLabel className="text-base font-semibold text-muted-foreground">
                                  {t('settings.notifications.email.title', language)}
                                </FormLabel>
                                <p className="text-sm text-muted-foreground">
                                  {t('settings.notifications.email.description', language)}
                                </p>
                              </div>
                              <FormControl>
                                <Switch
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                  disabled={true}
                                />
                              </FormControl>
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>

                    <div className="flex justify-end pt-6 border-t">
                      <Button type="submit">{t('settings.notifications.saveButton', language)}</Button>
                    </div>
                  </form>
                </Form>
              </TabsContent>

              <TabsContent value="account" className="mt-0 border-0">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6 h-full flex flex-col">
                    <div className="space-y-6 flex-1">
                      <FormField
                        control={form.control}
                        name="auto_login"
                        render={({ field }) => (
                          <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                            <div className="space-y-0.5">
                              <FormLabel className="text-base text-muted-foreground">
                                {t('settings.account.autoLogin', language)}
                              </FormLabel>
                            </div>
                            <FormControl>
                              <Switch
                                checked={field.value}
                                onCheckedChange={field.onChange}
                                disabled={true}
                              />
                            </FormControl>
                          </FormItem>
                        )}
                      />
                    </div>

                    <div className="flex justify-between items-center pt-6 border-t">
                      <Button 
                        type="button"
                        variant="outline" 
                        onClick={() => setShowPasswordReset(!showPasswordReset)}
                        className="text-destructive hover:text-destructive-foreground hover:bg-destructive"
                      >
                        {t('settings.account.resetPassword', language)}
                      </Button>
                      <Button type="submit">{t('settings.account.saveButton', language)}</Button>
                    </div>
                  </form>

                  {showPasswordReset && (
                    <Form {...passwordForm}>
                      <form 
                        onSubmit={passwordForm.handleSubmit(
                          (values: PasswordFormValues) => 
                            currentPasswordVerified 
                              ? handlePasswordReset(values)
                              : verifyCurrentPassword(values)
                        )} 
                        className="space-y-4 mt-4"
                      >
                        {!currentPasswordVerified ? (
                          <FormField
                            control={passwordForm.control}
                            name="currentPassword"
                            rules={{ required: "Please input your current password" }}
                            render={({ field }) => (
                              <FormItem>
                                <FormLabel>{t('settings.account.currentPassword', language)}</FormLabel>
                                <FormControl>
                                  <Input type="password" {...field} />
                                </FormControl>
                              </FormItem>
                            )}
                          />
                        ) : (
                          <>
                            <FormField
                              control={passwordForm.control}
                              name="newPassword"
                              rules={{ required: "Please input your new password" }}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>{t('settings.account.newPassword', language)}</FormLabel>
                                  <FormControl>
                                    <Input type="password" {...field} />
                                  </FormControl>
                                </FormItem>
                              )}
                            />
                            <FormField
                              control={passwordForm.control}
                              name="confirmPassword"
                              rules={{
                                required: "Please confirm your new password",
                                validate: (value) => 
                                  value === passwordForm.getValues("newPassword") || 
                                  "Passwords do not match"
                              }}
                              render={({ field }) => (
                                <FormItem>
                                  <FormLabel>{t('settings.account.confirmPassword', language)}</FormLabel>
                                  <FormControl>
                                    <Input type="password" {...field} />
                                  </FormControl>
                                </FormItem>
                              )}
                            />
                          </>
                        )}
                        <div className="flex justify-end pt-6 border-t">
                          <Button type="submit">
                            {currentPasswordVerified 
                              ? t('settings.account.updatePassword', language)
                              : t('settings.account.verifyPassword', language)}
                          </Button>
                        </div>
                      </form>
                    </Form>
                  )}
                </Form>
              </TabsContent>

              <TabsContent value="capacity" className="mt-0 border-0">
                <UserCapacitySettings 
                  visible={open} 
                  onVisibilityChange={(isVisible: boolean) => {
                    if (!isVisible) {
                      setActiveTab('appearance');
                    }
                  }}
                />
              </TabsContent>
            </div>
          </div>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}

export default UserSettings;
