import React, { createContext, useContext, useState, useEffect } from 'react';
import { Language, SUPPORTED_LANGUAGES } from '@/i18n/language';

type LanguageContextType = {
  language: Language;
  setLanguage: (lang: Language) => void;
};

const LanguageContext = createContext<LanguageContextType>({
  language: 'en',
  setLanguage: () => {},
});

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState<Language>(() => {
    const stored = localStorage.getItem('language');
    return (SUPPORTED_LANGUAGES.includes(stored as Language) ? stored : 'en') as Language;
  });

  const handleLanguageChange = (newLang: Language) => {
    console.log('LanguageProvider: changing language to:', newLang);
    localStorage.setItem('language', newLang);
    document.documentElement.lang = newLang;
    setLanguage(newLang);
  };

  const contextValue = React.useMemo(
    () => ({ 
      language, 
      setLanguage: handleLanguageChange 
    }),
    [language]
  );

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage = () => useContext(LanguageContext);