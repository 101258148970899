import { useState, useEffect } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { FileText, Trash2, BookOpen, X } from 'lucide-react'
import axios from '../api/axiosConfig'
import { toast } from "sonner"
import dayjs from 'dayjs'

interface Invoice {
  id: number
  invoice_number: string
  status: string
  total_amount: number
  created_at: string
  economic_invoice_id: string
}

interface EconomicInvoice {
  draftInvoiceNumber: string
  customer: {
    name: string
  }
  invoiceDate: string
  dueDate: string
  grossAmount: number
  lines: InvoiceLine[]
}

interface InvoiceLine {
  description: string
  quantity: number
  unitNetPrice: number
  totalNetAmount: number
}

export default function BillingSettings() {
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [economicInvoice, setEconomicInvoice] = useState<EconomicInvoice | null>(null)

  useEffect(() => {
    fetchInvoices()
  }, [])

  const fetchInvoices = async () => {
    setLoading(true)
    try {
      const response = await axios.get('/invoice/all')
      setInvoices(response.data)
    } catch (error) {
      toast.error('Failed to fetch invoices')
    }
    setLoading(false)
  }

  const handleReviewInvoice = async (invoice: Invoice) => {
    setSelectedInvoice(invoice)
    try {
      const response = await axios.get(`/invoice/economic/${invoice.economic_invoice_id}`)
      setEconomicInvoice(response.data)
      setIsModalOpen(true)
    } catch (error) {
      toast.error('Failed to fetch invoice details')
    }
  }

  const handleBookInvoice = async () => {
    if (!selectedInvoice) return
    try {
      await axios.post(`/invoice/book/${selectedInvoice.id}`)
      toast.success('Invoice booked successfully')
      setIsModalOpen(false)
      fetchInvoices()
    } catch (error) {
      toast.error('Failed to book invoice')
    }
  }

  const handleDeleteInvoice = async () => {
    if (!selectedInvoice) return
    try {
      await axios.delete(`/invoice/${selectedInvoice.id}`)
      toast.success('Invoice deleted successfully')
      setIsModalOpen(false)
      fetchInvoices()
    } catch (error) {
      toast.error('Failed to delete invoice')
    }
  }

  const handleViewPDF = async () => {
    if (!selectedInvoice) return
    try {
      const response = await axios.get(`/invoice/economic/${selectedInvoice.economic_invoice_id}/pdf`, {
        responseType: 'blob'
      })
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)
      window.open(pdfUrl, '_blank')
    } catch (error) {
      console.error('Error fetching PDF:', error)
      toast.error('Failed to fetch invoice PDF')
    }
  }

  const formatNumber = (number: number) => {
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  const columns = [
    { header: 'Invoice Number', accessorKey: 'invoice_number' },
    { header: 'Status', accessorKey: 'status' },
    { 
      header: 'Total Amount, kr.',
      accessorKey: 'total_amount',
      cell: ({ row }: { row: { original: Invoice } }) => (
        `${formatNumber(row.original.total_amount)} u/moms`
      )
    },
    { 
      header: 'Created At',
      accessorKey: 'created_at',
      cell: ({ row }: { row: { original: Invoice } }) => (
        dayjs(row.original.created_at).format('YYYY-MM-DD HH:mm:ss')
      )
    },
    {
      header: 'Action',
      id: 'actions',
      cell: ({ row }: { row: { original: Invoice } }) => (
        <Button 
          variant="outline" 
          size="sm"
          onClick={() => handleReviewInvoice(row.original)}
        >
          Review Invoice
        </Button>
      ),
    },
  ]

  return (
    <div className="space-y-6 w-full">
      <h2 className="text-2xl font-semibold">Billing Overview</h2>
      
      <Card className="w-full">
        <CardContent className="p-0">
          <Table>
            <TableHeader>
              <TableRow>
                {columns.map((column) => (
                  <TableHead key={column.accessorKey || column.id}>
                    {column.header}
                  </TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody>
              {invoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  {columns.map((column) => (
                    <TableCell key={column.accessorKey || column.id}>
                      {column.cell ? 
                        column.cell({ row: { original: invoice } }) : 
                        invoice[column.accessorKey as keyof Invoice]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Review Invoice</DialogTitle>
          </DialogHeader>

          {economicInvoice && (
            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm text-muted-foreground">Invoice Number</p>
                  <p className="font-medium">{economicInvoice.draftInvoiceNumber}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Customer</p>
                  <p className="font-medium">{economicInvoice.customer.name}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Invoice Date</p>
                  <p className="font-medium">{dayjs(economicInvoice.invoiceDate).format('YYYY-MM-DD')}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Due Date</p>
                  <p className="font-medium">{dayjs(economicInvoice.dueDate).format('YYYY-MM-DD')}</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Total Amount (excl. VAT)</p>
                  <p className="font-medium">{formatNumber(economicInvoice.grossAmount / 1.25)} kr.</p>
                </div>
                <div>
                  <p className="text-sm text-muted-foreground">Total Amount (incl. VAT)</p>
                  <p className="font-medium">{formatNumber(economicInvoice.grossAmount)} kr.</p>
                </div>
              </div>

              <div>
                <h3 className="text-lg font-semibold mb-2">Line Items</h3>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Description</TableHead>
                      <TableHead>Quantity</TableHead>
                      <TableHead>Unit Price (u/moms)</TableHead>
                      <TableHead>Total (u/moms)</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {economicInvoice.lines.map((line, index) => (
                      <TableRow key={index}>
                        <TableCell>{line.description}</TableCell>
                        <TableCell>{line.quantity}</TableCell>
                        <TableCell>{formatNumber(line.unitNetPrice)} kr.</TableCell>
                        <TableCell>{formatNumber(line.totalNetAmount)} kr.</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          )}

          <DialogFooter className="gap-2">
            <Button
              variant="outline"
              onClick={handleViewPDF}
            >
              <FileText className="h-4 w-4 mr-2" />
              View PDF
            </Button>
            <Button
              variant="destructive"
              onClick={handleDeleteInvoice}
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete Invoice
            </Button>
            <Button
              onClick={handleBookInvoice}
            >
              <BookOpen className="h-4 w-4 mr-2" />
              Book Invoice
            </Button>
            <Button
              variant="outline"
              onClick={() => setIsModalOpen(false)}
            >
              <X className="h-4 w-4 mr-2" />
              Cancel
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}