import * as React from "react"
import { 
  add,
  eachMonthOfInterval,
  endOfYear,
  format,
  isEqual,
  isFuture,
  parse,
  startOfMonth,
  startOfToday,
} from "date-fns"
import { Calendar as CalendarIcon, ChevronLeft, ChevronRight } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button, buttonVariants } from "@/components/ui/button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

function getStartOfCurrentMonth() {
  return startOfMonth(startOfToday())
}

interface MonthPickerProps {
  date?: Date
  onSelect?: (date: Date | undefined) => void
  className?: string
}

export function MonthPicker({ date, onSelect, className }: MonthPickerProps) {
  const [currentYear, setCurrentYear] = React.useState(
    format(date || new Date(), 'yyyy')
  )
  const firstDayCurrentYear = parse(currentYear, 'yyyy', new Date())

  const months = eachMonthOfInterval({
    start: firstDayCurrentYear,
    end: endOfYear(firstDayCurrentYear),
  })

  function previousYear() {
    let firstDayPrevYear = add(firstDayCurrentYear, { years: -1 })
    setCurrentYear(format(firstDayPrevYear, 'yyyy'))
  }

  function nextYear() {
    let firstDayNextYear = add(firstDayCurrentYear, { years: 1 })
    setCurrentYear(format(firstDayNextYear, 'yyyy'))
  }

  const isDateEqual = (date1: Date, date2: Date | undefined): boolean => {
    if (!date2) return false
    return isEqual(date1, date2)
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-[240px] justify-start text-left font-normal",
            !date && "text-muted-foreground",
            className
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "MMMM yyyy") : <span>Pick a month</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <div className="p-3">
          <div className="flex flex-col space-y-4">
            <div className="relative flex items-center justify-center pt-1">
              <div
                className="text-sm font-medium"
                aria-live="polite"
                role="presentation"
                id="month-picker"
              >
                {format(firstDayCurrentYear, 'yyyy')}
              </div>
              <div className="flex items-center space-x-1">
                <button
                  name="previous-year"
                  aria-label="Go to previous year"
                  className={cn(
                    buttonVariants({ variant: 'outline' }),
                    'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
                    'absolute left-1'
                  )}
                  onClick={previousYear}
                >
                  <ChevronLeft className="h-4 w-4" />
                </button>
                <button
                  name="next-year"
                  aria-label="Go to next year"
                  className={cn(
                    buttonVariants({ variant: 'outline' }),
                    'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
                    'absolute right-1'
                  )}
                  onClick={nextYear}
                >
                  <ChevronRight className="h-4 w-4" />
                </button>
              </div>
            </div>
            <div
              className="grid w-full grid-cols-3 gap-2"
              role="grid"
              aria-labelledby="month-picker"
            >
              {months.map((month) => (
                <div
                  key={month.toString()}
                  className="relative p-0 text-center text-sm focus-within:relative focus-within:z-20"
                  role="presentation"
                >
                  <button
                    name="month"
                    className={cn(
                      'inline-flex h-9 w-16 items-center justify-center rounded-md p-0 text-sm font-normal transition-colors hover:bg-accent hover:text-accent-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                      isDateEqual(month, date) && 
                        'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
                      !isDateEqual(month, date) &&
                        isEqual(month, getStartOfCurrentMonth()) &&
                        'bg-accent text-accent-foreground'
                    )}
                    onClick={() => onSelect?.(month)}
                  >
                    <time dateTime={format(month, 'yyyy-MM-dd')}>
                      {format(month, 'MMM')}
                    </time>
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}