import React, { useState, useEffect } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { 
  Calendar as CalendarIcon, 
  Bot, 
  Save, 
  History, 
  Beaker, 
  Copy, 
  Trash2, 
  Send, 
  CalendarDays 
} from 'lucide-react';
import { toast } from "sonner";
import dayjs from 'dayjs';
import axios from '@/api/axiosConfig';
import ErpHeader from '@/components/ErpHeader';

const MORTEN_AVATAR_URL = 'https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/images/morten_avatar.jpg';

interface Post {
  id: number;
  content: string;
  post_date: string | null;
}

export default function LinkedinBot() {
  const [topic, setTopic] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [posts, setPosts] = useState<Post[]>([]);
  const [newPostContent, setNewPostContent] = useState<string>('');
  const [newPostDate, setNewPostDate] = useState<Date | undefined>(undefined);
  const [expandedPosts, setExpandedPosts] = useState<Set<number>>(new Set());
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [generatedContent, setGeneratedContent] = useState<string>('');
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get<Post[]>('/tools/linkedin-posts');
      setPosts(response.data);
    } catch (error) {
      toast.error('Failed to fetch posts');
    }
  };

  const generatePost = async () => {
    if (!topic || !description) {
      toast.warning('Please fill in both topic and description');
      return;
    }
    
    try {
      setIsGenerating(true);
      setGeneratedContent('');
      
      const response = await axios.post('/tools/generate-post', { 
        topic, 
        description 
      });
      
      if (response.data.success) {
        setGeneratedContent(response.data.response);
        toast.success('Post generated successfully');
      } else {
        toast.error('Failed to generate post: ' + (response.data.error || 'Unknown error'));
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.error || error.message || 'Unknown error';
      toast.error('Error generating post: ' + errorMessage);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleSavePost = async () => {
    if (!newPostContent) {
      toast.warning('Please enter post content');
      return;
    }

    try {
      await axios.post('/tools/save-post', {
        content: newPostContent,
        post_date: newPostDate ? format(newPostDate, 'yyyy-MM-dd') : null
      });
      toast.success('Post saved successfully');
      setNewPostContent('');
      setNewPostDate(undefined);
      fetchPosts();
    } catch (error) {
      toast.error('Failed to save post');
    }
  };

  const handleDeletePost = async (postId: number) => {
    try {
      await axios.delete(`/tools/linkedin-posts/${postId}`);
      toast.success('Post deleted successfully');
      fetchPosts();
    } catch (error) {
      toast.error('Failed to delete post');
    }
  };

  const togglePostExpansion = (postId: number) => {
    const newExpandedPosts = new Set(expandedPosts);
    if (expandedPosts.has(postId)) {
      newExpandedPosts.delete(postId);
    } else {
      newExpandedPosts.add(postId);
    }
    setExpandedPosts(newExpandedPosts);
  };

  return (
    <div className="min-h-screen bg-background">
      <ErpHeader 
        icon={Beaker}
        title="Custom GPTs"
      />
      <main className="flex-1 p-8 max-w-[1600px] min-w-[800px] mx-auto">
        <Tabs defaultValue="generator" className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="generator" className="flex items-center gap-2">
              <Bot className="h-4 w-4" />
              Generate Post
            </TabsTrigger>
            <TabsTrigger value="save" className="flex items-center gap-2">
              <Save className="h-4 w-4" />
              Save Post
            </TabsTrigger>
            <TabsTrigger value="posts" className="flex items-center gap-2">
              <History className="h-4 w-4" />
              View Posts
            </TabsTrigger>
          </TabsList>

          <TabsContent value="generator">
            <Card>
              <CardHeader>
                <CardTitle>LinkedIn Post Generator</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <Input
                  placeholder="Skriv overordnet emne (f.eks. 'Digital transformation i revisionsbranchen')"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                />
                <Textarea
                  placeholder="Beskriv hvad indlægget skal handle om. Inkluder gerne:
• Hovedbudskabet du vil formidle
• Din personlige holdning eller erfaring med emnet
• Hvilke pointer du gerne vil have med
• Evt. konkrete eksempler du vil inkludere
• Målgruppen for indlægget"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="min-h-[140px]"
                />
                <Button
                  onClick={generatePost}
                  disabled={isGenerating}
                  className="flex items-center gap-2"
                >
                  <Send className="h-4 w-4" />
                  Generate Post
                </Button>

                {isGenerating ? (
                  <div className="flex flex-col items-center justify-center min-h-[200px] gap-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
                    <p className="text-muted-foreground">Generating your LinkedIn post...</p>
                  </div>
                ) : generatedContent && (
                  <div className="border rounded-lg p-4 space-y-4">
                    <div className="flex items-center gap-3 pb-4 border-b">
                      <img 
                        src={MORTEN_AVATAR_URL} 
                        alt="Morten Krog Kristensen" 
                        className="w-12 h-12 rounded-full object-cover"
                        onError={(e) => {
                          e.currentTarget.src = 'https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/images/default_avatar.jpg';
                        }}
                      />
                      <div>
                        <h3 className="font-semibold">Morten Mathiesen</h3>
                        <p className="text-sm text-muted-foreground">CEO at Digi-Tal</p>
                      </div>
                    </div>
                    <div className="whitespace-pre-wrap">{generatedContent}</div>
                    <div className="flex gap-2">
                      <Button
                        variant="default"
                        onClick={() => {
                          setNewPostContent(generatedContent);
                          toast.success('Post copied to editor');
                        }}
                        className="flex items-center gap-2"
                      >
                        <Save className="h-4 w-4" />
                        Save to Posts
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          navigator.clipboard.writeText(generatedContent);
                          toast.success('Content copied to clipboard');
                        }}
                        className="flex items-center gap-2"
                      >
                        <Copy className="h-4 w-4" />
                        Copy to Clipboard
                      </Button>
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="save">
            <Card>
              <CardHeader>
                <CardTitle>Save LinkedIn Post</CardTitle>
              </CardHeader>
              <CardContent className="space-y-4">
                <Textarea
                  value={newPostContent}
                  onChange={(e) => setNewPostContent(e.target.value)}
                  placeholder="Enter your LinkedIn post content"
                  className="min-h-[200px]"
                />
                <Popover open={isCalendarOpen} onOpenChange={setIsCalendarOpen}>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className={cn(
                        "w-full justify-start text-left font-normal",
                        !newPostDate && "text-muted-foreground"
                      )}
                    >
                      <CalendarIcon className="mr-2 h-4 w-4" />
                      {newPostDate ? format(newPostDate, "PPP") : "Select post date"}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0">
                    <Calendar
                      mode="single"
                      selected={newPostDate}
                      onSelect={(date) => {
                        setNewPostDate(date);
                        setIsCalendarOpen(false);
                      }}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <Button onClick={handleSavePost} className="flex items-center gap-2">
                  <Save className="h-4 w-4" />
                  Save Post
                </Button>
              </CardContent>
            </Card>
          </TabsContent>

          <TabsContent value="posts">
            <Card>
              <CardHeader>
                <CardTitle>Saved LinkedIn Posts</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  {posts.map((post) => (
                    <div
                      key={post.id}
                      className="border rounded-lg p-4 space-y-2"
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-2">
                          <CalendarDays className="h-4 w-4 text-muted-foreground" />
                          <span className="text-sm text-muted-foreground">
                            {post.post_date ? dayjs(post.post_date).format('YYYY-MM-DD') : 'Draft'}
                          </span>
                        </div>
                        <div className="flex gap-2">
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => {
                              navigator.clipboard.writeText(post.content);
                              toast.success('Content copied to clipboard');
                            }}
                            className="flex items-center gap-2"
                          >
                            <Copy className="h-4 w-4" />
                            Copy
                          </Button>
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button
                                variant="destructive"
                                size="sm"
                                className="flex items-center gap-2"
                              >
                                <Trash2 className="h-4 w-4" />
                                Delete
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>Delete Post</AlertDialogTitle>
                                <AlertDialogDescription>
                                  Are you sure you want to delete this post? This action cannot be undone.
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancel</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => handleDeletePost(post.id)}
                                >
                                  Delete
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </div>
                      </div>
                      <div
                        className={cn(
                          "cursor-pointer rounded-lg p-4 bg-muted/50",
                          !expandedPosts.has(post.id) && "max-h-[100px] overflow-hidden relative"
                        )}
                        onClick={() => togglePostExpansion(post.id)}
                      >
                        <div className="whitespace-pre-wrap">{post.content}</div>
                        {!expandedPosts.has(post.id) && (
                          <div className="absolute bottom-0 left-0 right-0 h-12 bg-gradient-to-t from-background to-transparent flex items-end justify-center pb-2">
                            <span className="text-sm text-primary">Click to expand</span>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </CardContent>
            </Card>
          </TabsContent>
        </Tabs>
      </main>
    </div>
  );
}