import React, { useState, useEffect } from 'react';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import { Download } from 'lucide-react';
import { Card, CardContent } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/specialized/mod-table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Badge } from "@/components/ui/badge";
import { toast } from "sonner"

interface SyncStatus {
  customer_access?: string;
  moms?: string;
  personlige?: string;
  selskaber?: string;
  udloebsdato_cpr?: string;
  transfer_pricing?: string;
}

interface CustomerData {
  cvr?: string;
  name?: string;
  access_types?: string[];
  latest_date?: string;
  periodFrom?: string;
  periodTo?: string;
  deadline?: string;
  isReported?: boolean;
  year?: string;
  cpr?: string;
  expirationDate?: string;
  documents?: number;
  tp_last_year?: string;
  status?: string;
  self_reported?: string;
  [key: string]: any;
}

interface ColumnDef<T> {
  title: string;
  dataIndex: keyof T;
  key: string;
  width: string;
  render?: (value: any) => React.ReactNode;
  sortable?: {
    enabled: boolean;
    type: 'date' | 'number' | 'string';
    compare?: (a: T, b: T) => number;
  };
  filterable?: boolean;
  filters?: { text: string; value: any }[];
  onFilter?: (value: any, record: T) => boolean;
}

const compareDates = (a: string | undefined, b: string | undefined) => {
  if (!a && !b) return 0;
  if (!a) return -1;
  if (!b) return 1;
  return dayjs(a, 'DD-MM-YYYY').unix() - dayjs(b, 'DD-MM-YYYY').unix();
};

function Revibot() {
  const [customerData, setCustomerData] = useState<CustomerData[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedCard, setSelectedCard] = useState<string | null>(null);
  const [searchText, setSearchText] = useState('');
  const [syncStatus, setSyncStatus] = useState<SyncStatus>({});
  const [pageSize, setPageSize] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchSyncStatus = async () => {
    try {
      const response = await axios.get('/revibot/sync/status');
      setSyncStatus(response.data);
    } catch (error) {
      console.error('Failed to fetch sync status:', error);
    }
  };

  useEffect(() => {
    fetchSyncStatus();
  }, []);

  const statsData = [
    {
      key: 'kundeadgange',
      label: 'Kundeadgange',
      value: syncStatus.customer_access ? (
        <Badge variant="default">
          Sidst ændret: {dayjs(syncStatus.customer_access).format('DD/MM/YYYY')}
        </Badge>
      ) : (
        <Badge variant="destructive">Ikke synkroniseret</Badge>
      )
    },
    {
      key: 'momsindberetning',
      label: 'Momsindberetning',
      value: syncStatus.moms ? (
        <Badge variant="default">
          Sidst ændret: {dayjs(syncStatus.moms).format('DD/MM/YYYY')}
        </Badge>
      ) : (
        <Badge variant="destructive">Ikke synkroniseret</Badge>
      )
    },
    {
      key: 'private_aarsopgoerelser',
      label: 'Personlige Selvangivelser',
      value: syncStatus.personlige ? (
        <Badge variant="default">
          Sidst ændret: {dayjs(syncStatus.personlige).format('DD/MM/YYYY')}
        </Badge>
      ) : (
        <Badge variant="destructive">Ikke synkroniseret</Badge>
      )
    },
    {
      key: 'erhvervs_selvangivelser',
      label: 'Erhvervs Selvangivelser',
      value: syncStatus.selskaber ? (
        <Badge variant="default">
          Sidst ændret: {dayjs(syncStatus.selskaber).format('DD/MM/YYYY')}
        </Badge>
      ) : (
        <Badge variant="destructive">Ikke synkroniseret</Badge>
      )
    },
    {
      key: 'udloebsdato_cpr',
      label: 'Udløbsdato (CPR)',
      value: syncStatus.udloebsdato_cpr ? (
        <Badge variant="default">
          Sidst ændret: {dayjs(syncStatus.udloebsdato_cpr).format('DD/MM/YYYY')}
        </Badge>
      ) : (
        <Badge variant="destructive">Ikke synkroniseret</Badge>
      )
    },
    {
      key: 'transfer_pricing',
      label: 'Transfer Pricing',
      value: syncStatus.transfer_pricing ? (
        <Badge variant="default">
          Sidst ændret: {dayjs(syncStatus.transfer_pricing).format('DD/MM/YYYY')}
        </Badge>
      ) : (
        <Badge variant="destructive">Ikke synkroniseret</Badge>
      )
    }
  ];

  const columns: ColumnDef<CustomerData>[] = [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '15%',
    },
    {
      title: 'Virksomhed',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: 'Adgange',
      dataIndex: 'access_types',
      key: 'access_types',
      width: '40%',
      render: (access_types: string[]) => Array.isArray(access_types) ? access_types.join(', ') : '',
    },
    {
      title: 'Dato',
      dataIndex: 'latest_date',
      key: 'latest_date',
      width: '15%',
      sortable: {
        enabled: true,
        type: 'date'
      }
    }
  ];

  const getMomsColumns = (): ColumnDef<CustomerData>[] => [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '15%',
    },
    {
      title: 'Virksomhed',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Fra',
      dataIndex: 'periodFrom',
      key: 'periodFrom',
      width: '20%',
      render: (date) => date || 'Ikke angivet',
      sortable: {
        enabled: true,
        type: 'date',
        compare: (a: CustomerData, b: CustomerData) => compareDates(a.periodFrom, b.periodFrom)
      }
    },
    {
      title: 'Til',
      dataIndex: 'periodTo',
      key: 'periodTo',
      width: '20%',
      render: (date) => date || 'Ikke angivet',
      sortable: {
        enabled: true,
        type: 'date',
        compare: (a: CustomerData, b: CustomerData) => compareDates(a.periodTo, b.periodTo)
      }
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: '20%',
      render: (date) => date || 'Ikke angivet',
      sortable: {
        enabled: true,
        type: 'date',
        compare: (a: CustomerData, b: CustomerData) => compareDates(a.deadline, b.deadline)
      }
    }
  ];

  const getPrivateColumns = (): ColumnDef<CustomerData>[] => [
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
      width: '50%',
    },
    {
      title: 'Er indberettet',
      dataIndex: 'isReported',
      key: 'isReported',
      width: '25%',
      render: (isReported: boolean) => (
        <Badge variant={isReported ? 'default' : 'destructive'}>
          {isReported ? 'TRUE' : 'FALSE'}
        </Badge>
      ),
      filterable: true,
      filters: [
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false }
      ],
      onFilter: (value: boolean, record: CustomerData) => record.isReported === value,
    },
    {
      title: 'År',
      dataIndex: 'year',
      key: 'year',
      width: '25%',
    },
  ];

  const getErhvervsColumns = (): ColumnDef<CustomerData>[] => [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '15%',
    },
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
      width: '45%',
    },
    {
      title: 'Er indberettet',
      dataIndex: 'isReported',
      key: 'isReported',
      width: '20%',
      render: (isReported: boolean) => (
        <Badge variant={isReported ? 'default' : 'destructive'}>
          {isReported ? 'TRUE' : 'FALSE'}
        </Badge>
      ),
      filters: [
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false }
      ],
      onFilter: (value: boolean, record: CustomerData) => record.isReported === value,
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: '20%',
      render: (date: string) => date || 'Ikke angivet',
      sortable: {
        enabled: true,
        type: 'date',
        compare: (a: CustomerData, b: CustomerData) => compareDates(a.deadline, b.deadline)
      }
    },
  ];

  const getCPRColumns = (): ColumnDef<CustomerData>[] => [
    {
      title: 'CPR',
      dataIndex: 'cpr',
      key: 'cpr',
      width: '20%',
    },
    {
      title: 'Navn',
      dataIndex: 'name',
      key: 'name',
      width: '55%',
    },
    {
      title: 'Udløbsdato',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      width: '25%',
      render: (date: string) => date || 'Ikke angivet',
      sortable: {
        enabled: true,
        type: 'date',
        compare: (a: CustomerData, b: CustomerData) => compareDates(a.expirationDate, b.expirationDate)
      }
    },
  ];

  const getTransferPricingColumns = (): ColumnDef<CustomerData>[] => [
    {
      title: 'CVR',
      dataIndex: 'cvr',
      key: 'cvr',
      width: '10%',
    },
    {
      title: 'Virksomhed',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
    },
    {
      title: 'Deadline',
      dataIndex: 'deadline',
      key: 'deadline',
      width: '15%',
      render: (date: string) => date || 'Ikke angivet',
      sortable: {
        enabled: true,
        type: 'date',
        compare: (a: CustomerData, b: CustomerData) => compareDates(a.deadline, b.deadline)
      }
    },
    {
      title: 'Dokumenter',
      dataIndex: 'documents',
      key: 'documents',
      width: '10%',
      sortable: {
        enabled: true,
        type: 'number',
        compare: (a: CustomerData, b: CustomerData) => (a.documents || 0) - (b.documents || 0)
      }
    },
    {
      title: 'TP Sidste År',
      dataIndex: 'tp_last_year',
      key: 'tp_last_year',
      width: '10%',
      filters: [
        { text: 'Ja', value: 'Ja' },
        { text: 'Nej', value: 'Nej' }
      ],
      onFilter: (value: string, record: CustomerData) => record.tp_last_year === value,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '15%',
      render: (status: string) => (
        <Badge variant={status === 'Ikke indberettet' ? 'destructive' : 'default'}>
          {status}
        </Badge>
      ),
      filters: [
        { text: 'Ikke indberettet', value: 'Ikke indberettet' },
        { text: 'Indberettet', value: 'Indberettet' }
      ],
      onFilter: (value: string, record: CustomerData) => record.status === value,
    },
    {
      title: 'Selv Indb',
      dataIndex: 'self_reported',
      key: 'self_reported',
      width: '15%',
      render: (status: string) => (
        <Badge variant={status === 'Ikke indberettet' ? 'destructive' : 'default'}>
          {status}
        </Badge>
      ),
      filters: [
        { text: 'Ikke indberettet', value: 'Ikke indberettet' },
        { text: 'Indberettet', value: 'Indberettet' }
      ],
      onFilter: (value: string, record: CustomerData) => record.self_reported === value,
    },
  ];

  const fetchCustomerData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/customer-access');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch customer data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMomsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/moms-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch moms data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPrivateData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/private-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch private data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchErhvervsData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/erhvervs-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch erhvervs data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCPRData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/cpr-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch CPR data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchTransferPricingData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/revibot/transfer-pricing-data');
      setCustomerData(response.data);
      await fetchSyncStatus();
    } catch (error) {
      console.error('Failed to fetch transfer pricing data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCardClick = (key: string) => {
    setSelectedCard(key);
    switch(key) {
      case 'kundeadgange':
        fetchCustomerData();
        break;
      case 'momsindberetning':
        fetchMomsData();
        break;
      case 'private_aarsopgoerelser':
        fetchPrivateData();
        break;
      case 'erhvervs_selvangivelser':
        fetchErhvervsData();
        break;
      case 'udloebsdato_cpr':
        fetchCPRData();
        break;
      case 'transfer_pricing':
        fetchTransferPricingData();
        break;
    }
  };

  const filteredData = customerData.filter(item => {
    const searchLower = searchText.toLowerCase();
    switch(selectedCard) {
      case 'private_aarsopgoerelser':
        return item.name?.toLowerCase().includes(searchLower);
      default:
        return (
          item.cvr?.toLowerCase().includes(searchLower) ||
          item.name?.toLowerCase().includes(searchLower)
        );
    }
  });

  const getColumns = () => {
    switch(selectedCard) {
      case 'momsindberetning':
        return getMomsColumns();
      case 'private_aarsopgoerelser':
        return getPrivateColumns();
      case 'erhvervs_selvangivelser':
        return getErhvervsColumns();
      case 'udloebsdato_cpr':
        return getCPRColumns();
      case 'transfer_pricing':
        return getTransferPricingColumns();
      default:
        return columns;
    }
  };

  const handleDownload = async (reportType: string) => {
    try {
      const response = await axios.get(`/revibot/download/${reportType}`);
      
      if (response.data.url) {
        const link = document.createElement('a');
        link.href = response.data.url;
        
        const fileNames: Record<string, string> = {
          'kundeadgange': 'kundeadgange.csv',
          'momsindberetning': 'momsindberetning.csv',
          'private_aarsopgoerelser': 'private_aarsopgoerelser.csv',
          'erhvervs_selvangivelser': 'erhvervs_selvangivelser.csv',
          'udloebsdato_cpr': 'udloebsdato_cpr.csv',
          'transfer_pricing': 'transfer_pricing.csv'
        };
        
        link.download = fileNames[reportType] || 'download.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("Download URL not found");
      }
    } catch (error) {
      console.error('Download failed:', error);
      toast.error("Failed to download file");
    }
  };

  const [tableData, setTableData] = useState<CustomerData[]>([]);

  const handleSort = (sortedData: CustomerData[]) => {
    setTableData(sortedData);
  };

  return (
    <div className="p-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {statsData.map((stat) => (
          <Card 
            key={stat.key}
            className={`cursor-pointer transition-all duration-300 hover:-translate-y-1 hover:shadow-lg
              ${selectedCard === stat.key ? 'border-primary bg-primary/5' : ''}`}
            onClick={() => handleCardClick(stat.key)}
          >
            <CardContent className="p-6 text-center">
              <h3 className="text-lg font-semibold mb-2">{stat.label}</h3>
              <div>{stat.value}</div>
            </CardContent>
          </Card>
        ))}
      </div>

      {selectedCard && (
        <div className="mt-8">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold text-foreground">
              {statsData.find(s => s.key === selectedCard)?.label} Oversigt
            </h2>
            <Button 
              onClick={() => handleDownload(selectedCard)}
              className="flex items-center gap-2"
            >
              <Download className="h-4 w-4" />
              Download Excel
            </Button>
          </div>

          <div className="mb-4">
            <Input
              placeholder="Søg efter CVR, Virksomhed eller Navn"
              onChange={(e) => setSearchText(e.target.value)}
              className="max-w-sm"
            />
          </div>

          <div className="rounded-md border border-border bg-card">
            <Table
              data={filteredData}
              columns={getColumns()}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              onPageSizeChange={setPageSize}
              onSort={handleSort}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Revibot;