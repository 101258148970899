import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { X, PlusCircle, LayoutGrid, Eye } from "lucide-react";
import { 
  NodeConfig, 
  NodeElement, 
  NodeElementType, 
  NodeRule, 
  InputConfig, 
  ButtonConfig, 
  NotionGuideConfig, 
  ApiTriggerConfig, 
  TextConfig, 
  SelectConfig, 
  ElementLayout, 
  RowSection, 
  ElementConfig 
} from '../node_types';
import { FlowNode } from '../../flows';
import { Node, Edge } from 'reactflow';
import { useState, useEffect } from 'react';
import { Checkbox } from "@/components/ui/checkbox";
import { cn } from "@/lib/utils";
import { PreviewNode, PreviewNodeProps } from './PreviewNode';
import { useDndSortable } from "../useDndSortable";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove, useSortable } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { DragEndEvent } from '@dnd-kit/core';
import { useSensors, useSensor, PointerSensor } from '@dnd-kit/core';

interface NodeConfigDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (config: NodeConfig) => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
  initialConfig?: NodeConfig;
  selectedNode?: FlowNode | null;
  nodes?: Node[];
  edges?: Edge[];
  setNodes?: (nodes: Node[]) => void;
}

export function NodeConfigDialog({
  open,
  onClose,
  onSave,
  onDelete,
  onDuplicate,
  initialConfig,
  selectedNode,
  nodes = [],
  edges = [],
  setNodes
}: NodeConfigDialogProps) {
  const [config, setConfig] = useState<NodeConfig>(initialConfig || {
    title: '',
    rows: [],
    rules: []
  });

  useEffect(() => {
    setConfig(initialConfig || {
      title: '',
      rows: [],
      rules: []
    });
  }, [initialConfig, selectedNode]);

  const getDefaultConfig = (type: NodeElementType): NodeElement['config'] => {
    switch (type) {
      case 'input':
        return {
          label: '',
          type: 'text',
          placeholder: '',
          required: false
        };
      case 'button':
        return {
          label: '',
          variant: 'default',
          action: 'next'
        };
      case 'text':
        return {
          content: '',
          variant: 'default'
        };
      case 'select':
        return {
          label: '',
          placeholder: '',
          required: false,
          options: []
        };
      case 'notionGuide':
        return {
          pageId: '',
          title: ''
        };
      default:
        throw new Error(`Unknown element type: ${type}`);
    }
  };

  const addElement = (type: NodeElement['type']) => {
    const newElement: NodeElement = {
      id: crypto.randomUUID(),
      type,
      config: getDefaultConfig(type)
    };

    setConfig(prev => ({
      ...prev,
      rows: [...prev.rows, {
        id: crypto.randomUUID(),
        elements: [newElement]
      }]
    }));
  };

  const updateElementConfig = (elementId: string, updates: Partial<NodeElement['config']>) => {
    setConfig(prev => ({
      ...prev,
      rows: prev.rows.map(row => ({
        ...row,
        elements: row.elements.map(element =>
          element.id === elementId
            ? { ...element, config: { ...element.config, ...updates } as NodeElement['config'] }
            : element
        )
      }))
    }));
  };

  const removeElement = (elementId: string) => {
    setConfig(prev => ({
      ...prev,
      rows: prev.rows.map(row => ({
        ...row,
        elements: row.elements.filter(element => element.id !== elementId)
      }))
    }));
  };

  const renderElementPreview = (element: NodeElement) => {
    switch (element.type) {
      case 'input': {
        const config = element.config as InputConfig;
        return (
          <div className="flex-1 px-2">
            <Label>{config.label}</Label>
            <Input
              type={config.type === 'email' ? 'email' : 'text'}
              placeholder={config.placeholder}
              disabled
            />
          </div>
        );
      }
      case 'button': {
        const config = element.config as ButtonConfig;
        return (
          <div className="flex-1 px-2">
            <Button variant={config.variant} disabled>
              {config.label}
            </Button>
          </div>
        );
      }
      case 'text': {
        const config = element.config as TextConfig;
        return (
          <div className="flex-1 px-2">
            <p className="text-sm text-muted-foreground">{config.content}</p>
          </div>
        );
      }
      case 'select': {
        const config = element.config as SelectConfig;
        return (
          <div className="flex-1 px-2">
            <Label>{config.label}</Label>
            <Select disabled>
              <SelectTrigger>
                <SelectValue placeholder={config.placeholder} />
              </SelectTrigger>
            </Select>
          </div>
        );
      }
      default:
        return null;
    }
  };

  const addRule = () => {
    setConfig(prev => ({
      ...prev,
      rules: [...(prev.rules || []), {
        id: crypto.randomUUID(),
        trigger: {
          elementId: '',
        },
        targetNodeId: '',
        requirements: []
      }]
    }));
  };

  const updateRule = (ruleId: string, updates: Partial<NodeRule>) => {
    setConfig(prev => ({
      ...prev,
      rules: prev.rules?.map(rule =>
        rule.id === ruleId
          ? { ...rule, ...updates }
          : rule
      ) || []
    }));
  };

  const removeRule = (ruleId: string) => {
    setConfig(prev => ({
      ...prev,
      rules: prev.rules?.filter(rule => rule.id !== ruleId) || []
    }));
  };

  const getConnectedNodes = () => {
    if (!selectedNode || !edges) return [];
    
    return nodes?.filter(node => 
      node.id !== selectedNode.id && 
      edges.some(edge => 
        (edge.source === selectedNode.id && edge.target === node.id) ||
        (edge.target === selectedNode.id && edge.source === node.id)
      )
    ) || [];
  };

  const renderTargetNodeSelect = (rule: NodeRule) => (
    <Select
      value={rule.targetNodeId}
      onValueChange={(value) => updateRule(rule.id, { targetNodeId: value })}
    >
      <SelectTrigger>
        <SelectValue placeholder="Select target node" />
      </SelectTrigger>
      <SelectContent>
        {getConnectedNodes().map(node => (
          <SelectItem key={node.id} value={node.id}>
            {node.data.label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );

  const renderRulesSection = () => (
    <div className="space-y-4">
      {/* Default Navigation Section - Only show if no conditional triggers exist */}
      {(!config.rules || config.rules.length === 0) && (
        <div className="border p-4 rounded-lg space-y-2">
          <Label>Default Navigation Trigger</Label>
          <Select
            value={config.defaultTriggerElementId || ''}
            onValueChange={(value) => {
              setConfig(prev => ({
                ...prev,
                defaultTriggerElementId: value
              }));
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select trigger element" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="default">Auto-generated Next Button</SelectItem>
              {config.rows
                .flatMap(row => row.elements)
                .filter(el => el.type === 'button' || el.type === 'select')
                .map(el => (
                  <SelectItem key={el.id} value={el.id}>
                    {(el.config as any).label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>

          {/* Default Next Node - Only show connected forward nodes */}
          <Select
            value={config.defaultNextNodeId || ''}
            onValueChange={(value) => {
              setConfig(prev => ({
                ...prev,
                defaultNextNodeId: value
              }));
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Select default next node" />
            </SelectTrigger>
            <SelectContent>
              {getForwardConnectedNodes().map(node => (
                <SelectItem key={node.id} value={node.id}>
                  {node.data.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {/* Conditional Triggers Section */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <Label>Conditional Triggers</Label>
          <Button variant="outline" size="sm" onClick={addRule}>
            <PlusCircle className="h-4 w-4 mr-2" />
            Add Trigger
          </Button>
        </div>
        {config.rules?.map(rule => (
          <div key={rule.id} className="border p-4 rounded-lg space-y-2">
            <div className="flex justify-between items-center">
              <Label>Rule Configuration</Label>
              <Button variant="ghost" size="sm" onClick={() => removeRule(rule.id)}>
                <X className="h-4 w-4" />
              </Button>
            </div>

            {/* Trigger Element */}
            <Select
              value={rule.trigger.elementId}
              onValueChange={(value) => updateRule(rule.id, { 
                trigger: { ...rule.trigger, elementId: value } 
              })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select trigger element" />
              </SelectTrigger>
              <SelectContent>
                {config.rows
                  .flatMap(row => row.elements)
                  .filter(el => el.type === 'button' || el.type === 'select')
                  .map(el => (
                    <SelectItem key={el.id} value={el.id}>
                      {(el.config as any).label}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>

            {/* Target Node */}
            {renderTargetNodeSelect(rule)}

            {/* Requirements */}
            <div className="space-y-2">
              <Label>Requirements</Label>
              <div className="border p-2 rounded-lg space-y-2">
                {config.rows
                  .flatMap(row => row.elements)
                  .filter(el => el.type === 'input' || el.type === 'select')
                  .map(el => (
                    <div key={el.id} className="flex items-center space-x-2">
                      <Checkbox
                        checked={rule.requirements?.includes(el.id)}
                        onCheckedChange={(checked) => {
                          updateRule(rule.id, {
                            requirements: checked
                              ? [...(rule.requirements || []), el.id]
                              : rule.requirements?.filter(id => id !== el.id)
                          });
                        }}
                      />
                      <Label>{(el.config as any).label}</Label>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const getForwardConnectedNodes = () => {
    if (!selectedNode || !edges) return [];
    
    return nodes?.filter(node => 
      node.id !== selectedNode.id && 
      edges.some(edge => edge.source === selectedNode.id && edge.target === node.id)
    ) || [];
  };

  const [showPreview, setShowPreview] = useState(false);

  const handlePreviewNode = () => {
    setShowPreview(true);
  };

  const updateElementLayout = (elementId: string, layout: Partial<ElementLayout>) => {
    setConfig(prev => ({
      ...prev,
      rows: prev.rows.map(row => ({
        ...row,
        elements: row.elements.map(element =>
          element.id === elementId
            ? { ...element, layout: { ...element.layout, ...layout } }
            : element
        )
      }))
    }));
  };

  const moveElement = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= config.rows.length) return;
    
    setConfig(prev => {
      const rows = [...prev.rows];
      const [movedRow] = rows.splice(fromIndex, 1);
      rows.splice(toIndex, 0, movedRow);
      return { ...prev, rows };
    });
  };

  const addRow = () => {
    setConfig(prev => ({
      ...prev,
      rows: [
        ...prev.rows,
        {
          id: crypto.randomUUID(),
          elements: []
        }
      ]
    }));
  };

  const addElementToRow = (rowId: string, type: NodeElementType) => {
    setConfig(prev => ({
      ...prev,
      rows: prev.rows.map(row =>
        row.id === rowId
          ? {
              ...row,
              elements: [...row.elements, {
                id: crypto.randomUUID(),
                type,
                config: getDefaultConfig(type)
              }]
            }
          : row
      )
    }));
  };

  const removeElementFromRow = (rowId: string, elementId: string) => {
    setConfig(prev => ({
      ...prev,
      rows: prev.rows.map(row =>
        row.id === rowId
          ? {
              ...row,
              elements: row.elements.filter(el => el.id !== elementId)
            }
          : row
      )
    }));
  };

  const removeRow = (rowId: string) => {
    setConfig(prev => ({
      ...prev,
      rows: prev.rows.filter(row => row.id !== rowId)
    }));
  };

  const [showHeaderConfig, setShowHeaderConfig] = useState(false);

  const renderElementsTab = () => (
    <div className="space-y-6">
      {/* Basic Info */}
      <div className="space-y-4">
        <div className="grid gap-4">
          <div className="space-y-2">
            <Label>Title</Label>
            <Input
              value={config.title}
              onChange={(e) => setConfig(prev => ({ ...prev, title: e.target.value }))}
              placeholder="Enter node title"
            />
          </div>
          <div className="space-y-2">
            <Label>Description (Optional)</Label>
            <Textarea
              value={config.description || ''}
              onChange={(e) => setConfig(prev => ({ ...prev, description: e.target.value }))}
              placeholder="Enter node description"
            />
          </div>
        </div>
      </div>

      {/* Header Section */}
      <div className="border p-4 rounded-lg space-y-2">
        <div className="flex justify-between items-center">
          <Label>Header Section</Label>
          {!config.header && (
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                setConfig(prev => ({
                  ...prev,
                  header: {
                    id: crypto.randomUUID(),
                    notionGuide: {
                      pageId: '',
                      title: ''
                    }
                  }
                }));
                setShowHeaderConfig(true);
              }}
            >
              Add Notion Guide Header
            </Button>
          )}
        </div>
        
        {config.header && (
          <div className="space-y-4">
            {showHeaderConfig ? (
              <>
                {renderElementConfig({
                  id: config.header.id,
                  type: 'notionGuide',
                  config: config.header.notionGuide!
                })}
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => setShowHeaderConfig(false)}
                >
                  Done
                </Button>
              </>
            ) : (
              <div className="flex justify-between items-center">
                <div className="text-sm">
                  {config.header.notionGuide?.title || 'Untitled Guide'}
                </div>
                <div className="space-x-2">
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowHeaderConfig(true)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setConfig(prev => ({ ...prev, header: undefined }))}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Content Rows */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <Label>Content Rows</Label>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={addRow}
          >
            Add Row
          </Button>
        </div>

        {config.rows.length === 0 && (
          <div className="border-2 border-dashed p-8 rounded-lg text-center text-muted-foreground">
            Add a row to start adding content elements
          </div>
        )}

        {config.rows.map((row, index) => (
          <div key={row.id} className="border p-4 rounded-lg space-y-4">
            <div className="flex justify-between items-center">
              <Label>Row {index + 1}</Label>
              <div className="flex gap-2">
                {index > 0 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => moveRow(index, index - 1)}
                  >
                    Move Up
                  </Button>
                )}
                {index < config.rows.length - 1 && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => moveRow(index, index + 1)}
                  >
                    Move Down
                  </Button>
                )}
                <Button 
                  variant="destructive" 
                  size="sm"
                  onClick={() => removeRow(row.id)}
                >
                  Remove Row
                </Button>
              </div>
            </div>

            {/* Elements in the row */}
            {row.elements.length > 0 && (
              <div className="grid grid-cols-12 gap-4">
                {row.elements.map((element) => (
                  <div key={element.id} className="col-span-12 border p-4 rounded-lg">
                    {renderElementPreview(element)}
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={() => removeElementFromRow(row.id, element.id)}
                    >
                      Remove Element
                    </Button>
                  </div>
                ))}
              </div>
            )}

            {/* Add element buttons */}
            <div className="flex gap-2">
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => addElementToRow(row.id, 'input')}
              >
                Add Input
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => addElementToRow(row.id, 'button')}
              >
                Add Button
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => addElementToRow(row.id, 'text')}
              >
                Add Text
              </Button>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => addElementToRow(row.id, 'select')}
              >
                Add Select
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const getButtonElements = () => {
    return config.rows.flatMap(row => 
      row.elements.filter(el => el.type === 'button' || el.type === 'select')
    );
  };

  const getInputElements = () => {
    return config.rows.flatMap(row => 
      row.elements.filter(el => el.type === 'input' || el.type === 'select')
    );
  };

  const moveRow = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= config.rows.length) return;
    
    setConfig(prev => {
      const rows = [...prev.rows];
      const [movedRow] = rows.splice(fromIndex, 1);
      rows.splice(toIndex, 0, movedRow);
      return { ...prev, rows };
    });
  };

  const [activeRow, setActiveRow] = useState<string | null>(null);
  const [activeElement, setActiveElement] = useState<string | null>(null);

  const handleRowDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = config.rows.findIndex(row => row.id === active.id);
      const newIndex = config.rows.findIndex(row => row.id === over.id);
      
      setConfig(prev => ({
        ...prev,
        rows: arrayMove(prev.rows, oldIndex, newIndex)
      }));
    }
    setActiveRow(null);
  };

  const handleElementDragEnd = (rowId: string) => ({ active, over }: DragEndEvent) => {
    if (!over || active.id === over.id) return;
    
    setConfig(prev => ({
      ...prev,
      rows: prev.rows.map(row => {
        if (row.id !== rowId) return row;
        
        const oldIndex = row.elements.findIndex(el => el.id === active.id);
        const newIndex = row.elements.findIndex(el => el.id === over.id);
        
        const newElements = [...row.elements];
        const [movedElement] = newElements.splice(oldIndex, 1);
        newElements.splice(newIndex, 0, movedElement);
        
        return {
          ...row,
          elements: newElements
        };
      })
    }));
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  interface SortableRowProps {
    row: RowSection;
    onRemove: () => void;
    onElementAdd: (type: NodeElementType) => void;
    onElementRemove: (elementId: string) => void;
    onElementUpdate: (elementId: string, updates: Partial<ElementConfig>) => void;
    elements: NodeElement[];
  }

  interface SortableElementProps {
    element: NodeElement;
    onRemove: () => void;
    onUpdate: (updates: Partial<ElementConfig>) => void;
  }

  const renderElementConfig = (element: NodeElement) => {
    switch (element.type) {
      case 'input': {
        const config = element.config as InputConfig;
        return (
          <div className="space-y-4">
            <div>
              <Label>Label</Label>
              <Input
                value={config.label}
                onChange={(e) => updateElementConfig(element.id, { label: e.target.value })}
                placeholder="Enter label"
              />
            </div>
            <div>
              <Label>Placeholder</Label>
              <Input
                value={config.placeholder}
                onChange={(e) => updateElementConfig(element.id, { placeholder: e.target.value })}
                placeholder="Enter placeholder text"
              />
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                checked={config.required}
                onCheckedChange={(checked) => updateElementConfig(element.id, { required: !!checked })}
              />
              <Label>Required</Label>
            </div>
          </div>
        );
      }
      case 'button': {
        const config = element.config as ButtonConfig;
        return (
          <div className="space-y-4">
            <div>
              <Label>Label</Label>
              <Input
                value={config.label}
                onChange={(e) => updateElementConfig(element.id, { label: e.target.value })}
                placeholder="Enter button text"
              />
            </div>
            <div>
              <Label>Action Type</Label>
              <Select
                value={config.action}
                onValueChange={(value: ButtonConfig['action']) => updateElementConfig(element.id, { action: value })}
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select action type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="next">Next Node</SelectItem>
                  <SelectItem value="api">Call API Endpoint</SelectItem>
                  <SelectItem value="submit">Submit Form</SelectItem>
                </SelectContent>
              </Select>
            </div>
            {config.action === 'api' && (
              <div>
                <Label>API Endpoint</Label>
                <Select disabled>
                  <SelectTrigger>
                    <SelectValue placeholder="Select API endpoint (coming soon)" />
                  </SelectTrigger>
                  <SelectContent>
                    {/* API endpoints will be populated here */}
                  </SelectContent>
                </Select>
              </div>
            )}
          </div>
        );
      }
      case 'text': {
        const config = element.config as TextConfig;
        return (
          <div className="space-y-4">
            <div>
              <Label>Label (Optional)</Label>
              <Input
                value={config.label ?? ''}
                onChange={(e) => updateElementConfig(element.id, { label: e.target.value })}
                placeholder="Enter section label"
              />
            </div>
            <div>
              <Label>Content</Label>
              <Textarea
                value={config.content}
                onChange={(e) => updateElementConfig(element.id, { content: e.target.value })}
                placeholder="Enter text content"
              />
            </div>
          </div>
        );
      }
      case 'select': {
        const config = element.config as SelectConfig;
        return (
          <div className="space-y-4">
            <div>
              <Label>Label</Label>
              <Input
                value={config.label}
                onChange={(e) => updateElementConfig(element.id, { label: e.target.value })}
                placeholder="Enter label"
              />
            </div>
            <div>
              <Label>Placeholder</Label>
              <Input
                value={config.placeholder}
                onChange={(e) => updateElementConfig(element.id, { placeholder: e.target.value })}
                placeholder="Enter placeholder text"
              />
            </div>
            <div className="flex items-center space-x-2">
              <Checkbox
                checked={config.required}
                onCheckedChange={(checked) => updateElementConfig(element.id, { required: !!checked })}
              />
              <Label>Required</Label>
            </div>
            <div>
              <Label>Options</Label>
              <div className="space-y-2">
                {config.options.map((option, index) => (
                  <div key={index} className="flex gap-2">
                    <Input
                      value={option.label}
                      onChange={(e) => {
                        const newOptions = [...config.options];
                        newOptions[index] = { ...option, label: e.target.value };
                        updateElementConfig(element.id, { options: newOptions });
                      }}
                      placeholder="Option label"
                    />
                    <Input
                      value={option.value}
                      onChange={(e) => {
                        const newOptions = [...config.options];
                        newOptions[index] = { ...option, value: e.target.value };
                        updateElementConfig(element.id, { options: newOptions });
                      }}
                      placeholder="Option value"
                    />
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        const newOptions = config.options.filter((_, i) => i !== index);
                        updateElementConfig(element.id, { options: newOptions });
                      }}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                ))}
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    const newOptions = [...config.options, { label: '', value: '' }];
                    updateElementConfig(element.id, { options: newOptions });
                  }}
                >
                  Add Option
                </Button>
              </div>
            </div>
          </div>
        );
      }
      case 'notionGuide': {
        const config = element.config as NotionGuideConfig;
        return (
          <div className="space-y-4">
            <div>
              <Label>Title</Label>
              <Input
                value={config.title}
                onChange={(e) => updateElementConfig(element.id, { title: e.target.value })}
                placeholder="Enter guide title"
              />
            </div>
            <div>
              <Label>Notion Page ID</Label>
              <Input
                value={config.pageId}
                onChange={(e) => updateElementConfig(element.id, { pageId: e.target.value })}
                placeholder="Enter Notion page ID"
              />
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  };

  const SortableElement = ({ element, onRemove, onUpdate }: SortableElementProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: element.id });

    return (
      <div
        ref={setNodeRef}
        style={{
          transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
          transition,
          opacity: isDragging ? 0.5 : 1,
        }}
        className="border p-4 rounded-lg mb-2"
      >
        <div className="flex items-center justify-between mb-4">
          <div {...attributes} {...listeners} className="cursor-move">
            ⋮⋮
          </div>
          <Button variant="ghost" size="sm" onClick={onRemove}>
            <X className="h-4 w-4" />
          </Button>
        </div>
        
        {renderElementConfig(element)}
      </div>
    );
  };

  const SortableRow = ({ row, onRemove, onElementAdd, onElementRemove, onElementUpdate, elements }: SortableRowProps) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: row.id });

    const handleElementDragEnd = ({ active, over }: DragEndEvent) => {
      if (!over || active.id === over.id) return;
      
      const oldIndex = row.elements.findIndex(el => el.id === active.id);
      const newIndex = row.elements.findIndex(el => el.id === over.id);
      
      setConfig(prev => ({
        ...prev,
        rows: prev.rows.map(r => {
          if (r.id !== row.id) return r;
          
          const newElements = [...r.elements];
          const [movedElement] = newElements.splice(oldIndex, 1);
          newElements.splice(newIndex, 0, movedElement);
          
          return {
            ...r,
            elements: newElements
          };
        })
      }));
    };

    return (
      <div ref={setNodeRef} {...attributes} {...listeners}>
        <DndContext onDragEnd={handleElementDragEnd}>
          <SortableContext items={row.elements.map(el => el.id)}>
            {row.elements.map(element => (
              <SortableElement
                key={element.id}
                element={element}
                onRemove={() => onElementRemove(element.id)}
                onUpdate={(updates) => onElementUpdate(element.id, updates)}
              />
            ))}
          </SortableContext>
        </DndContext>
        {/* Rest of the row content */}
      </div>
    );
  };

  return (
    <Dialog open={open} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <div className="flex items-center gap-2">
            <DialogTitle>Configure Node</DialogTitle>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => setShowPreview(true)}
            >
              <Eye className="h-4 w-4" />
            </Button>
          </div>
        </DialogHeader>
        
        {showPreview && (
          <PreviewNode
            config={config}
            onClose={() => setShowPreview(false)}
          />
        )}
        
        <div className="space-y-6 mb-6 border-b pb-6">
          <div className="space-y-4">
            <div>
              <Label>Node Title</Label>
              <Input
                value={config.title}
                onChange={(e) => setConfig(prev => ({ ...prev, title: e.target.value }))}
                placeholder="Enter node title"
              />
            </div>

            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <Label>Header Section</Label>
                {!config.header && (
                  <Button
                    variant="outline"
                    size="sm"
                    onClick={() => setConfig(prev => ({
                      ...prev,
                      header: {
                        id: crypto.randomUUID(),
                        notionGuide: {
                          title: '',
                          pageId: ''
                        }
                      }
                    }))}
                  >
                    Add Notion Guide Header
                  </Button>
                )}
              </div>

              {config.header && (
                <div className="border p-4 rounded-lg space-y-4">
                  {renderElementConfig({
                    id: config.header.id,
                    type: 'notionGuide',
                    config: config.header.notionGuide!
                  })}
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setConfig(prev => ({ ...prev, header: undefined }))}
                  >
                    Remove Header
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>

        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          modifiers={[restrictToVerticalAxis]}
          onDragEnd={handleRowDragEnd}
        >
          <Tabs defaultValue="elements">
            <TabsList>
              <TabsTrigger value="elements">Elements</TabsTrigger>
              <TabsTrigger value="rules">Triggers</TabsTrigger>
              <TabsTrigger value="style">Style</TabsTrigger>
            </TabsList>
            
            <TabsContent value="elements">
              <SortableContext
                items={config.rows.map(row => row.id)}
                strategy={verticalListSortingStrategy}
              >
                {config.rows.map((row, index) => (
                  <SortableRow
                    key={row.id}
                    row={row}
                    onRemove={() => removeRow(row.id)}
                    onElementAdd={(type) => addElementToRow(row.id, type)}
                    onElementRemove={(elementId) => removeElementFromRow(row.id, elementId)}
                    onElementUpdate={(elementId, updates) => updateElementConfig(elementId, updates)}
                    elements={row.elements}
                  />
                ))}
              </SortableContext>
              
              <Button
                variant="outline"
                onClick={addRow}
                className="w-full mt-4"
              >
                Add New Row
              </Button>
            </TabsContent>
            
            <TabsContent value="rules">
              {renderRulesSection()}
            </TabsContent>

            <TabsContent value="style">
              {/* Style options if needed */}
            </TabsContent>
          </Tabs>
        </DndContext>
        
        <DialogFooter>
          <div className="flex justify-between w-full">
            <div className="flex gap-2">
              {onDelete && (
                <Button variant="destructive" onClick={onDelete}>
                  Delete Node
                </Button>
              )}
              {onDuplicate && (
                <Button variant="outline" onClick={onDuplicate}>
                  Duplicate Node
                </Button>
              )}
            </div>
            <div className="flex gap-2">
              <Button variant="outline" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button onClick={() => onSave(config)}>
                Save Changes
              </Button>
            </div>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}