import React, { useState, useEffect, useMemo } from 'react';
import { Avatar, Button, Spin, Empty } from 'antd';
import { MailOutlined, RollbackOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import SendEmailModal from '../../modals/SendEmailModal';
import './TaskViewCommunication.css';

const TaskViewCommunication = ({ dealId, contact }) => {
	const [emails, setEmails] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [modalKey, setModalKey] = useState(0);
	const [replyToEmail, setReplyToEmail] = useState(null);
	const [users, setUsers] = useState({});

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true);
				const [emailsResponse, usersResponse] = await Promise.all([
					axios.get(`/contactmail/emails/${dealId}`),
					axios.get('/user/names')
				]);
				setEmails(emailsResponse.data);
				setUsers(usersResponse.data);
				setError(null);
			} catch (err) {
				console.error('Error fetching data:', err);
				setError('Failed to load data. Please try again later.');
			} finally {
				setLoading(false);
			}
		};

		if (dealId) {
			fetchData();
		}
	}, [dealId]);

	const handleOpenModal = () => {
		setModalKey(prevKey => prevKey + 1);
		setReplyToEmail(null);  // Clear any previous reply
		setIsModalVisible(true);
	};

	const handleCloseModal = () => {
		setIsModalVisible(false);
		setReplyToEmail(null);  // Clear any previous reply
	};

	const handleReply = (email) => {
		setReplyToEmail(email);
		setIsModalVisible(true);
	};

	if (loading) return (
		<div className="email-loading-container">
			<Spin size="large" />
		</div>
	);

	if (error) return <div className="error-message">{error}</div>;

	return (
		<div className="email-communication-container">
			<div className="email-header-actions">
				<Button 
					type="primary" 
					icon={<MailOutlined />} 
					onClick={handleOpenModal}
				>
					Send Email
				</Button>
			</div>
			{emails.length === 0 ? (
				<Empty
					image={Empty.PRESENTED_IMAGE_SIMPLE}
					description="No email communication with this customer has been synced"
				/>
			) : (
				<div className="email-communication">
					{emails.map((email) => (
						<EmailItem key={email.id} email={email} onReply={handleReply} users={users} />
					))}
				</div>
			)}
			{contact && (
				<SendEmailModal 
					key={modalKey}
					visible={isModalVisible} 
					onClose={handleCloseModal} 
					contact={contact}
					dealId={dealId}
					replyToEmail={replyToEmail}
				/>
			)}
		</div>
	);
};

const EmailItem = ({ email, onReply, users }) => {
	const [expanded, setExpanded] = useState(false);
	const [needsExpansion, setNeedsExpansion] = useState(false);

	const senderName = useMemo(() => {
		if (email.is_incoming) {
			return email.sender_name || 'Unknown';
		} else {
			// For outgoing emails, use sender_name (which contains the user ID)
			const userInfo = users[email.sender_name];
			return userInfo ? userInfo.full_name : 'Unknown';
		}
	}, [email, users]);

	const getInitials = (name) => {
		return name
			.split(' ')
			.map(word => word[0])
			.join('')
			.toUpperCase()
			.slice(0, 2);
	};

	useEffect(() => {
		const checkOverflow = () => {
			const maxHeight = 100; // Adjust based on design
			const tempDiv = document.createElement('div');
			tempDiv.style.visibility = 'hidden';
			tempDiv.style.position = 'absolute';
			tempDiv.style.width = '300px'; // Match email content width
			tempDiv.innerHTML = email.body;
			document.body.appendChild(tempDiv);
			const isOverflowing = tempDiv.scrollHeight > maxHeight;
			setNeedsExpansion(isOverflowing);
			document.body.removeChild(tempDiv);
		};

		checkOverflow();
		window.addEventListener('resize', checkOverflow);
		return () => window.removeEventListener('resize', checkOverflow);
	}, [email.body]);

	const toggleExpansion = () => {
		setExpanded(!expanded);
	};

	return (
		<div className={`email-item ${expanded ? 'expanded' : ''}`}>
			<div className="email-header">
				<Avatar className="avatar">{getInitials(senderName)}</Avatar>
				<div className="email-info">
					<div className="sender-name">{senderName}</div>
					<div className="sender-email">{email.sender_email}</div>
					<div className="recipients">To: {email.recipients.join(', ')}</div>
				</div>
				<div className="email-date">{new Date(email.sent_at).toLocaleString()}</div>
			</div>
			<div className="email-subject">{email.subject}</div>
			<div className="email-content-container">
				<div className={`email-content ${expanded ? 'expanded' : ''}`}>
					{email.body}
				</div>
				{needsExpansion && !expanded && (
					<div className="gradient-overlay"></div>
				)}
			</div>
			<div className="email-actions">
				{needsExpansion && (
					<Button
						onClick={toggleExpansion}
						className="read-more-button"
					>
						{expanded ? 'Show less' : 'Read more'}
					</Button>
				)}
				{email.is_incoming && (
					<Button
						icon={<RollbackOutlined />}
						onClick={() => onReply(email)}
						className="reply-button"
					>
						Reply
					</Button>
				)}
			</div>
		</div>
	);
};

export default TaskViewCommunication;
