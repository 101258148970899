import { useEffect, useState, useCallback, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import axios from '@/api/axiosConfig'
import {
  ArrowUp,
  ArrowDown,
  Mail,
  RefreshCw,
  FileText,
  User,
  Store,
  Clock,
  Calendar,
  Timer,
  Play,
  Users,
  ShoppingBag,
  BookOpen,
  DollarSign,
  Pause,
  Square,
  Edit,
  Building2,
  Calculator,
  UserPlus
} from 'lucide-react'

import { Dialog, DialogContent } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Badge } from '@/components/ui/badge'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip'
import { ScrollArea } from '@/components/ui/scroll-area'
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card'

import TaskViewCommunication from '../components/TaskViewCommunication'
import TaskViewNotes from '../components/TaskViewNotes'
import DealNavBar from '../components/DealNavBar'
import ContactInfoDrawer from '../drawers/ContactInfo'
import { TaskDateEditor } from '@/components/TaskDateEditor'
import TaskViewActivity from '../components/TaskViewActivity'
import { DealDrawer } from '@/drawers/DealDrawer'
import ProductsDrawer from '@/drawers/ProductsDrawer'
import { TaskStatusSelector } from '../components/TaskStatusSelector'
import TaskCompleteDrawer from '../drawers/TaskCompleteDrawer'
import { TimeTrackerContext } from '@/contexts/TimeTrackerContext'
import PayrollDrawer from '@/drawers/PayrollDrawer'
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig'

import type { Task, FrontendTaskType, TaskToComplete, EnhancedTask, BoardConfigKey } from '@/types/task'
import type { BoardStatusOption } from '@/types/taskboard'
import type { StoredUser } from '@/types/user'
import { isRecurringTask } from '@/types/task'

dayjs.extend(utc)
dayjs.extend(timezone)

interface DealData {
  name?: string;
  cvr?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  economic_customer_number?: string;
  onboard_status?: string;
}

interface ContactData {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
}

interface ContactInfo {
  text: string;
  [key: string]: any;
}

const PRODUCT_ICONS: Record<string, JSX.Element> = {
  'Bogføring': <Calculator className="h-5 w-5" />,
  'Onboarding': <UserPlus className="h-5 w-5" />,
  'Lønadministration': <Users className="h-5 w-5" />,
  'Årsregnskab': <FileText className="h-5 w-5" />
};

interface TaskViewProps {
  onClose?: () => void
  tasks: Task[]
  taskType: string
  boardStatusOptions: BoardStatusOption[]
  task?: Task
  fromWork?: boolean
  getBoardName?: (task: Task) => string
  dealName?: string
}

export function TaskView({
  onClose,
  tasks,
  taskType: propTaskType,
  boardStatusOptions,
  task: propTask,
  fromWork,
  getBoardName: propGetBoardName,
  dealName
}: TaskViewProps) {
  const { taskId } = useParams()
  const navigate = useNavigate()
  const [task, setTask] = useState<Task | null>(propTask || null)
  const [loading, setLoading] = useState(!propTask)
  const [activeTab, setActiveTab] = useState('1')
  const [taskType, setTaskType] = useState(propTaskType)
  const [accountData, setAccountData] = useState(null)
  const [productsData, setProductsData] = useState([])
  const [contactInfoVisible, setContactInfoVisible] = useState(false)
  const [contactInfo, setContactInfo] = useState<ContactInfo[]>([])
  const [users, setUsers] = useState<StoredUser[]>([])
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [productsDrawerVisible, setProductsDrawerVisible] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [isCompleteDrawerVisible, setIsCompleteDrawerVisible] = useState(false)
  const [taskToComplete, setTaskToComplete] = useState<TaskToComplete | null>(null)
  const [lonConfig, setLonConfig] = useState<{ salary_type?: string; salary_period_hourly?: { start?: number; end?: number; display_start?: string; display_end?: string } }>({})
  const [currentPeriod, setCurrentPeriod] = useState(dayjs().format('YYYY-MM'))
  const [payrollDrawerVisible, setPayrollDrawerVisible] = useState(false)
  const [dealData, setDealData] = useState<DealData>({})
  const [contactData, setContactData] = useState<ContactData>({})

  const { 
    isRunning, 
    isPaused, 
    currentTask,
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer
  } = useContext(TimeTrackerContext)

  const isCurrentTask = currentTask?.taskId === task?.id

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/user')
        setUsers(response.data)
      } catch (error) {
        console.error('Error fetching users:', error)
      }
    }
    fetchUsers()
  }, [])

  const getBoardName = useCallback((task: Task) => {
    if (propGetBoardName) {
      return propGetBoardName(task)
    }
    if (isRecurringTask(task)) {
      const recurringTypeMap: Record<string, string> = {
        'bogforing': 'bogforing',
        'lon': 'lon',
        'moms': 'moms',
        'arsafslutning': 'arsafslutning'
      }
      return recurringTypeMap[task.recurring_type || ''] || task.recurring_type
    }
    return task.task_type
  }, [propGetBoardName])

  const fetchTaskDetails = useCallback(async () => {
    try {
      let apiTaskType = taskType
      if (taskType === 'andre-opgaver') {
        apiTaskType = 'other'
      } else if (taskType === 'activities') {
        apiTaskType = 'activity'
      }

      const response = await axios.get(`/taskboard/tasks/${apiTaskType}/${taskId}`)
      setTask(response.data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching task details:', error)
      setLoading(false)
    }
  }, [taskId, taskType])

  useEffect(() => {
    if (!propTask && taskId) {
      fetchTaskDetails()
    } else if (propTask) {
      setTask(propTask)
      setLoading(false)
    }
  }, [taskId, propTask])

  useEffect(() => {
    if (task?.contact?.id) {
      fetchContactInfo(task.contact.id)
    }
  }, [task])

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose()
    } else {
      navigate(-1)
    }
  }, [onClose, navigate])

  const handlePrevTask = () => {
    if (fromWork) {
      const currentIndex = tasks.findIndex(t => t.id === task?.id)
      if (currentIndex > 0) {
        const prevTask = tasks[currentIndex - 1]
        navigate(`/work/${getBoardName(prevTask)}/${prevTask.id}`, { 
          replace: true, 
          state: { fromWork: true, filteredTasks: tasks } 
        })
      }
    } else {
      const currentIndex = tasks.findIndex(t => t.id.toString() === taskId)
      if (currentIndex > 0) {
        const prevTask = tasks[currentIndex - 1]
        navigate(`/boards/${getBoardName(prevTask)}/${prevTask.id}`)
      }
    }
  }

  const handleNextTask = () => {
    if (fromWork) {
      const currentIndex = tasks.findIndex(t => t.id === task?.id)
      if (currentIndex < tasks.length - 1) {
        const nextTask = tasks[currentIndex + 1]
        navigate(`/work/${getBoardName(nextTask)}/${nextTask.id}`, { 
          replace: true, 
          state: { fromWork: true, filteredTasks: tasks } 
        })
      }
    } else {
      const currentIndex = tasks.findIndex(t => t.id.toString() === taskId)
      if (currentIndex < tasks.length - 1) {
        const nextTask = tasks[currentIndex + 1]
        navigate(`/boards/${getBoardName(nextTask)}/${nextTask.id}`)
      }
    }
  }

  const handleTaskStatusChange = async (updatedTask: Task) => {
    setTask(prevTask => prevTask ? ({
      ...prevTask,
      board_status: updatedTask.board_status || '',
      progress_status: updatedTask.progress_status,
      status: updatedTask.status
    }) : null)

    if (updatedTask.board_status === boardStatusOptions[boardStatusOptions.length - 1].value) {
      if (isCurrentTask && isRunning) {
        await stopTracking()
      }
      
      const { taskType: mappedTaskType, recurringType } = getTaskTypeAndRecurringType(task)
      
      const taskToComplete: TaskToComplete = {
        ...updatedTask,
        task_type: mappedTaskType as FrontendTaskType,
        isRecurring: mappedTaskType === 'recurring',
        recurringType: recurringType || undefined,
        board_status: updatedTask.board_status || '',
        progress_status: updatedTask.progress_status || '',
        rollback: () => {
          setTask(prevTask => prevTask ? ({
            ...prevTask,
            board_status: prevTask.board_status
          }) : null)
        }
      }

      setTaskToComplete(taskToComplete)
      setIsCompleteDrawerVisible(true)
    }
  }

  const handleCompleteDrawerClose = () => {
    if (taskToComplete?.rollback) {
      taskToComplete.rollback()
    }
    setIsCompleteDrawerVisible(false)
    setTaskToComplete(null)
  }

  const handleCompleteDrawerSubmit = async (values: any) => {
    try {
      const response = await axios.put(`/taskboard/tasks/${taskType}/${taskToComplete?.id}/status`, {
        board_status: taskToComplete?.board_status,
        board_status_options: boardStatusOptions,
        completion_data: values
      })

      if (response.status === 200) {
        setIsCompleteDrawerVisible(false)
        setTaskToComplete(null)
        toast.success('Task status updated successfully')
      }
    } catch (error) {
      console.error('Error completing task:', error)
      toast.error('Failed to complete task')
      if (taskToComplete?.rollback) {
        taskToComplete.rollback()
      }
    }
  }

  const renderTaskStatus = (status: string) => {
    return (
      <div className="w-full overflow-hidden rounded-md">
        <TaskStatusSelector
          value={status}
          taskId={task?.id ?? 0}
          taskType={taskType as FrontendTaskType}
          boardStatusOptions={boardStatusOptions}
          progressStatus={task?.status || task?.progress_status || ''}
          onSuccess={handleTaskStatusChange}
          className="w-full"
        />
      </div>
    )
  }

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('YYYY-MM-DD')
  }

  const tabItems = [
    {
      value: "1",
      label: (
        <div className="flex items-center gap-2">
          <Mail className="h-4 w-4" /> Communication
        </div>
      ),
      content: (
        <TaskViewCommunication 
          dealId={task?.deal_id} 
          contact={task?.contact} 
        />
      )
    },
    {
      value: "2",
      label: (
        <div className="flex items-center gap-2">
          <RefreshCw className="h-4 w-4" /> Notes / Comments
        </div>
      ),
      content: (
        <TaskViewNotes 
          task={task}
          taskType={taskType}
          users={users}
          onUpdate={fetchTaskDetails}
        />
      )
    },
    {
      value: "3",
      label: (
        <div className="flex items-center gap-2">
          <FileText className="h-4 w-4" /> Activity Log
        </div>
      ),
      content: (
        <TaskViewActivity 
          updates={task?.updates || []} 
          users={users}
        />
      )
    }
  ]

  const fetchContactInfo = async (contactId: number) => {
    try {
      const response = await axios.get(`/account/contacts/${contactId}/info`)
      setContactInfo(response.data.entries)
    } catch (error) {
      console.error('Error fetching contact info:', error)
    }
  }

  const handleContactInfoClick = () => {
    setContactInfoVisible(true)
  }

  const handleContactInfoClose = () => {
    setContactInfoVisible(false)
    if (task?.contact?.id) {
      fetchContactInfo(task.contact.id)
    }
  }

  const showAccountDetails = async (account: any) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccount(response.data)
      setAccountModalVisible(true)
    } catch (error) {
      console.error('Error fetching account details:', error)
      toast.error('Failed to fetch account details')
    }
  }

  const getTaskTypeAndRecurringType = (task: Task | null) => {
    if (!task) return { taskType: '', recurringType: null };
    
    if (isRecurringTask(task)) {
      return {
        taskType: 'recurring' as const,
        recurringType: propTaskType as BoardConfigKey
      };
    } else if (['andre-opgaver', 'activities'].includes(propTaskType)) {
      return {
        taskType: 'onboarding' as const,
        recurringType: null
      };
    } else {
      return {
        taskType: propTaskType as BoardConfigKey,
        recurringType: null
      };
    }
  };

  const handleStartTimer = () => {
    if (!task) return

    const { taskType, recurringType } = getTaskTypeAndRecurringType(task)
    
    const taskData = {
      taskId: task.id,
      taskType: taskType,
      dealId: task.deal_id,
      recurringType: recurringType,
      dealName: task.deal_name || task.deal?.name,
      taskDescription: task.description || task.data?.Task
    }
    
    startTracking(taskData)
  }

  const renderTimerButton = () => {
    if (!task) return null

    if (isCurrentTask && isRunning) {
      return (
        <div className="flex gap-2 w-full">
          <Button
            variant={isPaused ? "default" : "secondary"}
            className="flex-1 h-10"
            onClick={() => isPaused ? resumeTimer() : pauseTracking()}
          >
            {isPaused ? (
              <Play className="mr-2 h-4 w-4" />
            ) : (
              <Pause className="mr-2 h-4 w-4" />
            )}
            {isPaused ? "Resume Timer" : "Pause Timer"}
          </Button>
          <Button
            variant="destructive"
            className="flex-1 h-10"
            onClick={stopTracking}
          >
            <Square className="mr-2 h-4 w-4" />
            Stop Timer
          </Button>
        </div>
      )
    }

    return (
      <Button
        className="w-full h-10"
        onClick={handleStartTimer}
        disabled={isRunning && !isCurrentTask}
      >
        <Play className="mr-2 h-4 w-4" />
        Start Timer
      </Button>
    )
  }

  const fetchLonConfig = async () => {
    if (task?.deal_id && taskType === 'lon') {
      try {
        const response = await axios.get(`/lon/lon-config/${task.deal_id}`)
        setLonConfig(response.data)
      } catch (error: any) {
        if (error.response?.status !== 404) {
          console.error('Error fetching lon configuration:', error)
          toast.error('Failed to fetch løn configuration')
        }
      }
    }
  }

  useEffect(() => {
    fetchLonConfig()
  }, [task?.deal_id, taskType])

  const handlePayrollConfigSave = () => {
    if (task?.deal_id && taskType === 'lon') {
      fetchLonConfig()
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary" />
      </div>
    )
  }

  if (!task) return null

  return (
    <Dialog open onOpenChange={handleClose} modal={false}>
      <DialogContent className="max-w-[90vw] h-[90vh] p-0">
        <div className="flex flex-col h-full">
          <div className="bg-primary text-primary-foreground p-4 rounded-t-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <div className="flex gap-2">
                  <Button
                    variant="secondary"
                    size="icon"
                    onClick={handlePrevTask}
                    disabled={tasks.findIndex(t => t.id.toString() === taskId) === 0}
                  >
                    <ArrowUp className="h-4 w-4" />
                  </Button>
                  <Button
                    variant="secondary"
                    size="icon"
                    onClick={handleNextTask}
                    disabled={tasks.findIndex(t => t.id.toString() === taskId) === tasks.length - 1}
                  >
                    <ArrowDown className="h-4 w-4" />
                  </Button>
                </div>
                <h2 className="text-lg font-semibold ml-4">
                  {dealData.name || dealName || 'N/A'}: {task.description || task.text}
                </h2>
              </div>
              <DealNavBar dealId={task?.deal_id} />
            </div>
          </div>

          <div className="flex flex-1 overflow-hidden">
            <div className="flex-[2]">
              <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
                <div className="bg-primary text-primary-foreground">
                  <TabsList className="bg-primary-foreground/10">
                    {tabItems.map((item) => (
                      <TabsTrigger
                        key={item.value}
                        value={item.value}
                        className="text-primary-foreground data-[state=active]:bg-primary-foreground/20"
                      >
                        {item.label}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </div>
                
                <ScrollArea className="flex-1 p-4">
                  <div className="space-y-4">
                    {tabItems.map((item) => (
                      <TabsContent key={item.value} value={item.value}>
                        {item.content}
                      </TabsContent>
                    ))}
                  </div>
                </ScrollArea>
              </Tabs>
            </div>

            <ScrollArea className="flex-1 border-l">
              <div className="p-4 space-y-6">
                {/* Task Card */}
                <Card>
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <FileText className="h-5 w-5" />
                      Task
                    </CardTitle>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-sm text-muted-foreground">
                          <Clock className="h-4 w-4" />
                          Work Date
                        </div>
                        <TaskDateEditor
                          date={task.next_work_date || null}
                          dateType="work"
                          taskId={task.id}
                          taskType={taskType as FrontendTaskType}
                          className="w-full"
                          onSuccess={(updatedTask) => setTask(prevTask => 
                            prevTask ? ({ ...prevTask, ...updatedTask }) : updatedTask
                          )}
                        />
                      </div>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2 text-sm text-muted-foreground">
                          <Calendar className="h-4 w-4" />
                          Due Date
                        </div>
                        <TaskDateEditor
                          date={task.next_due_date || null}
                          dateType="due"
                          taskId={task.id}
                          taskType={taskType as FrontendTaskType}
                          className="w-full"
                          onSuccess={(updatedTask) => setTask(updatedTask)}
                        />
                      </div>
                    </div>

                    <div className="space-y-2">
                      {renderTaskStatus(task.board_status || '')}
                    </div>

                    <div className="flex items-center gap-2">
                      <Timer className="h-4 w-4" />
                      <span className="text-sm text-muted-foreground">
                        {task.expected_workload} min
                      </span>
                    </div>

                    {renderTimerButton()}
                  </CardContent>
                </Card>

                {/* Payroll Card */}
                {taskType === 'lon' && (
                  <Card>
                    <CardHeader>
                      <div className="flex items-center justify-between">
                        <CardTitle className="flex items-center gap-2">
                          <DollarSign className="h-5 w-5" />
                          Payroll
                        </CardTitle>
                        <Button
                          variant="ghost"
                          size="icon"
                          onClick={() => setPayrollDrawerVisible(true)}
                        >
                          <Edit className="h-4 w-4" />
                        </Button>
                      </div>
                    </CardHeader>
                    <CardContent>
                      {!lonConfig?.salary_type ? (
                        <div className="text-sm text-muted-foreground italic">
                          Payroll not configured yet
                        </div>
                      ) : (
                        <div className="space-y-4">
                          {task?.deal_software?.lønprogram && (
                            <div className="flex items-center gap-2">
                              <span className="text-sm text-muted-foreground">Lønprogram:</span>
                              <Badge variant="outline">
                                {task.deal_software.lønprogram}
                              </Badge>
                            </div>
                          )}
                          <div className="flex items-center gap-2">
                            <span className="text-sm text-muted-foreground">Type:</span>
                            <span>{lonConfig.salary_type}</span>
                          </div>
                          {(lonConfig.salary_period_hourly?.start || lonConfig.salary_period_hourly?.end) && (
                            <div className="flex items-center gap-2">
                              <span className="text-sm text-muted-foreground">Period (hourly):</span>
                              <span>
                                {`${lonConfig.salary_period_hourly.display_start} - ${
                                  lonConfig.salary_period_hourly.end === -1 
                                    ? `${task.work_date_last_day}`
                                    : lonConfig.salary_period_hourly.display_end
                                }`}
                              </span>
                            </div>
                          )}
                          {/* ... Payroll history graph would go here ... */}
                        </div>
                      )}
                    </CardContent>
                  </Card>
                )}

                {/* Contact Card */}
                <Card>
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <CardTitle className="flex items-center gap-2">
                        <User className="h-5 w-5" />
                        Contact
                      </CardTitle>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="ghost"
                              size="icon"
                              onClick={handleContactInfoClick}
                            >
                              <User className="h-4 w-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="space-y-2">
                              <p className="font-semibold">Contact Details:</p>
                              {contactInfo.length > 0 ? (
                                <ul className="list-disc pl-4">
                                  {contactInfo.map((entry, index) => (
                                    <li key={index}>{entry.text}</li>
                                  ))}
                                </ul>
                              ) : (
                                <p>No info added yet, click to add</p>
                              )}
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <span className="text-sm text-muted-foreground">Name</span>
                        <p>{contactData.name || 'N/A'}</p>
                      </div>
                      <div>
                        <span className="text-sm text-muted-foreground">Email</span>
                        <p>{contactData.email || 'N/A'}</p>
                      </div>
                      <div>
                        <span className="text-sm text-muted-foreground">Phone</span>
                        <p>{contactData.phone || 'N/A'}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>

                {/* Deal Card */}
                <Card>
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <CardTitle className="flex items-center gap-2">
                        <Building2 className="h-5 w-5" />
                        Deal
                      </CardTitle>
                      <Button
                        variant="link"
                        onClick={() => setDealDrawerVisible(true)}
                      >
                        <Badge variant="outline" className="text-blue-600">
                          {dealData.name}
                        </Badge>
                      </Button>
                    </div>
                  </CardHeader>
                  <CardContent className="space-y-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <span className="text-sm text-muted-foreground">CVR</span>
                        <p>{dealData.cvr || 'N/A'}</p>
                      </div>
                      <div>
                        <span className="text-sm text-muted-foreground">Address</span>
                        <p>{`${dealData.address || ''} ${dealData.zipcode || ''} ${dealData.city || ''}`}</p>
                      </div>
                      <div>
                        <span className="text-sm text-muted-foreground">Customer Number</span>
                        <p>{dealData.economic_customer_number || 'N/A'}</p>
                      </div>
                      <div>
                        <span className="text-sm text-muted-foreground">Onboard Status</span>
                        <p>{dealData.onboard_status || 'N/A'}</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>

                {/* Products Card */}
                <Card>
                  <CardHeader>
                    <div className="flex items-center justify-between">
                      <CardTitle className="flex items-center gap-2">
                        <ShoppingBag className="h-5 w-5" />
                        Products
                      </CardTitle>
                      <Button
                        variant="link"
                        onClick={() => setProductsDrawerVisible(true)}
                      >
                        <Badge variant="outline" className="text-magenta-600">
                          {task?.products?.length || '0'} product(s)
                        </Badge>
                      </Button>
                    </div>
                  </CardHeader>
                  <CardContent className="grid grid-cols-2 gap-4">
                    {(task?.products || []).map((product, index) => (
                      <TooltipProvider key={index}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="flex items-center gap-2 p-2 bg-background rounded-lg shadow hover:shadow-lg transition">
                              <div className="flex items-center justify-center w-10 h-10 bg-primary rounded-md text-primary-foreground">
                                {PRODUCT_ICONS[product.name] || <ShoppingBag className="h-5 w-5" />}
                              </div>
                              <span className="text-sm font-medium">{product.name}</span>
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <div>
                              <p>{product.name}</p>
                              <p>Quantity: {product.quantity || 1}</p>
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ))}
                  </CardContent>
                </Card>

                {/* Account Card */}
                <Card>
                  <CardHeader>
                    <CardTitle className="flex items-center gap-2">
                      <Users className="h-5 w-5" />
                      Account
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    {task?.account && (
                      <Button
                        variant="link"
                        onClick={() => showAccountDetails(task.account)}
                      >
                        <Badge variant="outline" className="text-green-600">
                          {task.account.company_name}
                        </Badge>
                      </Button>
                    )}
                  </CardContent>
                </Card>
              </div>
            </ScrollArea>
          </div>
        </div>

        {/* Drawers and Modals */}
        <ContactInfoDrawer
          visible={contactInfoVisible}
          onClose={handleContactInfoClose}
          contactId={task?.contact?.id}
          contactInfo={contactInfo}
        />
        <DealDrawer
          visible={dealDrawerVisible}
          onClose={() => setDealDrawerVisible(false)}
          dealId={task?.deal_id || null}
        />
        <TaskCompleteDrawer
          visible={isCompleteDrawerVisible}
          onClose={handleCompleteDrawerClose}
          task={taskToComplete as EnhancedTask}
          onComplete={handleCompleteDrawerSubmit}
        />
        <PayrollDrawer
          visible={payrollDrawerVisible}
          onClose={() => setPayrollDrawerVisible(false)}
          dealId={task?.deal_id || null}
          onSave={handlePayrollConfigSave}
        />
        <ProductsDrawer
          visible={productsDrawerVisible}
          onClose={() => setProductsDrawerVisible(false)}
          products={task?.products || []}
        />
      </DialogContent>
    </Dialog>
  )
}

export default TaskView