import React from 'react';
import { Modal, Form, DatePicker, TimePicker, Input, Row, Col, Typography, Card, Button } from 'antd';
import { PhoneOutlined, MailOutlined, CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import './BookOnboardingMeeting.css';

const { Title, Text } = Typography;
const { TextArea } = Input;

const BookOnboardingMeeting = ({ visible, onCancel, deal, onSubmit }) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const meetingDate = dayjs(values.date)
      .hour(values.time.hour())
      .minute(values.time.minute());
    
    onSubmit({
      meeting_date: meetingDate,
      notes: values.notes
    });
  };

  return (
    <Modal
      title={<Title level={3}>Book Onboarding Meeting</Title>}
      open={visible}
      onCancel={onCancel}
      width={800}
      footer={null}
      className="book-onboarding-modal"
    >
      <div className="contact-info-section">
        <Card className="contact-card">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div className="contact-item">
                <PhoneOutlined className="contact-icon" />
                <div>
                  <Text type="secondary">Phone</Text>
                  <Text strong className="contact-value">{deal?.phone}</Text>
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div className="contact-item">
                <MailOutlined className="contact-icon" />
                <div>
                  <Text type="secondary">Email</Text>
                  <Text strong className="contact-value">{deal?.email}</Text>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        className="meeting-form"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="date"
              label="Meeting Date"
              rules={[{ required: true, message: 'Please select a date' }]}
            >
              <DatePicker
                className="date-picker"
                format="YYYY-MM-DD"
                placeholder="Select date"
                suffixIcon={<CalendarOutlined />}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="time"
              label="Meeting Time"
              rules={[{ required: true, message: 'Please select a time' }]}
            >
              <TimePicker
                className="time-picker"
                format="HH:mm"
                minuteStep={15}
                placeholder="Select time"
                suffixIcon={<ClockCircleOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="notes"
          label="Meeting Notes"
        >
          <TextArea
            rows={4}
            placeholder="Enter any notes about the meeting..."
            className="meeting-notes"
          />
        </Form.Item>

        <div className="form-actions">
          <Button onClick={onCancel} className="cancel-button">
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="submit-button">
            Book Meeting
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default BookOnboardingMeeting;