import React, { useState, useEffect, useCallback } from 'react';
import { Loader2, AlertCircle, CheckCircle2, AlertTriangle, X } from "lucide-react";
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import axios from '@/api/axiosConfig';
import { cn } from "@/lib/utils";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
  DrawerDescription,
} from "@/components/ui/drawer";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import type { EnhancedTask } from '@/types/task';

dayjs.extend(duration);

const TIME_REASONS = [
  { value: 'complex_case', label: 'Complex Case' },
  { value: 'technical_issues', label: 'Technical Issues' },
  { value: 'client_communication', label: 'Extra Client Communication' },
  { value: 'data_quality', label: 'Poor Data Quality' },
  { value: 'training', label: 'Training/Learning' },
  { value: 'documentation', label: 'Extra Documentation Required' },
] as const;

const formSchema = z.object({
  timeSpent: z.number().min(0),
  timeReason: z.string().optional(),
  timeComment: z.string().optional(),
  paySlips: z.number().min(0).optional(),
  updateInvoice: z.boolean(),
  updateContract: z.boolean(),
});

interface TaskCompleteDrawerProps {
  visible: boolean;
  onClose: () => void;
  task: EnhancedTask | null;
  onComplete: (values: z.infer<typeof formSchema>) => void;
}

interface TimeSession {
  id: string;
  start_time: string;
  duration: number;
  pauses: {
    start: string;
    end?: string;
    duration?: number;
  }[];
}

interface TimeHistory {
  tracking_history: TimeSession[];
  total_duration: number;
}

export function TaskCompleteDrawer({ visible, onClose, task, onComplete }: TaskCompleteDrawerProps) {
  console.log('TaskCompleteDrawer props:', {
    visible,
    taskId: task?.id,
    taskType: task?.task_type,
    isLonTask: task?.task_type === 'lon',
    fullTask: task
  });

  const [timeHistory, setTimeHistory] = useState<TimeHistory | null>(null);
  const [loading, setLoading] = useState(false);
  const [paySlipsDifference, setPaySlipsDifference] = useState(false);
  const [showTimeComment, setShowTimeComment] = useState(false);

  const contractPaySlips = 8;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      timeSpent: 0,
      timeReason: undefined,
      timeComment: "",
      paySlips: undefined,
      updateInvoice: false,
      updateContract: false,
    },
  });

  const fetchTimeHistory = useCallback(async () => {
    if (!task) return;
    
    setLoading(true);
    try {
      const response = await axios.get(`/timetracker/task-time-history/${task.id}/${task.task_type}`);
      setTimeHistory(response.data);
      
      const totalMinutes = Math.round(response.data.total_duration / 60);
      form.setValue('timeSpent', totalMinutes);
      
    } catch (error) {
      console.error('Failed to fetch time history:', error);
    } finally {
      setLoading(false);
    }
  }, [task, form]);

  // Reset function
  const resetDrawerState = useCallback(() => {
    form.reset();
    setTimeHistory(null);
    setLoading(false);
    setPaySlipsDifference(false);
    setShowTimeComment(false);
  }, [form]);

  const isLonTask = task?.task_type === 'lon';
  console.log('isLonTask evaluation:', {
    taskType: task?.task_type,
    isLonTask,
    typeComparison: `${task?.task_type} === 'lon'`
  });

  useEffect(() => {
    if (visible && task) {
      console.log('Resetting form with task:', {
        taskId: task.id,
        taskType: task.task_type,
        isLonTask,
        defaultValues: {
          timeSpent: 0,
          timeReason: undefined,
          timeComment: "",
          paySlips: isLonTask ? undefined : undefined,
          updateInvoice: false,
          updateContract: false,
        }
      });
      
      form.reset({
        timeSpent: 0,
        timeReason: undefined,
        timeComment: "",
        paySlips: isLonTask ? undefined : undefined,
        updateInvoice: false,
        updateContract: false,
      });
      fetchTimeHistory();
    }
    if (!visible) {
      resetDrawerState();
    }
  }, [visible, task, form, fetchTimeHistory, resetDrawerState, isLonTask]);

  const formatDuration = (seconds: number) => {
    return dayjs.duration(seconds, 'seconds').format('HH:mm:ss');
  };

  const onPaySlipsChange = (value: number | null) => {
    if (value !== null) {
      form.setValue('paySlips', value);
      setPaySlipsDifference(value !== contractPaySlips);
    }
  };

  function onSubmit(values: z.infer<typeof formSchema>) {
    onComplete(values);
  }

  const getTitle = () => {
    if (!task?.task_type) return 'Complete Task';
    return `Complete ${task.task_type.charAt(0).toUpperCase()}${task.task_type.slice(1)} Task`;
  };

  const handleTimeChange = (delta: number) => {
    const currentValue = form.getValues('timeSpent');
    form.setValue('timeSpent', Math.max(0, currentValue + delta));
  };

  return (
    <Drawer open={visible} onOpenChange={onClose}>
      <DrawerContent>
        <DrawerHeader>
          <DrawerTitle>
            {getTitle()}
          </DrawerTitle>
          <DrawerDescription>
            Complete the task details below
          </DrawerDescription>
        </DrawerHeader>

        <div className="px-4 max-w-md mx-auto">
          {loading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <Loader2 className="h-8 w-8 animate-spin" />
            </div>
          ) : (
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                  control={form.control}
                  name="timeSpent"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="mb-2">Time Spent</FormLabel>
                      <FormControl>
                        <div className="flex flex-col items-center space-y-1">
                          <div className="flex items-center space-x-2">
                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={() => handleTimeChange(-10)}
                              className="h-7 text-sm"
                            >
                              --
                            </Button>
                            
                            <Button
                              type="button"
                              variant="outline"
                              onClick={() => handleTimeChange(-1)}
                            >
                              -
                            </Button>

                            <div className="flex flex-col items-center">
                              <div 
                                className="text-4xl font-semibold w-24 text-center"
                                onClick={() => {
                                  const input = document.createElement('input');
                                  input.type = 'number';
                                  input.value = field.value.toString();
                                  input.className = 'text-4xl font-semibold w-24 text-center';
                                  input.onblur = (e: FocusEvent) => {
                                    const target = e.target as HTMLInputElement;
                                    if (target) {
                                      field.onChange(Number(target.value));
                                    }
                                  };
                                }}
                              >
                                {field.value}
                              </div>
                              <span className="text-sm text-muted-foreground">minutes</span>
                            </div>

                            <Button
                              type="button"
                              variant="outline"
                              onClick={() => handleTimeChange(1)}
                            >
                              +
                            </Button>

                            <Button
                              type="button"
                              variant="outline"
                              size="sm"
                              onClick={() => handleTimeChange(10)}
                              className="h-7 text-sm"
                            >
                              ++
                            </Button>
                          </div>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* Time Comment Section */}
                {showTimeComment ? (
                  <div className="space-y-4 bg-muted p-4 rounded-lg">
                    <div className="flex justify-between items-center mb-4">
                      <h3 className="font-medium">Additional Information</h3>
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        onClick={() => setShowTimeComment(false)}
                      >
                        <X className="h-4 w-4" />
                      </Button>
                    </div>
                    <FormField
                      control={form.control}
                      name="timeReason"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Reason</FormLabel>
                          <Select 
                            onValueChange={field.onChange} 
                            defaultValue={field.value}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a reason" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {TIME_REASONS.map(reason => (
                                <SelectItem key={reason.value} value={reason.value}>
                                  {reason.label}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={form.control}
                      name="timeComment"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Additional Comments</FormLabel>
                          <FormControl>
                            <Textarea 
                              placeholder="Add any additional context..."
                              className="resize-none"
                              {...field}
                            />
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  </div>
                ) : (
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => setShowTimeComment(true)}
                    className="w-full"
                  >
                    Add Comment
                  </Button>
                )}

                {/* Løn Task Fields */}
                {isLonTask && (
                  <div className="space-y-4">
                    <FormField
                      control={form.control}
                      name="paySlips"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Number of Pay Slips</FormLabel>
                          <FormControl>
                            <div className="flex flex-col items-center space-y-1">
                              <div className="flex items-center space-x-2">
                                <Button
                                  type="button"
                                  variant="outline"
                                  onClick={() => onPaySlipsChange(Math.max(0, (field.value || 0) - 1))}
                                >
                                  -
                                </Button>

                                <div className="flex flex-col items-center">
                                  <div 
                                    className="text-4xl font-semibold w-24 text-center"
                                    onClick={() => {
                                      const input = document.createElement('input');
                                      input.type = 'number';
                                      input.value = (field.value || 0).toString();
                                      input.className = 'text-4xl font-semibold w-24 text-center';
                                      input.onblur = (e: FocusEvent) => {
                                        const target = e.target as HTMLInputElement;
                                        if (target) {
                                          onPaySlipsChange(Number(target.value));
                                        }
                                      };
                                    }}
                                  >
                                    {field.value || 0}
                                  </div>
                                  <span className="text-sm text-muted-foreground">pay slips</span>
                                </div>

                                <Button
                                  type="button"
                                  variant="outline"
                                  onClick={() => onPaySlipsChange((field.value || 0) + 1)}
                                >
                                  +
                                </Button>
                              </div>
                            </div>
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    {form.watch('paySlips') === contractPaySlips && (
                      <Alert variant="success" className="mb-4">
                        <CheckCircle2 className="h-4 w-4" />
                        <AlertTitle>Pay Slips Match Contract</AlertTitle>
                        <AlertDescription>
                          The number of pay slips completed matches the contract.
                        </AlertDescription>
                      </Alert>
                    )}

                    {paySlipsDifference && (
                      <>
                        <Alert variant="warning" className="mb-4">
                          <AlertTriangle className="h-4 w-4" />
                          <AlertTitle>Pay Slip Difference Detected</AlertTitle>
                          <AlertDescription>
                            The number of pay slips completed differs from the contract.
                          </AlertDescription>
                        </Alert>

                        <FormField
                          control={form.control}
                          name="updateInvoice"
                          render={({ field }) => (
                            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                              <FormControl>
                                <Checkbox
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <div className="space-y-1 leading-none">
                                <FormLabel>
                                  Use this number for next invoice
                                </FormLabel>
                              </div>
                            </FormItem>
                          )}
                        />

                        <FormField
                          control={form.control}
                          name="updateContract"
                          render={({ field }) => (
                            <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                              <FormControl>
                                <Checkbox
                                  checked={field.value}
                                  onCheckedChange={field.onChange}
                                />
                              </FormControl>
                              <div className="space-y-1 leading-none">
                                <FormLabel>
                                  Update contract with the new number of pay slips as default going forward
                                </FormLabel>
                              </div>
                            </FormItem>
                          )}
                        />
                      </>
                    )}
                  </div>
                )}

                {/* Time History */}
                {timeHistory && timeHistory.tracking_history.length > 0 && (
                  <div className="space-y-4 bg-muted p-4 rounded-lg">
                    <h3 className="font-medium">Time Tracking History:</h3>
                    <div className="space-y-4">
                      {timeHistory.tracking_history.map((session, index) => (
                        <div key={session.id} className="space-y-2">
                          <div>
                            Session {index + 1}: {dayjs(session.start_time).format('YYYY-MM-DD HH:mm:ss')}
                          </div>
                          <div className="text-sm text-muted-foreground">
                            Duration: {formatDuration(session.duration)}
                          </div>
                          
                          {session.pauses.length > 0 && (
                            <div className="ml-6 space-y-1">
                              <div className="text-sm text-muted-foreground">Pauses:</div>
                              {session.pauses.map((pause, pIndex) => (
                                <div key={pIndex} className="text-sm text-muted-foreground">
                                  {dayjs(pause.start).format('HH:mm:ss')} - 
                                  {pause.end ? dayjs(pause.end).format('HH:mm:ss') : 'ongoing'}
                                  {pause.duration && ` (${formatDuration(pause.duration)})`}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <DrawerFooter>
                  <Button type="submit">Submit</Button>
                  <Button variant="outline" onClick={onClose}>
                    Cancel
                  </Button>
                </DrawerFooter>
              </form>
            </Form>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
}

export default TaskCompleteDrawer;