import React from 'react';
import { Timeline } from 'antd';
import dayjs from 'dayjs';
import UserAvatar from '@/components/user-avatar';
import './TaskViewActivity.css';

const TaskViewActivity = ({ updates = [], users = [] }) => {
  const formatUpdateMessage = (update) => {
    switch (update.type) {
      case 'work_date':
      case 'next_work_date':
        return `Changed work date from ${dayjs(update.changes.from).format('YYYY-MM-DD')} to ${dayjs(update.changes.to).format('YYYY-MM-DD')}`;
      case 'due_date':
      case 'next_due_date':
        return `Changed due date from ${dayjs(update.changes.from).format('YYYY-MM-DD')} to ${dayjs(update.changes.to).format('YYYY-MM-DD')}`;
      case 'board_status':
        return `Changed status from "${update.changes.from}" to "${update.changes.to}"`;
      default:
        return `Updated ${update.type}`;
    }
  };

  if (!updates || updates.length === 0) {
    return (
      <div className="task-activity task-activity-empty">
        <div className="empty-message">
          No activity recorded yet
        </div>
      </div>
    );
  }

  return (
    <div className="task-activity">
      <Timeline
        items={updates.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
          .map(update => {
            const user = users.find(u => u.user_id === update.user_id);
            return {
              dot: <UserAvatar 
                user={user}
                size={32}
              />,
              children: (
                <div className="activity-item">
                  <div className="activity-message">
                    {formatUpdateMessage(update)}
                  </div>
                  <div className="activity-timestamp">
                    {dayjs(update.timestamp).format('YYYY-MM-DD HH:mm')}
                  </div>
                </div>
              )
            };
          })}
      />
    </div>
  );
};

export default TaskViewActivity;