import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosConfig';
import ErpHeader from '../components/ErpHeader';
import { Button } from '@/components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Textarea } from '@/components/ui/textarea';
import { Avatar } from '@/components/ui/avatar';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { Bot, Send, Info, FileText, HelpCircle } from 'lucide-react';
import { Loading } from '@/components/ui/loading';

interface DigiRegnskabsbotProps {
  theme: string;
  toggleTheme: () => void;
}

const LoadingDots = () => (
  <span className="inline-flex items-center">
    <span className="animate-pulse">.</span>
    <span className="animate-pulse animation-delay-200">.</span>
    <span className="animate-pulse animation-delay-400">.</span>
  </span>
);

const DigiRegnskabsbot: React.FC<DigiRegnskabsbotProps> = ({ theme, toggleTheme }) => {
  const navigate = useNavigate();
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [threadId, setThreadId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const [sourceUrls, setSourceUrls] = useState<Record<string, string>>({});
  const [totalThreads, setTotalThreads] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [isNewThread, setIsNewThread] = useState(true);

  const user = JSON.parse(localStorage.getItem('user') || '{}') || { name: 'User', id: null };
  const initials = user.name.split(' ').map((n: string) => n[0]).join('').toUpperCase();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleMainMenuRedirect = () => {
    navigate('/main-menu');
  };

  useEffect(() => {
    createThread();
    fetchSourceUrls();
    fetchChatbotStats();
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  };

  const createThread = async () => {
    try {
      const response = await axiosInstance.post('/ai/create_thread');
      setThreadId(response.data.thread_id);
    } catch (error) {
      console.error('Error creating thread:', error);
      // Use Sonner for toast notifications
      // toast.error('Kunne ikke oprette chatsamtale');
    }
  };

  const fetchSourceUrls = async () => {
    try {
      const response = await axiosInstance.get('/ai/get_source_urls');
      setSourceUrls(response.data);
    } catch (error) {
      console.error('Error fetching source URLs:', error);
    }
  };

  const fetchChatbotStats = async () => {
    try {
      const response = await axiosInstance.get('/ai/get_chatbot_stats');
      setTotalThreads(response.data.total_threads);
      setTotalQuestions(response.data.total_questions);
    } catch (error) {
      console.error('Error fetching chatbot stats:', error);
    }
  };

  const handleSourceClick = (source: string) => {
    const url = sourceUrls[source];
    if (url) {
      window.open(url, '_blank');
    } else {
      console.warn(`No URL found for source: ${source}`);
    }
  };

  const formatMessage = (text: string) => {
    const lines = text.split('\n');
    return lines.map((line, lineIndex) => {
      if (line === 'NEWLINE') {
        return <br key={lineIndex} />;
      }
      const parts = line.split(/(\*\*.*?\*\*|\[[\d,]+\]|^-\s|__.*?__)/);
      return (
        <div key={lineIndex} className={line.startsWith('- ') ? 'bullet-point' : ''}>
          {parts.map((part, partIndex) => {
            if (part.startsWith('**') && part.endsWith('**')) {
              return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
            } else if (part.startsWith('__') && part.endsWith('__')) {
              return <strong key={partIndex}>{part.slice(2, -2)}</strong>;
            } else if (part.match(/\[[\d,]+\]/)) {
              return <sup key={partIndex}>{part}</sup>;
            } else if (part === '- ') {
              return null; // Remove the bullet point marker
            } else if (line.startsWith('# ')) {
              return <h1 key={partIndex}>{part.slice(2)}</h1>;
            } else if (line.startsWith('## ')) {
              return <h2 key={partIndex}>{part.slice(3)}</h2>;
            } else if (line.startsWith('### ')) {
              return <h3 key={partIndex}>{part.slice(4)}</h3>;
            }
            return part;
          })}
        </div>
      );
    });
  };

  const renderCitations = (citations: string[]) => {
    return citations.map((citation, index) => {
      const [numbers, source] = citation.split('] Kilde: ');
      return (
        <div key={index} className="citation">
          {numbers}] Kilde:{' '}
          <span
            className="source-link hover:underline cursor-pointer"
            onClick={() => handleSourceClick(source.trim())}
          >
            {source}
          </span>
        </div>
      );
    });
  };

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const newMessage = { text: inputMessage, sender: 'user' };
    setChatMessages(prevMessages => [...prevMessages, newMessage]);
    setInputMessage('');
    setIsLoading(true);

    // Add a temporary message for the AI's response
    setChatMessages(prevMessages => [...prevMessages, { text: '', sender: 'assistant', isTyping: true }]);

    try {
      const token = localStorage.getItem('token');
      const baseUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000';
      const response = await fetch(`${baseUrl}/ai/send_message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          thread_id: threadId,
          message: inputMessage,
          user_id: user.user_id,
          is_new_thread: isNewThread  // Include the is_new_thread flag
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Set isNewThread to false after the first message is sent
      setIsNewThread(false);

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      let botResponse = '';
      let citations: string[] = [];
      let isCitationMode = false;

      const processChunk = (chunk: string) => {
        const lines = chunk.split('\n');
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') return true;
            if (data === 'CITATIONS_START') {
              isCitationMode = true;
              continue;
            }
            if (data === 'CITATIONS_END') {
              isCitationMode = false;
              continue;
            }
            if (data.startsWith('Error:')) {
              botResponse = data;
              updateChatMessages(botResponse.trim(), citations);
              return true; // Signal completion on error
            }
            if (isCitationMode) {
              citations.push(data);
            } else {
              botResponse += data + '\n';
            }
            updateChatMessages(botResponse.trim(), citations);
          }
        }
        return false; // Not completed
      };

      while (true) {
        const { done, value } = await reader?.read() || {};
        if (done) break;
        const chunk = decoder.decode(value);
        const isCompleted = processChunk(chunk);
        if (isCompleted) break;
      }

    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages(prevMessages => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage.sender === 'assistant') {
          lastMessage.text = `Error: ${error instanceof Error ? error.message : 'Unknown error'}`;
          lastMessage.isTyping = false;
        }
        return updatedMessages;
      });
    } finally {
      setIsLoading(false);
      fetchChatbotStats();  // Refresh stats after each message
    }
  };

  const updateChatMessages = (text: string, cits: string[]) => {
    setChatMessages(prevMessages => {
      const updatedMessages = [...prevMessages];
      const lastMessage = updatedMessages[updatedMessages.length - 1];
      if (lastMessage.sender === 'assistant') {
        lastMessage.text = text;
        lastMessage.formatted = formatMessage(text);
        lastMessage.citations = cits;
        lastMessage.isTyping = false;
      }
      return updatedMessages;
    });
  };

  const handlePressEnter = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && inputMessage.trim()) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const chatbotDescription = `Denne chatbot er designet til at hjælpe med spørgsmål om bogføring og regnskab.
    Den kan assistere med vejledning i fakturering, momsindberetning, skat, årsrapporter, 
    og lovgivning vedrørende bogføring og regnskab.
  `;

  const sources = [
    'Bogføringsloven',
    'Revisorloven',
    'Årsregnskabsloven',
    'Virksomhedsskatteloven',
    'Personskatteloven',
    'Aktieavancebeskatningsloven',
    'Kildeskattebekendtgørelsen',
    'Virksomhedsordningen og kapitalafkastordningen',
    'Bekendtgørelse om krav til det skattemæssige årsregnskab m.v. for mindre virksomheder',
    'Bekendtgørelse om krav til det skattemæssige årsregnskab m.v. for større virksomheder',
    'Lovkrav til tidsregistrering',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-B - Beierholm',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-C-mellem - Beierholm',
    'Tjekliste-til-årsrapporten-for-regnskabsklasse-C-stor - Beierholm',
    'Ændringer til årsregnskabsloven for årsrapport 2020 - Beierholm',
    'Momsvejledning-2024 - pwc',
    'Regnskabshåndbogen-2024 - pwc',
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <ErpHeader 
        icon={Bot}
        title="Digi-Regnskabsbot"
      />
      <main className="flex-1 flex flex-col p-6">
        <div className="max-w-7xl mx-auto w-full">
          {/* Stats Cards */}
          <div className="flex justify-center gap-4 mb-6 mt-6">
            <Card className="w-[200px]">
              <CardHeader className="pb-2">
                <CardTitle className="text-sm font-medium">Total Threads</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center">
                  <HelpCircle className="h-4 w-4 mr-2 text-muted-foreground" />
                  <span className="text-2xl font-bold">{totalThreads}</span>
                </div>
              </CardContent>
            </Card>
            <Card className="w-[200px]">
              <CardHeader className="pb-2">
                <CardTitle className="text-sm font-medium">Questions Answered</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center">
                  <HelpCircle className="h-4 w-4 mr-2 text-muted-foreground" />
                  <span className="text-2xl font-bold">{totalQuestions}</span>
                </div>
              </CardContent>
            </Card>
          </div>

          {/* Chat Card */}
          <Card className="chatbot-card">
            <CardHeader className="border-b">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  <Avatar>
                    <Bot className="h-5 w-5" />
                  </Avatar>
                  <span className="font-semibold">Digi-Tal Regnskabs-Chatbot</span>
                </div>
                <div className="flex items-center gap-2">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="cursor-help">
                        <Info className="h-5 w-5 text-muted-foreground hover:text-primary transition-colors" />
                      </TooltipTrigger>
                      <TooltipContent className="max-w-[300px]">
                        <p className="text-sm">{chatbotDescription}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger className="cursor-help">
                        <FileText className="h-5 w-5 text-muted-foreground hover:text-primary transition-colors" />
                      </TooltipTrigger>
                      <TooltipContent side="right" className="max-w-[300px]">
                        <p className="font-semibold mb-1">Kilder:</p>
                        <ul className="text-sm space-y-1">
                          {sources.map((source, index) => (
                            <li key={index}>{source}</li>
                          ))}
                        </ul>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
            </CardHeader>
            <CardContent className="p-4">
              <div className="chat-container">
                <div className="chat-messages space-y-4" ref={chatContainerRef}>
                  {chatMessages.map((msg, index) => (
                    <div key={index} 
                      className={`flex ${msg.sender === 'user' ? 'justify-end' : 'justify-start'}`}
                    >
                      <div className={`message max-w-[80%] rounded-lg p-3 ${
                        msg.sender === 'user' 
                          ? 'bg-primary text-primary-foreground' 
                          : 'bg-muted'
                      }`}>
                        {msg.isTyping ? (
                          <LoadingDots />
                        ) : (
                          <div>{msg.formatted || msg.text}</div>
                        )}
                        {msg.citations && (
                          <div className="citation-section mt-2 text-sm opacity-80">
                            {renderCitations(msg.citations)}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="chat-input flex items-center gap-2 mt-4">
                  <Textarea
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyDown={handlePressEnter}
                    placeholder="Stil et spørgsmål om dine fakturaer..."
                    className="min-h-[60px]"
                    rows={1}
                  />
                  <Button 
                    onClick={handleSendMessage}
                    disabled={!inputMessage.trim() || !threadId || isLoading}
                    className="h-[60px] px-6"
                  >
                    {isLoading ? (
                      <Loading size="sm" message="" />
                    ) : (
                      <Send className="h-4 w-4" />
                    )}
                    <span className="ml-2">Send</span>
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default DigiRegnskabsbot;