import { useEffect, useCallback, useRef } from 'react';
import { socketService } from '../services/socket';

const ENABLE_REALTIME = false;

export const useRealTimeUpdates = (taskType, roomId, onUpdate) => {
  const retryTimeoutRef = useRef(null);
  const mountedRef = useRef(true);
  const roomRef = useRef(`board:${taskType}`);

  const handleUpdate = useCallback((update) => {
    console.log('Received update:', update);
    if (onUpdate && mountedRef.current) {
      onUpdate(update.data);
    }
  }, [onUpdate]);

  useEffect(() => {
    if (!ENABLE_REALTIME) return;

    mountedRef.current = true;
    const room = roomRef.current;
    console.log('Setting up real-time updates for room:', room);

    const setupSocket = async () => {
      try {
        await socketService.connect();
        console.log('Socket connected, joining room:', room);
        socketService.joinRoom(room);
        socketService.onRoomUpdate(handleUpdate);
      } catch (error) {
        console.error('Socket connection error:', error);
      }
    };

    setupSocket();

    return () => {
      mountedRef.current = false;
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
      }
      console.log('Leaving room:', room);
      socketService.leaveRoom(room);
      socketService.offRoomUpdate(handleUpdate);
    };
  }, [handleUpdate]);

  useEffect(() => {
    const newRoom = `board:${taskType}`;
    if (newRoom !== roomRef.current) {
      socketService.leaveRoom(roomRef.current);
      roomRef.current = newRoom;
      socketService.joinRoom(newRoom);
    }
  }, [taskType]);
};