/**
 * This file defines the column configurations for the table view of tasks.
 * It specifies which columns should be displayed for each task type.
 */

import { type ColumnDef } from '@/components/ui/specialized/mod-table';
import { type Task, type FrontendTaskType } from '@/types/task';

// Export the type for the task types that can have columns
export type ColumnConfigKey = 'moms' | 'lon' | 'bogforing' | 'arsafslutning' | 'andre-opgaver' | 'activities';

// Common columns that appear in all task types
const commonColumns: ColumnDef<Task>[] = [
  {
    title: "Deal",
    dataIndex: "deal_name",
    key: "deal_name",
  },
  {
    title: "Task",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Assignee",
    dataIndex: "assigned_users",
    key: "assignee",
  },
];

// Define recurring and non-recurring columns
const recurringColumns: ColumnDef<Task>[] = [
  ...commonColumns,
  // Add any additional columns specific to recurring tasks here
];

const nonRecurringColumns: ColumnDef<Task>[] = [
  ...commonColumns,
  // Add any additional columns specific to non-recurring tasks here
];

// Create column configurations using the defined columns
export const columnConfigs: Record<ColumnConfigKey, ColumnDef<Task>[]> = {
  moms: recurringColumns,
  lon: recurringColumns,
  bogforing: recurringColumns,
  arsafslutning: recurringColumns,
  'andre-opgaver': nonRecurringColumns,
  activities: nonRecurringColumns,
};