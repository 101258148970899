import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import UserAvatar from '@/components/user-avatar';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Separator } from "@/components/ui/separator";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

interface User {
  user_id: string;
  name: string;
  surname: string;
  email: string;
  status: 'online' | 'inactive' | 'offline';
  location_info?: {
    city?: string;
    country?: string;
    timezone?: string;
  };
  last_heartbeat?: string;
  is_test_user?: boolean;
  settings?: {
    avatar_color?: string;
  };
}

interface ActivityScreenProps {
  visible: boolean;
  onClose: () => void;
}

const ActivityScreen: React.FC<ActivityScreenProps> = ({ visible, onClose }) => {
  // Get users data from localStorage
  const usersData = JSON.parse(localStorage.getItem('usersData') || '{}');
  const users = Object.values(usersData) as User[];

  const getDisplayName = (user: User) => {
    return `${user.name || ''} ${user.surname || ''}`.trim() || user.email || 'Unknown User';
  };

  const getLocalTime = (locationInfo?: User['location_info']) => {
    if (!locationInfo?.timezone) return 'Unknown';
    try {
      return dayjs().tz(locationInfo.timezone).format('HH:mm');
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Unknown';
    }
  };

  const getLocation = (locationInfo?: User['location_info']) => {
    if (!locationInfo?.city || !locationInfo?.country) return 'Unknown';
    return `${locationInfo.city}, ${locationInfo.country}`;
  };

  const getLastOnline = (lastHeartbeat?: string) => {
    if (!lastHeartbeat) return 'Never';
    
    try {
      // Create UTC date objects for both times
      const lastHeartbeatUTC = dayjs.utc(lastHeartbeat);
      const nowUTC = dayjs.utc();
      
      // If the last heartbeat was less than a minute ago, show "Just now"
      if (nowUTC.diff(lastHeartbeatUTC, 'minute') < 1) {
        return 'Just now';
      }
      
      // Otherwise show relative time
      return lastHeartbeatUTC.from(nowUTC);
    } catch (error) {
      console.error('Error calculating last online time:', error);
      return 'Unknown';
    }
  };

  // Filter users
  const realUsers = users.filter(user => !user.is_test_user);
  const onlineUsers = realUsers.filter(user => user.status === 'online');
  const inactiveUsers = realUsers.filter(user => user.status === 'inactive');
  const offlineUsers = realUsers.filter(user => user.status === 'offline');

  const UserList = ({ users, status }: { users: User[], status: User['status'] }) => (
    <div className="space-y-4">
      {users.map((user) => (
        <div key={user.user_id} className="flex items-start space-x-4 p-4 rounded-lg hover:bg-muted/50">
          <UserAvatar user={user} size={40} />
          <div className="flex-1 space-y-1">
            <p className="font-medium leading-none">{getDisplayName(user)}</p>
            <p className="text-sm text-muted-foreground">{user.email}</p>
            <p className="text-sm text-muted-foreground">{getLocation(user.location_info)}</p>
            <p className="text-sm text-muted-foreground">
              Local time: {getLocalTime(user.location_info)}
              {status === 'online' && (
                <span className="ml-2 text-emerald-500">● Online</span>
              )}
              {status === 'inactive' && (
                <span className="ml-2 text-amber-500">● Inactive</span>
              )}
              {status === 'offline' && (
                <TooltipProvider delayDuration={200}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span className="ml-2 text-rose-500 cursor-help">● Offline</span>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Last online: {getLastOnline(user.last_heartbeat)}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              )}
            </p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Team Members</DialogTitle>
        </DialogHeader>

        <ScrollArea className="h-[70vh] pr-4">
          {onlineUsers.length > 0 && (
            <div className="space-y-4">
              <div className="flex items-center">
                <h4 className="text-sm font-semibold">Online Users</h4>
                <Separator className="ml-2 flex-1" />
              </div>
              <UserList users={onlineUsers} status="online" />
            </div>
          )}

          {inactiveUsers.length > 0 && (
            <div className="mt-6 space-y-4">
              <div className="flex items-center">
                <h4 className="text-sm font-semibold">Inactive Users</h4>
                <Separator className="ml-2 flex-1" />
              </div>
              <UserList users={inactiveUsers} status="inactive" />
            </div>
          )}

          {offlineUsers.length > 0 && (
            <div className="mt-6 space-y-4">
              <div className="flex items-center">
                <h4 className="text-sm font-semibold">Offline Users</h4>
                <Separator className="ml-2 flex-1" />
              </div>
              <UserList users={offlineUsers} status="offline" />
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityScreen;