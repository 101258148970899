import * as React from "react";
import { cn } from "@/lib/utils";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { StoredUser } from "@/types/user";

interface AvatarGroupProps extends React.HTMLAttributes<HTMLDivElement> {
  users: StoredUser[];
  max?: number;
  className?: string;
}

export function AvatarGroup({ users, max = 4, className }: AvatarGroupProps) {
  const totalUsers = users.length;
  const displayUsers = users.slice(0, max);
  const remainingUsers = totalUsers - max;

  return (
    <div className={cn("flex -space-x-2", className)}>
      {displayUsers.map((user, i) => (
        <div
          key={user.user_id}
          className={cn(
            "relative inline-block",
            i !== 0 && "-ml-2"
          )}
        >
          <Avatar className="h-8 w-8 ring-2 ring-background">
            <AvatarImage src={user.avatar_urls?.medium} alt={user.name} />
            <AvatarFallback 
              className="text-xs text-white"
              style={{ backgroundColor: user.settings?.avatar_color }}
            >
              {`${user.name?.[0] || ''}${user.surname?.[0] || ''}`.toUpperCase()}
            </AvatarFallback>
          </Avatar>
        </div>
      ))}
      {remainingUsers > 0 && (
        <div className="relative inline-block -ml-2">
          <Avatar className="h-8 w-8 ring-2 ring-background">
            <AvatarFallback className="text-xs text-foreground bg-muted">
              +{remainingUsers}
            </AvatarFallback>
          </Avatar>
        </div>
      )}
    </div>
  );
}