/**
 * This file contains the configuration for different board types.
 * It defines how each task type should be displayed and behave in the board view.
 */

import { type FrontendTaskType } from '@/types/task';
import { boardStatusConfigs } from './board-status';
import { type TaskTypeConfig, type DealConfigItem } from '@/types/taskboard';
import { dealDataConfig } from '@/configs/DealDataConfig';
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig';

// Helper to filter deal data config
const filterDealDataConfig = (keys: string[]): DealConfigItem[] => 
  dealDataConfig.filter(item => keys.includes(item.key));

// Helper to filter deal software config
const filterDealSoftwareConfig = (keys: string[]): DealConfigItem[] => 
  dealSoftwareConfig.filter(item => keys.includes(item.key));

// Export taskTypes directly instead of boardConfigs
export const taskTypes = {
  moms: {
    title: "Moms Tasks",
    fetchUrl: "/taskboard/tasks/moms",
    defaultView: "kanban" as const,
    boardStatusOptions: boardStatusConfigs.moms,
    cardConfig: {
      showAssignee: true,
      showFrequency: true,
    },
    dealDataConfig: filterDealDataConfig([
      'data.momsfrekvens',
      'data.momsperiode'
    ]),
    taskType: "moms" as FrontendTaskType,
    backendType: "recurring",
    isRecurring: true,
    recurringType: "moms",
  },
  lon: {
    title: "Løn Tasks",
    fetchUrl: "/taskboard/tasks/lon",
    defaultView: "kanban",
    boardStatusOptions: boardStatusConfigs.lon,
    cardConfig: {
      showAssignee: true,
      showFrequency: true,
    },
    dealDataConfig: filterDealDataConfig([
      'data.lønfrekvens',
      'data.antal_medarbejdere_til_lønhandtering'
    ]),
    dealSoftwareConfig: filterDealSoftwareConfig([
      'lønprogram'
    ]),
    taskType: "lon",
    backendType: "recurring",
    isRecurring: true,
    recurringType: "lon",
  },
  bogforing: {
    title: "Bogføring Tasks",
    fetchUrl: "/taskboard/tasks/bogforing",
    defaultView: "kanban",
    boardStatusOptions: boardStatusConfigs.bogforing,
    cardConfig: {
      showAssignee: true,
      showFrequency: true,
    },
    dealDataConfig: filterDealDataConfig([
      'data.regnskabsprogram',
      'data.bogføringsfrekvens'
    ]),
    taskType: "bogforing",
    backendType: "recurring",
    isRecurring: true,
    recurringType: "bogforing",
  },
  arsafslutning: {
    title: "Årsafslutning Tasks",
    fetchUrl: "/taskboard/tasks/arsafslutning",
    defaultView: "kanban",
    boardStatusOptions: boardStatusConfigs.arsafslutning,
    cardConfig: {
      showAssignee: true,
      showFrequency: true,
    },
    dealDataConfig: filterDealDataConfig([
      'data.regnskabsår',
      'data.selskabsform'
    ]),
    taskType: "arsafslutning",
    backendType: "recurring",
    isRecurring: true,
    recurringType: "arsafslutning",
  },
  "andre-opgaver": {
    title: "Andre Opgaver Tasks",
    fetchUrl: "/taskboard/tasks/onboarding/other",
    defaultView: "kanban" as const,
    boardStatusOptions: boardStatusConfigs["andre-opgaver"],
    cardConfig: {
      showAssignee: true,
    },
    dealDataConfig: filterDealDataConfig([
      'data.contact_name',
      'data.company_name'
    ]),
    taskType: "andre-opgaver" as FrontendTaskType,
    backendType: "other",
    isRecurring: false,
    recurringType: "onboarding",
  },
  activities: {
    title: "Activities Tasks",
    fetchUrl: "/taskboard/tasks/onboarding/activity",
    defaultView: "table",
    boardStatusOptions: boardStatusConfigs.activities,
    cardConfig: {
      showAssignee: true,
    },
    dealDataConfig: filterDealDataConfig([
      'data.contact_name',
      'data.company_name'
    ]),
    taskType: "activities",
    backendType: "activity",
    isRecurring: false,
    recurringType: "onboarding",
  },
} as const;

export type TaskTypeKey = keyof typeof taskTypes;

export const getTaskTypeConfig = (type: TaskTypeKey): TaskTypeConfig => {
  const config = taskTypes[type];
  if (!config) {
    throw new Error(`Invalid task type: ${String(type)}`);
  }
  return config;
};

export const isRecurringTaskType = (type: string): boolean => {
  return taskTypes[type as TaskTypeKey]?.isRecurring ?? false;
};

export const getBackendTaskType = (type: string): string => {
  const taskTypeConfig = taskTypes[type as TaskTypeKey];
  return taskTypeConfig ? taskTypeConfig.backendType || type : type;
};
