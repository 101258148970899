import React, { useState, useEffect } from 'react';
import { Drawer, List, Button, Input, Switch, message, Popconfirm, Avatar, Tooltip, Tag, Divider } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import axios from '../../api/axiosConfig';
import './ContactInfo.css';
import TextArea from 'antd/es/input/TextArea';
import UserAvatar from '@/components/user-avatar';

const ContactInfoDrawer = ({ visible, onClose, contactId, contactInfo }) => {
  const [entries, setEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({ text: '', isPublic: true });
  const [editingIndex, setEditingIndex] = useState(-1);
  const [users, setUsers] = useState({});

  useEffect(() => {
    if (visible) {
      setEntries(contactInfo);
      resetStates();
      if (contactInfo && contactInfo.length > 0) {
        fetchUsers(contactInfo.map(entry => entry.user_id));
      }
    }
  }, [visible, contactInfo]);

  const resetStates = () => {
    setNewEntry({ text: '', isPublic: true });
    setEditingIndex(-1);
  };

  const fetchUsers = async (userIds) => {
    try {
      const response = await axios.get('/user/names-and-avatars', {
        params: { user_ids: userIds }
      });
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const getUserInfo = (userId) => {
    return users[userId] || {};
  };

  const handleAddEntry = async () => {
    try {
      console.log('Adding new entry:', newEntry);
      const response = await axios.post(`/account/contacts/${contactId}/info`, {
        text: newEntry.text,
        is_public: newEntry.isPublic,
      });
      console.log('Response from server:', response.data);
      message.success('Contact info added successfully');
      setNewEntry({ text: '', isPublic: true });
      setEntries(response.data.entries); // Update entries with the response data
      onClose();
    } catch (error) {
      console.error('Error adding contact info:', error.response?.data || error);
      message.error('Failed to add contact info');
    }
  };

  const handleUpdateEntry = async (entry) => {
    try {
      const response = await axios.put(`/account/contacts/${contactId}/info/${entry.id}`, {
        text: entry.text,
        is_public: entry.is_public,
      });
      console.log('Response from server:', response.data);
      message.success('Contact info updated successfully');
      setEntries(response.data.entries); // Update entries with the response data
      setEditingIndex(-1);
    } catch (error) {
      console.error('Error updating contact info:', error.response?.data || error);
      message.error('Failed to update contact info');
    }
  };

  const handleDeleteEntry = async (entryId) => {
    try {
      await axios.delete(`/account/contacts/${contactId}/info/${entryId}`);
      message.success('Contact info deleted successfully');
      // Refresh the entries after deletion
      const response = await axios.get(`/account/contacts/${contactId}/info`);
      setEntries(response.data.entries);
    } catch (error) {
      console.error('Error deleting contact info:', error);
      message.error('Failed to delete contact info');
    }
  };

  const handleClose = () => {
    resetStates();
    onClose();
  };

  return (
    <Drawer
      title="Contact Info"
      placement="right"
      onClose={handleClose}
      open={visible}
      width={400}
    >
      <List
        dataSource={entries}
        renderItem={(entry, index) => {
          const user = getUserInfo(entry.user_id);
          return (
            <List.Item
              actions={[
                <Button icon={<EditOutlined />} onClick={() => setEditingIndex(index)} />,
                <Popconfirm
                  title="Are you sure you want to delete this entry?"
                  onConfirm={() => handleDeleteEntry(entry.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button icon={<DeleteOutlined />} />
                </Popconfirm>
              ]}
            >
              <List.Item.Meta
                avatar={
                  <UserAvatar 
                    user={user}
                    size={32}
                    tooltipPlacement="right"
                  />
                }
                title={
                  <div className="entry-content">
                    <div className="entry-text">
                      {editingIndex === index ? (
                        <TextArea
                          value={entry.text}
                          onChange={(e) => {
                            const updatedEntries = [...entries];
                            updatedEntries[index].text = e.target.value;
                            setEntries(updatedEntries);
                          }}
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          className="edit-textarea"
                        />
                      ) : (
                        entry.text
                      )}
                    </div>
                    <Tag 
                      className="entry-tag"
                      color={entry.is_public ? 'green' : 'red'} 
                      icon={entry.is_public ? <UnlockOutlined /> : <LockOutlined />}
                    >
                      {entry.is_public ? 'Public' : 'Private'}
                    </Tag>
                  </div>
                }
                description={
                  editingIndex === index ? (
                    <div className="entry-edit">
                      <Switch
                        checked={entry.is_public}
                        onChange={(checked) => {
                          const updatedEntries = [...entries];
                          updatedEntries[index].is_public = checked;
                          setEntries(updatedEntries);
                        }}
                      /> Public
                      <Button onClick={() => handleUpdateEntry(entry)}>Save</Button>
                    </div>
                  ) : null
                }
              />
            </List.Item>
          );
        }}
      />
      <Divider className="entries-divider" />
      <div className="new-entry">
        <Input
          placeholder="Add new entry"
          value={newEntry.text}
          onChange={(e) => setNewEntry({ ...newEntry, text: e.target.value })}
        />
        <div className="new-entry-controls">
          <Switch
            checked={newEntry.isPublic}
            onChange={(checked) => setNewEntry({ ...newEntry, isPublic: checked })}
          />
          <Tag color={newEntry.isPublic ? 'green' : 'red'}>
            {newEntry.isPublic ? 'Public' : 'Private'}
          </Tag>
          <Button icon={<PlusOutlined />} onClick={handleAddEntry}>
            Add
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default ContactInfoDrawer;
