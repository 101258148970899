import React, { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { DatePicker } from "@/components/ui/date/date-picker"
import { X } from "lucide-react"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { StoredUser as User } from '@/types/user'
import { BoardStatusOption } from '@/types/taskboard'
import { useUsers } from '@/contexts/UserContext'

type FilterType = 'status' | 'assignedUsers' | 'recurringType';

interface TaskFilterProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  onFiltersChange: (filters: TaskFilters) => void
  statusOptions: BoardStatusOption[]
  currentFilters: TaskFilters
}

export interface TaskFilters {
  status: string[]
  assignedUsers: string[]
  recurringType: string[]
}

export function TaskFilter({ open, onOpenChange, onFiltersChange, statusOptions, currentFilters }: TaskFilterProps) {
  const { users } = useUsers()
  const [activeFilters, setActiveFilters] = useState<Set<FilterType>>(new Set(
    Object.entries(currentFilters)
      .filter(([_, values]) => values.length > 0)
      .map(([key]) => key as FilterType)
  ))
  
  const [statusFilter, setStatusFilter] = useState<string[]>(currentFilters.status)
  const [assignedUsersFilter, setAssignedUsersFilter] = useState<string[]>(currentFilters.assignedUsers)
  const [recurringTypeFilter, setRecurringTypeFilter] = useState<string[]>(currentFilters.recurringType)

  useEffect(() => {
    setStatusFilter(currentFilters.status)
    setAssignedUsersFilter(currentFilters.assignedUsers)
    setRecurringTypeFilter(currentFilters.recurringType)
    setActiveFilters(new Set(
      Object.entries(currentFilters)
        .filter(([_, values]) => values.length > 0)
        .map(([key]) => key as FilterType)
    ))
  }, [currentFilters])

  // Reset filters when dialog opens
  useEffect(() => {
    if (open) {
      resetFilters()
    }
  }, [open])

  const resetFilters = () => {
    setActiveFilters(new Set())
    setStatusFilter([])
    setAssignedUsersFilter([])
    setRecurringTypeFilter([])
  }

  // Update parent component when filters change
  useEffect(() => {
    onFiltersChange({
      status: statusFilter,
      assignedUsers: assignedUsersFilter,
      recurringType: recurringTypeFilter,
    })
  }, [statusFilter, assignedUsersFilter, recurringTypeFilter])

  const filterOptions = [
    {
      id: 'status',
      label: 'Status',
      component: (
        <Select
          value=""
          onValueChange={(value) => {
            if (!statusFilter.includes(value)) {
              setStatusFilter([...statusFilter, value])
            }
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select status" />
          </SelectTrigger>
          <SelectContent>
            {statusOptions
              .filter(status => !statusFilter.includes(status.value))
              .map(status => (
                <SelectItem key={status.value} value={status.value}>
                  {status.label}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'assignedUsers',
      label: 'Assigned Users',
      component: (
        <Select
          value=""
          onValueChange={(value) => {
            if (!assignedUsersFilter.includes(value)) {
              setAssignedUsersFilter([...assignedUsersFilter, value])
            }
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select users" />
          </SelectTrigger>
          <SelectContent>
            {users
              .filter(user => !assignedUsersFilter.includes(String(user.user_id)))
              .map(user => (
                <SelectItem 
                  key={String(user.user_id)} 
                  value={String(user.user_id)}
                >
                  {user.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      )
    },
    {
      id: 'recurringType',
      label: 'Recurring Type',
      component: (
        <Select
          value=""
          onValueChange={(value) => {
            if (!recurringTypeFilter.includes(value)) {
              setRecurringTypeFilter([...recurringTypeFilter, value])
            }
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder="Select recurring type" />
          </SelectTrigger>
          <SelectContent>
            {['DAILY', 'WEEKLY', 'BIWEEKLY', 'MONTHLY', 'QUARTERLY', 'YEARLY']
              .filter(type => !recurringTypeFilter.includes(type))
              .map(type => (
                <SelectItem key={type} value={type}>
                  {type}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
      )
    }
  ]

  const removeFilter = (filterId: FilterType, value?: string) => {
    switch (filterId) {
      case 'status':
        if (value) {
          setStatusFilter(statusFilter.filter(s => s !== value))
        } else {
          setStatusFilter([])
        }
        break
      case 'assignedUsers':
        if (value) {
          setAssignedUsersFilter(assignedUsersFilter.filter(u => u !== value))
        } else {
          setAssignedUsersFilter([])
        }
        break
      case 'recurringType':
        if (value) {
          setRecurringTypeFilter(recurringTypeFilter.filter(r => r !== value))
        } else {
          setRecurringTypeFilter([])
        }
        break
    }
    
    if (!value || 
        (filterId === 'status' && statusFilter.length <= 1) ||
        (filterId === 'assignedUsers' && assignedUsersFilter.length <= 1) ||
        (filterId === 'recurringType' && recurringTypeFilter.length <= 1)) {
      activeFilters.delete(filterId)
      setActiveFilters(new Set(activeFilters))
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>Filter Tasks</DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          {/* Add Filter Button */}
          <Select 
            value=""
            onValueChange={(value) => {
              setActiveFilters(prev => new Set(Array.from(prev).concat(value as FilterType)))
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder="Add filter..." />
            </SelectTrigger>
            <SelectContent>
              {filterOptions
                .filter(filter => !activeFilters.has(filter.id as FilterType))
                .map(filter => (
                  <SelectItem key={filter.id} value={filter.id}>
                    {filter.label}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>

          {/* Active Filters */}
          {activeFilters.size > 0 && (
            <Card>
              <CardContent className="pt-6 space-y-4">
                {Array.from(activeFilters).map(filterId => {
                  const filter = filterOptions.find(f => f.id === filterId)
                  if (!filter) return null

                  return (
                    <div key={filterId} className="space-y-2">
                      <div className="flex items-center justify-between">
                        <label className="text-sm font-medium">{filter.label}</label>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => removeFilter(filterId)}
                        >
                          <X className="h-4 w-4" />
                        </Button>
                      </div>
                      {filter.component}
                      {/* Show selected values as badges */}
                      <div className="flex flex-wrap gap-2">
                        {(filterId === 'status' ? statusFilter : 
                          filterId === 'assignedUsers' ? assignedUsersFilter : []
                        ).map(value => (
                          <Badge
                            key={value}
                            variant="secondary"
                            className="flex items-center gap-1"
                          >
                            {value}
                            <Button
                              variant="ghost"
                              size="icon"
                              className="h-4 w-4 p-0"
                              onClick={() => removeFilter(filterId, value)}
                            >
                              <X className="h-3 w-3" />
                            </Button>
                          </Badge>
                        ))}
                      </div>
                    </div>
                  )
                })}
              </CardContent>
            </Card>
          )}

          <DialogFooter>
            <Button variant="outline" onClick={() => {
              resetFilters()
              onFiltersChange({
                status: [],
                assignedUsers: [],
                recurringType: [],
              })
            }}>
              Reset Filters
            </Button>
            <Button onClick={() => onOpenChange(false)}>
              Apply Filters
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  )
}