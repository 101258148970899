import React from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Send, Save, Trash2 } from 'lucide-react';
import { EmailEditor } from './EmailEditor';
import { EmailRecipients } from './EmailRecipients';
import { EmailAttachments } from './EmailAttachments';
import { EmailTemplates } from './EmailTemplates';

export default function EmailMain() {
  const [recipients, setRecipients] = React.useState<Array<{ email: string; name: string }>>([]);
  const [subject, setSubject] = React.useState('');
  const [content, setContent] = React.useState('');
  const [attachments, setAttachments] = React.useState<Array<{
    id: string;
    name: string;
    size: number;
    progress: number;
  }>>([]);

  const handleAddRecipient = (recipient: { email: string; name: string }) => {
    setRecipients((prev) => [...prev, recipient]);
  };

  const handleRemoveRecipient = (email: string) => {
    setRecipients((prev) => prev.filter((r) => r.email !== email));
  };

  const handleAddAttachments = (files: FileList) => {
    const newAttachments = Array.from(files).map((file) => ({
      id: Math.random().toString(36).substr(2, 9),
      name: file.name,
      size: file.size,
      progress: 0,
    }));
    setAttachments((prev) => [...prev, ...newAttachments]);
  };

  const handleRemoveAttachment = (id: string) => {
    setAttachments((prev) => prev.filter((a) => a.id !== id));
  };

  return (
    <div className="flex flex-col h-full gap-4">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-foreground">New Email</h1>
        <div className="flex gap-2">
          <EmailTemplates
            onSelectTemplate={(template) => {
              setSubject(template.subject);
              setContent(template.content);
            }}
            onSaveAsTemplate={(name, subject, content) => {
              // Implement save template logic
            }}
          />
          <Button variant="outline" size="sm">
            <Save className="h-4 w-4 mr-2" />
            Save Draft
          </Button>
          <Button variant="outline" size="sm" className="text-destructive">
            <Trash2 className="h-4 w-4 mr-2" />
            Discard
          </Button>
        </div>
      </div>

      {/* Email Form */}
      <div className="bg-card rounded-lg border border-border p-4 flex flex-col gap-4">
        <EmailRecipients
          recipients={recipients}
          onAddRecipient={handleAddRecipient}
          onRemoveRecipient={handleRemoveRecipient}
        />

        <Input
          type="text"
          placeholder="Subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          className="w-full"
        />

        <EmailEditor
          content={content}
          onChange={setContent}
        />

        <EmailAttachments
          attachments={attachments}
          onAddAttachments={handleAddAttachments}
          onRemoveAttachment={handleRemoveAttachment}
        />

        {/* Footer */}
        <div className="flex justify-end gap-2">
          <Button>
            <Send className="h-4 w-4 mr-2" />
            Send Email
          </Button>
        </div>
      </div>
    </div>
  );
}