import { useState, useEffect } from 'react'
import { Users, Info, Briefcase, BookOpen, Download } from 'lucide-react'
import { toast } from 'sonner'
import axios from '../api/axiosConfig'
import { cn } from '@/lib/utils'
import { DatePicker } from "@/components/ui/date/date-picker"

import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { Card, CardContent } from "@/components/ui/card"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { Loading } from "@/components/ui/loading"

import { Input } from "@/components/ui/input"
import { DealDrawer } from '../drawers/DealDrawer'
import { PieDonutChart } from "@/components/ui/charts/pie-donut"
import { DealSetupConfig } from '../configs/DealSetupConfig'

interface SetupData {
  id: number
  name: string
  cvr: string
  accounting_system: string
  htd: string
  ls: string
  aiia: string
  officebot: string
  corpay: string
  pleo: string
  follow_up: string
}

const acronyms = [
  {
    acronym: "HTD",
    name: "Holding til drift",
    description: "Invoice management system"
  },
  {
    acronym: "LS",
    name: "Leverandørservice",
    description: "Leverandørservice til automatiske betalinger"
  },
  {
    acronym: "Aiia",
    name: "Aiia Integration",
    description: "Banking data integration"
  },
  {
    acronym: "Officebot",
    name: "Officebot System",
    description: "Automation tool"
  },
  {
    acronym: "Corpay",
    name: "Corpay Integration",
    description: "Payment processing"
  },
  {
    acronym: "Pleo",
    name: "Pleo Integration",
    description: "Expense management"
  }
]

const BooleanSelector = ({ 
  value, 
  onChange, 
  options, 
  disabled 
}: { 
  value: string | null, 
  onChange: (value: string | null) => void, 
  options: string[], 
  disabled?: boolean 
}) => {
  // Check if current value is the last option in the array (completed state)
  const isComplete = value === options[options.length - 1]

  return (
    <Select
      value={value || options[0]}
      onValueChange={(v) => onChange(v)}
      disabled={disabled}
    >
      <SelectTrigger className={cn(
        "w-[200px]",
        isComplete && "bg-success/20 text-success-foreground",
        !isComplete && "bg-muted/20 text-muted-foreground"
      )}>
        <SelectValue defaultValue={value || options[0]} />
      </SelectTrigger>
      <SelectContent>
        {options.map(option => (
          <SelectItem key={option} value={option}>{option}</SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}

const getConfigKey = (acronym: string): keyof typeof DealSetupConfig => {
  const mapping: Record<string, keyof typeof DealSetupConfig> = {
    'htd': 'HTD',
    'ls': 'LS',
    'aiia': 'Aiia',
    'officebot': 'Officebot',
    'corpay': 'Corpay',
    'pleo': 'Pleo'
  }
  return mapping[acronym.toLowerCase()]
}

const Setup = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [setupData, setSetupData] = useState<SetupData[]>([])
  const [filteredData, setFilteredData] = useState<SetupData[]>([])
  const [searchQuery, setSearchQuery] = useState("")
  const [pageSize, setPageSize] = useState(15);
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null)

  useEffect(() => {
    fetchCustomers()
  }, [])

  const fetchCustomers = async () => {
    try {
      const response = await axios.get('/account/deals')
      const transformedData = response.data.map((deal: any) => ({
        id: deal.id,
        name: deal.name,
        cvr: deal.cvr,
        accounting_system: deal.data.regnskabsprogram || '',
        htd: deal.setup_data?.htd || DealSetupConfig.HTD[0],
        ls: deal.setup_data?.ls || DealSetupConfig.LS[0],
        aiia: deal.setup_data?.aiia || DealSetupConfig.Aiia[0],
        officebot: deal.setup_data?.officebot || (
          deal.data.regnskabsprogram === 'Economic' 
            ? DealSetupConfig.Officebot[0]  // "Ikke opsat" for Economic
            : DealSetupConfig.Officebot[1]  // "Skal ikke opsættes" for other systems
        ),
        corpay: deal.setup_data?.corpay || DealSetupConfig.Corpay[0],
        pleo: deal.setup_data?.pleo || DealSetupConfig.Pleo[0],
        follow_up: deal.setup_data?.follow_up || ''
      }))
      setSetupData(transformedData)
      setFilteredData(transformedData)
    } catch (error) {
      console.error('Error fetching setup data:', error)
      toast.error('Failed to fetch setup data')
    } finally {
      setLoading(false)
    }
  }

  const handleStatusUpdate = async (id: number, field: keyof SetupData, value: string) => {
    // Store old value for rollback if needed
    const oldValue = setupData.find(item => item.id === id)?.[field]
    
    console.log('Updating status:', { id, field, value, oldValue })
    
    // Optimistically update the UI
    setSetupData(prev => prev.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    ))
    setFilteredData(prev => prev.map(item => 
      item.id === id ? { ...item, [field]: value } : item
    ))
    
    try {
      const response = await axios.post(`/setup/data/${id}`, {
        field,
        value
      })
      console.log('Response:', response.data)
      
      toast.success('Status updated successfully')
    } catch (error) {
      // Rollback on error
      setSetupData(prev => prev.map(item => 
        item.id === id ? { ...item, [field]: oldValue } : item
      ))
      setFilteredData(prev => prev.map(item => 
        item.id === id ? { ...item, [field]: oldValue } : item
      ))
      
      console.error('Error updating status:', error)
      toast.error('Failed to update status')
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchQuery(value)
    if (!value) {
      setFilteredData(setupData)
      return
    }
    
    const filtered = setupData.filter(item => 
      item.name.toLowerCase().includes(value.toLowerCase()) ||
      item.cvr.toLowerCase().includes(value.toLowerCase()) ||
      item.accounting_system.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredData(filtered)
  }

  const getSystemStats = (systemKey: keyof SetupData) => {
    const total = setupData.length
    const configKey = getConfigKey(systemKey)
    // Get the last (completed) option for the given system
    const lastOption = DealSetupConfig[configKey]?.slice(-1)[0]
    // Count deals that have reached the final status
    const count = setupData.filter(item => item[systemKey] === lastOption).length

    return {
      count,
      chartData: [
        { browser: lastOption, visitors: count, fill: "hsl(var(--chart-1))" },
        { browser: "Ikke opsat", visitors: total - count, fill: "hsl(var(--chart-2))" }
      ]
    }
  }

  const columns: ColumnDef<SetupData>[] = [
    {
      key: 'name',
      title: 'Company',
      dataIndex: 'name',
      cell: ({ row }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
          onClick={() => {
            setSelectedDealId(row.original.id)
            setDealDrawerVisible(true)
          }}
        >
          <Briefcase className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.name}
          </span>
        </Badge>
      ),
      sortable: { enabled: true, type: 'string' }
    },
    {
      key: 'cvr',
      title: 'CVR',
      dataIndex: 'cvr',
      sortable: { enabled: false }
    },
    {
      key: 'accounting_system',
      title: 'Regnskabsprogram',
      dataIndex: 'accounting_system',
      filterable: true,
      filters: [
        { text: 'Economic', value: 'Economic' },
        { text: 'Dinero', value: 'Dinero' },
        { text: 'Billy', value: 'Billy' },
        { text: 'Uniconta', value: 'Uniconta' }
      ],
      onFilter: (value: string, record: SetupData) => record.accounting_system === value
    },
    {
      key: 'htd',
      title: 'HTD',
      dataIndex: 'htd',
      cell: ({ row }) => (
        <BooleanSelector 
          value={row.original.htd}
          onChange={(value) => handleStatusUpdate(row.original.id, 'htd', value || DealSetupConfig.HTD[0])}
          options={DealSetupConfig.HTD}
        />
      ),
      filterable: true,
      filters: DealSetupConfig.HTD.map(option => ({ text: option, value: option })),
      onFilter: (value: string, record: SetupData) => record.htd === value
    },
    {
      key: 'ls',
      title: 'LS',
      dataIndex: 'ls',
      cell: ({ row }) => (
        <BooleanSelector 
          value={row.original.ls}
          onChange={(value) => handleStatusUpdate(row.original.id, 'ls', value || DealSetupConfig.LS[0])}
          options={DealSetupConfig.LS}
        />
      ),
      filterable: true,
      filters: DealSetupConfig.LS.map(option => ({ text: option, value: option })),
      onFilter: (value: string, record: SetupData) => record.ls === value
    },
    {
      key: 'aiia',
      title: 'Aiia',
      dataIndex: 'aiia',
      cell: ({ row }) => (
        <BooleanSelector 
          value={row.original.aiia}
          onChange={(value) => handleStatusUpdate(row.original.id, 'aiia', value || DealSetupConfig.Aiia[0])}
          options={DealSetupConfig.Aiia}
        />
      ),
      filterable: true,
      filters: DealSetupConfig.Aiia.map(option => ({ text: option, value: option })),
      onFilter: (value: string, record: SetupData) => record.aiia === value
    },
    {
      key: 'officebot',
      title: 'Officebot',
      dataIndex: 'officebot',
      cell: ({ row }) => (
        <BooleanSelector 
          value={row.original.officebot}
          onChange={(value) => handleStatusUpdate(row.original.id, 'officebot', value || DealSetupConfig.Officebot[0])}
          options={DealSetupConfig.Officebot}
          disabled={row.original.accounting_system !== 'Economic'}
        />
      ),
      filterable: true,
      filters: DealSetupConfig.Officebot.map(option => ({ text: option, value: option })),
      onFilter: (value: string, record: SetupData) => record.officebot === value
    },
    {
      key: 'corpay',
      title: 'Corpay',
      dataIndex: 'corpay',
      cell: ({ row }) => (
        <BooleanSelector 
          value={row.original.corpay}
          onChange={(value) => handleStatusUpdate(row.original.id, 'corpay', value || DealSetupConfig.Corpay[0])}
          options={DealSetupConfig.Corpay}
        />
      ),
      filterable: true,
      filters: DealSetupConfig.Corpay.map(option => ({ text: option, value: option })),
      onFilter: (value: string, record: SetupData) => record.corpay === value
    },
    {
      key: 'pleo',
      title: 'Pleo',
      dataIndex: 'pleo',
      cell: ({ row }) => (
        <BooleanSelector 
          value={row.original.pleo}
          onChange={(value) => handleStatusUpdate(row.original.id, 'pleo', value || DealSetupConfig.Pleo[0])}
          options={DealSetupConfig.Pleo}
        />
      ),
      filterable: true,
      filters: DealSetupConfig.Pleo.map(option => ({ text: option, value: option })),
      onFilter: (value: string, record: SetupData) => record.pleo === value
    },
    {
      key: 'follow_up',
      title: 'Opfølgning',
      dataIndex: 'follow_up',
      sortable: { 
        enabled: true, 
        type: 'date'  // This will use the date sorting logic
      },
      cell: ({ row }) => (
        <DatePicker
          date={row.original.follow_up ? new Date(row.original.follow_up) : undefined}
          onSelect={(date) => handleStatusUpdate(row.original.id, 'follow_up', date ? date.toISOString() : '')}
          className="w-[180px]"
          variant="short"
        />
      )
    }
  ]

  const handleExportToExcel = async () => {
    try {
      const response = await axios.get('/setup/export', {
        responseType: 'blob'  // Important for handling file downloads
      })
      
      // Create a blob from the response data
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      })
      
      // Create a temporary URL for the blob
      const url = window.URL.createObjectURL(blob)
      
      // Create a temporary link element
      const link = document.createElement('a')
      link.href = url
      
      // Get filename from response headers or use default
      const contentDisposition = response.headers['content-disposition']
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'setup_overview.xlsx'
      
      link.setAttribute('download', filename)
      
      // Append link to body, click it, and remove it
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      
      // Clean up the URL
      window.URL.revokeObjectURL(url)
      
      toast.success('Export completed successfully')
    } catch (error) {
      console.error('Error exporting data:', error)
      toast.error('Failed to export data')
    }
  }

  if (loading) {
    return <Loading message="Loading setup data..." />
  }

  return (
    <div className="space-y-6 p-8">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold text-foreground">Setup Overview</h2>
      </div>

      <div className="space-y-4">
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
          {acronyms.map((item) => {
            const configKey = getConfigKey(item.acronym.toLowerCase())
            const stats = getSystemStats(item.acronym.toLowerCase() as keyof SetupData)
            return (
              <Card 
                key={item.acronym}
                className="p-2 relative"
              >
                <Button 
                  variant="ghost" 
                  size="icon"
                  className="absolute right-2 top-2 h-6 w-6"
                  onClick={() => window.open('https://example.com', '_blank')}
                >
                  <BookOpen className="h-3 w-3" />
                </Button>

                <CardContent className="flex flex-col items-center !p-2">
                  <div className="w-full mb-2">
                    <div className="flex items-center gap-1">
                      <h3 className="font-semibold text-sm">{item.acronym}</h3>
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger>
                            <Info className="h-3 w-3 text-muted-foreground" />
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>{item.description}</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                    <p className="text-xs text-muted-foreground">{item.name}</p>
                  </div>

                  <div className="w-full h-32">
                    <PieDonutChart
                      data={stats.chartData}
                      config={{
                        visitors: {
                          label: "Deals",
                        },
                      }}
                      centerLabel={{
                        value: stats.count,
                        label: DealSetupConfig[configKey].slice(-1)[0]
                      }}
                      innerRadius={35}
                      outerRadius={45}
                      centerLabelFontSize="text-2xl"
                      className="[&>*]:!border-0 [&>*]:!shadow-none [&_*]:!border-0"
                    />
                  </div>
                </CardContent>
              </Card>
            )
          })}
        </div>
      </div>

      <div className="w-full flex justify-between items-center mb-4">
        <Input 
          placeholder="Search companies..."
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm"
        />
        <Button 
          onClick={handleExportToExcel}
          className="flex items-center gap-2"
        >
          <Download className="h-4 w-4" />
          Export to Excel
        </Button>
      </div>

      <Table 
        data={filteredData.length > 0 ? filteredData : setupData}
        columns={columns}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        onPageSizeChange={setPageSize}
      />

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false)
          setSelectedDealId(null)
        }}
        dealId={selectedDealId}
      />
    </div>
  )
}

export default Setup