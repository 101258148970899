import { useState, useEffect } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'
import { useUsers } from '@/contexts/UserContext'

// Lucide Icons
import { Plus, Table2, LayoutGrid, Settings, Search, Filter } from 'lucide-react'

// Shadcn Components
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table'
import { ScrollArea } from '@/components/ui/scroll-area'

// Custom Components
import { TimeTrackerCell } from '@/components/TimeTrackerCell'
import CreateTaskModal from './modals/CreateTaskModal'
import BoardSettingsModal from './modals/BoardSettingsModal'
import { CustomBoardKanban } from './views/CustomBoardKanban'
import CustomBoardStatusSelector from './components/CustomBoardStatusSelector'
import DealLink from './components/DealLink'
import { UserAvatar } from '@/components/user-avatar'

// Types
import { Task } from '@/types/task'
import { Board, BoardConfig, BoardStatusOption, CustomBoardKanbanProps } from '@/types/custom-board'
import { CustomTask } from '@/types/custom-task'

export function CustomBoard() {
  const { boardId } = useParams()
  const [board, setBoard] = useState<Board | null>(null)
  const [tasks, setTasks] = useState<CustomTask[]>([])
  const [isCreateTaskModalVisible, setIsCreateTaskModalVisible] = useState(false)
  const [isBoardSettingsVisible, setIsBoardSettingsVisible] = useState(false)
  const [viewMode, setViewMode] = useState('kanban')
  const [loading, setLoading] = useState(true)
  const [statusOptions, setStatusOptions] = useState<BoardStatusOption[]>([])
  const [searchText, setSearchText] = useState('')
  const { users } = useUsers()

  useEffect(() => {
    if (boardId && boardId !== 'undefined') {
      fetchBoardConfig()
      fetchTasks()
    }
  }, [boardId])

  const fetchBoardConfig = async () => {
    try {
      const boardResponse = await axios.get(`/custom-board/boards/${boardId}`)
      const boardData: Board = {
        ...boardResponse.data,
        is_public: boardResponse.data.is_public ?? false,
        user_access: boardResponse.data.user_access ?? []
      }
      setBoard(boardData)
      const source = boardData.board_config.kanban_source || 'progress'
      const options = source === 'progress'
        ? boardData.board_config.progress_status_options
        : boardData.board_config.board_status_options
      setStatusOptions(options || [])
    } catch (error) {
      console.error('Error fetching board config:', error)
      toast.error('Failed to load board configuration')
    }
  }

  const fetchTasks = async () => {
    try {
      setLoading(true)
      const tasksResponse = await axios.get(`/custom-board/boards/${boardId}/tasks`)
      setTasks(tasksResponse.data)
    } catch (error) {
      console.error('Error fetching tasks:', error)
      toast.error('Failed to load tasks')
    } finally {
      setLoading(false)
    }
  }

  const handleCreateTask = async (values: any) => {
    try {
      const initialProgressStatus = board?.board_config.progress_status_options[0].value

      const formattedValues = {
        ...values,
        assigned_users: values.assigned_users?.map((id: string | number) => String(id)),
        progress_status: board?.board_config.kanban_source === 'progress' 
          ? initialProgressStatus 
          : 'not_started',
      }

      const response = await axios.post(`/custom-board/boards/${boardId}/tasks`, formattedValues)
      
      if (response.status === 200) {
        toast.success('Task created successfully')
        setTasks(prev => [...prev, response.data])
        setIsCreateTaskModalVisible(false)
      }
    } catch (error) {
      console.error('Error creating task:', error)
      toast.error('Failed to create task')
    }
  }

  const handleTaskUpdate = (taskId: number, updatedData: Partial<Task> | null) => {
    if (updatedData === null) {
      // Task was deleted
      setTasks(prevTasks => prevTasks.filter(t => t.id !== taskId))
      return
    }

    // Handle regular updates
    setTasks(prevTasks => 
      prevTasks.map(task => 
        task.id === taskId 
          ? { ...task, ...updatedData }
          : task
      )
    )
  }

  const handleBoardUpdate = (updatedBoard: Board) => {
    setBoard(updatedBoard)
  }

  const columns = [
    {
      title: 'Title',
      key: 'title',
      className: 'w-[20%]',
      render: (task: Task) => task.title,
    },
    {
      title: 'Description',
      key: 'description',
      className: 'w-[30%]',
      render: (task: Task) => (
        <div className="max-w-[400px] truncate">
          {task.description || '-'}
        </div>
      ),
    },
    {
      title: 'Assignees',
      key: 'assigned_users',
      className: 'w-[15%]',
      render: (task: Task) => (
        <div className="flex flex-wrap gap-1">
          {task.assigned_users?.map(userId => {
            const user = users.find(u => u.user_id === userId)
            return user ? (
              <UserAvatar
                key={userId}
                user={user}
                className="h-6 w-6"
              />
            ) : null
          })}
        </div>
      ),
    },
    // Add other columns as needed
  ]

  return (
    <div className="p-4 pt-0 min-h-screen">
      <h1 className="text-2xl text-primary mb-6">{board?.name}</h1>
      
      <Tabs 
        defaultValue={viewMode} 
        value={viewMode} 
        onValueChange={setViewMode}
        className="mb-6"
      >
        <TabsList>
          <TabsTrigger value="kanban">
            <LayoutGrid className="h-4 w-4 mr-2" />
            Kanban
          </TabsTrigger>
          <TabsTrigger value="table">
            <Table2 className="h-4 w-4 mr-2" />
            Table
          </TabsTrigger>
        </TabsList>

        <div className="flex justify-between items-center mb-6 mt-6">
          <div className="flex-1 flex gap-2">
            <div className="relative w-[300px]">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Search tasks..."
                value={searchText}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchText(e.target.value)}
                className="pl-8"
              />
            </div>
            <Button variant="outline" size="icon">
              <Filter className="h-4 w-4" />
            </Button>
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => setIsCreateTaskModalVisible(true)}
            >
              <Plus className="h-4 w-4 mr-2" />
              New Task
            </Button>

            <Button
              variant="outline"
              onClick={() => setIsBoardSettingsVisible(true)}
            >
              <Settings className="h-4 w-4 mr-2" />
              Settings
            </Button>
          </div>
        </div>

        {!loading && (
          <TabsContent value="kanban">
            {board && (
              <CustomBoardKanban
                tasks={tasks}
                statusOptions={statusOptions}
                onTaskUpdate={handleTaskUpdate}
                kanbanSource={board.board_config.kanban_source}
                board={board}
              />
            )}
          </TabsContent>
        )}

        {!loading && (
          <TabsContent value="table">
            <ScrollArea className="h-[calc(100vh-280px)]">
              <Table>
                <TableHeader>
                  <TableRow>
                    {columns.map((column) => (
                      <TableHead key={column.key} className={column.className}>
                        {column.title}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {tasks.map((task) => (
                    <TableRow key={task.id}>
                      {columns.map((column) => (
                        <TableCell key={`${task.id}-${column.key}`}>
                          {column.render(task)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </ScrollArea>
          </TabsContent>
        )}
      </Tabs>

      {loading && (
        <div className="flex items-center justify-center h-[400px]">
          Loading...
        </div>
      )}

      <CreateTaskModal
        open={isCreateTaskModalVisible}
        onClose={() => setIsCreateTaskModalVisible(false)}
        onSubmit={handleCreateTask}
        board={board || undefined}
      />

      <BoardSettingsModal
        open={isBoardSettingsVisible}
        onClose={() => setIsBoardSettingsVisible(false)}
        board={board!}
        onBoardUpdate={handleBoardUpdate}
      />

      <Outlet />
    </div>
  )
}