import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'

export interface Task {
  workload: number
  description: string
  deal_name: string
}

export interface CellData {
  workload?: number
  tasks?: Task[]
  capacity?: number
}

export interface User {
  user_id: number
  name: string
  surname: string
  settings?: {
    avatar_color?: string
  }
}

export interface TaskRecord {
  user: User
  task_details: Record<string, {
    assigned_tasks: number
    total_workload: number
    daily_workload: Record<string, CellData>
  }>
  daily_capacity: Record<string, number>
}

export interface ExpandedTableRecord {
  key: string
  task_type: string
  assigned_tasks: number
  total_workload: number
  daily_workload: Record<string, CellData>
  daily_capacity: Record<string, number>
}

export const TASK_TYPE_DISPLAY_NAMES = {
  TaskBogforing: 'Bogføring',
  TaskLon: 'Løn',
  TaskMoms: 'Moms',
  TaskArsafslutning: 'Arsafslutning',
  activity: 'Aktiviteter',
  other: 'Andet'
} as const

export type TaskType = keyof typeof TASK_TYPE_DISPLAY_NAMES

export const convertMinutesToHours = (workload: number): string | number => {
  return workload ? Number((workload / 60).toFixed(1)) : ''
}

export const getWorkloadColor = (workload: number, capacity: number = 0, isWeekend: boolean = false): string => {
  if (isWeekend) return 'hsl(var(--background))' // Weekend background
  if (!workload || !capacity) return 'hsl(var(--empty-cell))' // Empty cells with custom color
  
  const hours = workload / 60
  const percentage = hours / (capacity / 60)

  // Define color ranges using CSS variables
  if (percentage > 1) {
    return 'hsl(var(--destructive))' // Over capacity - red
  } else if (percentage > 0.8) {
    return '#6BBF59' // High workload - darker green
  } else if (percentage > 0.6) {
    return '#A3D4A1' // Medium workload - medium green
  } else if (percentage > 0.3) {
    return '#D4EDDA' // Light workload - light green
  }
  
  return '#E8F5E9' // Very light workload - very light green
}