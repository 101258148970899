import axios from '../api/axiosConfig';

export const permissionLevels = {
  owner: ['owner'],
  admin: ['owner', 'admin'],
  accounting: ['owner', 'admin', 'accounting'],
  payroll: ['owner', 'admin', 'payroll'],
  sales: ['owner', 'admin', 'sales'],
  user: ['owner', 'admin', 'user', 'accounting', 'payroll', 'sales']
};

export const hasRequiredPermission = (userPermissions, requiredPermission) => {
  //console.log('Checking permissions. User permissions:', userPermissions);
  //console.log('Required permission:', requiredPermission);

  // Convert userPermissions to an array if it's a string
  const permissionsArray = Array.isArray(userPermissions) ? userPermissions : [userPermissions];

  const result = permissionsArray.some(userPerm => permissionLevels[requiredPermission].includes(userPerm));
  //console.log('Permission check result:', result);
  return result;
};

// Moving user-related functions here
export const fetchUsersByPermission = async () => {
  try {
    const response = await axios.get('/user/users-by-permission');
    return response.data;
  } catch (error) {
    console.error('Error fetching users by permission:', error);
    return { owner: [], admin: [], accounting: [], payroll: [], sales: [], user: [] };
  }
};

export const getUserOptions = (users) => {
  return users.map(user => ({
    value: user.user_id,
    label: user.full_name,
  }));
};