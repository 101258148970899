import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerFooter,
} from "@/components/ui/drawer";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Badge } from "@/components/ui/badge";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import {
  Mail,
  Phone,
  RefreshCw,
  MessageSquare,
  FileText,
  Download,
  Ban,
  Edit,
  Trash,
  ChevronRight,
} from 'lucide-react';
import axios from '../api/axiosConfig';
import dayjs from 'dayjs';
import UserAvatar from '../components/user-avatar';
import { ProgressSteps } from "@/components/ui/progress-steps";
import { toast } from "sonner"

const commentFormSchema = z.object({
  comment: z.string().min(1, "Comment is required"),
});

type CommentFormValues = z.infer<typeof commentFormSchema>;

interface Comment {
  id: number;
  comment: string;
  created_at: string;
  user: User;
}

interface Invoice {
  invoice_number: string;
  amount: number;
  due_date: string;
  days_overdue: number;
  farpay_status: string;
  blacklisted: boolean;
}

interface Case {
  id: number;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  current_stage: string;
  next_action: string;
  is_active: boolean;
  total_amount: number;
  invoice_count: number;
  invoice_number: string;
  amount: number;
  due_date: string;
  days_overdue: number;
  invoices: Invoice[];
  comments: Comment[];
}

interface OverdueAccountsProps {
  allUsers: User[];
  currentUser: User;
}

interface User {
  user_id: number;
  name: string;
  surname?: string;
  email: string;
  permissions: string;
}

function OverdueAccounts({ allUsers, currentUser }: OverdueAccountsProps) {
  // State declarations
  const [loading, setLoading] = useState(false);
  const [syncingData, setSyncingData] = useState(false);
  const [cases, setCases] = useState<Case[]>([]);
  const [showResolved, setShowResolved] = useState(false);
  const [selectedCase, setSelectedCase] = useState<Case | null>(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [editingComment, setEditingComment] = useState<Comment | null>(null);
  const [blacklistDialogOpen, setBlacklistDialogOpen] = useState(false);
  const [selectedInvoiceForBlacklist, setSelectedInvoiceForBlacklist] = useState<string | null>(null);
  const commentForm = useForm<CommentFormValues>({
    resolver: zodResolver(commentFormSchema),
    defaultValues: {
      comment: "",
    },
  });
  const editForm = useForm<CommentFormValues>({
    resolver: zodResolver(commentFormSchema),
    defaultValues: {
      comment: "",
    },
  });

  useEffect(() => {
    fetchCollectionCases();
  }, [showResolved]);

  const fetchCollectionCases = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/invoice/collection-cases?show_resolved=${showResolved}`);
      setCases(response.data.cases);
    } catch (error) {
      toast.error('Failed to fetch collection cases');
    } finally {
      setLoading(false);
    }
  };

  const syncOverdueInvoices = async () => {
    try {
      setLoading(true);
      const response = await axios.post('/invoice/sync-overdue-invoices');
      toast.success(`Sync completed, ${response.data.new_cases} new cases, ${response.data.new_invoices} new invoices`);
      fetchCollectionCases();
    } catch (error) {
      toast.error('Failed to sync overdue invoices');
    } finally {
      setLoading(false);
    }
  };

  const handlePreviewPdf = async (invoiceNumber: string) => {
    try {
      const response = await axios.get(`/invoice/booked-invoices/${invoiceNumber}/pdf`, {
        responseType: 'blob'
      });
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdfBlob);
      setPdfUrl(url);
    } catch (error) {
      toast.error('Failed to fetch invoice PDF');
    }
  };

  const handleDownloadPdf = () => {
    if (pdfUrl) {
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `invoice.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const updateCaseStage = async (caseId: number, nextAction: string) => {
    try {
      const response = await axios.post(`/invoice/collection-cases/${caseId}/update-stage`, {
        stage: nextAction
      });
      toast.success('Case stage updated successfully');
      fetchCollectionCases();
    } catch (error) {
      toast.error('Failed to update case stage');
    }
  };

  const addComment = async (values: { comment: string }) => {
    try {
      const response = await axios.post(`/invoice/collection-cases/${selectedCase?.id}/comments`, {
        comment: values.comment
      });
      toast.success('Comment added');
      commentForm.reset();
      setSelectedCase(prev => ({
        ...prev!,
        comments: [...prev!.comments, response.data.comment]
      }));
      fetchCollectionCases();
    } catch (error) {
      toast.error('Failed to add comment');
    }
  };

  const handleEditComment = async (values: { comment: string }) => {
    try {
      const response = await axios.put(`/invoice/collection-cases/comments/${editingComment?.id}`, {
        comment: values.comment
      });
      setSelectedCase(prev => ({
        ...prev!,
        comments: prev!.comments.map(comment =>
          comment.id === editingComment?.id ? response.data.comment : comment
        )
      }));
      setEditingComment(null);
      editForm.reset();
      toast.success('Comment updated successfully');
    } catch (error) {
      toast.error('Failed to update comment');
    }
  };

  const handleDeleteComment = async (commentId: number) => {
    try {
      await axios.delete(`/invoice/collection-cases/comments/${commentId}`);
      setSelectedCase(prev => ({
        ...prev!,
        comments: prev!.comments.filter(comment => comment.id !== commentId)
      }));
      toast.success('Comment deleted successfully');
    } catch (error) {
      toast.error('Failed to delete comment');
    }
  };

  const getCurrentUser = () => {
    try {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      return user;
    } catch (error) {
      console.error('Error parsing user from localStorage:', error);
      return null;
    }
  };

  return (
    <div className="space-y-6 w-full">
      <h2 className="text-2xl font-semibold">Overdue Accounts</h2>
      <Card className="w-full">
        <CardContent className="p-0">
          <div className="flex justify-between items-center p-4 border-b">
            <Button onClick={syncOverdueInvoices} disabled={loading}>
              {loading ? (
                <RefreshCw className="mr-2 animate-spin" />
              ) : (
                <RefreshCw className="mr-2" />
              )}
              Sync Overdue Invoices
            </Button>
            <Button onClick={() => setShowResolved(!showResolved)}>
              {showResolved ? 'Hide Resolved' : 'Show Resolved'}
            </Button>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Invoice Number</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Due Date</TableHead>
                <TableHead>Days Overdue</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {cases.map((caseData) => (
                <TableRow key={caseData.id}>
                  <TableCell>{caseData.invoice_number}</TableCell>
                  <TableCell>{caseData.amount}</TableCell>
                  <TableCell>{dayjs(caseData.due_date).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{caseData.days_overdue}</TableCell>
                  <TableCell>
                    <Button onClick={() => handlePreviewPdf(caseData.invoice_number)}>
                      <FileText className="mr-2" /> Preview
                    </Button>
                    <Button onClick={handleDownloadPdf}>
                      <Download className="mr-2" /> Download
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Dialog open={!!pdfUrl} onOpenChange={() => setPdfUrl(null)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Invoice PDF Preview</DialogTitle>
          </DialogHeader>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              style={{ width: '100%', height: '70vh' }}
              title="Invoice PDF Preview"
            />
          )}
        </DialogContent>
      </Dialog>
      <Drawer open={drawerVisible} onOpenChange={() => setDrawerVisible(false)}>
        <DrawerHeader>
          <DrawerTitle>Case Notes - {selectedCase?.customer_name}</DrawerTitle>
        </DrawerHeader>
        <div className="case-comments">
          {selectedCase?.comments.map((comment) => (
            <div key={comment.id} className="comment">
              <div className="comment-header">
                <UserAvatar user={comment.user} size={32} />
                <div className="comment-user-info">
                  <strong>{comment.user?.name || 'Unknown User'}</strong>
                  <span>{dayjs(comment.created_at).format('DD/MM/YYYY HH:mm')}</span>
                </div>
                {comment.user?.user_id === getCurrentUser()?.user_id && (
                  <div className="comment-actions">
                    <Button onClick={() => {
                      setEditingComment(comment);
                      editForm.setValue('comment', comment.comment);
                    }}>Edit</Button>
                    <AlertDialog>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>Delete Comment</AlertDialogTitle>
                          <AlertDialogDescription>
                            Are you sure you want to delete this comment?
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction onClick={() => handleDeleteComment(comment.id)}>
                            Delete
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                )}
              </div>
              <div className="comment-content">
                {editingComment?.id === comment.id ? (
                  <form onSubmit={editForm.handleSubmit(handleEditComment)}>
                    <textarea {...editForm.register('comment')} />
                    <button type="submit">Save</button>
                    <button type="button" onClick={() => setEditingComment(null)}>Cancel</button>
                  </form>
                ) : (
                  <p>{comment.comment}</p>
                )}
              </div>
            </div>
          ))}
        </div>
        <form onSubmit={commentForm.handleSubmit(addComment)}>
          <textarea {...commentForm.register('comment')} placeholder="Add a comment..." />
          <button type="submit">Add Comment</button>
        </form>
      </Drawer>
    </div>
  );
}

export default OverdueAccounts;