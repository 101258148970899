import * as React from "react"
import { cn } from "@/lib/utils"
import { ScrollArea } from "@/components/ui/scroll-area"

export const KANBAN_COLUMN_WIDTH = 315; // Set your desired fixed width here

const KanbanCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "rounded-xl border border-border bg-card text-card-foreground shadow",
      "flex flex-col",
      "h-full",
      "flex-shrink-0",
      className
    )}
    style={{ width: `${KANBAN_COLUMN_WIDTH}px` }}
    {...props}
  />
))
KanbanCard.displayName = "KanbanCard"

const KanbanCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    color?: string;
  }
>(({ className, color, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "flex items-center justify-between p-4 rounded-t-xl text-white font-semibold",
      "flex-shrink-0",
      className
    )}
    style={{ backgroundColor: color }}
    {...props}
  />
))
KanbanCardHeader.displayName = "KanbanCardHeader"

const KanbanCardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div className="flex-1 min-h-0">
    <ScrollArea
      ref={ref}
      className={cn(
        "h-full",
        className
      )}
    >
      <div className="p-4" {...props} />
    </ScrollArea>
  </div>
))
KanbanCardContent.displayName = "KanbanCardContent"

export { KanbanCard, KanbanCardHeader, KanbanCardContent }