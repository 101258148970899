import { useCallback, useState, useEffect } from 'react';
import ReactFlow, {
  Background,
  Controls,
  Panel,
  useNodesState,
  useEdgesState,
  Connection,
  Edge,
  addEdge,
  Node,
} from 'reactflow';
import { Button } from "@/components/ui/button";
import { Plus, Save } from "lucide-react";
import { FlowNode, FlowEdge, NodeType } from './flows';
import 'reactflow/dist/style.css';
import { NodeConfigDialog } from './onboarding/nodes/NodeConfigDialog';
import FormNode from './onboarding/nodes/FormNode';

const nodeTypes = {
  form: FormNode,
};

export interface FlowEditorProps {
  initialNodes?: FlowNode[];
  initialEdges?: FlowEdge[];
  onSave?: (nodes: FlowNode[], edges: FlowEdge[]) => void;
  readOnly?: boolean;
  startNodeId?: string;
}

export function FlowEditor({
  initialNodes = [],
  initialEdges = [],
  onSave,
  readOnly = false,
  startNodeId,
}: FlowEditorProps) {
  const [nodes, setNodes, onNodesChange] = useNodesState<FlowNode['data']>(initialNodes as Node[]);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges as Edge[]);
  const [configDialogOpen, setConfigDialogOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState<FlowNode | null>(null);

  const onConnect = useCallback(
    (params: Connection | Edge) => setEdges((prevEdges) => addEdge(params, prevEdges)),
    [setEdges],
  );

  const handleNodeClick = (event: React.MouseEvent, node: Node) => {
    setSelectedNode(node as FlowNode);
    setConfigDialogOpen(true);
  };

  const handleAddNode = () => {
    const newNode: FlowNode = {
      id: crypto.randomUUID(),
      type: 'form',
      position: { x: 100, y: 100 },
      data: {
        label: 'New Node',
        config: {
          title: 'New Node',
          rows: [],
          rules: [],
          defaultNextNodeId: undefined
        }
      }
    };
    setNodes(nodes => [...nodes, newNode]);
  };

  const handleDeleteNode = useCallback((nodeId: string) => {
    setNodes(nodes => nodes.filter(n => n.id !== nodeId));
    setEdges(edges => edges.filter(e => e.source !== nodeId && e.target !== nodeId));
    if (selectedNode?.id === nodeId) {
      setConfigDialogOpen(false);
      setSelectedNode(null);
    }
  }, [selectedNode, setNodes, setEdges]);

  const handleDuplicateNode = useCallback((nodeId: string) => {
    const nodeToClone = nodes.find(n => n.id === nodeId);
    if (nodeToClone) {
      const newNodeId = crypto.randomUUID();
      const newNode: FlowNode = {
        ...nodeToClone,
        id: newNodeId,
        type: nodeToClone.type as NodeType,
        position: {
          x: nodeToClone.position.x + 50,
          y: nodeToClone.position.y + 50
        },
        data: {
          ...nodeToClone.data,
          label: `${nodeToClone.data.label} (Copy)`,
          config: {
            ...nodeToClone.data.config,
            title: `${nodeToClone.data.config.title} (Copy)`,
            header: nodeToClone.data.config.header 
              ? { ...nodeToClone.data.config.header, id: crypto.randomUUID() }
              : undefined,
            rows: nodeToClone.data.config.rows.map(row => ({
              ...row,
              id: crypto.randomUUID(),
              elements: row.elements.map(el => ({
                ...el,
                id: crypto.randomUUID()
              }))
            }))
          }
        }
      };
      setNodes(nodes => [...nodes, newNode]);
    }
  }, [nodes, setNodes]);

  useEffect(() => {
    if (startNodeId) {
      setNodes(nodes => nodes.map(node => ({
        ...node,
        className: node.id === startNodeId ? 'border-primary border-2' : undefined
      })));
    }
  }, [startNodeId, setNodes]);

  return (
    <>
      <div className="h-screen w-full border rounded-lg bg-background">
        <ReactFlow
          nodeTypes={nodeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeClick={handleNodeClick}
          fitView
          className="bg-background"
        >
          <Background className="bg-muted" />
          <Controls className="bg-background border rounded-md" />
          
          <Panel position="top-right" className="space-x-2">
            {!readOnly && (
              <>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={handleAddNode}
                >
                  <Plus className="h-4 w-4 mr-2" />
                  Add Node
                </Button>
                <Button
                  variant="default"
                  size="sm"
                  onClick={() => onSave?.(nodes as FlowNode[], edges as FlowEdge[])}
                >
                  <Save className="h-4 w-4 mr-2" />
                  Save Flow
                </Button>
              </>
            )}
          </Panel>
        </ReactFlow>
      </div>

      <NodeConfigDialog
        open={configDialogOpen}
        onClose={() => setConfigDialogOpen(false)}
        initialConfig={selectedNode?.data.config}
        onSave={(config) => {
          if (selectedNode) {
            setNodes(nodes.map(node =>
              node.id === selectedNode.id
                ? { ...node, data: { ...node.data, config, label: config.title } }
                : node
            ));
          }
          setConfigDialogOpen(false);
        }}
        onDelete={selectedNode ? () => handleDeleteNode(selectedNode.id) : undefined}
        onDuplicate={selectedNode ? () => handleDuplicateNode(selectedNode.id) : undefined}
        selectedNode={selectedNode}
        nodes={nodes}
        edges={edges}
        setNodes={setNodes}
      />
    </>
  );
}