import React, { useState, useEffect } from 'react';
import { cn } from "@/lib/utils";
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { format } from "date-fns";
import { 
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerDescription,
  DrawerFooter,
} from "@/components/ui/drawer";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Calendar as CalendarIcon, Check, X, AlertTriangle } from 'lucide-react';
import { dealDataConfig } from '@/configs/DealDataConfig';
import { dealSoftwareConfig } from '@/configs/DealSoftwareConfig';
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { 
  Receipt, 
  Wallet, 
  AppWindow, 
  FileText,
  Building2
} from 'lucide-react';

interface DealDrawerProps {
  visible: boolean;
  onClose: () => void;
  dealId: number | null;
}

interface DealData {
  [key: string]: any;
}

interface Software {
  [key: string]: any;
}

interface Deal {
  id: number;
  name: string;
  data?: DealData;
  software?: Software;
  [key: string]: any;
}

interface FieldConfig {
  key: string;
  label: string;
  type: 'text' | 'number' | 'dropdown' | 'boolean' | 'date';
  editable?: boolean;
  options?: Array<{
    value: string;
    label: string;
    color: string;
  }>;
}

interface DataGroup {
  title: string;
  icon: React.ReactNode;
  fields: Array<any>;
}

const dataGroups: DataGroup[] = [
  {
    title: "Company Info",
    icon: <Building2 className="h-5 w-5 text-primary" />,
    fields: [
      'data.virksomhedsform',
      'data.aktiv_kunde',
      'data.onboarding_dato',
      'data.hvidvask_compliant',
      'data.sidste_fakturadato',
      'main_mailbox',
      'corpay_mailbox',
      'description',
      'data.onboarding_opgaver',
      'data.deal_source'
    ]
  },
  {
    title: "Accounting",
    icon: <Receipt className="h-5 w-5 text-primary" />,
    fields: [
      'data.value',
      'data.momsfrekvens',
      'data.bogføringsfrekvens',
      'data.regnskabsprogram',
      'data.seneste_momsopgørelse',
      'data.ansvarlig_bogholder',
      'data.ansvarlig_revisor_for_årsregnskab',
      'data.hvem_er_ansvarlig_for_årsregnskab',
      'data.hvornår_slutter_næste_regnskabsår',
      'data.kan_bankkonto_integreres_i_bogføringssystem',
      'data.kundenummer_i_economic'
    ]
  },
  {
    title: "Payroll",
    icon: <Wallet className="h-5 w-5 text-primary" />,
    fields: [
      'data.lønfrekvens',
      'data.lønansvarlig',
      'data.ansvarlig_lønbogholder',
      'data.antal_medarbejdere_til_lønhandtering',
      'software.lønprogram'
    ]
  },
  {
    title: "Software",
    icon: <AppWindow className="h-5 w-5 text-primary" />,
    fields: [
      'software.lagerstyring',
      'software.kreditorstyring',
      'software.betalingsgateway',
      'software.abonnementsstyring',
      'software.debitorstyring_kredit',
      'software.debitorstyring_kontant',
      'software.projekt_og_tidsstyring',
      'software.kreditorstyring_kvitteringer',
      'software.andre_softwares'
    ]
  }
];

const DealDrawer: React.FC<DealDrawerProps> = ({ visible, onClose, dealId }) => {
  const [deal, setDeal] = useState<Deal | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingFields, setEditingFields] = useState<Record<string, boolean>>({});
  const [changes, setChanges] = useState<Record<string, any>>({});
  const [confirmVisible, setConfirmVisible] = useState(false);

  useEffect(() => {
    if (dealId && visible) {
      fetchDealData();
    }
  }, [dealId, visible]);

  const fetchDealData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/account/deals/${dealId}`);
      setDeal(response.data);
    } catch (error) {
      toast.error('Error fetching deal data');
    } finally {
      setLoading(false);
    }
  };

  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((prev, curr) => {
      return prev ? prev[curr] : undefined;
    }, obj);
  };

  const handleEdit = (key: string, value: any) => {
    setEditingFields(prev => ({ ...prev, [key]: true }));
    
    if (key.includes('.')) {
      const [mainKey, nestedKey] = key.split('.');
      if (mainKey === 'data') {
        setChanges(prev => ({
          ...prev,
          [key]: value
        }));
      } else if (mainKey === 'software') {
        setChanges(prev => ({
          ...prev,
          software: {
            ...(prev.software || {}),
            [nestedKey]: value
          }
        }));
      }
    } else {
      setChanges(prev => ({
        ...prev,
        [key]: value
      }));
    }
  };

  const handleSoftwareEdit = (key: string, value: any) => {
    setChanges(prev => ({
      ...prev,
      software: {
        ...(prev.software || {}),
        [key]: value
      }
    }));
  };

  const handleSave = async () => {
    setConfirmVisible(true);
  };

  const confirmSave = async () => {
    try {
      const updatedChanges: Record<string, any> = {};
      
      Object.entries(changes).forEach(([key, value]) => {
        if (key.startsWith('data.')) {
          if (!updatedChanges.data) {
            updatedChanges.data = { ...(deal?.data || {}) };
          }
          const nestedKey = key.split('.')[1];
          updatedChanges.data[nestedKey] = value;
        } else if (key.startsWith('software.')) {
          if (!updatedChanges.software) {
            updatedChanges.software = { ...(deal?.software || {}) };
          }
          const softwareKey = key.split('.')[1];
          updatedChanges.software[softwareKey] = value;
        } else {
          updatedChanges[key] = value;
        }
      });

      await axios.patch(`/account/deals/${dealId}`, updatedChanges);
      
      setDeal(prev => prev ? {
        ...prev,
        ...(updatedChanges.data ? { data: updatedChanges.data } : {}),
        ...(updatedChanges.software ? { software: updatedChanges.software } : {}),
        ...updatedChanges
      } : null);
      
      setEditingFields({});
      setChanges({});
      setConfirmVisible(false);
      toast.success('Changes saved successfully');
    } catch (error) {
      console.error('Error updating deal data:', error);
      toast.error('Failed to save changes');
    }
  };

  const handleCloseDrawer = () => {
    setEditingFields({});
    setChanges({});
    onClose();
  };

  const renderEditableField = (fieldConfig: FieldConfig, value: any) => {
    const currentValue = changes[fieldConfig.key] ?? value;

    if (!fieldConfig.editable) {
      return value?.toString() || 'N/A';
    }

    let config = fieldConfig;
    if (fieldConfig.key.startsWith('software.')) {
      const softwareKey = fieldConfig.key.split('.')[1];
      const softwareConfig = dealSoftwareConfig.find(c => c.key === softwareKey);
      if (softwareConfig) {
        config = { ...fieldConfig, options: softwareConfig.options };
      }
    }

    switch (config.type) {
      case 'text':
      case 'number':
        return (
          <Input
            type={config.type}
            value={currentValue}
            onChange={(e) => handleEdit(config.key, e.target.value)}
            className="h-9 px-2"
          />
        );

      case 'dropdown':
        return (
          <Select
            value={currentValue}
            onValueChange={(value) => handleEdit(config.key, value)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select option" />
            </SelectTrigger>
            <SelectContent>
              {(config.options || []).map((option) => (
                <SelectItem
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );

      case 'boolean':
        return (
          <Switch
            checked={currentValue}
            onCheckedChange={(checked) => handleEdit(config.key, checked)}
            className="data-[state=checked]:bg-primary"
          />
        );

      case 'date':
        return (
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !currentValue && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {currentValue ? format(new Date(currentValue), 'PPP') : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                mode="single"
                selected={currentValue ? new Date(currentValue) : undefined}
                onSelect={(date) => handleEdit(config.key, date?.toISOString())}
                initialFocus
              />
            </PopoverContent>
          </Popover>
        );

      default:
        return currentValue?.toString() || 'N/A';
    }
  };

  const renderGroupedData = () => {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 px-4">
        {dataGroups.map((group) => (
          <div key={group.title} className="flex flex-col">
            <div className="flex items-center gap-2 p-2 border-b">
              {group.icon}
              <h3 className="font-semibold">{group.title}</h3>
            </div>
            <ScrollArea className="h-[calc(85vh-220px)] border rounded-b-md">
              <div className="p-4 space-y-4">
                {group.fields.map((fieldKey) => {
                  const config = fieldKey.startsWith('software.') 
                    ? dealSoftwareConfig.find(c => `software.${c.key}` === fieldKey)
                    : dealDataConfig.find(c => c.key === fieldKey);
                  
                  if (!config) return null;

                  const value = getNestedValue(deal, fieldKey);
                  
                  return (
                    <div key={fieldKey} className="space-y-2">
                      <div className="text-sm font-medium text-muted-foreground">
                        {config.label}
                      </div>
                      <div className="min-h-[32px]">
                        {renderEditableField(config, value)}
                      </div>
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        ))}
      </div>
    );
  };

  const renderChanges = () => {
    return Object.entries(changes).map(([key, newValue]) => {
      const fieldConfig = dealDataConfig.find(config => config.key === key);
      
      if (!fieldConfig) {
        console.warn(`No configuration found for key: ${key}`);
        return null;
      }

      const originalValue = getNestedValue(deal, key);
      
      if (fieldConfig.type === 'dropdown') {
        const getOptionColor = (value: string) => {
          const option = fieldConfig.options?.find(opt => opt.value === value);
          return option?.color || '#d9d9d9';
        };
        
        return (
          <div key={key} className="flex items-center gap-2 py-2">
            <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
            <span 
              className={cn(
                "px-2 py-1 rounded",
                getOptionColor(originalValue) === '#d9d9d9' ? 'text-foreground' : 'text-white'
              )}
              style={{ backgroundColor: getOptionColor(originalValue) }}
            >
              {originalValue || 'N/A'}
            </span>
            <span className="text-muted-foreground mx-2">→</span>
            <span 
              className={cn(
                "px-2 py-1 rounded",
                getOptionColor(newValue) === '#d9d9d9' ? 'text-foreground' : 'text-white'
              )}
              style={{ backgroundColor: getOptionColor(newValue) }}
            >
              {newValue}
            </span>
          </div>
        );
      }
      
      return (
        <div key={key} className="flex items-center gap-2 py-2">
          <span className="font-medium min-w-[200px]">{fieldConfig.label}:</span>
          <span className="px-2 py-1 bg-muted rounded">{originalValue || 'N/A'}</span>
          <span className="text-muted-foreground mx-2">→</span>
          <span className="px-2 py-1 bg-muted rounded">{newValue}</span>
        </div>
      );
    }).filter(Boolean);
  };

  const renderSoftwareField = (fieldConfig: FieldConfig) => {
    const currentValue = changes.software?.[fieldConfig.key] ?? deal?.software?.[fieldConfig.key];

    if (fieldConfig.type === 'dropdown') {
      return (
        <Select
          value={currentValue}
          onValueChange={(value) => handleSoftwareEdit(fieldConfig.key, value)}
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select option" />
          </SelectTrigger>
          <SelectContent>
            {fieldConfig.options?.map((option) => (
              <SelectItem
                key={option.value}
                value={option.value}
              >
                {option.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      );
    }
    
    return (
      <Input
        value={currentValue}
        onChange={(e) => handleSoftwareEdit(fieldConfig.key, e.target.value)}
        className="h-9"
      />
    );
  };

  if (!visible) return null;

  return (
    <>
      <Drawer open={visible} onOpenChange={handleCloseDrawer}>
        <DrawerContent className="h-[85vh]">
          <DrawerHeader className="flex flex-row items-center justify-between">
            <div>
              <DrawerTitle className="text-primary">
                {deal?.name || 'Deal Details'}
              </DrawerTitle>
              <DrawerDescription>
                Manage deal information and settings
              </DrawerDescription>
            </div>
            <div className="flex gap-2">
              <Button 
                onClick={handleSave} 
                disabled={!Object.keys(changes).length}
                className="shrink-0"
              >
                Save Changes
              </Button>
              <Button 
                variant="outline" 
                onClick={handleCloseDrawer}
                className="shrink-0"
              >
                Close
              </Button>
            </div>
          </DrawerHeader>

          {loading ? (
            <div className="flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
            </div>
          ) : (
            <div className="flex-1 overflow-hidden">
              {renderGroupedData()}
            </div>
          )}
        </DrawerContent>
      </Drawer>

      <Dialog open={confirmVisible} onOpenChange={setConfirmVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Confirm Changes</DialogTitle>
            <DialogDescription>
              Are you sure you want to save the following changes?
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            {renderChanges()}
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setConfirmVisible(false)}>
              Cancel
            </Button>
            <Button onClick={confirmSave}>
              Save Changes
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { DealDrawer };
