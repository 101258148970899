import { ExternalLink } from 'lucide-react'
import { Button } from "@/components/ui/button"

interface Guide {
  title: string
  url: string
}

export default function Guides() {
  const guides: Guide[] = [
    {
      title: "Opsætning af Corpay",
      url: "https://example.com/corpay-guide"
    },
    {
      title: "Opsætning af Aiia",
      url: "https://example.com/aiia-guide"
    },
    {
      title: "Officebot",
      url: "https://example.com/officebot-guide"
    }
  ]

  const openGuide = (url: string) => {
    window.open(url, '_blank')
  }

  return (
    <div className="w-full space-y-6">
      <div className="flex flex-col space-y-1.5">
        <h2 className="text-2xl font-semibold">Guides & Manuals</h2>
        <p className="text-muted-foreground">
          Access our comprehensive guides and documentation
        </p>
      </div>

      <div className="grid gap-4">
        {guides.map((guide) => (
          <div
            key={guide.title}
            className="flex items-center justify-between p-4 rounded-lg border border-border bg-card"
          >
            <span className="font-medium">{guide.title}</span>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => openGuide(guide.url)}
              title={`Open ${guide.title}`}
            >
              <ExternalLink className="h-4 w-4" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  )
}