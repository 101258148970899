import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wrench, Upload } from 'lucide-react'
import axios from '../../api/axiosConfig'
import { Button } from '@/components/ui/button'
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { toast } from 'sonner'
import ErpHeader from '../../components/ErpHeader'

interface ErpHeaderProps {
  icon: React.ElementType
  title: string
}

interface OfficebotProps {
  theme: string
  toggleTheme: () => void
}

const Officebot = ({ theme, toggleTheme }: OfficebotProps) => {
  const navigate = useNavigate()
  const user = JSON.parse(localStorage.getItem('user') || '{"name": "User", "id": null}')
  const initials = user.name.split(' ').map((n: string) => n[0]).join('').toUpperCase()
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null)
  const [monthAdjustment, setMonthAdjustment] = useState<number>(0)

  const handleLogout = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    navigate('/login')
  }

  const userMenu = {
    items: [
      { key: 'logout', label: 'Logout' },
    ],
    onClick: ({ key }: { key: string }) => {
      if (key === 'logout') {
        handleLogout()
      }
    },
  }

  const handleMainMenuRedirect = () => {
    navigate('/main-menu')
  }

  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return

    const file = event.target.files[0]
    const formData = new FormData()
    formData.append('file', file)
    formData.append('month_adjustment', monthAdjustment.toString())

    try {
      const response = await axios.post('/officebot/upload', formData, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      if (
        contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        contentType === 'application/vnd.ms-excel'
      ) {
        const blob = new Blob([response.data], { type: contentType })
        const url = window.URL.createObjectURL(blob)
        setDownloadUrl(url)
        toast.success(`${file.name} file uploaded and processed successfully`)
      } else if (contentType === 'application/json') {
        const reader = new FileReader()
        reader.onload = () => {
          try {
            const errorResponse = JSON.parse(reader.result as string)
            toast.error(errorResponse.error || 'File upload failed')
          } catch (parseError) {
            toast.error('Failed to parse error message from server')
          }
        }
        reader.readAsText(response.data)
      } else {
        toast.error('Unexpected response from the server')
      }
    } catch (error) {
      toast.error(`File upload failed: ${error instanceof Error ? error.message : 'Unknown error'}`)
    }
  }

  useEffect(() => {
    return () => {
      if (downloadUrl) {
        window.URL.revokeObjectURL(downloadUrl)
        console.log('Object URL revoked:', downloadUrl)
      }
    }
  }, [downloadUrl])

  const handleDownloadClick = () => {
    setTimeout(() => {
      if (downloadUrl) {
        window.URL.revokeObjectURL(downloadUrl)
        setDownloadUrl(null)
        console.log('Object URL revoked after download:', downloadUrl)
      }
    }, 0)
  }

  return (
    <div className="min-h-screen flex flex-col">
      <ErpHeader 
        icon={Wrench}
        title="Officebot Tools"
      />
      <main className="flex-1 flex flex-col gap-6 p-6">
        <Card>
          <CardHeader>
            <CardTitle>Adjust Month</CardTitle>
          </CardHeader>
          <CardContent>
            <Select
              value={monthAdjustment.toString()}
              onValueChange={(value) => setMonthAdjustment(parseInt(value))}
            >
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Select month adjustment" />
              </SelectTrigger>
              <SelectContent>
                {Array.from({ length: 13 }, (_, i) => i - 6).map((value) => (
                  <SelectItem key={value} value={value.toString()}>
                    {value > 0 ? `+${value}` : value}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle>Upload File</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="flex items-center gap-4">
              <input
                type="file"
                accept=".csv,.xlsx"
                onChange={handleFileUpload}
                className="hidden"
                id="file-upload"
              />
              <label htmlFor="file-upload">
                <Button variant="outline" asChild>
                  <span>
                    <Upload className="mr-2 h-4 w-4" />
                    Click to Upload
                  </span>
                </Button>
              </label>
            </div>
          </CardContent>
        </Card>

        {downloadUrl && (
          <Card>
            <CardHeader>
              <CardTitle>Download File</CardTitle>
            </CardHeader>
            <CardContent>
              <a
                href={downloadUrl}
                download="FakturaLinje_processed.xlsx"
                onClick={handleDownloadClick}
              >
                <Button>Download File</Button>
              </a>
            </CardContent>
          </Card>
        )}
      </main>
    </div>
  )
}

export default Officebot