import React, { useState, useEffect } from 'react';
import { Tabs, Form, Table, InputNumber, Switch, message } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from '../../api/axiosConfig';

function AdminTasks() {
  const [userCapacities, setUserCapacities] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUserCapacities();
  }, []);

  const fetchUserCapacities = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/admin/user-capacities');
      setUserCapacities(response.data);
    } catch (error) {
      message.error('Failed to fetch user capacities');
    } finally {
      setLoading(false);
    }
  };

  const handleCapacityChange = async (userId, updates) => {
    try {
      await axios.put(`/admin/user-capacities/${userId}`, updates);
      message.success('Capacity updated successfully');
      fetchUserCapacities();
    } catch (error) {
      message.error('Failed to update capacity');
    }
  };

  const WorkCapacity = () => {
    const columns = [
      { 
        title: 'Team Member', 
        dataIndex: 'name', 
        key: 'name' 
      },
      {
        title: 'Daily Capacity (hours)',
        dataIndex: 'default_daily_capacity',
        key: 'default_daily_capacity',
        render: (value, record) => (
          <InputNumber
            min={0}
            max={24}
            value={value / 60} // Convert minutes to hours
            onChange={(newValue) => handleCapacityChange(record.user_id, {
              default_daily_capacity: newValue * 60 // Convert hours to minutes
            })}
          />
        )
      },
      ...['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => ({
        title: day.charAt(0).toUpperCase() + day.slice(1),
        dataIndex: ['default_work_days', day],
        key: day,
        render: (value, record) => (
          <Switch
            checked={value}
            onChange={(checked) => {
              const newWorkDays = { ...record.default_work_days, [day]: checked };
              handleCapacityChange(record.user_id, { default_work_days: newWorkDays });
            }}
          />
        )
      }))
    ];

    return (
      <Table 
        columns={columns} 
        dataSource={userCapacities}
        rowKey="user_id"
        loading={loading}
        pagination={false}
      />
    );
  };

  const items = [
    {
      key: 'workCapacity',
      label: (
        <span>
          <TeamOutlined />
          Work Capacity
        </span>
      ),
      children: <WorkCapacity />,
    }
  ];

  return (
    <div className="admin-tasks">
      <h2>Team Capacity Configuration</h2>
      <Tabs items={items} />
    </div>
  );
}

export default AdminTasks;