import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import CreateBoardModal from '../modals/CreateBoardModal';
import { Table, ColumnDef } from '@/components/ui/specialized/mod-table';
import { useUsers } from '@/contexts/UserContext';

interface Board {
  id: number;
  name: string;
  description: string;
  is_public: boolean;
}

const CustomBoardList: React.FC = () => {
  const { users } = useUsers();
  const [boards, setBoards] = useState<Board[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    fetchBoards();
  }, []);

  const fetchBoards = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/custom-board/boards');
      setBoards(response.data);
    } catch (error) {
      console.error('Error fetching boards:', error);
      toast.error('Failed to load boards');
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBoard = () => {
    setIsCreateModalVisible(true);
  };

  const handleBoardCreated = () => {
    fetchBoards();
    setIsCreateModalVisible(false);
  };

  const columns: ColumnDef<Board>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      cell: ({ row }) => (
        <a 
          className="cursor-pointer hover:underline"
          onClick={() => navigate(`/custom-boards/${row.original.id}`)}
        >
          {row.original.name}
        </a>
      )
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description'
    },
    {
      key: 'is_public',
      title: 'Access',
      dataIndex: 'is_public',
      cell: ({ row }) => (
        row.original.is_public ? 'Public' : 'Private'
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <Button onClick={() => navigate(`/custom-boards/${row.original.id}`)}>
          View Board
        </Button>
      )
    }
  ];

  return (
    <div className="custom-board-list">
      <div className="flex justify-between mb-4">
        <h1 className="text-xl font-bold">Custom Boards</h1>
        <Button
          className="flex items-center"
          onClick={handleCreateBoard}
        >
          <Plus className="mr-2" />
          Create Board
        </Button>
      </div>

      <Table
        data={boards}
        columns={columns}
        pageSize={10}
        currentPage={1}
        onPageChange={(page) => console.log('Page:', page)}
      />

      <CreateBoardModal
        open={isCreateModalVisible}
        onClose={() => setIsCreateModalVisible(false)}
        onSuccess={handleBoardCreated}
        currentUser={currentUser}
      />
    </div>
  );
};

export default CustomBoardList;