import React from 'react';
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { type FrontendTaskType } from '@/types/task';

interface TaskDateEditorProps {
  date: string | null;
  dateType: 'work' | 'due';
  taskId: number;
  taskType: FrontendTaskType;
  onSuccess?: (response: any) => void;
  className?: string;
  disabled?: boolean;
  variant?: 'default' | 'compact';
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.ReactNode;
}

const TaskDateEditor: React.FC<TaskDateEditorProps> = ({ 
  date, 
  dateType, 
  taskId, 
  taskType, 
  onSuccess, 
  className = '',
  disabled = false,
  variant = 'default',
  onClick,
  icon
}) => {
  const mapTaskTypeToBackend = (frontendTaskType: FrontendTaskType): string => {
    const mapping: Record<string, string> = {
      'andre-opgaver': 'other',
      'activities': 'activity',
      'bogforing': 'bogforing',
      'lon': 'lon',
      'moms': 'moms',
      'arsafslutning': 'arsafslutning'
    };
    return mapping[frontendTaskType] || frontendTaskType;
  };

  const getDateStatus = (date: string | null): string => {
    if (!date) return '';
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateValue = new Date(date);
    dateValue.setHours(0, 0, 0, 0);
    
    if (dateValue.getTime() === today.getTime()) {
      return 'due-today';
    } else if (dateValue < today) {
      return 'overdue';
    }
    return '';
  };

  const handleDateChange = async (selectedDate: Date | undefined) => {
    try {
      const formattedDate = selectedDate ? format(selectedDate, 'yyyy-MM-dd') : null;
      
      let endpoint: string;
      let dateKey: string;

      if (taskType === 'custom') {
        // Custom board tasks
        endpoint = `/custom-board/tasks/${taskId}/dates`;
        dateKey = `${dateType}_date`; // work_date or due_date
      } else {
        // Regular taskboard tasks
        endpoint = `/taskboard/tasks/${mapTaskTypeToBackend(taskType)}/${taskId}/dates`;
        dateKey = dateType === 'work' ? 'next_work_date' : 'next_due_date';
      }
      
      const response = await axios.put(endpoint, { [dateKey]: formattedDate });

      if (response.status === 200) {
        if (onSuccess) {
          onSuccess(response.data);
        }
      }
    } catch (error) {
      console.error('Error updating date:', error);
      toast.error('Failed to update date');
    }
  };

  const dateStatus = getDateStatus(date);
  const statusStyles = {
    'due-today': 'text-warning border-warning hover:border-warning focus:ring-warning/20',
    'overdue': 'text-destructive border-destructive hover:border-destructive focus:ring-destructive/20',
    '': ''
  };

  const formatDate = (date: string) => {
    if (variant === 'compact') {
      return format(new Date(date), 'MMM d'); // e.g., "Jan 15"
    }
    return format(new Date(date), 'MMM d, yyyy'); // e.g., "Jan 15, 2024"
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size={variant === 'compact' ? 'sm' : 'default'}
          className={cn(
            "justify-start text-left font-normal",
            variant === 'compact' ? "min-w-[90px] text-sm px-2 py-1" : "min-w-[140px]",
            !date && "text-muted-foreground",
            statusStyles[dateStatus as keyof typeof statusStyles],
            className
          )}
          disabled={disabled}
          onClick={onClick}
        >
          {(variant !== 'compact' || icon) && (
            <div className="mr-2 h-4 w-4 shrink-0">
              {icon || <CalendarIcon />}
            </div>
          )}
          <span className="truncate">
            {date ? formatDate(date) : 'Pick date'}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date ? new Date(date) : undefined}
          onSelect={(day) => handleDateChange(day || undefined)}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  );
};

export { TaskDateEditor };