import * as React from "react"
import { Label, Pie, PieChart } from "recharts"

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui"

interface PieDonutProps {
  data: Array<{
    browser: string
    visitors: number
    fill: string
  }>
  config: ChartConfig
  title?: string
  description?: string
  footerContent?: React.ReactNode
  centerLabel?: {
    value: number
    label: string
  }
  className?: string
  innerRadius?: number
  outerRadius?: number
  centerLabelFontSize?: string
}

export function PieDonutChart({
  data,
  config,
  title,
  description,
  footerContent,
  centerLabel,
  className,
  innerRadius = 60,
  outerRadius = 80,
  centerLabelFontSize = "text-3xl",
}: PieDonutProps) {
  return (
    <Card className="flex flex-col !border-none">
      {(title || description) && (
        <CardHeader className="items-center pb-0">
          {title && <CardTitle>{title}</CardTitle>}
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
      )}
      <CardContent className="flex-1 pb-0">
        <ChartContainer
          config={config}
          className="mx-auto aspect-square max-h-[250px]"
        >
          <PieChart>
            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Pie
              data={data}
              dataKey="visitors"
              nameKey="browser"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              strokeWidth={5}
            >
              {centerLabel && (
                <Label
                  content={({ viewBox }) => {
                    if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                      return (
                        <text
                          x={viewBox.cx}
                          y={(viewBox?.cy ?? 0) + 24}
                          textAnchor="middle"
                          dominantBaseline="middle"
                        >
                          <tspan
                            x={viewBox.cx}
                            y={viewBox.cy}
                            className={`fill-foreground ${centerLabelFontSize} font-bold`}
                          >
                            {centerLabel.value.toLocaleString()}
                          </tspan>
                          <tspan
                            x={viewBox.cx}
                            y={(viewBox?.cy ?? 0) + 24}
                            className="fill-muted-foreground"
                          >
                            {centerLabel.label}
                          </tspan>
                        </text>
                      )
                    }
                  }}
                />
              )}
            </Pie>
          </PieChart>
        </ChartContainer>
      </CardContent>
      {footerContent && (
        <CardFooter className="flex-col gap-2 text-sm">
          {footerContent}
        </CardFooter>
      )}
    </Card>
  )
}
