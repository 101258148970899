import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import { cn } from '@/lib/utils';
import { Skeleton } from "@/components/ui/skeleton";
import { useUsers } from '@/contexts/UserContext';

import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
  TouchSensor,
  closestCenter,
  UniqueIdentifier,
  useDroppable,
  useDraggable
} from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';

// Lucide Icons
import { 
  Eye, 
  MessageSquare, 
  Clock, 
  Calendar,
  Briefcase
} from 'lucide-react';

// Shadcn Components
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card, CardContent } from "@/components/ui/card";

// Custom Components
import { TaskDateEditor } from '@/components/TaskDateEditor';
import { TaskNotes } from '../modals/TaskNotes';
import { TaskCompleteDrawer } from '../drawers/TaskCompleteDrawer';
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { DealDrawer } from '@/drawers/DealDrawer';
import { KanbanCard, KanbanCardHeader, KanbanCardContent, KANBAN_COLUMN_WIDTH } from "@/components/ui/specialized/kanban-card"
import TaskView from './TaskView'; // Import TaskView component
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { getBoardStatus } from '../config/board-status'; // Import the function to get board statuses

// Types
import { Task, FrontendTaskType, BackendTaskType, mapToBackendTaskType, TaskToComplete, isRecurringTask } from '@/types/task';
import { StoredUser } from '@/types/user';
import { BoardStatusOption } from '@/types/taskboard';

// Import the SortableTaskCard component
import { SortableTaskCard } from '../components/SortableTaskCard';

// Import the correct types
import { CardConfig } from '@/taskboards/config/card';
import { type DealConfigItem } from '@/types/taskboard';
import { TaskBadge } from "@/components/ui/specialized/task-badge";
import { type ColorScale } from '@/configs/DealDataConfig';

dayjs.extend(utc);
dayjs.extend(timezone);

const ENABLE_REALTIME = false; // Toggle this to enable/disable real-time updates

// Update the kanban styles
const kanbanStyles = {
  board: "flex gap-4 h-[calc(100vh-100px)] p-6 overflow-x-hidden overflow-y-hidden", // Hide default scrollbars
  columnContent: "flex-1",
  columnCount: "bg-white/20 rounded-full px-2 py-1 text-xs",
};

const DroppableColumn: React.FC<{
  id: string;
  children: React.ReactNode;
  className?: string;
  tasks: Task[];
  loading?: boolean;
  status: BoardStatusOption;
  taskType: FrontendTaskType;
}> = ({ id, children, className, tasks, loading, status, taskType }) => {
  const { isOver, setNodeRef } = useDroppable({ 
    id: `column-${status.value}`,
    data: {
      type: 'column',
      status: status.value
    }
  });

  return (
    <KanbanCard ref={setNodeRef}>
      <KanbanCardHeader 
        className="sticky top-0 z-10"
        color={status.color}
      >
        <div className="flex items-center gap-2">
          {status.label}
          <span className="bg-white/20 rounded-full px-2 py-1 text-xs">
            {tasks.length}
          </span>
        </div>
      </KanbanCardHeader>
      <KanbanCardContent
        className={cn(
          isOver && "bg-muted/50",
          className
        )}
      >
        <div className="space-y-2">
          <SortableContext items={tasks.map(t => t.id.toString())} strategy={verticalListSortingStrategy}>
            {children}
          </SortableContext>
        </div>
      </KanbanCardContent>
    </KanbanCard>
  );
};

const TaskCardSkeleton = () => {
  return (
    <div className="my-2 px-2">
      <Card className="w-full p-4">
        <div className="space-y-4">
          {/* Deal badge skeleton */}
          <div className="flex flex-col items-center text-center">
            <Skeleton className="h-6 w-24" />
            
            {/* Description skeleton */}
            <div className="mt-2 space-y-2">
              <Skeleton className="h-4 w-48" />
              <Skeleton className="h-4 w-32" />
            </div>
            
            {/* Frequency badge skeleton */}
            <Skeleton className="mt-2 h-5 w-16" />
          </div>

          {/* Date buttons skeleton */}
          <div className="grid grid-cols-2 gap-2">
            <Skeleton className="h-8" />
            <Skeleton className="h-8" />
          </div>

          {/* Footer skeleton */}
          <div className="flex justify-between items-center">
            <Skeleton className="h-8 w-8 rounded-full" />
            <div className="flex gap-2">
              <Skeleton className="h-6 w-6" />
              <Skeleton className="h-6 w-6" />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

interface TaskKanbanProps {
  tasks: Task[];
  onTaskUpdate: (taskId: string, updatedFields: Partial<Task>) => Promise<void>;
  boardStatusOptions: BoardStatusOption[];
  cardConfig: CardConfig;
  dealDataConfig: DealConfigItem[];
  dealSoftwareConfig: DealConfigItem[];
  taskType: FrontendTaskType;
  setTasks: React.Dispatch<React.SetStateAction<Task[]>>;
  loading: boolean;
  onTaskClick: (task: Task) => void;
  onAssigneeUpdate: (taskId: number, newAssignees: string[]) => Promise<void>;
}

export function TaskKanban({
  tasks,
  onTaskUpdate,
  boardStatusOptions,
  cardConfig,
  dealDataConfig,
  dealSoftwareConfig,
  taskType,
  setTasks,
  loading,
  onTaskClick,
  onAssigneeUpdate,
}: TaskKanbanProps) {
  const { users } = useUsers();
  const [localTasks, setLocalTasks] = useState<Task[]>(tasks);
  const navigate = useNavigate();
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isCompleteDrawerOpen, setIsCompleteDrawerOpen] = useState(false);
  const [completedTask, setCompletedTask] = useState<Task | null>(null);
  const [isAssigneeModalOpen, setIsAssigneeModalOpen] = useState(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<Task | null>(null);
  const [isDealDrawerOpen, setIsDealDrawerOpen] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [activeTask, setActiveTask] = useState<Task | null>(null);
  const [isTaskViewOpen, setIsTaskViewOpen] = useState(false);
  const [taskViewTask, setTaskViewTask] = useState<Task | null>(null);
  const [originalTaskState, setOriginalTaskState] = useState<Task | null>(null);

  useEffect(() => {
    setLocalTasks(tasks);
  }, [tasks]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 8,
      },
    })
  );

  const getTasksForStatus = (status: string): Task[] => {
    return localTasks.filter(task => task.board_status === status);
  };

  const getColumnColor = (status: string): string => {
    const option = boardStatusOptions.find(o => o.value === status);
    return option?.color || 'gray';
  };

  const handleOpenTask = (taskId: number) => {
    navigate(`/boards/${taskType}/${taskId}`, { state: { fromKanban: true } });
  };

  const getTagColor = (key: string, value: any): string => {
    const config = dealDataConfig.find(item => item.key === `data.${key}`);
    if (config && config.type === 'dropdown') {
      const option = config.options?.find((opt: any) => opt.value === value);
      return option?.color || 'default';
    }
    return 'default';
  };

  const mapFrequencyToLabel = (frequency?: { freq: string }): string => {
    if (!frequency?.freq) return 'N/A';
    const mapping: Record<string, string> = {
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly',
      QUARTERLY: 'Quarterly',
      YEARLY: 'Yearly'
    };
    return mapping[frequency.freq.toUpperCase()] || frequency.freq;
  };

  const handleMessageIconClick = async (task: Task, e: React.MouseEvent) => {
    e.stopPropagation();
    try {
      const backendTaskType = getBackendTaskType(taskType);
      
      const response = await axios.get<Task>(`/taskboard/tasks/${backendTaskType}/${task.id}`);
      
      // Ensure we're setting the full task data including comments
      const updatedTask = {
        ...task,
        ...response.data,
        comments: response.data.comments || []
      };
      
      setSelectedTask(updatedTask);
      setIsNotesModalOpen(true);
    } catch (error) {
      console.error('Error fetching task details:', error);
      toast.error('Failed to load task comments');
    }
  };

  const getUpdatesCount = (task: Task): number => {
    return task.comments?.length || 0;
  };

  const handleDealClick = (e: React.MouseEvent, dealId: number) => {
    e.stopPropagation();
    setSelectedDealId(dealId);
    setIsDealDrawerOpen(true);
  };

  const getAssignedUsers = (task: Task): StoredUser[] => {
    if (!task.assigned_users?.length) return [];
    return task.assigned_users
      .map(userId => users.find(u => u.user_id.toString() === userId.toString()))
      .filter((user): user is StoredUser => user !== undefined);
  };

  // Add a function to get tags based on task configuration
  const getTaskTags = (task: Task, dealDataConfig: DealConfigItem[], dealSoftwareConfig: DealConfigItem[]): JSX.Element => {
    const tags: { key: string; value: string; color?: string; label: string }[] = [];

    // Helper function to get nested value and handle both data and software paths
    const getNestedValue = (obj: any, path: string) => {
      // Handle both data and software paths
      const parts = path.split('.');
      let value = obj;
      
      // For data fields
      if (parts[0] === 'data') {
        value = obj.deal_data;
        parts.shift(); // Remove 'data'
      }
      // For software fields
      else if (parts[0] === 'software') {
        value = obj.deal_software;
        parts.shift(); // Remove 'software'
      }

      // Navigate through remaining parts
      for (const part of parts) {
        if (value && typeof value === 'object') {
          value = value[part];
        } else {
          return undefined;
        }
      }
      return value;
    };

    // Get values from deal_data
    dealDataConfig.forEach(config => {
      const value = getNestedValue(task, config.key);
      
      if (value) {
        let color;
        
        if (config.type === 'number' && config.colorScale) {
          const numValue = Number(value);
          const scale = config.colorScale.find((s: ColorScale) => numValue <= s.max);
          color = scale?.color;
        } else if (config.type === 'dropdown' && config.options) {
          const option = config.options.find(opt => opt.value === value);
          color = option?.color;
        }
        
        tags.push({
          key: config.key,
          value: value.toString(),
          color,
          label: config.label
        });
      }
    });

    // Get values from deal_software
    dealSoftwareConfig.forEach(config => {
      const value = getNestedValue(task, `software.${config.key}`);
      
      if (value && config.options) {
        const option = config.options.find(opt => opt.value === value);
        
        tags.push({
          key: `software.${config.key}`,
          value: value.toString(),
          color: option?.color,
          label: config.label
        });
      }
    });

    return <TaskBadge items={tags} />;
  };

  // Update the renderTaskContent function to include the new tags
  const renderTaskContent = (task: Task) => {
    return (
      <Card 
        variant="kanban"
        className="w-full p-4 hover:shadow-md transition-shadow relative group cursor-grab active:cursor-grabbing"
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        <div className="space-y-4">
          {/* Header Section - Centered Content */}
          <div className="flex flex-col items-center text-center">
            {task.deal_name && (
              <Badge 
                variant="secondary"
                className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
                onClick={(e) => {
                  e.stopPropagation();
                  task.deal_id && handleDealClick(e, task.deal_id);
                }}
              >
                <Briefcase className="w-3 h-3 flex-shrink-0 mr-1" />
                <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
                  {task.deal_name}
                </span>
              </Badge>
            )}
            <p className="text-sm text-primary font-medium mt-2 line-clamp-2">
              {task.description}
            </p>
            {/* Frequency badge centered below description */}
            {cardConfig?.showFrequency && task.frequency && (
              <Badge variant="secondary" className="mt-2">
                {mapFrequencyToLabel(task.frequency)}
              </Badge>
            )}
          </div>

          {/* Dates Section */}
          <div className="grid grid-cols-2 gap-2">
            <TaskDateEditor
              date={task.next_work_date || null}
              dateType="work"
              taskId={task.id}
              taskType={taskType as FrontendTaskType}
              onSuccess={handleTaskUpdated}
              variant="compact"
              className="w-full"
              icon={<Clock className="h-4 w-4 text-muted-foreground" />}
            />
            <TaskDateEditor
              date={task.next_due_date || null}
              dateType="due"
              taskId={task.id}
              taskType={taskType as FrontendTaskType}
              onSuccess={handleTaskUpdated}
              variant="compact"
              className="w-full"
              icon={<Calendar className="h-4 w-4 text-muted-foreground" />}
            />
          </div>

          {/* Tags Section - Centered */}
          <div className="flex justify-center mt-2">
            {getTaskTags(task, dealDataConfig, dealSoftwareConfig)}
          </div>

          {/* Footer Section */}
          <div className="flex justify-between items-center">
            {/* Assignees on the left */}
            {cardConfig?.showAssignee && getAssignedUsers(task).length > 0 && (
              <div 
                className="flex items-center gap-2 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTaskForAssignee(task);
                  setIsAssigneeModalOpen(true);
                }}
              >
                <AvatarGroup
                  users={getAssignedUsers(task)}
                  max={3}
                  className="hover:opacity-80 transition-opacity"
                />
              </div>
            )}
            
            {/* Task view button centered */}
            <Button
              variant="ghost"
              size="icon"
              className="opacity-0 group-hover:opacity-100 transition-opacity mx-auto"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenTask(task.id);
              }}
            >
              <Eye className="h-4 w-4" />
            </Button>

            {/* Message button on the right */}
            <Button
              variant="ghost"
              size="icon"
              className="relative"
              onClick={(e) => handleMessageIconClick(task, e)}
            >
              <MessageSquare className="h-4 w-4" />
              {getUpdatesCount(task) > 0 && (
                <Badge 
                  variant="compact"
                  className="absolute -top-2 -right-2 h-4 w-4 flex items-center justify-center"
                >
                  {getUpdatesCount(task)}
                </Badge>
              )}
            </Button>
          </div>
        </div>
      </Card>
    );
  };

  const handleCompleteDrawerClose = () => {
    if (completedTask?.rollback) {
      completedTask.rollback();
    }
    if (originalTaskState) {
      setLocalTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === originalTaskState.id ? originalTaskState : t
        )
      );
      setTasks(prevTasks =>
        prevTasks.map(t =>
          t.id === originalTaskState.id ? originalTaskState : t
        )
      );
    }
    setIsCompleteDrawerOpen(false);
    setCompletedTask(null);
    setOriginalTaskState(null);
  };

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveId(active.id);
    const draggedTask = localTasks.find(task => task.id.toString() === active.id);
    setActiveTask(draggedTask || null);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over) return;

    const task = localTasks.find(t => t.id.toString() === active.id);
    if (!task) return;

    let newStatus: string;
    if (over.id.toString().startsWith('column-')) {
      newStatus = over.id.toString().replace('column-', '');
    } else {
      const targetTask = localTasks.find(t => t.id.toString() === over.id);
      if (!targetTask || !targetTask.board_status) return;
      newStatus = targetTask.board_status;
    }

    const validStatuses = boardStatusOptions.map(status => status.value);
    if (!validStatuses.includes(newStatus) || task.board_status === newStatus) {
      return;
    }

    const originalStatus = task.board_status;
    const updateTasks = (tasks: Task[]) =>
      tasks.map(t =>
        t.id === task.id ? { ...t, board_status: newStatus } : t
      );

    setLocalTasks(updateTasks);
    setTasks(updateTasks);

    if (newStatus === boardStatusOptions[boardStatusOptions.length - 1].value) {
      console.log('Task being completed:', {
        taskId: task.id,
        taskType: taskType,
        isLonTask: taskType === 'lon',
        originalTask: task
      });

      const taskToComplete: Task = {
        ...task,
        task_type: taskType,
        isRecurring: isRecurringTask({ ...task, task_type: taskType }),
        board_status: newStatus,
        progress_status: task.progress_status || '',
        rollback: () => {
          if (originalTaskState) {
            console.log('Rolling back task:', {
              taskId: originalTaskState.id,
              taskType: taskType,
              originalState: originalTaskState
            });
            handleTaskUpdated(originalTaskState);
          }
        }
      };
      
      console.log('TaskToComplete object:', taskToComplete);
      
      setOriginalTaskState({ ...task, task_type: taskType });
      setCompletedTask(taskToComplete);
      setIsCompleteDrawerOpen(true);
    } else {
      try {
        const response = await axios.put(`/taskboard/tasks/${taskType}/${active.id}/status`, {
          board_status: newStatus,
          board_status_options: getBoardStatus(taskType)
        });

        if (response.data) {
          const updateWithServerData = (tasks: Task[]) =>
            tasks.map(t =>
              t.id === task.id ? { ...t, ...response.data } : t
            );

          setLocalTasks(updateWithServerData);
          setTasks(updateWithServerData);
        }
      } catch (error) {
        const revertTasks = (tasks: Task[]) =>
          tasks.map(t =>
            t.id === task.id ? { ...t, board_status: originalStatus } : t
          );

        setLocalTasks(revertTasks);
        setTasks(revertTasks);

        console.error('Error updating task status:', error);
        toast.error('Failed to update task status');
      }
    }

    setActiveId(null);
    setActiveTask(null);
  };

  const getBackendTaskType = (frontendTaskType: FrontendTaskType): BackendTaskType => {
    return mapToBackendTaskType(frontendTaskType);
  };

  const handleTaskUpdated = (updatedTask: Task) => {
    if (!ENABLE_REALTIME) return;
    
    setLocalTasks(prevTasks =>
      prevTasks.map(t =>
        t.id === updatedTask.id
          ? { ...t, ...updatedTask }
          : t
      )
    );
    
    setTasks(prevTasks => 
      prevTasks.map(t => t.id === updatedTask.id ? { ...t, ...updatedTask } : t)
    );
  };

  // Ensure onTaskClick is defined or provide a default function
  const handleTaskClick = onTaskClick || (() => {});

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <ScrollArea className="h-full w-full">
          <div className={kanbanStyles.board}>
            {boardStatusOptions.map((status) => (
              <DroppableColumn
                key={status.value}
                id={status.value}
                className={kanbanStyles.columnContent}
                tasks={getTasksForStatus(status.value)}
                loading={loading}
                status={status}
                taskType={taskType}
              >
                {getTasksForStatus(status.value).map((task) => (
                  <SortableTaskCard
                    key={task.id}
                    id={task.id.toString()}
                    task={task}
                    onTaskClick={handleTaskClick}
                    renderContent={renderTaskContent}
                  />
                ))}
              </DroppableColumn>
            ))}
          </div>
        </ScrollArea>

        <DragOverlay>
          {activeTask ? (
            <div style={{ width: KANBAN_COLUMN_WIDTH }}>
              {renderTaskContent(activeTask)}
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
      {selectedTask && (
        <TaskNotes
          visible={isNotesModalOpen}
          onClose={async (refresh?: boolean) => {
            setIsNotesModalOpen(false);
            if (refresh && selectedTask) {
              try {
                const backendTaskType = getBackendTaskType(taskType);
                const response = await axios.get<Task>(`/taskboard/tasks/${backendTaskType}/${selectedTask.id}`);
                
                const updatedTask = {
                  ...selectedTask,
                  ...response.data,
                  comments: response.data.comments || []
                };
                
                setSelectedTask(updatedTask);
                setTasks(prevTasks => 
                  prevTasks.map(t => t.id === updatedTask.id ? updatedTask : t)
                );
              } catch (error) {
                console.error('Error refreshing task:', error);
                toast.error('Failed to refresh task data');
              }
            }
            setSelectedTask(null);
          }}
          task={selectedTask}
          taskType={taskType}
          users={users}
        />
      )}
      <TaskCompleteDrawer 
        visible={isCompleteDrawerOpen}
        onClose={handleCompleteDrawerClose}
        task={completedTask}
        onComplete={(values) => {
          console.log('Task completed with values:', values);
          setIsCompleteDrawerOpen(false);
          setCompletedTask(null);
        }}
      />
      <AssigneeSelection
        visible={isAssigneeModalOpen}
        onClose={() => {
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={(newAssignees) => {
          if (selectedTaskForAssignee) {
            onAssigneeUpdate(selectedTaskForAssignee.id, newAssignees);
          }
          setIsAssigneeModalOpen(false);
          setSelectedTaskForAssignee(null);
        }}
        initialAssignees={selectedTaskForAssignee?.assigned_users?.map(id => id.toString()) || []}
        maxAssignees={10}
      />
      <DealDrawer
        visible={isDealDrawerOpen}
        onClose={() => {
          setIsDealDrawerOpen(false);
          setSelectedDealId(null);
        }}
        dealId={selectedDealId}
      />
      {isTaskViewOpen && taskViewTask && (
        <TaskView
          onClose={() => setIsTaskViewOpen(false)}
          tasks={tasks}
          taskType={taskType}
          boardStatusOptions={boardStatusOptions}
          task={taskViewTask}
          fromWork={false}
          getBoardName={(task) => task.task_type}
          dealName={taskViewTask.deal_name || ''}
        />
      )}
    </>
  );
}

