/**
 * This file defines how task cards should be displayed in the board view.
 * It specifies which elements should be visible for each task type.
 */

import type { FrontendTaskType } from '@/types/task';

export interface CardConfig {
  showAssignee: boolean;
  showFrequency?: boolean;
}

export const cardConfigs: Record<string, CardConfig> = {
  bogforing: {
    showAssignee: true,
    showFrequency: true,
  },
  lon: {
    showAssignee: true,
    showFrequency: true,
  },
  moms: {
    showAssignee: true,
    showFrequency: true,
  },
  arsafslutning: {
    showAssignee: true,
    showFrequency: true,
  },
  activities: {
    showAssignee: true,
  },
  "andre-opgaver": {
    showAssignee: true,
  },
};

export type CardConfigKey = keyof typeof cardConfigs;

export const getCardConfig = (type: CardConfigKey): CardConfig => {
  return cardConfigs[type] || cardConfigs.bogforing;
};