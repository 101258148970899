/**
 * This file contains the core task-related types and utilities.
 * It defines the structure of a task and its various type classifications.
 */

// Task Data Structure
export interface TaskData {
  Task?: string;
  'Due Date'?: string;
  'Work Date'?: string;
  Status?: string;
  Assignee: string[];
  [key: string]: any;
}

export type CustomTaskType = 'custom';

export type RecurringTaskType = 'moms' | 'lon' | 'bogforing' | 'arsafslutning';

export type OnboardingTaskType = 'andre-opgaver' | 'activities';

export type FrontendTaskType = RecurringTaskType | OnboardingTaskType | 'custom';

export type BackendTaskType = 'recurring' | 'other' | 'activity' | 'custom';

export const mapToBackendTaskType = (frontendType: FrontendTaskType): BackendTaskType => {
  if (['moms', 'lon', 'bogforing', 'arsafslutning'].includes(frontendType as RecurringTaskType)) {
    return 'recurring';
  }
  if (frontendType === 'andre-opgaver') {
    return 'other';
  }
  if (frontendType === 'activities') {
    return 'activity';
  }
  return 'custom';
};

export interface DealData {
  name?: string;
  cvr?: string;
  address?: string;
  zipcode?: string;
  city?: string;
  economic_customer_number?: string;
  onboard_status?: string;
}

export interface ContactData {
  id?: number;
  name?: string;
  email?: string;
  phone?: string;
}

export interface ContactInfo {
  text: string;
  [key: string]: any;
}

export interface Task {
  id: number;
  key: string;
  task_type: FrontendTaskType;
  data: Record<string, any>;
  board_status?: string;
  progress_status?: string;
  recurring_type?: string;
  frequency?: {
    freq: string;
    [key: string]: any;
  };
  isRecurring?: boolean;
  recurringType?: string;
  rollback?: () => void;
  board_name?: string;
  due_date?: string;
  work_date?: string;
  task_date?: string;
  deal_name?: string | null;
  deal_id?: number;
  enable_time_tracking?: boolean;
  title?: string;
  description?: string;
  text?: string;
  next_due_date?: string;
  next_work_date?: string;
  status?: string;
  assigned_users?: string[];
  user_id?: string | number;
  deal?: DealData;
  deal_data?: Record<string, any>;
  deal_software?: Record<string, any>;
  comments?: {
    id: number;
    user_id: number;
    content: string;
    timestamp: string;
  }[];
  contact?: ContactData;
  products?: any[];
  account?: {
    company_name: string;
  };
  updates?: any[];
  expected_workload?: number;
  work_date_last_day?: string;
}

export interface DateRange {
  start: Date | null;
  end: Date | null;
}

export interface WorkFilters {
  progress_status: string[] | null;
  board_name: string[];
  [key: string]: string[] | null;
}

export type ConfigKeyType = 'bogforing' | 'lon' | 'moms' | 'arsafslutning' | 'andreOpgaver' | 'activities';

export const isValidConfigKey = (key: string): key is ConfigKeyType => {
  const validKeys: ConfigKeyType[] = ['bogforing', 'lon', 'moms', 'arsafslutning', 'andreOpgaver', 'activities'];
  return validKeys.includes(key as ConfigKeyType);
};

export interface DatePickerRangeProps {
  value: {
    from: Date | null;
    to: Date | null;
  };
  onChange: (dates: { from: Date | null; to: Date | null }) => void;
}

export interface CustomDateRange {
  from: Date | null;
  to: Date | null;
}

export interface EnhancedTask extends Task {
  isRecurring?: boolean;
  recurringType?: string;
  rollback?: () => void;
}

export type TaskConfigKey = BoardConfigKey | FrontendTaskType;

export interface UpdatedTask extends Task {
  rollback?: () => void;
  board_status: string;
  progress_status: string;
  task_type: FrontendTaskType;
  recurring_type?: string;
}

export interface TaskDateRange {
  from: Date | null;
  to: Date | null;
}

export interface TaskToComplete extends Partial<Task> {
  id: number;
  task_type: FrontendTaskType;
  isRecurring: boolean;
  recurringType?: string;
  rollback?: () => void;
  board_status: string;
  progress_status: string;
}

export type FrequencyType = 'DAILY' | 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY' | 'QUARTERLY' | 'YEARLY'

export interface RecurringTask extends Task {
  id: number
  deal_name: string
  description: string
  frequency: {
    freq: FrequencyType
    interval: number
  }
  next_work_date: string
  next_due_date: string
  status_active: boolean
}

export interface CardConfig {
  showAssignee?: boolean;
  showFrequency?: boolean;
  // other properties
}

export type BoardConfigKey = 'recurring' | 'onboarding' | 'custom';

export const isRecurringTask = (task: Task): boolean => {
  return ['moms', 'lon', 'bogforing', 'arsafslutning'].includes(task.task_type as RecurringTaskType);
};