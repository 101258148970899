import { memo } from 'react';
import { Handle, Position, NodeProps } from 'reactflow';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { FlowNode } from '../../flows';
import { NodeElement, InputConfig, ButtonConfig, TextConfig } from '../node_types';

const FormNode = memo(({ data }: NodeProps<FlowNode['data']>) => {
  const { config } = data;
  
  const renderElement = (element: NodeElement) => {
    switch (element.type) {
      case 'input':
        const inputConfig = element.config as InputConfig;
        return (
          <div key={element.id} className="text-sm text-muted-foreground">
            📝 Input: {inputConfig.label}
          </div>
        );
      case 'button':
        const buttonConfig = element.config as ButtonConfig;
        return (
          <div key={element.id} className="text-sm text-muted-foreground">
            🔘 Button: {buttonConfig.label}
          </div>
        );
      case 'text':
        const textConfig = element.config as TextConfig;
        return (
          <div key={element.id} className="text-sm text-muted-foreground">
            📄 Text: {textConfig.content.substring(0, 30)}...
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <Card className="min-w-[300px] max-w-[400px] bg-background border-2">
      <CardHeader className="p-4">
        <CardTitle className="text-lg">{config.title}</CardTitle>
      </CardHeader>
      
      <CardContent className="p-4 space-y-2">
        {config.rows.map(row => (
          <div key={row.id}>
            {row.elements.map(renderElement)}
          </div>
        ))}
        
        {config.rules && config.rules.length > 0 && (
          <div className="text-xs text-muted-foreground mt-2">
            🔄 {config.rules.length} rule(s)
          </div>
        )}
      </CardContent>

      <Handle type="target" position={Position.Top} className="w-3 h-3 bg-primary" />
      <Handle type="source" position={Position.Bottom} className="w-3 h-3 bg-primary" />
    </Card>
  );
});

FormNode.displayName = 'FormNode';

export default FormNode;