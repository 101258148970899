import { useState, useEffect } from 'react'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import { Loading } from "@/components/ui/loading"
import {
  Table,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form"
import { Input } from "@/components/ui/input"
import { DatePicker } from "@/components/ui/date/date-picker"
import {
  Command,
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { Check } from "lucide-react"
import { cn } from "@/lib/utils"

// Icons
import { Package2, Building2, Users2, Tag } from 'lucide-react'

// Types
import type { Product, Deal, Account, BillingHistory } from '@/types/customers'

// Form validation
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  code: z.string().min(1, "Code is required"),
  item_price: z.number().min(0, "Price must be positive"),
  quantity: z.number().min(1, "Quantity must be at least 1"),
  billing_start_date: z.date().optional(),
  billing_frequency: z.string()
})

const Products = () => {
  const [products, setProducts] = useState<Product[]>([])
  const [deals, setDeals] = useState<Deal[]>([])
  const [accounts, setAccounts] = useState<Account[]>([])
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null)
  const [selectedAccountId, setSelectedAccountId] = useState<number | null>(null)
  const [loading, setLoading] = useState(true)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null)
  const [dealModalVisible, setDealModalVisible] = useState(false)
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [selectedDealDetails, setSelectedDealDetails] = useState<Deal | null>(null)
  const [selectedAccountDetails, setSelectedAccountDetails] = useState<Account | null>(null)
  const [billingHistory, setBillingHistory] = useState<BillingHistory[]>([])

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      code: "",
      item_price: 0,
      quantity: 1,
      billing_frequency: "monthly"
    }
  })

  useEffect(() => {
    fetchDeals()
    fetchAccounts()
    setLoading(false)
  }, [])

  useEffect(() => {
    if (selectedDealId) {
      fetchProductsForDeal(selectedDealId)
      fetchBillingHistoryForDeal(selectedDealId)
    } else if (selectedAccountId) {
      fetchProductsForAccount(selectedAccountId)
      fetchBillingHistoryForAccount(selectedAccountId)
    } else {
      setProducts([])
      setBillingHistory([])
      setLoading(false)
    }
  }, [selectedDealId, selectedAccountId])

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals')
      setDeals(response.data)
    } catch (error) {
      console.error('Error fetching deals:', error)
      toast.error('Failed to fetch deals')
    }
  }

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('/account/accounts')
      setAccounts(response.data)
    } catch (error) {
      console.error('Error fetching accounts:', error)
      toast.error('Failed to fetch accounts')
    }
  }

  const fetchProductsForDeal = async (dealId: number) => {
    try {
      const response = await axios.get(`/account/deals/${dealId}/products`)
      setProducts(response.data)
    } catch (error) {
      console.error('Error fetching products for deal:', error)
      toast.error('Failed to fetch products for deal')
    } finally {
      setLoading(false)
    }
  }

  const fetchProductsForAccount = async (accountId: number) => {
    try {
      const response = await axios.get(`/account/accounts/${accountId}/products`)
      setProducts(response.data)
    } catch (error) {
      console.error('Error fetching products for account:', error)
      toast.error('Failed to fetch products for account')
    } finally {
      setLoading(false)
    }
  }

  const fetchBillingHistoryForDeal = async (dealId: number) => {
    try {
      const response = await axios.get(`/invoice/deals/${dealId}/billing_history`)
      setBillingHistory(response.data)
    } catch (error) {
      console.error('Error fetching billing history for deal:', error)
      toast.error('Failed to fetch billing history for deal')
    }
  }

  const fetchBillingHistoryForAccount = async (accountId: number) => {
    try {
      const response = await axios.get(`/invoice/accounts/${accountId}/billing_history`)
      setBillingHistory(response.data)
    } catch (error) {
      console.error('Error fetching billing history for account:', error)
      toast.error('Failed to fetch billing history for account')
    }
  }

  const showProductDetails = (product: Product) => {
    setSelectedProduct(product)
    form.reset({
      name: product.name,
      code: product.code,
      item_price: product.item_price,
      quantity: product.quantity,
      billing_start_date: product.billing_start_date ? new Date(product.billing_start_date) : undefined,
      billing_frequency: product.billing_frequency
    })
    setEditModalOpen(true)
  }

  const handleUpdate = async (values: z.infer<typeof formSchema>) => {
    if (!selectedProduct) return

    try {
      await axios.put(`/account/products/${selectedProduct.id}`, values)
      toast.success('Product updated successfully')
      setEditModalOpen(false)
      if (selectedDealId) {
        fetchProductsForDeal(selectedDealId)
      } else if (selectedAccountId) {
        fetchProductsForAccount(selectedAccountId)
      }
    } catch (error) {
      console.error('Error updating product:', error)
      toast.error('Failed to update product')
    }
  }

  const showDealDetails = async (deal: Deal) => {
    try {
      const response = await axios.get(`/account/deals/${deal.id}`)
      setSelectedDealDetails(response.data)
      setDealModalVisible(true)
    } catch (error) {
      console.error('Error fetching deal details:', error)
      toast.error('Failed to fetch deal details')
    }
  }

  const showAccountDetails = async (account: Account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccountDetails(response.data)
      setAccountModalVisible(true)
    } catch (error) {
      console.error('Error fetching account details:', error)
      toast.error('Failed to fetch account details')
    }
  }

  const handleDeleteProduct = async (productId: number) => {
    try {
      await axios.delete(`/product/products/${productId}`)
      toast.success('Product deleted successfully')
      if (selectedDealId) {
        fetchProductsForDeal(selectedDealId)
      } else if (selectedAccountId) {
        fetchProductsForAccount(selectedAccountId)
      }
    } catch (error) {
      console.error('Error deleting product:', error)
      toast.error('Failed to delete product')
    }
  }

  const columns: ColumnDef<Product>[] = [
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Package2 className="h-4 w-4" />
          <span>{row.original.name}</span>
        </div>
      ),
      sortable: {
        enabled: true
      }
    },
    {
      key: 'code',
      title: 'Code',
      dataIndex: 'code',
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Tag className="h-4 w-4" />
          <span>{row.original.code}</span>
        </div>
      )
    },
    {
      key: 'item_price',
      title: 'Item Price',
      dataIndex: 'item_price',
      cell: ({ row }) => (
        <span>
          {row.original.item_price?.toLocaleString('da-DK', { 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 0 
          })} DKK
        </span>
      ),
      sortable: {
        enabled: true,
        type: 'number'
      }
    },
    {
      key: 'sum',
      title: 'Sum',
      dataIndex: 'sum',
      cell: ({ row }) => (
        <span>
          {row.original.sum?.toLocaleString('da-DK', { 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 0 
          })} DKK
        </span>
      ),
      sortable: {
        enabled: true,
        type: 'number'
      }
    },
    {
      key: 'deal',
      title: 'Deal',
      dataIndex: 'deal',
      cell: ({ row }) => row.original.deal ? (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80"
          onClick={() => showDealDetails(row.original.deal!)}
        >
          <Building2 className="h-3 w-3 mr-1 inline" />
          {row.original.deal.name}
        </Badge>
      ) : (
        <span className="text-muted-foreground">N/A</span>
      )
    },
    {
      key: 'account',
      title: 'Account',
      dataIndex: 'deal',
      cell: ({ row }) => row.original.deal?.account ? (
        <Badge 
          variant="outline"
          className="cursor-pointer hover:bg-secondary/80"
          onClick={() => showAccountDetails(row.original.deal!.account)}
        >
          <Users2 className="h-3 w-3 mr-1 inline" />
          {row.original.deal.account.company_name}
        </Badge>
      ) : (
        <span className="text-muted-foreground">N/A</span>
      )
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <div className="flex items-center gap-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => showProductDetails(row.original)}
          >
            Edit
          </Button>
          <Button
            variant="destructive"
            size="sm"
            onClick={() => handleDeleteProduct(row.original.id)}
          >
            Delete
          </Button>
        </div>
      )
    }
  ]

  const billingColumns: ColumnDef<BillingHistory>[] = [
    {
      key: 'product_name',
      title: 'Product Name',
      dataIndex: 'product_name'
    },
    {
      key: 'product_code',
      title: 'Product Code',
      dataIndex: 'product_code'
    },
    {
      key: 'sum',
      title: 'Sum',
      dataIndex: 'sum',
      cell: ({ row }) => (
        <span>
          {row.original.sum?.toLocaleString('da-DK', { 
            minimumFractionDigits: 0, 
            maximumFractionDigits: 0 
          })} DKK
        </span>
      )
    },
    {
      key: 'date',
      title: 'Date',
      dataIndex: 'date'
    }
  ]

  if (loading) {
    return <Loading message="Loading products..." />
  }

  return (
    <div className="space-y-4 p-8">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold text-foreground">Products</h2>
      </div>

      <div className="flex items-center gap-4">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="w-[200px] justify-between"
            >
              {selectedDealId 
                ? deals.find(d => d.id === selectedDealId)?.name
                : "Select a deal"}
              <Building2 className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] p-0" align="start">
            <Command>
              <CommandInput placeholder="Search deals..." />
              <CommandEmpty>No deal found.</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {deals.map((deal) => (
                    <CommandItem
                      key={deal.id}
                      value={deal.name}
                      onSelect={() => {
                        setSelectedAccountId(null)
                        setSelectedDealId(deal.id)
                      }}
                    >
                      {deal.name}
                      <Check
                        className={cn(
                          "ml-auto h-4 w-4",
                          selectedDealId === deal.id ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>

        <span className="text-muted-foreground">or</span>

        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="w-[200px] justify-between"
            >
              {selectedAccountId 
                ? accounts.find(a => a.id === selectedAccountId)?.company_name
                : "Select an account"}
              <Users2 className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] p-0">
            <Command>
              <CommandInput placeholder="Search accounts..." />
              <CommandEmpty>No account found.</CommandEmpty>
              <CommandList>
                <CommandGroup>
                  {accounts.map((account) => (
                    <CommandItem
                      key={account.id}
                      value={account.company_name}
                      onSelect={() => {
                        setSelectedDealId(null)
                        setSelectedAccountId(account.id)
                      }}
                    >
                      {account.company_name}
                      <Check
                        className={cn(
                          "ml-auto h-4 w-4",
                          selectedAccountId === account.id ? "opacity-100" : "opacity-0"
                        )}
                      />
                    </CommandItem>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      <Card>
        <CardContent className="pt-6">
          <Table 
            data={products}
            columns={columns}
            pageSize={10}
          />
        </CardContent>
      </Card>

      {billingHistory.length > 0 && (
        <Card>
          <CardContent className="pt-6">
            <h3 className="text-lg font-semibold mb-4">Billing History</h3>
            <Table 
              data={billingHistory}
              columns={billingColumns}
              pageSize={5}
            />
          </CardContent>
        </Card>
      )}

      <Dialog open={editModalOpen} onOpenChange={setEditModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Edit Product</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(handleUpdate)} className="space-y-4">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="item_price"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Item Price</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        {...field} 
                        onChange={e => field.onChange(parseFloat(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="quantity"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Quantity</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        {...field}
                        onChange={e => field.onChange(parseInt(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billing_start_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Billing Start Date</FormLabel>
                    <FormControl>
                      <DatePicker
                        date={field.value}
                        onSelect={field.onChange}
                        variant="short"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="billing_frequency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Billing Frequency</FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={field.onChange}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="monthly">Monthly</SelectItem>
                        <SelectItem value="quarterly">Quarterly</SelectItem>
                        <SelectItem value="yearly">Yearly</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter>
                <Button type="submit">Save Changes</Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <Dialog open={dealModalVisible} onOpenChange={setDealModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Deal Details</DialogTitle>
          </DialogHeader>
          {selectedDealDetails && (
            <div className="space-y-4">
              <p><span className="font-semibold">Name:</span> {selectedDealDetails.name}</p>
              <p><span className="font-semibold">Status:</span> {selectedDealDetails.data.status}</p>
              <p><span className="font-semibold">Value:</span> {selectedDealDetails.data.value} {selectedDealDetails.data.currency}</p>
              <p><span className="font-semibold">Created:</span> {selectedDealDetails.created_at}</p>
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setDealModalVisible(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={accountModalVisible} onOpenChange={setAccountModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Account Details</DialogTitle>
          </DialogHeader>
          {selectedAccountDetails && (
            <div className="space-y-4">
              <p><span className="font-semibold">Company Name:</span> {selectedAccountDetails.company_name}</p>
              <p><span className="font-semibold">CVR:</span> {selectedAccountDetails.cvr || 'N/A'}</p>
              <p><span className="font-semibold">Address:</span> {selectedAccountDetails.address || 'N/A'}</p>
              <p><span className="font-semibold">Zipcode:</span> {selectedAccountDetails.zipcode || 'N/A'}</p>
              <p><span className="font-semibold">City:</span> {selectedAccountDetails.city || 'N/A'}</p>
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setAccountModalVisible(false)}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default Products