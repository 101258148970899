import React, { useState } from 'react';
import { Button, message, Card, Space, Select } from 'antd';
import axios from '../../api/axiosConfig';

const AdminPipedrive = () => {
    const [selectedStage, setSelectedStage] = useState('29'); // Default to Pending onboarding
    const [selectedStatus, setSelectedStatus] = useState('won'); // Default to Won deals

    const fetchDealFields = async () => {
        try {
            const response = await axios.get('/admin/pipedrive/dealfields');
            message.success('Deal fields printed to server console!');
        } catch (error) {
            message.error('Error: ' + (error.response?.data?.error || error.message));
        }
    };

    const handleExportDeals = async () => {
        try {
            const response = await axios.get('/admin/pipedrive/export-deals', {
                params: {
                    stage_id: selectedStage,
                    status: selectedStatus
                },
                responseType: 'blob' // Important for file download
            });
            
            // Create download link
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'pipedrive_deals.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
            
            message.success('Deals exported successfully!');
        } catch (error) {
            message.error('Error: ' + (error.response?.data?.error || error.message));
        }
    };

    return (
        <Card title="Pipedrive Data Fetching">
            <Space direction="vertical" style={{ width: '100%' }}>
                <Button 
                    type="primary" 
                    onClick={fetchDealFields}
                >
                    Fetch Deal Fields
                </Button>

                <Card title="Import pipedrive deals to list" style={{ marginTop: 16 }}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Select
                            style={{ width: '100%' }}
                            value={selectedStage}
                            onChange={setSelectedStage}
                            options={[
                                { label: 'Pending onboarding', value: '29' },
                                { label: 'Onboarded', value: '32' }
                            ]}
                        />
                        <Select
                            style={{ width: '100%' }}
                            value={selectedStatus}
                            onChange={setSelectedStatus}
                            options={[
                                { label: 'All won deals', value: 'won' },
                                { label: 'Aktive kunder', value: 'active' }
                            ]}
                        />
                        <Button type="primary" onClick={handleExportDeals}>
                            Export Deals
                        </Button>
                    </Space>
                </Card>
            </Space>
        </Card>
    );
};

export default AdminPipedrive;