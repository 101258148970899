import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '@/api/axiosConfig';
import type { StoredUser } from '@/types/user';

interface UserContextType {
  users: StoredUser[];
  onlineUsers: StoredUser[];
  currentUser: StoredUser | null;
  refreshUsers: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [users, setUsers] = useState<StoredUser[]>([]);
  const [onlineUsers, setOnlineUsers] = useState<StoredUser[]>([]);
  const [currentUser, setCurrentUser] = useState<StoredUser | null>(() => {
    const stored = localStorage.getItem('user');
    return stored ? JSON.parse(stored) : null;
  });

  const fetchAndStoreUsers = async () => {
    try {
      const response = await axios.get('/user');
      const users = response.data.filter((user: any) => !user.is_test_user);
      
      const usersData = users.reduce((acc: Record<string, StoredUser>, user: any) => {
        acc[user.user_id] = {
          user_id: user.user_id,
          name: user.name,
          surname: user.surname,
          email: user.email,
          avatar_urls: user.avatar_urls || {},
          settings: {
            avatar_color: user.settings?.avatar_color || '#f56a00',
            ...user.settings
          },
          status: user.status,
          birthday: user.birthday,
          location_info: user.location_info,
          last_heartbeat: user.last_heartbeat,
          initials: `${user.name?.[0] || ''}${user.surname?.[0] || ''}`.toUpperCase() || '?'
        };
        return acc;
      }, {});

      localStorage.setItem('usersData', JSON.stringify(usersData));
      
      const usersArray = Object.values(usersData) as StoredUser[];
      setUsers(usersArray);
      setOnlineUsers(usersArray.filter((user: StoredUser) => user.status === 'online'));
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchAndStoreUsers();
    // Refresh online status more frequently
    const interval = setInterval(fetchAndStoreUsers, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <UserContext.Provider value={{ 
      users, 
      onlineUsers, 
      currentUser,
      refreshUsers: fetchAndStoreUsers 
    }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUsers = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUsers must be used within a UserProvider');
  }
  return context;
};