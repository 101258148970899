import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import dayjs, { type Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import axios from '../api/axiosConfig';
import { toast } from 'sonner';
import { 
  Search,
  X,
  AlertTriangle,
  Clock,
  ChevronDown,
  CalendarIcon
} from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Badge } from "@/components/ui/badge";
import { 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/specialized/mod-table";
import { AvatarGroup } from "@/components/ui/avatars/avatar-group";
import { type StoredUser, type UserColors } from '@/types/user';
import { DatePickerWithRange } from "@/components/ui/date/date-picker-with-range";

import { 
  boardStatusConfigs, 
  getBoardStatus,
  isBoardConfigKey,
  type BoardStatusOption,
  type BoardConfigKey
} from '@/taskboards/config/board-status';

import { DealDrawer } from '@/drawers/DealDrawer';
import { TaskCompleteDrawer } from '@/taskboards/drawers/TaskCompleteDrawer';
import TaskView from '../taskboards/views/TaskView'; // Still a js file
import { AssigneeSelection } from '@/modals/AssigneeSelection';
import { UserAvatar } from '@/components/user-avatar';
import { TaskDateEditor } from '@/components/TaskDateEditor';
import { TaskStatusSelector } from '@/taskboards/components/TaskStatusSelector';
import { TimeTrackerCell } from '@/components/TimeTrackerCell';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"

import { 
  type Task,
  type FrontendTaskType,
  type ConfigKeyType,
  type EnhancedTask,
  type WorkFilters,
  isRecurringTask,
  type TaskData,
  type RecurringTaskType,
  type OnboardingTaskType,
} from '../types/task';
import { MultiSelectProps, DropdownProps } from '@/types/props';
import { ColumnDef } from '@/types/table';
import { StatusSelect } from "@/components/ui/select/StatusSelect";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Loading } from "@/components/ui/loading";
import { useUsers } from '@/contexts/UserContext';

// Define types
type User = StoredUser;

// Register the plugin
dayjs.extend(isBetween);

// Add these type definitions at the top
interface BoardNameMap {
  [key: string]: string;
}

interface RecurringTypeMap {
  [key: string]: string;
}

interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
  };
}

// Update getBoardDisplayName function
const getBoardDisplayName = (boardName: string | undefined): string => {
  if (!boardName) return '';
  
  const boardNameMap: Record<string, string> = {
    'Recurring lon': 'Løn',
    'Recurring bogforing': 'Bogføring',
    'Recurring moms': 'Moms',
    'Recurring arsafslutning': 'Årsafslutning',
    'Personal Task': 'Personal Task',
    'activity': 'Activity',
    'other': 'Andre Opgaver'
  };

  return boardNameMap[boardName] || boardName;
};

interface MenuClickEvent {
  key: string;
}

interface DatePickerRangeValue extends Array<Dayjs | null> {
  0: Dayjs | null;
  1: Dayjs | null;
}

interface DatePickerWithRangeProps {
  value: {
    from: Date | null;
    to: Date | null;
  };
  onChange: (dates: { from: Date | null; to: Date | null }) => void;
}

// Type the configKey function
const getConfigKey = (taskType: FrontendTaskType): ConfigKeyType => {
  switch(taskType) {
    case 'andre-opgaver':
    case 'activities':
      return 'andreOpgaver';
    default:
      return 'bogforing';
  }
};

// Update boardStatusConfigs usage
const getBoardStatusOptions = (configKey: string): BoardStatusOption[] => {
  if (isBoardConfigKey(configKey)) {
    return boardStatusConfigs[configKey];
  }
  return boardStatusConfigs.bogforing;
};

interface CompletionValues {
  timeSpent: number;
  timeReason?: string;
  timeComment?: string;
  paySlips?: number;
  updateInvoice?: boolean;
  updateContract?: boolean;
}

// Add proper type for updatedTask
interface UpdatedTask extends Task {
  board_status: string;
  progress_status: string;
}

// Add type for menu event
interface MenuEvent {
  key: string;
}

interface CustomColumnDef extends Omit<ColumnDef<EnhancedTask>, 'dataIndex'> {
  key: string;
  title: string;
  dataIndex?: keyof EnhancedTask | keyof TaskData;
}

interface CustomDateRange {
  from: Dayjs | null;
  to: Dayjs | null;
}

// Add type for the row prop
type TableCellProps<T> = {
  row: {
    original: T;
  };
};

// Helper function to check if task is recurring
const isRecurringTaskType = (taskType: FrontendTaskType): taskType is RecurringTaskType => {
  return ['moms', 'lon', 'bogforing', 'arsafslutning'].includes(taskType as string);
};

// Helper function to check if task is onboarding
const isOnboardingTaskType = (taskType: FrontendTaskType): taskType is OnboardingTaskType => {
  return ['andre-opgaver', 'activities'].includes(taskType as string);
};

export default function Work() {
  const { users, currentUser } = useUsers();
  const [selectedPeriod, setSelectedPeriod] = useState<string | null>(null);
  const [selectedTodo, setSelectedTodo] = useState<string>('todo');
  const [selectedAssignee, setSelectedAssignee] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [customDateRange, setCustomDateRange] = useState<CustomDateRange>({
    from: null,
    to: null
  });
  const [allTasks, setAllTasks] = useState<EnhancedTask[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [assignees, setAssignees] = useState<User[]>([]);
  const [userColors, setUserColors] = useState<UserColors>({});
  const [dealDrawerVisible, setDealDrawerVisible] = useState<boolean>(false);
  const [selectedDeal, setSelectedDeal] = useState<number | null>(null);
  const [filters, setFilters] = useState<WorkFilters>({
    progress_status: ['Not started', 'In progress'],
    board_name: []
  });
  const [taskCompleteDrawerVisible, setTaskCompleteDrawerVisible] = useState<boolean>(false);
  const [selectedTaskForCompletion, setSelectedTaskForCompletion] = useState<Task | null>(null);
  const navigate = useNavigate();
  const { boardType, taskId } = useParams();
  const [isTaskViewVisible, setIsTaskViewVisible] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [assigneeModalVisible, setAssigneeModalVisible] = useState<boolean>(false);
  const [selectedTaskForAssignee, setSelectedTaskForAssignee] = useState<Task | null>(null);
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      setSelectedAssignee([String(currentUser.user_id)]);
    }
  }, [currentUser]);

  useEffect(() => {
    if (boardType && taskId) {
      const task = allTasks.find(t => t.id.toString() === taskId && getBoardName(t) === boardType);
      if (task) {
        setSelectedTask(task);
        setIsTaskViewVisible(true);
      }
    }
  }, [boardType, taskId, allTasks]);

  const fetchTasks = useCallback(async () => {
    try {
      const assigneeIds = selectedAssignee.length > 0 ? selectedAssignee : (currentUser ? [currentUser.user_id] : []);
      const params = new URLSearchParams({
        assignees: assigneeIds.join(','),
      });
      const response = await axios.get(`/work/assigned_tasks?${params}`);
      setAllTasks(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setLoading(false);
    }
  }, [currentUser, selectedAssignee]);

  // Update the allTasksData useMemo
  const allTasksData = useMemo(() => {
    return allTasks.map(task => ({
      ...task,
      key: isRecurringTask(task) 
          ? `${task.task_type}-${task.recurring_type}-${task.id}`
          : `${task.task_type}-${task.id}`,
      board_name: isRecurringTask(task) ? `Recurring ${task.recurring_type}` : 
                 task.task_type === 'custom' ? task.board_name : 'Onboarding',
      data: {
        Task: task.task_type === 'custom' ? task.title : (task.description || task.text),
        'Due Date': task.next_due_date || task.due_date || task.task_date,
        'Work Date': task.next_work_date || task.work_date || task.task_date,
        Status: task.progress_status || task.status,
        Assignee: (task.assigned_users || [task.user_id]).filter((id): id is string => id !== undefined),
      } as TaskData,
      deal_id: task.deal_id,
      deal_name: task.deal_name || (task.deal ? task.deal.name : null),
    }));
  }, [allTasks]);

  // Update getDateRange function
  const getDateRange = (key: string | null): [Dayjs | null, Dayjs | null] => {
    const today = dayjs();
    
    if (!key) return [null, null];

    switch (key) {
      case 'today':
        return [today.startOf('day'), today.endOf('day')];
      case 'thisMonth':
        return [today.startOf('month'), today.endOf('month')];
      case 'lastMonth':
        return [
          today.subtract(1, 'month').startOf('month'),
          today.subtract(1, 'month').endOf('month')
        ];
      case 'thisAndNextMonth':
        return [
          today.startOf('month'),
          today.add(1, 'month').endOf('month')
        ];
      case 'next3Months':
        return [today.startOf('day'), today.add(3, 'months').endOf('day')];
      case 'custom':
        if (!customDateRange || !customDateRange.from || !customDateRange.to) {
          return [null, null];
        }
        return [
          dayjs(customDateRange.from),
          dayjs(customDateRange.to)
        ];
      default:
        return [null, null];
    }
  };

  // Update isTaskInPeriod function
  const isTaskInPeriod = (task: Task, period: string): boolean => {
    try {
      const rawDueDate = task.due_date;
      if (!rawDueDate) return false;
      const dueDate = dayjs(rawDueDate);
      if (!dueDate.isValid()) return false;

      const [startDate, endDate] = getDateRange(period);
      if (!startDate || !endDate) return false;

      // Use inclusive comparison
      return dueDate >= startDate && dueDate <= endDate;
    } catch (error) {
      console.error('Error checking task period:', error);
      return false;
    }
  };

  const isTaskOverdue = (task: Task): boolean => {
    const dueDate = dayjs(task.due_date);
    return dueDate.isBefore(dayjs(), 'day');
  };

  const filteredTasks = useMemo(() => {
    return allTasksData.filter((task) => {
      const taskDescription = task.data.Task || task.description || '';
      const boardName = task.board_name || '';
      
      const matchesSearch = taskDescription.toLowerCase().includes(searchText.toLowerCase()) ||
        boardName.toLowerCase().includes(searchText.toLowerCase());
      
      const matchesPeriod = selectedPeriod ? isTaskInPeriod(task, selectedPeriod) : true;
      
      const matchesTodo = selectedTodo === 'todo' ? task.data.Status !== 'Completed' :
                         selectedTodo === 'done' ? task.data.Status === 'Completed' :
                         selectedTodo === 'overdue' ? isTaskOverdue(task) : true;
      
      return matchesSearch && matchesPeriod && matchesTodo;
    });
  }, [allTasksData, searchText, selectedPeriod, selectedTodo]);

  const sortedAndFilteredTasks = useMemo(() => {
    return filteredTasks.sort((a, b) => {
      const dateA = dayjs(a.data['Work Date'] || a.work_date);
      const dateB = dayjs(b.data['Work Date'] || b.work_date);
      return dateA.diff(dateB);
    });
  }, [filteredTasks]);

  useEffect(() => {
    if (currentUser && !isTaskViewVisible) {
      fetchTasks();
    }
  }, [currentUser, fetchTasks, isTaskViewVisible]);

  useEffect(() => {
    const fetchAssignees = async () => {
      try {
        const response = await axios.get('/user');
        setAssignees(response.data);
      } catch (error) {
        console.error('Error fetching assignees:', error);
      }
    };
    fetchAssignees();
  }, []);

  const getRowClassName = (record: EnhancedTask) => {
    const dueDate = dayjs(record.data['Due Date']);
    const workDate = dayjs(record.data['Work Date'] || record.work_date);
    const today = dayjs().startOf('day');
    const status = record.data.Status;

    if (workDate.isSame(today, 'day') || 
        (dueDate.isBefore(today) && status !== 'Completed') || 
        (workDate.isBefore(today) && status !== 'Completed')) {
      return 'needs-attention';
    }

    if (dueDate.isSame(today, 'day')) {
      return 'due-today';
    } else if (dueDate.isBefore(today)) {
      return 'overdue';
    }

    return '';
  };

  const handleDealClick = (dealId: string | number): void => {
    setSelectedDeal(typeof dealId === 'string' ? parseInt(dealId, 10) : dealId);
    setDealDrawerVisible(true);
  };

  const getUniqueValues = (data: EnhancedTask[], key: keyof EnhancedTask | keyof TaskData) => {
    if (key === 'board_name') {
      const uniqueBoards = new Set(data.map(item => item[key as keyof EnhancedTask]));
      return Array.from(uniqueBoards).map(boardName => ({
        text: getBoardDisplayName(boardName as string),
        value: boardName
      }));
    }
    const values = new Set(data.map(item => item.data[key as keyof TaskData]));
    return Array.from(values).map(value => ({ text: value, value: value }));
  };

  // Update handleProgressStatusChange function
  const handleProgressStatusChange = async (taskId: number, taskType: FrontendTaskType, newStatus: string): Promise<void> => {
    setAllTasks(prevTasks =>
      prevTasks.map(task => 
        task.id === taskId && (
          task.task_type === taskType || 
          (isOnboardingTaskType(taskType) && ['andre-opgaver', 'activities'].includes(task.task_type))
        )
        ? { ...task, progress_status: newStatus, status: newStatus }
        : task
      )
    );
    
    try {
      await axios.patch(`/api/tasks/${taskId}/progress`, {
        status: newStatus,
        task_type: taskType
      });
      toast.success('Status updated successfully');
    } catch (error) {
      console.error('Error updating status:', error);
      toast.error('Failed to update status');
      // Rollback optimistic update
      setAllTasks(prevTasks =>
        prevTasks.map(task =>
          task.id === taskId
            ? { ...task, progress_status: task.progress_status || 'Not started' }
            : task
        )
      );
    }
  };

  const handleTaskCompletion = async (values: CompletionValues) => {
    if (!selectedTaskForCompletion) return;
    
    const configKey = getConfigKey(selectedTaskForCompletion.task_type);
    const boardStatusOptions = boardStatusConfigs[configKey as BoardConfigKey] || boardStatusConfigs.bogforing;
    
    if (selectedTaskForCompletion.board_status === boardStatusOptions[boardStatusOptions.length - 1].value) {
      const taskToComplete: Task = {
        ...selectedTaskForCompletion,
        key: selectedTaskForCompletion.key || `${selectedTaskForCompletion.task_type}-${selectedTaskForCompletion.id}`,
        data: selectedTaskForCompletion.data || {}
      } as Task;
      setSelectedTaskForCompletion(taskToComplete);
      setTaskCompleteDrawerVisible(true);
    }
  };

  const getBoardName = (task: Task): FrontendTaskType => {
    if (isRecurringTaskType(task.task_type)) {
      return task.recurring_type as FrontendTaskType || 'bogforing';
    }
    
    if (task.task_type === 'andre-opgaver') {
      return 'andre-opgaver';
    }
    
    if (task.task_type === 'activities') {
      return 'activities';
    }
    
    return 'bogforing';
  };

  const handleOpenTask = (task: Task) => {
    setSelectedTask(task);
    setIsTaskViewVisible(true);
    navigate(`/work/${getBoardName(task)}/${task.id}`, { 
      state: { previousPath: location.pathname }
    });
  };

  const handleCloseTask = () => {
    setIsTaskViewVisible(false);
    setSelectedTask(null);
    navigate('/work', { replace: true });
  };

  const handleAssigneeUpdate = async (newAssignees: string[]) => {
    if (!selectedTaskForAssignee) return;
    
    try {
      const response = await axios.put(`/work/update_assignees`, {
        task_id: selectedTaskForAssignee.id,
        task_type: selectedTaskForAssignee.task_type,
        assignees: newAssignees
      });
      
      if (response.status === 200) {
        setAllTasks(prevTasks => 
          prevTasks.map(task => 
            task.id === selectedTaskForAssignee.id && task.task_type === selectedTaskForAssignee.task_type
              ? { ...task, assigned_users: newAssignees }
              : task
          )
        );
        toast.success('Assignees updated successfully');
      }
    } catch (error) {
      console.error('Error updating assignees:', error);
      toast.error('Failed to update assignees');
    }
  };

  // Column definition for the mod-table
  const columns: ColumnDef<EnhancedTask>[] = [
    {
      key: "task",
      title: "Task",
      dataIndex: "data.Task",
      cell: ({ row }: TableCellProps<EnhancedTask>) => {
        const text = row.original.data.Task;
        const dueDate = dayjs(row.original.data['Due Date']);
        const today = dayjs().startOf('day');

        if (dueDate.isBefore(today)) {
          return (
            <div className="flex items-center gap-2">
              <span>{text}</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <AlertTriangle className="h-4 w-4 text-destructive" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Overdue</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        } else if (dueDate.isSame(today, 'day')) {
          return (
            <div className="flex items-center gap-2">
              <span>{text}</span>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Clock className="h-4 w-4 text-warning" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Due Today</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        }
        return text;
      }
    },
    {
      key: "assignee",
      title: "Assignee",
      dataIndex: "data",
      cell: ({ row }: TableCellProps<EnhancedTask>) => {
        const record = row.original;
        const assigneeIds: string[] = record.assigned_users || 
          (record.user_id ? [String(record.user_id)] : []);

        const assigneeUsers = assigneeIds
          .map((id: string) => users.find(u => String(u.user_id) === id))
          .filter((user): user is StoredUser => user !== undefined);

        return (
          <div 
            onClick={(e) => {
              e.stopPropagation();
              setSelectedTaskForAssignee(record);
              setAssigneeModalVisible(true);
            }}
            className="flex items-center cursor-pointer hover:opacity-80"
          >
            {assigneeUsers.length > 0 ? (
              <AvatarGroup 
                users={assigneeUsers}
                max={3}
              />
            ) : (
              <span className="text-sm text-muted-foreground">Unassigned</span>
            )}
            {assigneeUsers.length > 3 && (
              <span className="ml-1 text-sm text-muted-foreground">
                +{assigneeUsers.length - 3}
              </span>
            )}
          </div>
        );
      }
    },
    {
      key: "deal",
      title: "Deal",
      dataIndex: "deal_name",
      cell: ({ row }: TableCellProps<EnhancedTask>) => {
        const deal_name = row.original.deal_name;
        const deal_id = row.original.deal_id;
        if (!deal_name || !deal_id) return null;
        
        return (
          <div 
            className="cursor-pointer"
            onClick={() => handleDealClick(deal_id)}
          >
            <Badge variant="secondary">
              {deal_name}
            </Badge>
          </div>
        );
      }
    },
    {
      key: "timetracker",
      title: "Time Tracker",
      dataIndex: "timetracker",
      cell: ({ row }: TableCellProps<EnhancedTask>) => {
        if (row.original.task_type === 'custom' && !row.original.enable_time_tracking) {
          return null;
        }
        return (
          <TimeTrackerCell
            task={row.original}
          />
        );
      }
    },
    {
      key: "progress",
      title: "Progress",
      dataIndex: "data.Status",
      cell: ({ row }: TableCellProps<EnhancedTask>) => {
        const status = row.original.data?.Status || 'Not started';
        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className={cn(
                "border-primary",
                getStatusColor(status)
              )}>
                {status}
                <ChevronDown className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {['Not started', 'In progress', 'Completed'].map(option => (
                <DropdownMenuItem 
                  key={option}
                  onClick={() => handleProgressStatusChange(
                    row.original.id, 
                    row.original.task_type, 
                    option
                  )}
                >
                  {option}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      }
    },
    {
      key: "dueDate",
      title: "Due Date",
      dataIndex: "due_date",
      cell: ({ row }: TableCellProps<EnhancedTask>) => (
        <TaskDateEditor
          date={(row.original.data['Due Date'] || row.original.due_date) ?? null}
          dateType="due"
          taskId={row.original.id}
          taskType={row.original.task_type}
        />
      )
    },
    {
      key: "workDate",
      title: "Work Date",
      dataIndex: "work_date",
      cell: ({ row }: TableCellProps<EnhancedTask>) => (
        <TaskDateEditor
          date={(row.original.data['Work Date'] || row.original.work_date) ?? null}
          dateType="work"
          taskId={row.original.id}
          taskType={row.original.task_type}
        />
      )
    },
    {
      key: "board_name",
      title: "Board",
      dataIndex: "board_name",
      cell: ({ row }: TableCellProps<EnhancedTask>) => {
        const record = row.original;
        return (
          <Badge variant="secondary">
            {getBoardDisplayName(record.board_name)}
          </Badge>
        );
      }
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "board_status",
      cell: ({ row: { original: record } }: TableCellProps<EnhancedTask>) => {
        const taskType = record.task_type;
        const configKey = getConfigKeyFromTask(record);
        
        return (
          <TaskStatusSelector
            value={record.board_status || ''}
            taskId={record.id}
            taskType={taskType}
            boardStatusOptions={boardStatusConfigs[configKey] || boardStatusConfigs.bogforing}
            progressStatus={record.progress_status || ''}
            onSuccess={(updatedTask) => {
              const enhancedUpdatedTask = {
                ...updatedTask,
                task_type: record.task_type,
                isRecurring: isRecurringTaskType(record.task_type),
                recurringType: record.recurring_type
              };
              handleTaskStatusChange(enhancedUpdatedTask);
            }}
          />
        );
      }
    }
  ];

  const getPeriodLabel = (key: string | null): string => {
    if (!key) return 'Period';

    switch (key) {
      case 'today':
        return 'To do today';
      case 'thisMonth':
        return 'This month';
      case 'lastMonth':
        return 'Last month';
      case 'thisAndNextMonth':
        return 'This and next month';
      case 'next3Months':
        return 'Next 3 months';
      case 'custom':
        if (customDateRange && customDateRange.from && customDateRange.to) {
          return `${customDateRange.from.format('YYYY-MM-DD')} - ${customDateRange.to.format('YYYY-MM-DD')}`;
        }
        return 'Custom Period';
      default:
        return 'Period';
    }
  };

  const periodMenu = {
    items: [
      { key: 'today', label: 'To do today' },
      { key: 'thisMonth', label: 'This month' },
      { key: 'lastMonth', label: 'Last month' },
      { key: 'thisAndNextMonth', label: 'This and next month' },
      { key: 'next3Months', label: 'Next 3 months' },
      {
        key: 'custom',
        label: (
          <div 
            onClick={e => e.stopPropagation()}
            style={{ padding: '8px' }}
          >
            <DatePickerWithRange
              value={{
                from: customDateRange.from?.toDate() || null,
                to: customDateRange.to?.toDate() || null
              }}
              onChange={(dates) => {
                setCustomDateRange({
                  from: dates.from ? dayjs(dates.from) : null,
                  to: dates.to ? dayjs(dates.to) : null
                });
              }}
            />
          </div>
        ),
      },
    ],
    onClick: (e: MenuClickEvent) => {
      if (e.key !== 'custom') {
        setSelectedPeriod(e.key);
        const [start, end] = getDateRange(e.key);
        setCustomDateRange({
          from: start,
          to: end
        });
      }
    },
  };

  const todoOptions = [
    { key: 'todo', label: 'To do' },
    { key: 'overdue', label: 'Overdue' },
    { key: 'done', label: 'Done' },
  ];

  const todoMenu = {
    items: todoOptions.map(option => ({
      key: option.key,
      label: option.label,
    })),
    onClick: (e: MenuClickEvent) => {
      setSelectedTodo(e.key);
      if (e.key === 'done') {
        setFilters({
          ...filters,
          progress_status: ['Completed']
        });
      } else if (e.key === 'todo') {
        setFilters({
          ...filters,
          progress_status: ['Not started', 'In progress']
        });
      } else {
        setFilters({
          ...filters,
          progress_status: []
        });
      }
    },
  };

  const renderMultiSelect = ({ options, placeholder, value, setValue }: MultiSelectProps) => ({
    items: [{
      key: 'content',
      label: (
        <Select
          value={value[0]}
          onValueChange={(newValue: string) => setValue([newValue])}
        >
          <SelectContent>
            {options.map((option) => (
              <SelectItem 
                key={option.user_id.toString()} 
                value={option.user_id.toString()}
              >
                {option.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      )
    }]
  });

  const renderDropdown = (menu: { items: Array<{ key: string; label: string | JSX.Element }> }, buttonText: string, className = '') => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button 
          variant="outline" 
          className={cn(
            "text-foreground border-primary",
            className
          )}
        >
          {buttonText}
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {menu.items.map((item) => (
          <DropdownMenuItem key={item.key}>
            {item.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );

  const clearFilters = () => {
    setFilters({
      progress_status: ['Not started', 'In progress'],
      board_name: []
    });
    setSelectedPeriod(null);
    setSelectedTodo('todo');
    setSelectedAssignee(currentUser?.user_id ? [String(currentUser.user_id)] : []);
    setSearchText('');
    setCustomDateRange({
      from: null,
      to: null
    });
  };

  // Helper function to get status color
  const getStatusColor = (status: string): string => {
    switch (status) {
      case 'Completed':
        return 'green';
      case 'In progress':
        return 'orange';
      case 'Not started':
        return 'red';
      default:
        return 'gray';
    }
  };

  const handleTaskStatusChange = (updatedTask: UpdatedTask) => {
    // Update tasks state optimistically
    setAllTasks(prevTasks =>
      prevTasks.map(task =>
        task.id === updatedTask.id
          ? { 
              ...task, 
              board_status: updatedTask.board_status,
              progress_status: updatedTask.progress_status,
              data: {
                ...task.data,
                Status: updatedTask.progress_status
              }
            }
          : task
      )
    );

    const configKey = getConfigKeyFromTask(updatedTask);
    const boardStatusOptions = boardStatusConfigs[configKey];

    if (updatedTask.board_status === boardStatusOptions[boardStatusOptions.length - 1].value) {
      const taskToComplete = {
        ...updatedTask,
        task_type: updatedTask.task_type,
        isRecurring: isRecurringTaskType(updatedTask.task_type),
        recurringType: updatedTask.recurring_type,
        rollback: updatedTask.rollback
      };
      setSelectedTaskForCompletion(taskToComplete);
      setTaskCompleteDrawerVisible(true);
    }
  };

  const handleCompleteDrawerClose = () => {
    // Rollback the optimistic update when drawer is closed without submission
    if (selectedTaskForCompletion?.rollback) {
      selectedTaskForCompletion.rollback();
    }
    setTaskCompleteDrawerVisible(false);
    setSelectedTaskForCompletion(null);
  };

  const handleStatusChange = (record: EnhancedTask, newStatus: string) => {
    // Implementation here
  };

  const getApiTaskType = (task: Task): FrontendTaskType => {
    if (isRecurringTask(task)) {
      return task.recurring_type as FrontendTaskType;
    }
    return task.task_type;
  };

  const getConfigKeyFromTask = (task: Task): BoardConfigKey => {
    if (isRecurringTaskType(task.task_type)) {
      return task.recurring_type as BoardConfigKey || 'bogforing';
    }
    
    if (task.task_type === 'andre-opgaver') {
      return 'andre-opgaver';
    }
    
    if (task.task_type === 'activities') {
      return 'activities';
    }
    
    return 'bogforing'; // Default for custom tasks
  };

  const isValidConfigKey = (key: string): key is BoardConfigKey => {
    return ['bogforing', 'lon', 'moms', 'arsafslutning', 'andreOpgaver', 'activities'].includes(key);
  };

  // Add helper function for personal task check
  const isPersonalTask = (task: Task): boolean => {
    return task.task_type === 'andre-opgaver' && task.user_id !== undefined;
  };

  // Update loading spinner component
  if (!users) {
    return <Loading message="Loading tasks..." />;
  }

  return (
    <div className="p-6 max-w-full overflow-x-hidden">
      <h1 className="text-2xl font-bold text-foreground">My Work</h1>
      
      <div className="flex flex-wrap items-center gap-4 my-6">
        {renderDropdown(periodMenu, getPeriodLabel(selectedPeriod), selectedPeriod ? 'filter-active' : '')}
        {renderDropdown(todoMenu, todoOptions.find(option => option.key === selectedTodo)?.label || 'To do', 'filter-active')}
        {renderDropdown(
          renderMultiSelect({
            options: users,
            placeholder: 'Select assignee',
            value: selectedAssignee,
            setValue: setSelectedAssignee,
          }),
          selectedAssignee.length > 0 ? `Assignee (${selectedAssignee.length})` : 'Assignee',
          selectedAssignee.length > 0 ? 'filter-active' : ''
        )}
        <Button 
          variant="ghost"
          onClick={clearFilters}
          className="gap-2 text-foreground"
        >
          <X className="h-4 w-4" />
          Clear Filters
        </Button>
      </div>

      <div className="mb-6 w-[280px]">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            type="text"
            placeholder="Search by account or task name"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            className="pl-9 w-full"
          />
        </div>
      </div>

      <div className="w-full">
        <Table
          data={sortedAndFilteredTasks}
          columns={columns}
          pageSize={15}
          currentPage={1}
          className="w-full"
        />
      </div>

      {/* Render the TaskView modal */}
      {isTaskViewVisible && selectedTask && (
        <TaskView
          onClose={handleCloseTask}
          taskType={getBoardName(selectedTask)}
          boardStatusOptions={boardStatusConfigs[getBoardName(selectedTask)] || boardStatusConfigs.bogforing}
          task={selectedTask}
          fromWork={true}
          tasks={sortedAndFilteredTasks}
          getBoardName={getBoardName}
          dealName={selectedTask.deal_name || selectedTask.deal?.name}
        />
      )}

      <DealDrawer 
        visible={dealDrawerVisible} 
        onClose={() => setDealDrawerVisible(false)} 
        dealId={selectedDeal}
      />
      <TaskCompleteDrawer
        visible={taskCompleteDrawerVisible}
        onClose={handleCompleteDrawerClose}
        task={selectedTaskForCompletion}
        onComplete={handleTaskCompletion}
      />
      <AssigneeSelection
        visible={assigneeModalVisible}
        onClose={() => {
          setAssigneeModalVisible(false);
          setSelectedTaskForAssignee(null);
        }}
        onSave={async (assignees) => {
          if (!selectedTaskForAssignee) return;
          
          try {
            await axios.patch(`/api/tasks/${selectedTaskForAssignee.id}/assignees`, {
              assignees
            });
            
            // Update the task in the local state
            setAllTasks(prevTasks =>
              prevTasks.map(task =>
                task.id === selectedTaskForAssignee.id
                  ? { ...task, data: { ...task.data, Assignee: assignees } }
                  : task
              )
            );
            
            toast.success('Assignees updated successfully');
          } catch (error) {
            console.error('Error updating assignees:', error);
            toast.error('Failed to update assignees');
          }
        }}
        initialAssignees={selectedTaskForAssignee?.data.Assignee || []}
      />
    </div>
  );
}