import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Settings,
  LogOut,
  UserCog,
  ChevronsUpDown,
} from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@/components/ui/sidebar';
import axios from '@/api/axiosConfig';
import UserManagementModal from '@/modals/UserManagement';
import UserSettingsModal from '@/modals/UserSettings';
import { UserProfile } from '@/modals/UserProfile';
import SettingsModal from '@/settings/Settings';
import UserAvatar from '@/components/user-avatar';
import { ProfileData, UserSettings as UserSettingsType } from '@/types/user'
import { cn } from '@/lib/utils';

interface User {
  user_id: string | number;
  name: string;
  email: string;
  avatar_url?: string;
  initials?: string;
  status?: string;
}

interface NavUserProps {
  user: User;
  onUpdateUsers: (allUsers: User[], onlineUsers: User[]) => void;
  collapsed?: boolean;
}

export function NavUser({ user, onUpdateUsers, collapsed }: NavUserProps) {
  const { isMobile } = useSidebar();
  const navigate = useNavigate();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showUserManagementModal, setShowUserManagementModal] = useState(false);
  const [showDigiTalModal, setShowDigiTalModal] = useState(false);
  const [profileData, setProfileData] = useState<ProfileData>({
    user_id: '',
    name: '',
    surname: '',
    email: '',
    birthday: null,
    location_info: {
      city: '',
      country: '',
      latitude: 0,
      longitude: 0,
      timezone: ''
    },
    settings: {
      theme: 'light',
      language: 'en',
      avatar_color: '#1890ff',
      desktop_notifications: true,
      email_notifications: true,
      auto_login: true
    },
    avatar_urls: {}
  });

  const [countries, setCountries] = useState<string[]>([]);


  const fetchProfile = async () => {
    try {
      const response = await axios.get('/user/profile');
      setProfileData(response.data);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const fetchCountries = async () => {
    try {
      const response = await axios.get('https://restcountries.com/v3.1/all');
      const sortedCountries = response.data
        .map((country: any) => country.name.common)
        .sort((a: string, b: string) => a.localeCompare(b));
      setCountries(sortedCountries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
    fetchCountries();
  }, []);

  if (!user) return null;

  const handleLogout = () => {
    // Optimistically clear local storage and navigate
    localStorage.clear(); // Or be more specific with what to remove
    navigate('/login');
    
    // Then attempt server logout
    axios.post('/auth/logout').catch((error) => {
      console.error('Error logging out:', error);
      // Optionally handle failed logout
      // In most cases, we don't need to do anything since the user is already logged out locally
    });
  };

  return (
    <>
      <SidebarMenu className="mt-2">
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                size="lg"
                className={cn(
                  "w-full justify-start gap-2",
                  collapsed && "justify-center"
                )}
              >
                <UserAvatar
                  user={user}
                  size={32}
                  className="shrink-0"
                />
                {!collapsed && (
                  <>
                    <div className="grid flex-1 text-left text-sm leading-tight">
                      <span className="truncate font-semibold">{user?.name || 'User'}</span>
                      <span className="truncate text-xs text-muted-foreground">{user?.email || ''}</span>
                    </div>
                    <ChevronsUpDown className="h-4 w-4 shrink-0" />
                  </>
                )}
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-56"
              side={isMobile ? "bottom" : "right"}
              align="end"
              sideOffset={4}
            >
              <DropdownMenuLabel>My Account</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => setShowProfileModal(true)}>
                  <UserCog className="mr-2 h-4 w-4" />
                  Profile
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => setShowSettingsModal(true)}>
                  <Settings className="mr-2 h-4 w-4" />
                  Settings
                </DropdownMenuItem>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={handleLogout}>
                <LogOut className="mr-2 h-4 w-4" />
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>

      {/* Modals */}
      <UserManagementModal
        open={showUserManagementModal}
        onClose={() => setShowUserManagementModal(false)}
      />
      <UserSettingsModal
        open={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
      <UserProfile
        visible={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        profileData={profileData}
        onProfileUpdate={async (values: ProfileData) => {
          try {
            await axios.put('/user/profile', values);
            fetchProfile();
          } catch (error) {
            console.error('Error updating profile:', error);
          }
        }}
        onAvatarUpdate={fetchProfile}
        countries={countries}
      />
      <SettingsModal
        visible={showDigiTalModal}
        onClose={() => setShowDigiTalModal(false)}
      />
    </>
  );
}
