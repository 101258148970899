import React, { useContext } from 'react';
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Play, Pause, Square } from 'lucide-react';
import { TimeTrackerContext } from '@/contexts/TimeTrackerContext';
import { type Task } from '@/types/task';
import { isRecurringTask } from '@/types/task';

interface TimeTrackerCellProps {
  task: Task;
}

const TimeTrackerCell: React.FC<TimeTrackerCellProps> = ({ task }) => {
  const { 
    isRunning, 
    isPaused, 
    currentTask,
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer
  } = useContext(TimeTrackerContext);

  const isCurrentTask = currentTask?.taskId === task.id;

  const handleStartTimer = () => {
    startTracking({
      taskId: task.id,
      taskType: task.task_type,
      dealId: task.deal_id,
      recurringType: isRecurringTask(task) ? task.recurring_type : null,
      dealName: task.deal_name || task.deal?.name,
      taskDescription: task.description || task.data?.Task
    });
  };

  return (
    <div className="flex items-center gap-1">
      {isCurrentTask && isRunning ? (
        <div className={cn(
          "flex items-center gap-1 transition-all duration-300 ease-in-out",
          "opacity-0 max-w-0 overflow-hidden",
          isCurrentTask && isRunning && "opacity-100 max-w-[100px]"
        )}>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  variant={isPaused ? "default" : "destructive"}
                  className={cn(
                    "h-8 w-8 rounded-full",
                    !isPaused && "bg-warning hover:bg-warning/90"
                  )}
                  onClick={() => isPaused ? resumeTimer() : pauseTracking()}
                >
                  {isPaused ? (
                    <Play className="h-4 w-4 text-white" />
                  ) : (
                    <Pause className="h-4 w-4 text-white" />
                  )}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{isPaused ? "Resume Timer" : "Pause Timer"}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="icon"
                  variant="destructive"
                  className="h-8 w-8 rounded-full"
                  onClick={stopTracking}
                >
                  <Square className="h-4 w-4 text-white" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Stop Timer</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ) : (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                size="icon"
                variant="default"
                className={cn(
                  "h-8 w-8 rounded-full bg-gradient-to-br from-primary to-rose-500",
                  "hover:scale-110 transition-transform duration-300",
                  "shadow-sm hover:shadow-md",
                  isRunning && !isCurrentTask && "opacity-50 cursor-not-allowed",
                  "group"
                )}
                onClick={handleStartTimer}
                disabled={isRunning && !isCurrentTask}
              >
                <Play className={cn(
                  "h-4 w-4 text-white",
                  "transition-transform duration-300 ease-in-out",
                  "group-hover:rotate-[360deg]"
                )} />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>Start Timer</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};

export { TimeTrackerCell };