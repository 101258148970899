import React from 'react';
import { Paperclip, X, File } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";

interface Attachment {
  id: string;
  name: string;
  size: number;
  progress: number;
}

interface EmailAttachmentsProps {
  attachments: Attachment[];
  onRemoveAttachment: (id: string) => void;
  onAddAttachments: (files: FileList) => void;
}

export function EmailAttachments({
  attachments,
  onRemoveAttachment,
  onAddAttachments,
}: EmailAttachmentsProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const formatFileSize = (bytes: number): string => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Button
          variant="outline"
          size="sm"
          className="gap-2"
          onClick={() => fileInputRef.current?.click()}
        >
          <Paperclip className="h-4 w-4" />
          Add Attachment
        </Button>
        <input
          type="file"
          ref={fileInputRef}
          className="hidden"
          multiple
          onChange={(e) => e.target.files && onAddAttachments(e.target.files)}
        />
      </div>
      
      {attachments.length > 0 && (
        <div className="flex flex-col gap-2 mt-2">
          {attachments.map((attachment) => (
            <div
              key={attachment.id}
              className="flex items-center gap-2 p-2 border border-border rounded-lg"
            >
              <File className="h-4 w-4 text-muted-foreground" />
              <div className="flex-1 min-w-0">
                <div className="flex justify-between items-center">
                  <span className="text-sm truncate">{attachment.name}</span>
                  <span className="text-sm text-muted-foreground">
                    {formatFileSize(attachment.size)}
                  </span>
                </div>
                {attachment.progress < 100 && (
                  <Progress value={attachment.progress} className="h-1 mt-1" />
                )}
              </div>
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={() => onRemoveAttachment(attachment.id)}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}