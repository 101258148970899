import { useState, useEffect } from 'react'
import { Bug, Clock } from 'lucide-react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Badge } from "@/components/ui/badge"
import { toast } from "sonner"
import { Table, type ColumnDef } from "@/components/ui/specialized/mod-table"
import axios from '../../api/axiosConfig'
import dayjs from 'dayjs'

interface JobStatus {
  id: string
  job_name: string
  run_time: string
  status: 'success' | 'partial_success' | 'error' | 'failed'
  logs?: string
}

interface ErrorLog {
  id: string
  timestamp: string
  endpoint: string
  error_type: string
  error_message: string
  stack_trace: string
  request_data: any
}

interface AdminStatusProps {
  activeTab?: string;
}

export default function AdminStatus({ activeTab = "schedule" }: AdminStatusProps) {
  const [jobStatuses, setJobStatuses] = useState<JobStatus[]>([])
  const [loading, setLoading] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedJobLogs, setSelectedJobLogs] = useState('')

  useEffect(() => {
    fetchJobStatuses()
  }, [])

  const fetchJobStatuses = async () => {
    try {
      const response = await axios.get('/admin/job-status')
      setJobStatuses(response.data)
      setLoading(false)
    } catch (error) {
      console.error('Error fetching job statuses:', error)
      toast.error('Failed to fetch job statuses')
      setLoading(false)
    }
  }

  const showLogs = async (jobId: string) => {
    try {
      const response = await axios.get(`/admin/job-status/${jobId}`)
      setSelectedJobLogs(response.data.logs || 'No logs available')
      setModalVisible(true)
    } catch (error) {
      console.error('Error fetching job logs:', error)
      toast.error('Failed to fetch job logs')
    }
  }

  const jobColumns: ColumnDef<JobStatus>[] = [
    {
      key: 'job_name',
      title: 'Job Name',
      dataIndex: 'job_name',
      sortable: {
        enabled: true,
        type: 'string'
      },
      filterable: true,
      filters: Array.from(new Set(jobStatuses.map(job => job.job_name))).map(name => ({ 
        text: name, 
        value: name 
      })),
      onFilter: (value, record) => record.job_name.indexOf(value) === 0,
    },
    {
      key: 'run_time',
      title: 'Run Time',
      dataIndex: 'run_time',
      sortable: {
        enabled: true,
        type: 'date'
      },
      render: (run_time) => dayjs(run_time).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (status) => {
        const variants: Record<string, "default" | "destructive" | "success" | "warning"> = {
          success: "success",
          partial_success: "warning",
          error: "destructive",
          failed: "destructive"
        }
        return (
          <Badge variant={variants[status]}>
            {status.toUpperCase().replace('_', ' ')}
          </Badge>
        )
      },
      filterable: true,
      filters: [
        { text: 'Success', value: 'success' },
        { text: 'Partial Success', value: 'partial_success' },
        { text: 'Failed', value: 'failed' },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'id',
      cell: ({ row }) => (
        <Button 
          variant="outline" 
          size="sm"
          onClick={() => showLogs(row.original.id)}
        >
          View Logs
        </Button>
      ),
    },
  ]

  const ErrorLogsContent = () => {
    const [errorLogs, setErrorLogs] = useState<ErrorLog[]>([])
    const [loading, setLoading] = useState(true)
    const [selectedError, setSelectedError] = useState<ErrorLog | null>(null)
    const [errorModalVisible, setErrorModalVisible] = useState(false)

    useEffect(() => {
      fetchErrorLogs()
    }, [])

    const fetchErrorLogs = async () => {
      try {
        const response = await axios.get('/admin/error-logs')
        setErrorLogs(response.data.logs)
        setLoading(false)
      } catch (error) {
        console.error('Error fetching error logs:', error)
        toast.error('Failed to fetch error logs')
        setLoading(false)
      }
    }

    const errorColumns: ColumnDef<ErrorLog>[] = [
      {
        key: 'timestamp',
        title: 'Timestamp',
        dataIndex: 'timestamp',
        sortable: {
          enabled: true,
          type: 'date'
        },
        render: (timestamp) => dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
      },
      {
        key: 'endpoint',
        title: 'Endpoint',
        dataIndex: 'endpoint',
        sortable: {
          enabled: true,
          type: 'string'
        }
      },
      {
        key: 'error_type',
        title: 'Error Type',
        dataIndex: 'error_type',
        filterable: true,
        filters: Array.from(new Set(errorLogs.map(log => log.error_type))).map(type => ({
          text: type,
          value: type,
        })),
        onFilter: (value, record) => record.error_type === value,
      },
      {
        key: 'error_message',
        title: 'Error Message',
        dataIndex: 'error_message',
        render: (message) => (
          <div className="max-w-[300px] truncate">{message}</div>
        )
      },
      {
        key: 'actions',
        title: 'Actions',
        dataIndex: 'id',
        cell: ({ row }) => (
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              setSelectedError(row.original)
              setErrorModalVisible(true)
            }}
          >
            View Details
          </Button>
        ),
      },
    ]

    return (
      <>
        <Table
          columns={errorColumns}
          data={errorLogs}
          pageSize={15}
        />
        <Dialog 
          open={errorModalVisible} 
          onOpenChange={setErrorModalVisible}
        >
          <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>Error Details</DialogTitle>
            </DialogHeader>
            {selectedError && (
              <div className="space-y-4">
                <div>
                  <h4 className="font-semibold mb-2">Stack Trace</h4>
                  <pre className="bg-muted p-4 rounded-md overflow-x-auto">
                    {selectedError.stack_trace}
                  </pre>
                </div>
                <div>
                  <h4 className="font-semibold mb-2">Request Data</h4>
                  <pre className="bg-muted p-4 rounded-md overflow-x-auto">
                    {JSON.stringify(selectedError.request_data, null, 2)}
                  </pre>
                </div>
              </div>
            )}
          </DialogContent>
        </Dialog>
      </>
    )
  }

  return (
    <div className="space-y-6">
      <h2 className="text-3xl font-bold tracking-tight text-foreground">Admin Status</h2>
      {activeTab === "schedule" && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-foreground">Schedule/Jobs Status</h3>
          <Table
            columns={jobColumns}
            data={jobStatuses}
            pageSize={15}
          />
          <Dialog 
            open={modalVisible} 
            onOpenChange={setModalVisible}
          >
            <DialogContent className="max-w-3xl max-h-[80vh] overflow-y-auto">
              <DialogHeader>
                <DialogTitle>Job Logs</DialogTitle>
              </DialogHeader>
              <pre className="bg-muted p-4 rounded-md overflow-x-auto">
                {selectedJobLogs}
              </pre>
            </DialogContent>
          </Dialog>
        </div>
      )}
      {activeTab === "errors" && <ErrorLogsContent />}
    </div>
  )
}