import React from 'react';
import { cn } from "@/lib/utils";
import { Check } from "lucide-react";

interface Step {
  title: string;
  description?: string;
}

interface ProgressStepsProps {
  steps: Step[];
  currentStep: number;
  className?: string;
  variant?: 'horizontal' | 'vertical';
}

export function ProgressSteps({ 
  steps, 
  currentStep, 
  className,
  variant = 'vertical' 
}: ProgressStepsProps) {
  return (
    <div className={cn(
      "space-y-4",
      variant === 'horizontal' && "space-y-0 flex items-center gap-4",
      className
    )}>
      {steps.map((step, index) => {
        const isCompleted = index < currentStep;
        const isCurrent = index === currentStep;
        
        return (
          <div
            key={index}
            className={cn(
              "flex items-center",
              variant === 'vertical' ? (
                cn(
                  "space-x-4",
                  index !== steps.length - 1 && "pb-8 relative"
                )
              ) : (
                cn(
                  "flex-col space-y-2",
                  index !== steps.length - 1 && "flex-1 relative"
                )
              )
            )}
          >
            {/* Connecting line */}
            {index !== steps.length - 1 && (
              <div
                className={cn(
                  "-z-10",
                  variant === 'vertical' ? (
                    cn(
                      "absolute left-[15px] top-[30px] w-[2px] h-full",
                      isCompleted ? "bg-primary" : "bg-muted"
                    )
                  ) : (
                    cn(
                      "absolute top-4 left-[30px] h-[2px] w-full",
                      isCompleted ? "bg-primary" : "bg-muted"
                    )
                  )
                )}
              />
            )}
            
            {/* Step indicator */}
            <div
              className={cn(
                "w-8 h-8 rounded-full flex items-center justify-center border-2",
                isCompleted && "bg-primary border-primary text-primary-foreground",
                isCurrent && "border-primary",
                !isCompleted && !isCurrent && "border-muted"
              )}
            >
              {isCompleted ? (
                <Check className="h-4 w-4" />
              ) : (
                <span className={cn(
                  "text-sm",
                  isCurrent && "text-primary"
                )}>
                  {index + 1}
                </span>
              )}
            </div>
            
            {/* Step content */}
            <div className={cn(
              "space-y-1",
              variant === 'horizontal' && "text-center"
            )}>
              <p className={cn(
                "text-sm font-medium whitespace-nowrap",
                isCompleted && "text-primary",
                isCurrent && "text-primary",
                !isCompleted && !isCurrent && "text-muted-foreground"
              )}>
                {step.title}
              </p>
              {step.description && (
                <p className="text-sm text-muted-foreground">
                  {step.description}
                </p>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
}