import * as React from "react"
import { cn } from "@/lib/utils"
import { Button } from "../button"
import { type ButtonProps } from "../button"

interface ColorButtonProps extends ButtonProps {
  color: string
  selected?: boolean
}

const ColorButton = React.forwardRef<HTMLButtonElement, ColorButtonProps>(
  ({ color, selected, className, style, ...props }, ref) => {
    return (
      <Button
        ref={ref}
        type="button"
        variant="ghost"
        className={cn(
          'w-12 h-12 rounded-full p-0 relative transition-all hover:opacity-90',
          selected && 'ring-2 ring-primary ring-offset-2',
          className
        )}
        style={{
          ...style,
          backgroundColor: color,
          borderColor: color,
        }}
        {...props}
      />
    )
  }
)
ColorButton.displayName = "ColorButton"

export { ColorButton }