import React, { useEffect, useState } from 'react';
import { X, Plus } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import axios from '@/api/axiosConfig';
import { EmailRecipientSelector } from './EmailRecipientSelector';

interface Contact {
  id: number;
  name: string;
  email: string;
  phone?: string;
  account_id: number;
  accounts?: Array<{
    id: number;
    company_name: string;
  }>;
}

interface Recipient {
  email: string;
  name: string;
}

interface EmailRecipientsProps {
  recipients: Recipient[];
  onAddRecipient: (recipient: Recipient) => void;
  onRemoveRecipient: (email: string) => void;
}

export function EmailRecipients({
  recipients,
  onAddRecipient,
  onRemoveRecipient,
}: EmailRecipientsProps) {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [customEmail, setCustomEmail] = React.useState('');
  const [showSuggestions, setShowSuggestions] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectorOpen, setSelectorOpen] = useState(false);

  useEffect(() => {
    const fetchContacts = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await axios.get('/account/contacts');
        setContacts(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching contacts:', error);
        setError('Failed to load contacts');
        setIsLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const handleCustomEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCustomEmail(value);
    
    if (value.length > 0) {
      const filtered = contacts.filter(
        contact => 
          contact.email.toLowerCase().includes(value.toLowerCase()) ||
          contact.name.toLowerCase().includes(value.toLowerCase()) ||
          contact.accounts?.some(account => 
            account.company_name.toLowerCase().includes(value.toLowerCase())
          )
      );
      setFilteredContacts(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleCustomEmailSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && customEmail) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(customEmail)) {
        onAddRecipient({
          email: customEmail,
          name: customEmail.split('@')[0],
        });
        setCustomEmail('');
        setShowSuggestions(false);
      }
    }
  };

  const handleAddRecipients = (newRecipients: Array<{ email: string; name: string }>) => {
    newRecipients.forEach(recipient => onAddRecipient(recipient));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <div className="flex-1 flex flex-wrap gap-2 min-h-[2.5rem] p-2 border border-border rounded-lg relative">
          {recipients.map((recipient) => (
            <Badge key={recipient.email} variant="secondary">
              {recipient.name} <span className="text-muted-foreground ml-1">&lt;{recipient.email}&gt;</span>
              <Button
                variant="ghost"
                size="icon"
                className="h-4 w-4 ml-1"
                onClick={() => onRemoveRecipient(recipient.email)}
              >
                <X className="h-3 w-3" />
              </Button>
            </Badge>
          ))}
          <div className="flex-1 relative">
            <Input
              type="email"
              value={customEmail}
              onChange={handleCustomEmailChange}
              onKeyDown={handleCustomEmailSubmit}
              onFocus={() => customEmail && setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
              placeholder={isLoading ? "Loading contacts..." : "Type email and press Enter..."}
              className="border-0 bg-transparent px-2 flex-1 min-w-[200px] h-6 focus-visible:ring-0"
              disabled={isLoading}
            />
            {showSuggestions && filteredContacts.length > 0 && (
              <div className="absolute top-full left-0 w-full z-10 bg-popover border border-border rounded-md shadow-md mt-1">
                <ScrollArea className="h-[200px]">
                  <div className="p-1">
                    {filteredContacts.map((contact) => (
                      <div
                        key={contact.id}
                        className="px-2 py-1.5 hover:bg-accent cursor-pointer rounded-sm"
                        onClick={() => {
                          onAddRecipient({
                            email: contact.email,
                            name: contact.name,
                          });
                          setCustomEmail('');
                          setShowSuggestions(false);
                        }}
                      >
                        <div className="font-medium text-foreground">{contact.name}</div>
                        <div className="text-sm text-muted-foreground">
                          {contact.email}
                          {contact.accounts?.[0] && (
                            <span className="ml-2">({contact.accounts[0].company_name})</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
              </div>
            )}
          </div>
        </div>
        <Button
          variant="outline"
          size="icon"
          onClick={() => setSelectorOpen(true)}
        >
          <Plus className="h-4 w-4" />
        </Button>
      </div>
      {error && (
        <p className="text-sm text-destructive">{error}</p>
      )}

      <EmailRecipientSelector
        open={selectorOpen}
        onOpenChange={setSelectorOpen}
        onAddRecipients={handleAddRecipients}
      />
    </div>
  );
}