import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import io from 'socket.io-client'
import axios from '../api/axiosConfig'
import { toast } from 'sonner'

// UI Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Badge } from "@/components/ui/badge"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip"
import { ScrollArea } from "@/components/ui/scroll-area"
import { Loading } from "@/components/ui/loading"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  type ColumnDef
} from "@/components/ui/specialized/mod-table"
import { Input } from "@/components/ui/input"

// Icons
import {
  Users,
  User,
  ShoppingBag,
  DollarSign,
  Hash,
  Calendar,
  Clock,
  ShoppingCart,
  FilePlus,
  ClockIcon,
  LayoutGrid,
  Briefcase,
  Building2,
  UserCircle2,
  Package,
  Copy,
  Mail,
  Phone,
  MoreHorizontal,
  Trash2
} from 'lucide-react'

// Types
import type { Deal, Product, Contact, Account } from '../types/customers'

// Components
import ImportDeals from './modals/ImportDeals'
import DealDetails from './modals/DealDetails'
import { DealDrawer }from '../drawers/DealDrawer'
import type { Deal as ImportDeal } from './modals/ImportDeals'
import { DeleteDeal } from './modals/DeleteDeal'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"

const excludedDataKeys = ['status', 'title', 'value', 'currency', 'products']

const formatDataKey = (key: string): string => {
  return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
}

const formatDataValue = (value: any): string => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No'
  }
  if (value instanceof Date) {
    return value.toLocaleString()
  }
  if (typeof value === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T/)) {
    return new Date(value).toLocaleString()
  }
  return String(value)
}

const ProductItem = ({ product }: { product: Product }) => (
  <div className="flex flex-col gap-2 p-4 border rounded-lg">
    <TooltipProvider>
      <div className="space-y-2">
        <Tooltip>
          <TooltipTrigger asChild>
            <h4 className="font-semibold">{product.name}</h4>
          </TooltipTrigger>
          <TooltipContent>Product Name: {product.name}</TooltipContent>
        </Tooltip>

        <div className="grid grid-cols-2 gap-2 text-sm">
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <ShoppingCart className="w-4 h-4" />
                <span>Code: {product.code}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Product Code: {product.code}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4" />
                <span>Price: {product.item_price}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Price: {product.item_price}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <Hash className="w-4 h-4" />
                <span>Qty: {product.quantity}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Quantity: {product.quantity}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <DollarSign className="w-4 h-4" />
                <span>Sum: {product.item_price * product.quantity}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Sum: {product.item_price * product.quantity}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <Calendar className="w-4 h-4" />
                <span>Start: {product.billing_start_date || 'N/A'}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Billing Start Date: {product.billing_start_date || 'N/A'}</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center gap-2">
                <Clock className="w-4 h-4" />
                <span>Freq: {product.billing_frequency}</span>
              </div>
            </TooltipTrigger>
            <TooltipContent>Billing Frequency: {product.billing_frequency}</TooltipContent>
          </Tooltip>
        </div>
      </div>
    </TooltipProvider>
  </div>
)

interface DealTableRow extends Deal {
  data: {
    value: number;
    currency: string;
    status: string;
  }
}

const Deals = () => {
  const [deals, setDeals] = useState<Deal[]>([])
  const [pendingDeals, setPendingDeals] = useState<ImportDeal[]>([])
  const [loading, setLoading] = useState(true)
  const [importModalVisible, setImportModalVisible] = useState(false)
  const [detailModalVisible, setDetailModalVisible] = useState(false)
  const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null)
  const [totalDeals, setTotalDeals] = useState(0)
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null)
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null)
  const [selectedProducts, setSelectedProducts] = useState<Product[]>([])
  const [accountModalVisible, setAccountModalVisible] = useState(false)
  const [contactModalVisible, setContactModalVisible] = useState(false)
  const [productsModalVisible, setProductsModalVisible] = useState(false)
  const [dealDrawerVisible, setDealDrawerVisible] = useState(false)
  const [selectedDealId, setSelectedDealId] = useState<number | null>(null)
  const [onboardingDealsCount, setOnboardingDealsCount] = useState(0)
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useState(15)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredData, setFilteredData] = useState<Deal[]>([])
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [selectedDealForDeletion, setSelectedDealForDeletion] = useState<Deal | null>(null)

  useEffect(() => {
    fetchDeals()
    fetchPendingDeals()
    const socket = io(window._env_?.REACT_APP_API_URL || 'http://localhost:5000')
    socket.on('pending_deal_added', fetchPendingDeals)
    socket.on('pending_deal_imported', () => {
      fetchDeals()
      fetchPendingDeals()
    })
    return () => {
      socket.disconnect()
    }
  }, [])

  useEffect(() => {
    setFilteredData(deals)
  }, [deals])

  const fetchDeals = async () => {
    try {
      const response = await axios.get('/account/deals')
      const allDeals = response.data || []
      const completedDeals = allDeals.filter((deal: Deal) => 
        deal.onboard_status === 'onboarded' || deal.onboard_status === 'migrated'
      )
      const onboardingDeals = allDeals.filter((deal: Deal) => 
        deal.onboard_status === 'onboarding' || deal.onboard_status === 'not_onboarded'
      )
      
      setDeals(completedDeals)
      setTotalDeals(completedDeals.length)
      setOnboardingDealsCount(onboardingDeals.length)
    } catch (error) {
      console.error('Error fetching deals:', error)
      setDeals([])
      setTotalDeals(0)
      setOnboardingDealsCount(0)
    } finally {
      setLoading(false)
    }
  }

  const fetchPendingDeals = async () => {
    try {
      const response = await axios.get('/account/pending-deals')
      setPendingDeals(response.data || [])
    } catch (error) {
      console.error('Error fetching pending deals:', error)
      setPendingDeals([])
    }
  }

  const showDealDetails = (deal: ImportDeal) => {
    setSelectedDeal(deal as any)
    setDetailModalVisible(true)
  }

  const showAccountDetails = async (account: Account) => {
    try {
      const response = await axios.get(`/account/accounts/${account.id}`)
      setSelectedAccount(response.data)
      setAccountModalVisible(true)
    } catch (error) {
      console.error('Error fetching account details:', error)
      // Using Sonner toast instead of antd message
      toast.error('Failed to fetch account details')
    }
  }

  const showContactDetails = (contact: Contact) => {
    setSelectedContact(contact)
    setContactModalVisible(true)
  }

  const showProductsDetails = (products: Product[]) => {
    setSelectedProducts(products)
    setProductsModalVisible(true)
  }

  const handleImportSuccess = (importedDealIds: string | string[]) => {
    if (typeof importedDealIds === 'string') {
      setPendingDeals(prevDeals => prevDeals.filter(deal => deal.id !== importedDealIds))
    }
    else if (Array.isArray(importedDealIds)) {
      setPendingDeals(prevDeals => prevDeals.filter(deal => !importedDealIds.includes(deal.id)))
    }
    fetchDeals()
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setSearchQuery(value)
    if (!value) {
      setFilteredData(deals)
      return
    }
    
    const filtered = deals.filter(item => 
      item.name.toLowerCase().includes(value.toLowerCase()) ||
      item.email?.toLowerCase().includes(value.toLowerCase()) ||
      item.phone?.toLowerCase().includes(value.toLowerCase()) ||
      item.description?.toLowerCase().includes(value.toLowerCase())
    )
    setFilteredData(filtered)
  }

  const handleDealUpdate = async (dealId: string) => {
    try {
      const response = await axios.get(`/account/pending-deals/${dealId}`);
      const updatedDeal = response.data;
      
      setPendingDeals(prevDeals => 
        prevDeals.map(deal => 
          deal.id === dealId ? updatedDeal : deal
        )
      );

      fetchDeals();
    } catch (error) {
      console.error('Error fetching updated deal:', error);
      toast.error('Failed to refresh deal data');
    }
  };

  const columns: ColumnDef<DealTableRow>[] = [
    {
      key: 'name',
      title: 'Deal',
      dataIndex: 'name',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-deal))] text-white hover:bg-[hsl(var(--badge-deal)/.8)]"
          onClick={() => {
            setSelectedDealId(row.original.id)
            setDealDrawerVisible(true)
          }}
        >
          <Briefcase className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.name}
          </span>
        </Badge>
      )
    },
    {
      key: 'cvr',
      title: 'CVR',
      dataIndex: 'cvr'
    },
    {
      key: 'phone',
      title: 'Phone',
      dataIndex: 'phone',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <div className="flex items-center gap-2">
          <Phone className="w-4 h-4" />
          <span>{row.original.phone}</span>
          {row.original.phone && (
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 opacity-50 hover:opacity-100"
              onClick={() => {
                navigator.clipboard.writeText(row.original.phone)
                toast.success('Phone number copied to clipboard')
              }}
            >
              <Copy className="h-3 w-3" />
            </Button>
          )}
        </div>
      )
    },
    {
      key: 'email',
      title: 'Email',
      dataIndex: 'email',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <div className="flex items-center gap-2">
          <Mail className="w-4 h-4" />
          <span>{row.original.email}</span>
          {row.original.email && (
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 opacity-50 hover:opacity-100"
              onClick={() => {
                navigator.clipboard.writeText(row.original.email)
                toast.success('Email copied to clipboard')
              }}
            >
              <Copy className="h-3 w-3" />
            </Button>
          )}
        </div>
      )
    },
    {
      key: 'value',
      title: 'Value',
      dataIndex: 'data',
      render: (data: DealTableRow['data']) => `${data.value} ${data.currency}`
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'data',
      render: (data: DealTableRow['data']) => data.status
    },
    {
      key: 'created_at',
      title: 'Created\u00A0At',
      dataIndex: 'created_at',
      render: (date: string) => {
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }).format(new Date(date));
        return formattedDate;
      },
      sortable: {
        enabled: true,
        type: 'date'
      }
    },
    {
      key: 'account',
      title: 'Account',
      dataIndex: 'account',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-account))] text-white hover:bg-[hsl(var(--badge-account)/.8)]"
          onClick={() => showAccountDetails(row.original.account)}
        >
          <Building2 className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.account.company_name}
          </span>
        </Badge>
      )
    },
    {
      key: 'contact',
      title: 'Contact',
      dataIndex: 'contact',
      cell: ({ row }: { row: { original: Deal } }) => {
        // Get the primary contact from the deal
        const contact = row.original.contact

        if (!contact) {
          return <span className="text-muted-foreground">No contact</span>
        }

        return (
          <Badge 
            variant="secondary"
            className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-contact))] text-white hover:bg-[hsl(var(--badge-contact)/.8)]"
            onClick={() => showContactDetails(contact)}
          >
            <UserCircle2 className="w-3 h-3 flex-shrink-0" />
            <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
              {contact.name}
            </span>
          </Badge>
        )
      }
    },
    {
      key: 'products',
      title: 'Products',
      dataIndex: 'products',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <Badge 
          variant="secondary"
          className="cursor-pointer hover:bg-secondary/80 bg-[hsl(var(--badge-product))] text-white hover:bg-[hsl(var(--badge-product)/.8)]"
          onClick={() => showProductsDetails(row.original.products)}
        >
          <Package className="w-3 h-3 flex-shrink-0" />
          <span className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
            {row.original.products.length} product(s)
          </span>
        </Badge>
      )
    },
    {
      key: 'address',
      title: 'Address',
      dataIndex: 'address'
    },
    {
      key: 'zipcode',
      title: 'Zipcode',
      dataIndex: 'zipcode'
    },
    {
      key: 'city',
      title: 'City',
      dataIndex: 'city'
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className="block max-w-[250px] truncate">
                {row.original.description}
              </span>
            </TooltipTrigger>
            <TooltipContent>
              <p>{row.original.description}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'id',
      cell: ({ row }: { row: { original: DealTableRow } }) => (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" size="icon">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem
              onClick={() => {
                setSelectedDealForDeletion(row.original)
                setDeleteModalVisible(true)
              }}
              className="text-destructive"
            >
              <Trash2 className="h-4 w-4 mr-2" />
              Delete Deal
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    }
  ]

  if (loading) {
    return <Loading message="Loading deals..." />
  }

  return (
    <div className="space-y-4 p-8">
      <div className="flex items-center justify-between">
        <h2 className="text-3xl font-bold text-foreground">Deals</h2>
        <div className="flex items-center gap-2">
          <Button 
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => setImportModalVisible(true)}
          >
            <FilePlus className="w-4 h-4" />
            Import Deals
          </Button>
          <Button 
            variant="outline"
            className="flex items-center gap-2"
            onClick={() => navigate('/customers/onboarding')}
          >
            <ClockIcon className="w-4 h-4" />
            Onboarding
          </Button>
        </div>
      </div>

      <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <Users className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Total Deals</p>
              <p className="text-2xl font-bold">{totalDeals}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <ShoppingBag className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Onboarding</p>
              <p className="text-2xl font-bold">{onboardingDealsCount}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <ClockIcon className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Pending</p>
              <p className="text-2xl font-bold">{pendingDeals.length}</p>
            </div>
          </CardContent>
        </Card>

        <Card>
          <CardContent className="flex items-center gap-4 pt-6">
            <LayoutGrid className="h-8 w-8 text-primary" />
            <div>
              <p className="text-sm font-medium">Setup</p>
              <Button 
                variant="link" 
                className="p-0 h-auto font-bold text-2xl"
                onClick={() => navigate('/customers/deals/setup')}
              >
                View Setup
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>

      <div className="w-full flex justify-between items-center mb-4">
        <Input 
          placeholder="Search deals..."
          value={searchQuery}
          onChange={handleSearch}
          className="max-w-sm"
        />
      </div>

      <div>
        <Table 
          data={filteredData.length > 0 ? filteredData : deals}
          columns={columns}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
          onPageSizeChange={setPageSize}
        />
      </div>

      <ImportDeals
        visible={importModalVisible}
        onClose={() => setImportModalVisible(false)}
        pendingDeals={pendingDeals}
        onImportSuccess={handleImportSuccess}
        onShowDealDetails={showDealDetails}
      />

      <DealDetails
        visible={detailModalVisible}
        onClose={() => setDetailModalVisible(false)}
        deal={selectedDeal}
        onUpdateSuccess={handleDealUpdate}
      />

      <Dialog open={accountModalVisible} onOpenChange={setAccountModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Account Details</DialogTitle>
          </DialogHeader>
          {selectedAccount && (
            <div className="space-y-4">
              <p><span className="font-semibold">Company Name:</span> {selectedAccount.company_name}</p>
              <p><span className="font-semibold">CVR:</span> {selectedAccount.cvr || 'N/A'}</p>
              <p><span className="font-semibold">Address:</span> {selectedAccount.address || 'N/A'}</p>
              <p><span className="font-semibold">Zipcode:</span> {selectedAccount.zipcode || 'N/A'}</p>
              <p><span className="font-semibold">City:</span> {selectedAccount.city || 'N/A'}</p>
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setAccountModalVisible(false)}>
              Close
            </Button>
            <Button onClick={() => navigate(`/accounts/${selectedAccount?.id}`)}>
              View Full Details
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog open={contactModalVisible} onOpenChange={setContactModalVisible}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Contact Details</DialogTitle>
          </DialogHeader>
          {selectedContact && (
            <div className="space-y-4">
              <p><span className="font-semibold">Name:</span> {selectedContact.name}</p>
              <p><span className="font-semibold">Email:</span> {selectedContact.email}</p>
              <p><span className="font-semibold">Phone:</span> {selectedContact.phone}</p>
            </div>
          )}
          <DialogFooter>
            <Button variant="outline" onClick={() => setContactModalVisible(false)}>
              Close
            </Button>
            <Button onClick={() => navigate(`/contacts/${selectedContact?.id}`)}>
              View Full Details
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <Dialog 
        open={productsModalVisible} 
        onOpenChange={setProductsModalVisible}
      >
        <DialogContent className="max-w-3xl">
          <DialogHeader>
            <DialogTitle>Products Details</DialogTitle>
          </DialogHeader>
          <ScrollArea className="h-[500px] pr-4">
            <div className="space-y-4">
              {selectedProducts.map((product, index) => (
                <ProductItem key={index} product={product} />
              ))}
            </div>
          </ScrollArea>
          <DialogFooter>
            <Button variant="outline" onClick={() => setProductsModalVisible(false)}>
              Close
            </Button>
            <Button onClick={() => navigate('/products')}>
              View All Products
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <DealDrawer
        visible={dealDrawerVisible}
        onClose={() => {
          setDealDrawerVisible(false)
          setSelectedDealId(null)
        }}
        dealId={selectedDealId}
      />

      <DeleteDeal
        visible={deleteModalVisible}
        onClose={() => {
          setDeleteModalVisible(false)
          setSelectedDealForDeletion(null)
        }}
        deal={selectedDealForDeletion}
        onDeleteSuccess={fetchDeals}
      />
    </div>
  )
}

export default Deals