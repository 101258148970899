import React, { useState, useEffect } from 'react';
import { Drawer, Descriptions, Spin, List, Typography, Tooltip } from 'antd';
import { ShoppingCartOutlined, DollarOutlined, NumberOutlined, CalendarOutlined, FieldTimeOutlined } from '@ant-design/icons';
import axios from '../api/axiosConfig';
import CustomScrollbar from '../components/CustomScrollbar';
import './ProductsDrawer.css';

const { Text } = Typography;

const ProductsDrawer = ({ visible, onClose, products }) => {
  const [loading, setLoading] = useState(false);


  const renderProductDetails = (product) => (
    <List.Item className="product-item">
      <Tooltip title={`Product Name: ${product.name}`}>
        <Text strong className="product-name">{product.name}</Text>
      </Tooltip>
      <Tooltip title={`Product Code: ${product.code}`}>
        <Text><ShoppingCartOutlined /> Code: {product.code}</Text>
      </Tooltip>
      <Tooltip title={`Price: ${product.price || product.item_price}`}>
        <Text><DollarOutlined /> Price: {product.price || product.item_price}</Text>
      </Tooltip>
      <Tooltip title={`Quantity: ${product.quantity}`}>
        <Text><NumberOutlined /> Qty: {product.quantity}</Text>
      </Tooltip>
      <Tooltip title={`Sum: ${(product.price || product.item_price) * product.quantity}`}>
        <Text><DollarOutlined /> Sum: {(product.price || product.item_price) * product.quantity}</Text>
      </Tooltip>
      <Tooltip title={`Billing Start Date: ${product.billing_start_date || 'N/A'}`}>
        <Text><CalendarOutlined /> Start: {product.billing_start_date || 'N/A'}</Text>
      </Tooltip>
      <Tooltip title={`Billing Frequency: ${product.billing_frequency}`}>
        <Text><FieldTimeOutlined /> Freq: {product.billing_frequency}</Text>
      </Tooltip>
    </List.Item>
  );

  return (
    <Drawer
      title="Products Details"
      placement="right"
      onClose={onClose}
      open={visible}
      width={700}
      className="products-drawer"
    >
      {loading ? (
        <div className="products-drawer-loading">
          <Spin size="large" />
        </div>
      ) : (
        <CustomScrollbar className="products-drawer-content">
          {products && products.length > 0 ? (
            <List
              dataSource={products}
              renderItem={renderProductDetails}
              className="products-list"
            />
          ) : (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              No products found
            </div>
          )}
        </CustomScrollbar>
      )}
    </Drawer>
  );
};

export default ProductsDrawer;