import { type FrontendTaskType } from '@/types/task';

export interface BoardStatusOption {
  value: string;
  label: string;
  color: string;
}

export type BoardConfigKey = FrontendTaskType;

export const boardStatusConfigs: Record<FrontendTaskType, BoardStatusOption[]> = {
  bogforing: [
    { value: 'Blank', label: 'Blank', color: '#8c8c8c' },
    { value: 'Indhent bankudtog', label: 'Indhent bankudtog', color: '#1890ff' },
    { value: 'Bogfør op', label: 'Bogfør op', color: '#ffa940' },
    { value: 'Indsaml data', label: 'Indsaml data', color: '#722ed1' },
    { value: 'Bogfør op igen', label: 'Bogfør op igen', color: '#13c2c2' },
    { value: 'Klar til gennemgang', label: 'Klar til gennemgang', color: '#f5222d' },
    { value: 'Udført', label: 'Udført', color: '#52c41a' },
  ],
  lon: [
    { value: 'Blank', label: 'Blank', color: '#8c8c8c' },
    { value: 'Lønkunder', label: 'Lønkunder', color: '#1890ff' },
    { value: 'Indhent timer', label: 'Indhent timer', color: '#ffa940' },
    { value: 'Timer indsendt', label: 'Timer indsendt', color: '#722ed1' },
    { value: 'Opfølgning', label: 'Opfølgning', color: '#f5222d' },
    { value: 'Løn lavet', label: 'Løn lavet', color: '#52c41a' },
  ],
  moms: [
    { value: 'Blank', label: 'Blank', color: '#8c8c8c' },
    { value: 'Indsaml data', label: 'Indsaml data', color: '#1890ff' },
    { value: 'Udarbejd momsangivelse', label: 'Udarbejd momsangivelse', color: '#ffa940' },
    { value: 'Klar til gennemgang', label: 'Klar til gennemgang', color: '#f5222d' },
    { value: 'Indberettet', label: 'Indberettet', color: '#52c41a' },
  ],
  arsafslutning: [
    { value: 'Blank', label: 'Blank', color: '#8c8c8c' },
    { value: 'Indsaml data', label: 'Indsaml data', color: '#1890ff' },
    { value: 'Udarbejd årsregnskab', label: 'Udarbejd årsregnskab', color: '#ffa940' },
    { value: 'Klar til gennemgang', label: 'Klar til gennemgang', color: '#f5222d' },
    { value: 'Indberettet', label: 'Indberettet', color: '#52c41a' },
  ],
  'andre-opgaver': [
    { value: 'All tasks', label: 'All tasks', color: '#8c8c8c' },
    { value: 'Not started', label: 'Not started', color: '#ffa940' },
    { value: 'In progress', label: 'In progress', color: '#1890ff' },
    { value: 'Pending customer', label: 'Pending customer', color: '#722ed1' },
    { value: 'Main task completed', label: 'Main task completed', color: '#13c2c2' },
    { value: 'Completed', label: 'Completed', color: '#52c41a' },
  ],
  activities: [
    { value: 'Not started', label: 'Not started', color: '#8c8c8c' },
    { value: 'In progress', label: 'In progress', color: '#1890ff' },
    { value: 'Completed', label: 'Completed', color: '#52c41a' },
  ],
  custom: [],
}

export const isBoardConfigKey = (key: string): key is BoardConfigKey => {
  return Object.keys(boardStatusConfigs).includes(key);
};

export const getBoardStatus = (taskType: FrontendTaskType): BoardStatusOption[] => {
  return boardStatusConfigs[taskType] || boardStatusConfigs.bogforing;
};

export const getBoardStatusColor = (status: string, taskType: FrontendTaskType): string => {
  const options = getBoardStatus(taskType);
  return options.find(opt => opt.value === status)?.color || '#8c8c8c';
};