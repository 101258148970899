// frontend/src/customers/modals/DeleteDeal.tsx
import { useState, useEffect } from 'react'
import axios from '@/api/axiosConfig'
import { toast } from 'sonner'

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog"
import { Loading } from "@/components/ui/loading"

import type { Deal } from '@/types/customers'

interface DeletionData {
  account: {
    id: number
    company_name: string
    otherDealsCount: number
  }
  contacts: {
    id: number
    name: string
    otherDealsCount: number
  }[]
  products: {
    id: number
    name: string
  }[]
}

interface DeleteDealProps {
  visible: boolean
  onClose: () => void
  deal: Deal | null
  onDeleteSuccess: () => void
}

export function DeleteDeal({ visible, onClose, deal, onDeleteSuccess }: DeleteDealProps) {
  const [loading, setLoading] = useState(false)
  const [deletionData, setDeletionData] = useState<DeletionData | null>(null)

  useEffect(() => {
    if (deal && visible) {
      fetchDeletionData()
    }
  }, [deal, visible])

  const fetchDeletionData = async () => {
    if (!deal) return
    
    setLoading(true)
    try {
      const response = await axios.get(`/account/deals/${deal.id}/deletion-data`)
      setDeletionData(response.data)
    } catch (error) {
      console.error('Error fetching deletion data:', error)
      toast.error('Failed to fetch deletion data')
      onClose()
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async () => {
    if (!deal) return
    
    try {
      await axios.delete(`/account/deals/${deal.id}`)
      toast.success('Deal deleted successfully')
      onDeleteSuccess()
      onClose()
    } catch (error) {
      console.error('Error deleting deal:', error)
      toast.error('Failed to delete deal')
    }
  }

  if (!visible || !deal) return null

  return (
    <AlertDialog open={visible} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete Deal</AlertDialogTitle>
          <AlertDialogDescription className="space-y-4">
            {loading ? (
              <Loading message="Loading deletion data..." />
            ) : deletionData ? (
              <>
                <div className="space-y-2">
                  <p className="font-semibold">Account:</p>
                  <p>{deletionData.account.company_name}</p>
                  {deletionData.account.otherDealsCount > 0 && (
                    <p className="text-muted-foreground text-sm">
                      This account has {deletionData.account.otherDealsCount} other deal(s) and will not be deleted.
                    </p>
                  )}
                </div>

                <div className="space-y-2">
                  <p className="font-semibold">Products to be deleted:</p>
                  <ul className="list-disc pl-4">
                    {deletionData.products.map(product => (
                      <li key={product.id}>{product.name}</li>
                    ))}
                  </ul>
                </div>

                <div className="space-y-2">
                  <p className="font-semibold">Contacts:</p>
                  <ul className="list-disc pl-4">
                    {deletionData.contacts.map(contact => (
                      <li key={contact.id}>
                        {contact.name}
                        {contact.otherDealsCount > 0 && (
                          <span className="text-muted-foreground text-sm">
                            {' '}(associated with {contact.otherDealsCount} other deal(s) - will not be deleted)
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>

                <p className="text-destructive mt-4">
                  This action cannot be undone. Are you sure you want to proceed?
                </p>
              </>
            ) : null}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            onClick={handleDelete}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}