import React from 'react';
import axios from '@/api/axiosConfig';
import { toast } from 'sonner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { type BoardStatusOption } from '@/taskboards/config/board-status';
import { type FrontendTaskType, type UpdatedTask } from '@/types/task';
import { cn } from "@/lib/utils";

interface TaskStatusSelectorProps {
  value: string;
  taskId: number;
  taskType: FrontendTaskType;
  boardStatusOptions: BoardStatusOption[];
  progressStatus: string;
  onSuccess: (updatedTask: UpdatedTask) => void;
  className?: string;
}

const TaskStatusSelector: React.FC<TaskStatusSelectorProps> = ({
  value,
  taskId,
  taskType,
  boardStatusOptions,
  progressStatus,
  onSuccess,
  className,
}) => {
  const handleStatusChange = async (newStatus: string) => {
    // Store original values for potential rollback
    const originalBoardStatus = value;
    const originalProgressStatus = progressStatus;

    // Check if moving to last status
    const isMovingToLastStatus = newStatus === boardStatusOptions[boardStatusOptions.length - 1].value;

    // Determine new progress status
    let newProgressStatus: string;
    if (newStatus === boardStatusOptions[0].value) {
      newProgressStatus = 'Not started';
    } else if (isMovingToLastStatus) {
      newProgressStatus = 'Completed';
    } else {
      newProgressStatus = 'In progress';
    }

    // Create base task data
    const baseTaskData = {
      id: taskId,
      task_type: taskType,
      key: `${taskType}-${taskId}`,
      data: {
        Status: newProgressStatus,
        Assignee: [], // This will be filled by the parent component if needed
      },
    };

    // Create rollback function
    const rollback = () => {
      onSuccess({
        ...baseTaskData,
        board_status: originalBoardStatus,
        progress_status: originalProgressStatus,
      });
    };

    try {
      // Optimistically update frontend
      onSuccess({
        ...baseTaskData,
        board_status: newStatus,
        progress_status: newProgressStatus,
        rollback,
      });

      // Make API call
      const response = await axios.put('/work/update_board_status', {
        task_id: taskId,
        task_type: taskType,
        new_status: newStatus,
        progress_status: newProgressStatus,
      });

      if (response.status !== 200) {
        rollback();
        toast.error('Failed to update status');
      }
    } catch (error) {
      rollback();
      toast.error('Failed to update status');
      console.error('Error updating status:', error);
    }
  };

  // Find the current status option to get its color
  const currentOption = boardStatusOptions.find(opt => opt.value === value);
  const statusColor = currentOption?.color || '#8c8c8c';

  return (
    <Select
      value={value}
      onValueChange={handleStatusChange}
    >
      <SelectTrigger className={cn("w-full border-none focus:ring-0", className)}>
        <SelectValue>
          <div 
            className="px-2 py-1 rounded-md text-white w-full text-left"
            style={{ backgroundColor: statusColor }}
          >
            {value}
          </div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {boardStatusOptions.map((option) => (
          <SelectItem 
            key={option.value} 
            value={option.value}
            className="cursor-pointer hover:bg-secondary"
          >
            <div 
              className="px-2 py-1 rounded-md text-white w-[90%] text-left transition-opacity hover:opacity-85"
              style={{ backgroundColor: option.color }}
            >
              {option.value}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export { TaskStatusSelector };