import { useState, useEffect } from 'react';
import { FlowEditor } from '../FlowEditor';
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
} from "@/components/ui/card";
import { toast } from "sonner";
import axios from '@/api/axiosConfig';
import { FlowNode, FlowEdge } from '../flows';
import { useOnboardingFlowStore } from '../flowStore';

export default function OnboardingFlowEditor() {
  const [isLoading, setIsLoading] = useState(true);
  const { activeFlow, setActiveFlow } = useOnboardingFlowStore();

  useEffect(() => {
    const fetchFlow = async () => {
      try {
        const response = await axios.get('/api/onboarding/flow');
        setActiveFlow(response.data);
      } catch (error) {
        toast.error('Failed to load flow');
        console.error('Error loading flow:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFlow();
  }, [setActiveFlow]);

  const handleSave = async (nodes: FlowNode[], edges: FlowEdge[]) => {
    try {
      const response = await axios.post('/api/onboarding/flow', { 
        nodes: nodes.map(node => ({
          ...node,
          type: node.type
        })), 
        edges 
      });
      setActiveFlow(response.data);
      toast.success('Flow saved successfully');
    } catch (error) {
      toast.error('Failed to save flow');
      console.error('Error saving flow:', error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container mx-auto py-6 space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>Onboarding Flow Editor</CardTitle>
          <CardDescription>
            Design and configure your customer onboarding process
          </CardDescription>
        </CardHeader>
        <CardContent>
          <FlowEditor
            initialNodes={activeFlow?.nodes}
            initialEdges={activeFlow?.edges}
            onSave={handleSave}
          />
        </CardContent>
      </Card>
    </div>
  );
}