export const DealSetupConfig = {
    HTD: [
      "Ikke igangsat",
      "Driftsselskab",
      "Accepteret",
      "Afvist",
      "E-mail",
      "Krediterer bagudrettet og fakturerer",
      "Ændret i system",
      "Færdig"
    ],
    LS: [
      "Ikke opsat",
      "Kunden har afvist",
      "Færdig"
    ],
    Aiia: [
      "Ikke opsat",
      "Kunden har afvist",
      "Færdig"
    ],
    Officebot: [
      "Ikke opsat",
      "Skal ikke opsættes",
      "Under udarbejdelse",
      "Færdig"
    ],
    Pleo: [
      "Ikke opsat",
      "Skal ikke have pleo",
      "Skal have pleo",
      "Konto oprettet",
      "Kategorier opsat",
      "Kunden har downloadet app",
      "Opsætte LS til Pleo",
      "Integration til regnskabsprogram",
      "Færdig"
    ],
    Corpay: [
      "Ikke opsat",
      "Ændre bilags e-mail",
      "Bilagsmail ændret",
      "Bilagsregler opsat",
      "Tilknytning af betaling igangsat/betalingsmetode igangsat",
      "Betalingsmetode opsat",
      "Færdig"
    ]
  }