import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Routes, Route, useLocation, useSearchParams, Navigate, useParams, Outlet, useNavigate } from 'react-router-dom';
import { AppSidebar } from '@/components/app-sidebar';
import { SidebarProvider } from '@/contexts/sidebar-contexts';
import { useSidebar } from "@/contexts/sidebar-contexts";
import { cn } from '@/lib/utils';
import { useTheme } from '@/contexts/ThemeContext';
import { StoredUser } from '@/types/user';

import { AppHeader } from '@/components/app-header';

import Home from './Home';
import Work from './Work';

import Dashboard from './Dashboard';
import OverviewDash from '../digi-tal-dashboard/OverviewDash';
import TaskDash from '../digi-tal-dashboard/tasks/TaskDash';
import CustomerDash from '../digi-tal-dashboard/CustomerDash';
import SalesDash from '../digi-tal-dashboard/SalesDash';

import Deals from '../customers/Deals';
import Setup from '../customers/Setup';
import Accounts from '../customers/Accounts';
import Contacts from '../customers/Contacts';
import Products from '../customers/Products';
import OnboardingDashboard from '../onboarding/OnboardingDashboard';
import OnboardingNew from '../onboarding/OnboardingNew';
import OnboardingCurrent from '../onboarding/OnboardingCurrent';
import OffboardingDashboard from '../offboarding/OffboardingDashboard';
import ChurnDetails from '../offboarding/ChurnDetails';
import Revibot from './Revibot';
import TaskView from '../taskboards/views/TaskView';
import CustomBoards from '../custom-boards/CustomBoards';
import OnboardingFormPage from '../onboarding/forms/OnboardingFormPage';
import MigrateDeal from '../onboarding/MigrateDeal';

import EmailMain from '../email/EmailMain';

import TaskBoards from '../taskboards/TaskBoards';
import { getTaskTypeConfig, TaskTypeKey } from '../taskboards/config/board-configs';
import { columnConfigs, type ColumnConfigKey } from '@/taskboards/config/columns';
import { getCardConfig } from '../taskboards/config/card';
import { getBoardStatus } from '../taskboards/config/board-status';
import { CardConfigKey } from '../taskboards/config/card';
import { BoardStatusOption } from '../taskboards/config/board-status';
import { type ColumnDef } from '@/types/table';  // Update import path
import { FrontendTaskType, Task } from '@/types/task';
import axios from '@/api/axiosConfig';
import { useUsers } from '@/contexts/UserContext';

interface TaskBoardProps {
  users: StoredUser[];
  key?: string;
  // ... other props
}

interface TaskViewProps {
  onClose: () => void;
  tasks: any[];
  taskType: string;
  boardStatusOptions: any[];
  task?: any;
  fromWork: boolean;
  getBoardName: (type: string) => string;
  dealName: string;
}

interface TaskBoardRouterProps {
  users: StoredUser[];
}

type ThemeType = 'light' | 'dark';

interface PagesUser {
  user_id: string;
  name: string;
  surname: string;
  email: string;
  status: string;
  birthday?: string;
  location_info?: {
    city: string;
    country: string;
    timezone: string;
    latitude: number;
    longitude: number;
  };
  settings?: {
    avatar_color: string;
  };
  is_test_user?: boolean;
}

interface PagesProps {
  user: PagesUser;
}

// Update the TaskViewComponent type
type TaskViewComponent = React.FC<{
  onClose: () => void;
  tasks: Task[];
  taskType: string;
  boardStatusOptions: BoardStatusOption[];
  task?: Task;
  fromWork: boolean;
  getBoardName: (type: string) => string;
  dealName: string;
  boardType?: string;
  taskId?: string;
}>;

// Use type assertion with unknown intermediary
const TaskViewWithTypes = TaskView as unknown as TaskViewComponent;

export function Pages({ user }: PagesProps) {
  const { theme, toggleTheme } = useTheme();
  const { collapsed } = useSidebar();
  const location = useLocation();
  const { users } = useUsers();
  const navigate = useNavigate();
  const isOnboardingForm = location.pathname.includes('/onboarding/customer-onboarding-form');

  useEffect(() => {
    const handleDirectTaskRoute = () => {
      const navigation = window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
      const isDirectNavigation = navigation?.type === 'reload' || navigation?.type === 'navigate';
      const isFromWorkPage = location.state?.fromWork;
      const isTaskBoardPath = location.pathname.startsWith('/boards/');
      const hasNavigated = sessionStorage.getItem('hasNavigated');

      if (isDirectNavigation && !isFromWorkPage && !isTaskBoardPath && !hasNavigated) {
        const match = location.pathname.match(/^\/work\/([^/]+)\/(\d+)/);
        if (match) {
          const [, taskType, taskId] = match;
          let boardType = taskType === 'other' ? 'andre-opgaver' : 
                         taskType === 'activity' ? 'activities' : 
                         taskType;

          sessionStorage.setItem('hasNavigated', 'true');
          navigate(`/boards/${boardType}/${taskId}`, { 
            replace: true,
            state: { directNavigation: true }
          });
        }
      }
    };

    handleDirectTaskRoute();
    return () => sessionStorage.removeItem('hasNavigated');
  }, [location, navigate]);

  return (
    <SidebarProvider>
      <div className="flex min-h-screen">
        <AppSidebar 
          user={user}
          onUpdateUsers={() => {}}
        />
        <div className="flex-1 flex flex-col min-w-0">
          <div className="flex h-full flex-col">
            <AppHeader 
              theme={theme}
              toggleTheme={toggleTheme}
            />
            <main className={cn(
              "flex-1 relative",
              isOnboardingForm ? "p-0" : "p-6"
            )}>
              <Routes>
                <Route path="/home" element={<Home user={user} />} />

                <Route path="/work" element={<WorkWithTaskView />}>
                  <Route path=":boardType/:taskId" element={null} />
                </Route>
                
                <Route path="/dashboard" element={<Dashboard />}>
                  <Route index element={<OverviewDash />} />
                  <Route path="tasks" element={<TaskDash />} />
                  <Route path="customers" element={<CustomerDash />} />
                  <Route path="sales" element={<SalesDash />} />
                </Route>
                
                <Route path="/customers" element={<Outlet />}>
                  <Route index element={<Navigate to="/customers/deals" replace />} />
                  <Route path="deals">
                    <Route index element={<Deals />} />
                    <Route path="setup" element={<Setup />} />
                  </Route>
                  <Route path="accounts" element={<Accounts />} />
                  <Route path="contacts" element={<Contacts />} />
                  <Route path="products" element={<Products />} />
                  
                  <Route path="onboarding">
                    <Route index element={<OnboardingDashboard />} />
                    <Route path="cases" element={<OnboardingNew />} />
                    <Route path="current" element={<OnboardingCurrent />} />
                    <Route path="form/:dealId/:onboardingId" element={<OnboardingFormPage />} />
                    <Route path="migrations" element={<MigrateDeal />} />
                  </Route>

                  <Route path="offboarding">
                    <Route index element={<OffboardingDashboard />} />
                    <Route path=":id" element={<ChurnDetails />} />
                  </Route>
                </Route>

                <Route path="/revibot" element={<Revibot />} />

                <Route 
                  path="/custom-boards/*" 
                  element={<CustomBoards />} 
                />
                <Route 
                  path="/boards/:boardType/*" 
                  element={<TaskBoardRouter users={users} />}
                />
                <Route path="/email" element={<EmailMain />} />
              </Routes>
            </main>
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
}

function WorkWithTaskView() {
  const location = useLocation();
  const { boardType, taskId } = useParams();
  const [tasks, setTasks] = useState<any[]>([]);
  const [taskType, setTaskType] = useState<string>('');
  const [boardStatusOptions, setBoardStatusOptions] = useState<any[]>([]);

  return (
    <>
      <Work />
      <Outlet />
      {boardType && taskId && (
        <TaskViewWithTypes 
          boardType={boardType}
          taskId={taskId}
          tasks={tasks}
          taskType={taskType}
          boardStatusOptions={boardStatusOptions}
          fromWork={true}
          getBoardName={(type: string) => type}
          dealName=""
          onClose={() => {/* implement close handler */}}
          task={undefined}
        />
      )}
    </>
  );
}

const TaskBoardRouter: React.FC<TaskBoardRouterProps> = React.memo(({ users }) => {
  const { boardType = '' } = useParams<{ boardType: string }>();
  const location = useLocation();
  const [tasks, setTasks] = useState<Task[]>([]);

  const taskConfig = useMemo(() => {
    try {
      return getTaskTypeConfig(boardType as TaskTypeKey);
    } catch {
      return null;
    }
  }, [boardType]);

  useEffect(() => {
    if (taskConfig) {
      // Fetch tasks whenever boardType changes
      const fetchTasks = async () => {
        try {
          const response = await axios.get(taskConfig.fetchUrl);
          setTasks(response.data);
        } catch (error) {
          console.error('Error fetching tasks:', error);
        }
      };
      fetchTasks();
    }
  }, [boardType, taskConfig]);

  if (!taskConfig) {
    return <Navigate to="/work" replace />;
  }

  const boardStatusOptions = getBoardStatus(boardType as FrontendTaskType).map(option => ({
    ...option,
    label: option.value
  }));

  return (
    <TaskBoards
      title={taskConfig.title}
      fetchUrl={taskConfig.fetchUrl}
      columns={columnConfigs[boardType as ColumnConfigKey] || []}
      boardStatusOptions={boardStatusOptions}
      cardConfig={getCardConfig(boardType as CardConfigKey)}
      dealDataConfig={taskConfig.dealDataConfig}
      dealSoftwareConfig={taskConfig.dealSoftwareConfig}
      taskType={boardType as FrontendTaskType}
      defaultView={taskConfig.defaultView}
      users={users}
      tasks={tasks}
      setTasks={setTasks}
      key={location.key}
    />
  );
});
