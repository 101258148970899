import { useState, useEffect } from 'react'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Form, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Input } from '@/components/ui/input'
import { Switch } from '@/components/ui/switch'
import { Badge } from '@/components/ui/badge'
import { DatePicker } from '@/components/ui/date/date-picker'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import { RecurringTask, FrequencyType } from '@/types/task'
import { cn } from '@/lib/utils'
import { toast } from 'sonner';

const formSchema = z.object({
  id: z.number(),
  frequency: z.object({
    freq: z.string(),
    interval: z.number()
  }),
  next_work_date: z.date(),
  next_due_date: z.date()
})

type FormValues = z.infer<typeof formSchema>

interface TaskScheduleProps {
  onClose: () => void
}

const frequencyOptions: { value: FrequencyType; label: string }[] = [
  { value: 'DAILY', label: 'Daily' },
  { value: 'WEEKLY', label: 'Weekly' },
  { value: 'BIWEEKLY', label: 'Every 2 weeks' },
  { value: 'MONTHLY', label: 'Monthly' },
  { value: 'QUARTERLY', label: 'Quarterly' },
  { value: 'YEARLY', label: 'Yearly' }
]

export function TaskSchedule({ onClose }: TaskScheduleProps) {
  const [tasks, setTasks] = useState<RecurringTask[]>([])
  const [loading, setLoading] = useState(true)
  const [editingTask, setEditingTask] = useState<RecurringTask | null>(null)
  const [interval, setInterval] = useState(1)

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema)
  })

  useEffect(() => {
    fetchTasks()
  }, [])

  const fetchTasks = async () => {
    try {
      const response = await axios.get('/taskboard/recurring-tasks')
      setTasks(response.data)
    } catch (error) {
      console.error('Error fetching tasks:', error)
      toast.error('Failed to fetch tasks')
    } finally {
      setLoading(false)
    }
  }

  const handleFrequencyChange = (value: FrequencyType) => {
    let newInterval: number
    switch (value) {
      case 'QUARTERLY':
        newInterval = 3
        break
      case 'BIWEEKLY':
        newInterval = 2
        break
      default:
        newInterval = 1
    }
    setInterval(newInterval)
    form.setValue('frequency', { freq: value, interval: newInterval })
  }

  const onSubmit = async (values: FormValues) => {
    try {
      const payload = {
        ...values,
        next_work_date: dayjs(values.next_work_date).format('YYYY-MM-DD'),
        next_due_date: dayjs(values.next_due_date).format('YYYY-MM-DD')
      }
      await axios.put(`/taskboard/recurring-tasks/${values.id}`, payload)
      toast.success('Task updated successfully')
      fetchTasks()
      setEditingTask(null)
    } catch (error) {
      console.error('Error updating task:', error)
      toast.error('Failed to update task')
    }
  }

  const resetEditing = () => {
    setEditingTask(null)
    form.reset()
  }

  const toggleTaskStatus = async (taskId: number, currentStatus: boolean) => {
    const updatedTasks = tasks.map(task =>
      task.id === taskId ? { ...task, status_active: !currentStatus } : task
    )
    setTasks(updatedTasks)

    try {
      await axios.put(`/taskboard/recurring-tasks/${taskId}/toggle-status`, {
        status_active: !currentStatus
      })
      toast.success('Task status updated successfully')
    } catch (error) {
      console.error('Error toggling task status:', error)
      toast.error('Failed to update task status')
      
      const revertedTasks = tasks.map(task =>
        task.id === taskId ? { ...task, status_active: currentStatus } : task
      )
      setTasks(revertedTasks)
    }
  }

  return (
    <div className="space-y-4">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Deal</TableHead>
            <TableHead>Task</TableHead>
            <TableHead>Frequency</TableHead>
            <TableHead>Next Work Date</TableHead>
            <TableHead>Next Due Date</TableHead>
            <TableHead>Active</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tasks.map((task) => (
            <TableRow
              key={task.id}
              className={cn(!task.status_active && 'opacity-60 bg-muted')}
            >
              <TableCell>
                <Badge variant="secondary">{task.deal_name}</Badge>
              </TableCell>
              <TableCell>{task.description}</TableCell>
              <TableCell>
                {frequencyOptions.find(opt => opt.value === task.frequency.freq)?.label || task.frequency.freq}
              </TableCell>
              <TableCell>{dayjs(task.next_work_date).format('YYYY-MM-DD')}</TableCell>
              <TableCell>{dayjs(task.next_due_date).format('YYYY-MM-DD')}</TableCell>
              <TableCell>
                <Switch
                  checked={task.status_active}
                  onCheckedChange={() => toggleTaskStatus(task.id, task.status_active)}
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="outline"
                  size="sm"
                  onClick={() => {
                    setEditingTask(task)
                    form.reset({
                      ...task,
                      next_work_date: dayjs(task.next_work_date).toDate(),
                      next_due_date: dayjs(task.next_due_date).toDate()
                    })
                  }}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {editingTask && (
        <Dialog open={!!editingTask} onOpenChange={() => setEditingTask(null)}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Edit Task Schedule</DialogTitle>
            </DialogHeader>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <input type="hidden" {...form.register('id')} />
                
                <FormItem>
                  <FormLabel>Deal</FormLabel>
                  <Badge variant="secondary">{editingTask.deal_name}</Badge>
                </FormItem>
                
                <FormItem>
                  <FormLabel>Task</FormLabel>
                  <div className="text-sm">{editingTask.description}</div>
                </FormItem>
                
                <FormField
                  control={form.control}
                  name="frequency.freq"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Frequency</FormLabel>
                      <Select
                        value={field.value}
                        onValueChange={(value: FrequencyType) => handleFrequencyChange(value)}
                      >
                        <SelectTrigger>
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          {frequencyOptions.map(option => (
                            <SelectItem key={option.value} value={option.value}>
                              {option.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="frequency.interval"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Interval</FormLabel>
                      <Input
                        type="number"
                        {...field}
                        disabled
                        className="bg-muted"
                      />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="next_work_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Next Work Date</FormLabel>
                      <DatePicker
                        date={field.value}
                        onSelect={field.onChange}
                      />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={form.control}
                  name="next_due_date"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Next Due Date</FormLabel>
                      <DatePicker
                        date={field.value}
                        onSelect={field.onChange}
                      />
                    </FormItem>
                  )}
                />
                
                <div className="flex justify-end gap-2">
                  <Button variant="outline" onClick={resetEditing}>
                    Cancel
                  </Button>
                  <Button type="submit">Save</Button>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}