import * as React from "react"
import { Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts"
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"

interface LineChartMixProps {
  data: any[]
  title?: string
  categories: string[]
  index: string
  colors?: string[]
  valueFormatter?: (value: number) => string
}

export function LineChartMix({
  data,
  title,
  categories,
  index,
  colors = ["hsl(var(--primary))", "hsl(var(--secondary))"],
  valueFormatter = (value: number) => value.toString(),
}: LineChartMixProps) {
  return (
    <Card>
      {title && (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
      )}
      <CardContent className="pb-4">
        <ResponsiveContainer width="100%" height={350}>
          <LineChart data={data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
            <XAxis dataKey={index} />
            <YAxis tickFormatter={valueFormatter} />
            <Tooltip />
            {categories.map((category, index) => (
              <Line
                key={category}
                type="monotone"
                dataKey={category}
                stroke={colors[index]}
                strokeWidth={2}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  )
}