import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Textarea } from '@/components/ui/textarea'
import { Send, Trash, Loader2 } from 'lucide-react'
import axios from '@/api/axiosConfig'
import dayjs from 'dayjs'
import { UserAvatar } from '@/components/user-avatar'
import { StoredUser } from '@/types/user'
import { Task } from '@/types/task'
import { toast } from 'sonner'

interface TaskNotesProps {
  visible: boolean
  onClose: (refresh?: boolean) => void
  task: Task
  taskType: string
  users: StoredUser[]
}

export function TaskNotes({ visible, onClose, task, taskType, users }: TaskNotesProps) {
  const [newComment, setNewComment] = useState('')
  const [loading, setLoading] = useState(false)

  const getBackendTaskType = (frontendTaskType: string): string => {
    if (['bogforing', 'lon', 'moms', 'arsafslutning'].includes(frontendTaskType)) {
      return frontendTaskType;
    } else if (frontendTaskType === 'andre-opgaver') {
      return 'other';
    } else if (frontendTaskType === 'activities') {
      return 'activity';
    }
    return frontendTaskType;
  };

  const handleSubmit = async () => {
    if (!newComment.trim()) return

    setLoading(true)
    try {
      const backendTaskType = getBackendTaskType(taskType)
      const response = await axios.post(`/taskboard/tasks/${backendTaskType}/${task.id}/comments`, {
        content: newComment.trim()
      })

      if (response.status === 200) {
        toast.success('Comment added successfully')
        setNewComment('')
        onClose(true) // Refresh the task data
      }
    } catch (error) {
      console.error('Error adding comment:', error)
      toast.error('Failed to add comment')
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = async (commentId: number) => {
    try {
      const backendTaskType = getBackendTaskType(taskType)
      const response = await axios.delete(`/taskboard/tasks/${backendTaskType}/${task.id}/comments/${commentId}`)
      if (response.status === 200) {
        toast.success('Comment deleted successfully')
        onClose(true) // Refresh the task data
      }
    } catch (error) {
      console.error('Error deleting comment:', error)
      toast.error('Failed to delete comment')
    }
  }

  return (
    <Dialog open={visible} onOpenChange={() => onClose()}>
      <DialogContent className="max-w-lg">
        <DialogHeader>
          <DialogTitle>Task Notes & Comments</DialogTitle>
          <DialogDescription>
            Add or view comments for this task
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4 max-h-[600px]">
          <div className="flex flex-col gap-2">
            <Textarea
              placeholder="Write a comment..."
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              className="resize-none"
              rows={2}
            />
            <Button
              onClick={handleSubmit}
              disabled={!newComment.trim() || loading}
              className="self-end"
            >
              {loading ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Posting...
                </>
              ) : (
                <>
                  <Send className="mr-2 h-4 w-4" />
                  Post
                </>
              )}
            </Button>
          </div>

          <ScrollArea className="max-h-[400px]">
            <div className="flex flex-col gap-5 pr-2">
              {(!task?.comments || task.comments.length === 0) ? (
                <div className="flex justify-center items-center min-h-[100px] rounded-md">
                  <p className="text-muted-foreground">No comments yet</p>
                </div>
              ) : (
                [...task.comments].reverse().map((comment) => {
                  const user = users.find(u => u.user_id === comment.user_id);
                  return (
                    <div key={comment.id} className="flex gap-2 relative group">
                      <UserAvatar 
                        user={user || { 
                          name: 'Unknown',
                          surname: 'User',
                          user_id: comment.user_id,
                        }} 
                        size={32} 
                      />
                      <div className="flex-1 flex flex-col gap-1 ml-2 pr-8">
                        <div className="flex justify-between items-start">
                          <p className="text-sm font-medium">
                            {user ? `${user.name} ${user.surname}` : `User ${comment.user_id}`}
                          </p>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleDelete(comment.id)}
                            className="absolute right-0 top-0 opacity-0 group-hover:opacity-100 transition-opacity"
                          >
                            <Trash className="h-4 w-4" />
                          </Button>
                        </div>
                        <p className="text-sm whitespace-pre-wrap">{comment.content}</p>
                        <p className="text-xs text-muted-foreground">
                          {dayjs(comment.timestamp).format('YYYY-MM-DD HH:mm')}
                        </p>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  )
}