import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { format } from 'date-fns'
import { Calendar as CalendarIcon, Camera, User, Lock, MapPin, UserCircle2 } from 'lucide-react'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { toast } from "sonner"
import axiosPublic from '../api/axiosPublic'
import UserAvatar from '@/components/user-avatar'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Check, ChevronsUpDown } from "lucide-react"

const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  surname: z.string().min(1, 'Surname is required'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  birthday: z.date({
    required_error: 'Birthday is required',
  }),
  city: z.string().min(1, 'City is required'),
  country: z.string().min(1, 'Country is required'),
})

export default function CompleteRegistration() {
  const navigate = useNavigate()
  const [countries, setCountries] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [showAvatarUpload, setShowAvatarUpload] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [currentUser, setCurrentUser] = useState<any>(
    JSON.parse(localStorage.getItem('user') || '{}')
  )
  const [imageError, setImageError] = useState(false);
  const [openCountry, setOpenCountry] = useState(false)

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  })

  useEffect(() => {
    axiosPublic
      .get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const sortedCountries = response.data
          .map((country: any) => country.name.common)
          .sort((a: string, b: string) => a.localeCompare(b))
        setCountries(sortedCountries)
      })
      .catch((error) => console.error('Error fetching countries:', error))
  }, [])

  const handleAvatarUpload = async (file: File) => {
    const formData = new FormData()
    formData.append('avatar', file)

    try {
      setIsUploading(true)
      const response = await axiosPublic.post('/user/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })

      if (response.data.avatar_urls) {
        const updatedUser = {
          ...currentUser,
          avatar_urls: response.data.avatar_urls,
        }
        setCurrentUser(updatedUser)
        localStorage.setItem('user', JSON.stringify(updatedUser))
        toast('Avatar uploaded successfully')
      }
    } catch (error) {
      toast('Failed to upload avatar')
    } finally {
      setIsUploading(false)
      setShowAvatarUpload(false)
    }
  }

  const handleAvatarDelete = async () => {
    try {
      setIsUploading(true)
      await axiosPublic.delete('/user/avatar', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      const updatedUser = {
        ...currentUser,
        avatar_urls: {},
      }
      setCurrentUser(updatedUser)
      localStorage.setItem('user', JSON.stringify(updatedUser))
      toast('Avatar removed successfully')
    } catch (error) {
      toast('Failed to remove avatar')
    } finally {
      setIsUploading(false)
    }
  }

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    setIsLoading(true)
    try {
      const token = localStorage.getItem('token')
      const response = await axiosPublic.post('/auth/complete-registration', values, {
        headers: { Authorization: `Bearer ${token}` },
      })
      
      if (response.data.user && response.data.user.is_registered) {
        toast('Registration completed successfully. Please log in with your new credentials.')
        localStorage.removeItem('user')
        localStorage.removeItem('token')
        setTimeout(() => {
          navigate('/')
        }, 2000)
      } else {
        throw new Error('Registration failed')
      }
    } catch (error) {
      toast('Failed to complete registration. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex min-h-screen items-center justify-center bg-background p-4">
      <div className="w-full max-w-[600px] rounded-lg bg-card p-8 shadow-lg">
        <img
          src="https://digi-tal-spaces.ams3.cdn.digitaloceanspaces.com/ERP/logos/logo.png"
          alt="Company Logo"
          className="mx-auto h-24 w-auto rounded-lg mb-8"
          onError={() => setImageError(true)}
          style={{ display: imageError ? 'none' : 'block' }}
        />
        <h2 className="mb-8 text-center text-2xl font-bold">
          Complete Your Profile
        </h2>

        <div
          className="relative mx-auto mb-8 h-[120px] w-[120px] cursor-pointer overflow-hidden rounded-full"
          onMouseEnter={() => setShowAvatarUpload(true)}
          onMouseLeave={() => setShowAvatarUpload(false)}
        >
          {currentUser.avatar_urls && Object.keys(currentUser.avatar_urls).length > 0 ? (
            <UserAvatar
              user={currentUser}
              className="h-[120px] w-[120px]"
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center bg-muted">
              <UserCircle2 className="h-16 w-16 text-muted-foreground" />
            </div>
          )}
          
          {!currentUser.avatar_urls || Object.keys(currentUser.avatar_urls).length === 0 ? (
            <div
              className={cn(
                'absolute inset-0 flex flex-col items-center justify-center bg-black/50 transition-opacity',
                showAvatarUpload ? 'opacity-100' : 'opacity-0'
              )}
            >
              <input
                type="file"
                className="absolute inset-0 cursor-pointer opacity-0"
                onChange={(e) => {
                  const file = e.target.files?.[0]
                  if (file) handleAvatarUpload(file)
                }}
                accept="image/*"
              />
              <Camera className="mb-1 h-6 w-6 text-white" />
              <span className="text-sm text-white">Add Image</span>
            </div>
          ) : (
            <div
              className={cn(
                'absolute inset-0 flex flex-col items-center justify-center gap-2 bg-black/50 transition-opacity',
                showAvatarUpload ? 'opacity-100' : 'opacity-0'
              )}
            >
              <input
                type="file"
                className="absolute inset-0 cursor-pointer opacity-0"
                onChange={(e) => {
                  const file = e.target.files?.[0]
                  if (file) handleAvatarUpload(file)
                }}
                accept="image/*"
              />
              <Button
                variant="secondary"
                size="sm"
                className="w-24"
                disabled={isUploading}
              >
                <Camera className="mr-2 h-4 w-4" />
                Change
              </Button>
              <Button
                variant="destructive"
                size="sm"
                className="w-24"
                onClick={handleAvatarDelete}
                disabled={isUploading}
              >
                Remove
              </Button>
            </div>
          )}
        </div>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Name</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <User className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Input className="pl-9" {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="surname"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Surname</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <User className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Input className="pl-9" {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Lock className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                      <Input type="password" className="pl-9" {...field} />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <FormField
                control={form.control}
                name="birthday"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Birthday</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            className={cn(
                              'pl-3 text-left font-normal',
                              !field.value && 'text-muted-foreground'
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {field.value ? (
                              format(field.value, 'PPP')
                            ) : (
                              <span>Pick a date</span>
                            )}
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date: Date) =>
                            date > new Date() || date < new Date('1900-01-01')
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="city"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>City</FormLabel>
                    <FormControl>
                      <div className="relative">
                        <MapPin className="absolute left-3 top-3 h-4 w-4 text-muted-foreground" />
                        <Input className="pl-9" {...field} />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Country</FormLabel>
                  <Popover open={openCountry} onOpenChange={setOpenCountry}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={openCountry}
                          className="w-full justify-between"
                        >
                          {field.value || "Select country..."}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[200px] p-0">
                      <Command>
                        <CommandInput placeholder="Search country..." />
                        <CommandList>
                          <CommandEmpty>No country found.</CommandEmpty>
                          <CommandGroup>
                            {countries.map((country) => (
                              <CommandItem
                                key={country}
                                value={country}
                                onSelect={(currentValue) => {
                                  field.onChange(currentValue)
                                  setOpenCountry(false)
                                }}
                              >
                                {country}
                                <Check
                                  className={cn(
                                    "ml-auto h-4 w-4",
                                    field.value === country ? "opacity-100" : "opacity-0"
                                  )}
                                />
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                </FormItem>
              )}
            />

            <div className="text-right">
              <Button type="submit" disabled={isLoading}>
                Complete Registration
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  )
}