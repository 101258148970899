import React from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import * as z from 'zod'
import { format } from 'date-fns'
import { Calendar, UserPlus } from 'lucide-react'
import { toast } from 'sonner'

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Textarea } from '@/components/ui/textarea'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'
import { cn } from '@/lib/utils'
import { Calendar as CalendarComponent } from '@/components/ui/calendar'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { DealSelector } from '../components/DealSelector'
import { AssigneeSelection } from '@/modals/AssigneeSelection'

import { Task } from '@/types/task'
import { StoredUser } from '@/types/user'
import { Board } from '@/types/custom-board'
import { useUsers } from '@/contexts/UserContext'

interface CreateTaskModalProps {
  open: boolean
  onClose: () => void
  onSubmit: (values: Partial<Task>) => void
  board: Board | undefined
}

const formSchema = z.object({
  title: z.string().min(1, 'Please enter a title'),
  description: z.string().optional(),
  deal_id: z.string().optional(),
  work_date: z.date().optional(),
  due_date: z.date().optional(),
  board_status: z.string().optional(),
  assigned_users: z.array(z.string()).optional(),
})

const CreateTaskModal: React.FC<CreateTaskModalProps> = ({
  open,
  onClose,
  onSubmit: handleSubmit,
  board,
}) => {
  const { users, currentUser } = useUsers();
  const [showAssigneeModal, setShowAssigneeModal] = React.useState(false)
  const hasBoardStatus = Boolean(
    board?.board_config?.board_status_options && 
    board.board_config.board_status_options.length > 0
  )

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: '',
      description: '',
      deal_id: '',
      assigned_users: [],
    },
  })

  const handleAssigneeSelection = (assignees: string[]) => {
    form.setValue('assigned_users', assignees)
  }

  const handleAddSelf = () => {
    const currentAssignees = form.getValues('assigned_users') || [];
    if (currentUser && !currentAssignees.includes(currentUser.user_id.toString())) {
      const newAssignees = [...currentAssignees, currentUser.user_id.toString()];
      form.setValue('assigned_users', newAssignees);
    }
  }

  const submitForm = async (values: z.infer<typeof formSchema>) => {
    try {
      const formattedValues = {
        ...values,
        deal_id: values.deal_id ? parseInt(values.deal_id, 10) : undefined,
        work_date: values.work_date ? format(values.work_date, 'yyyy-MM-dd') : undefined,
        due_date: values.due_date ? format(values.due_date, 'yyyy-MM-dd') : undefined,
      }

      await handleSubmit(formattedValues)
      form.reset()
      onClose()
    } catch (error) {
      console.error('Error creating task:', error)
      toast.error('Failed to create task')
    }
  }

  return (
    <>
      <Dialog open={open} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Create Task</DialogTitle>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(submitForm)} className="space-y-4">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Title</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Description</FormLabel>
                    <FormControl>
                      <Textarea {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {hasBoardStatus && board?.board_config?.board_status_options && (
                <FormField
                  control={form.control}
                  name="board_status"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Board Status</FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        value={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue placeholder="Select board status (optional)" />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {(board.board_config.board_status_options || []).map((option) => (
                            <SelectItem
                              key={option.value || option.label}
                              value={option.value || ''}
                            >
                              <div
                                className="px-2 py-1 rounded text-white"
                                style={{ backgroundColor: option.color }}
                              >
                                {option.label}
                              </div>
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                control={form.control}
                name="deal_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Deal
                      {board?.board_config?.requires_deal && (
                        <span className="text-destructive"> *</span>
                      )}
                    </FormLabel>
                    <FormControl>
                      <DealSelector
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="work_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Work Date
                      {board?.board_config?.requires_work_date && (
                        <span className="text-destructive"> *</span>
                      )}
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            className={cn(
                              'w-full pl-3 text-left font-normal',
                              !field.value && 'text-muted-foreground'
                            )}
                          >
                            {field.value ? (
                              format(field.value, 'PPP')
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <Calendar className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <CalendarComponent
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="due_date"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      Due Date
                      {board?.board_config?.requires_due_date && (
                        <span className="text-destructive"> *</span>
                      )}
                    </FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            className={cn(
                              'w-full pl-3 text-left font-normal',
                              !field.value && 'text-muted-foreground'
                            )}
                          >
                            {field.value ? (
                              format(field.value, 'PPP')
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <Calendar className="ml-auto h-4 w-4 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <CalendarComponent
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="assigned_users"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Assignees</FormLabel>
                    <div className="flex gap-2">
                      <Button
                        type="button"
                        variant="outline"
                        className="w-full"
                        onClick={() => setShowAssigneeModal(true)}
                      >
                        Select Assignees ({field.value?.length || 0})
                      </Button>
                      <Button
                        type="button"
                        variant="outline"
                        size="icon"
                        onClick={handleAddSelf}
                        title="Assign to me"
                      >
                        <UserPlus className="h-4 w-4" />
                      </Button>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>

          <DialogFooter>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" onClick={form.handleSubmit(submitForm)}>
              Create
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      <AssigneeSelection
        visible={showAssigneeModal}
        onClose={() => setShowAssigneeModal(false)}
        onSave={handleAssigneeSelection}
        initialAssignees={form.getValues('assigned_users') || []}
      />
    </>
  )
}

export default CreateTaskModal