import React from 'react';
import { Bell } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { X as CloseIcon } from 'lucide-react';

interface NotificationsModalProps {
  visible: boolean;
  onClose: () => void;
}

const NotificationsModal: React.FC<NotificationsModalProps> = ({ visible, onClose }) => {
  return (
    <Dialog open={visible} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[400px]">
        <DialogHeader className="flex flex-row items-center justify-between">
          <DialogTitle>Notifications</DialogTitle>
        </DialogHeader>
        
        <div className="flex flex-col items-center p-8 text-center">
          <Bell 
            className="h-16 w-16 mb-6 text-blue-500 animate-pulse" 
            strokeWidth={1.5} 
          />
          <div className="max-w-[300px]">
            <h3 className="text-lg font-semibold mb-4">
              You have 0 new notifications
            </h3>
            <p className="text-muted-foreground leading-relaxed">
              Actually, I have yet to develop notification functionality in Tuesday, 
              so don't expect to see anything here for a few days ;)
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationsModal;