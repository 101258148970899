import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { UserAvatar } from '@/components/user-avatar'
import { CustomTask } from '@/types/custom-task'
import { StoredUser } from '@/types/user'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

interface CustomBoardActivityProps {
  open: boolean
  onClose: () => void
  task: CustomTask
  users: StoredUser[]
}

export function CustomBoardActivity({ open, onClose, task, users }: CustomBoardActivityProps) {
  const activities = task.activity_log || []

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent className="max-h-[90vh] max-w-2xl">
        <DialogHeader>
          <DialogTitle>Activity History</DialogTitle>
          <p className="text-sm text-muted-foreground">
            {activities.length} activities recorded
          </p>
        </DialogHeader>

        <ScrollArea className="h-[calc(90vh-160px)] px-4">
          {activities.length > 0 ? (
            <div className="space-y-8 py-4">
              {activities.map((activity, index) => {
                const user = users.find(u => u.user_id === activity.user?.id)
                return (
                  <div key={index} className="relative pl-8">
                    <div className="absolute left-0 top-0">
                      {user && <UserAvatar user={user} className="h-6 w-6" />}
                    </div>
                    <div className="space-y-1">
                      <div className="flex items-center justify-between gap-2">
                        <span className="font-medium">{activity.action}</span>
                        <span className="text-sm text-muted-foreground">
                          {dayjs(activity.timestamp).fromNow()}
                        </span>
                      </div>
                      {user && (
                        <p className="text-sm text-muted-foreground">
                          by {user.name} {user.surname}
                        </p>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className="flex h-[400px] items-center justify-center">
              <p className="text-muted-foreground">No activities recorded</p>
            </div>
          )}
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}