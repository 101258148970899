import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { cn } from "@/lib/utils";
import { 
  NodeConfig, 
  NodeElement, 
  ButtonConfig, 
  InputConfig, 
  TextConfig, 
  SelectConfig,
  NotionGuideConfig 
} from '../node_types';

export interface PreviewNodeProps {
  config: NodeConfig;
  onClose?: () => void;
}

const renderPreviewElement = (element: NodeElement) => {
  switch (element.type) {
    case 'input': {
      const config = element.config as InputConfig;
      return (
        <div className="space-y-2">
          <Label>{config.label}</Label>
          <Input
            type={config.type}
            placeholder={config.placeholder}
            required={config.required}
          />
        </div>
      );
    }
    case 'button': {
      const config = element.config as ButtonConfig;
      return (
        <Button variant={config.variant}>
          {config.label}
        </Button>
      );
    }
    case 'text': {
      const config = element.config as TextConfig;
      const className = cn(
        config.variant === 'heading' && 'text-2xl font-bold',
        config.variant === 'subheading' && 'text-xl font-semibold',
        config.variant === 'default' && 'text-base'
      );
      return <p className={className}>{config.content}</p>;
    }
    case 'select': {
      const config = element.config as SelectConfig;
      return (
        <div className="space-y-2">
          <Label>{config.label}</Label>
          <Select>
            <SelectTrigger>
              <SelectValue placeholder={config.placeholder} />
            </SelectTrigger>
            <SelectContent>
              {config.options.map(option => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      );
    }
    case 'notionGuide': {
      const config = element.config as NotionGuideConfig;
      return (
        <div className="border p-4 rounded-lg">
          <h3 className="font-semibold">{config.title}</h3>
          <p className="text-sm text-muted-foreground">Notion Guide: {config.pageId}</p>
        </div>
      );
    }
    default:
      return null;
  }
};

export function PreviewNode({ config, onClose }: PreviewNodeProps) {
  return (
    <Dialog open={true} onOpenChange={() => onClose?.()}>
      <DialogContent className="max-w-2xl">
        <DialogHeader>
          <DialogTitle>{config.title}</DialogTitle>
        </DialogHeader>

        <div className="space-y-6">
          {config.description && (
            <p className="text-muted-foreground">{config.description}</p>
          )}
          
          <div className="grid grid-cols-12 gap-4">
            {config.rows.map((row) => (
              <div key={row.id} className="col-span-12 grid grid-cols-12 gap-4">
                {row.elements.map((element) => (
                  <div
                    key={element.id}
                    className={cn(
                      'col-span-12',
                      element.layout?.width === '1/2' && 'col-span-6',
                      element.layout?.width === '1/3' && 'col-span-4',
                      element.layout?.width === '2/3' && 'col-span-8'
                    )}
                  >
                    {renderPreviewElement(element)}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <Button variant="outline" onClick={onClose}>
            Close Preview
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}