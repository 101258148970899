import React, { createContext, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import axios from '../api/axiosConfig';
import { message } from 'antd';

export const TimeTrackerContext = createContext();

export const TimeTrackerProvider = ({ children }) => {
  const [state, setState] = useState({
    isRunning: false,
    isPaused: false,
    currentTask: null,
    time: 0
  });
  const intervalRef = useRef(null);
  const previousStateRef = useRef(null);

  useEffect(() => {
    const checkActiveTracking = async () => {
      const manualLogout = sessionStorage.getItem('manualLogout');
      const token = localStorage.getItem('token');
      if (manualLogout || !token) {
        return;
      }

      try {
        const response = await axios.get('/timetracker/active-tracking');
        if (response.data.active) {
          setState({
            isRunning: response.data.isRunning,
            isPaused: response.data.isPaused,
            currentTask: response.data.currentTask,
            time: response.data.time
          });
        }
      } catch (error) {
        if (error.response?.status === 401) {
          return;
        }
        console.error('Failed to check active tracking:', error);
      }
    };

    checkActiveTracking();
  }, []);

  const updateState = useCallback((updates) => {
    previousStateRef.current = state;
    setState(prev => ({ ...prev, ...updates }));
  }, [state]);

  const startTracking = useCallback(async (taskData) => {
    try {
      updateState({
        isRunning: true,
        isPaused: false,
        currentTask: {
          ...taskData,
          dealName: taskData.dealName,
          taskName: taskData.taskDescription
        },
        time: 0
      });

      await axios.post('/timetracker/start', {
        taskId: taskData.taskId,
        taskType: taskData.taskType,
        dealId: taskData.dealId,
        recurringType: taskData.recurringType
      });
    } catch (error) {
      setState(previousStateRef.current);
      console.error('Failed to start time tracking:', error);
      if (error.response?.status === 400) {
        message.error('Please stop your current timer before starting a new one');
      }
    }
  }, [updateState]);

  const stopTracking = useCallback(async () => {
    if (state.currentTask) {
      try {
        updateState({
          isRunning: false,
          isPaused: false,
          currentTask: null,
          time: 0
        });

        await axios.post('/timetracker/stop', {
          taskId: state.currentTask.taskId,
          taskType: state.currentTask.taskType
        });

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      } catch (error) {
        setState(previousStateRef.current);
        console.error('Failed to stop time tracking:', error);
        message.error('Failed to stop timer');
      }
    }
  }, [state.currentTask, updateState]);

  const pauseTracking = useCallback(async () => {
    try {
      updateState({
        isPaused: true
      });

      await axios.post('/timetracker/pause', {
        taskId: state.currentTask.taskId,
        taskType: state.currentTask.taskType
      });
    } catch (error) {
      setState(previousStateRef.current);
      console.error('Failed to pause time tracking:', error);
      message.error('Failed to pause timer');
    }
  }, [state.currentTask, updateState]);

  const resumeTimer = useCallback(async () => {
    try {
      updateState({
        isPaused: false
      });

      await axios.post('/timetracker/resume', {
        taskId: state.currentTask.taskId,
        taskType: state.currentTask.taskType
      });
    } catch (error) {
      setState(previousStateRef.current);
      console.error('Failed to resume time tracking:', error);
      message.error('Failed to resume timer');
    }
  }, [state.currentTask, updateState]);

  const completeTask = async (taskData) => {
    try {
      const response = await axios.post('/timetracker/complete-task', {
        taskId: taskData.taskId,
        taskType: taskData.taskType
      });
      // Handle completion success
    } catch (error) {
      console.error('Failed to complete task:', error);
    }
  };

  useEffect(() => {
    let rafId;
    let lastTime = performance.now();

    const updateTimer = (currentTime) => {
      const deltaTime = currentTime - lastTime;
      if (deltaTime >= 1000) {
        setState(prev => ({
          ...prev,
          time: prev.time + 1
        }));
        lastTime = currentTime;
      }
      rafId = requestAnimationFrame(updateTimer);
    };

    if (state.isRunning && !state.isPaused) {
      rafId = requestAnimationFrame(updateTimer);
    }

    return () => {
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
    };
  }, [state.isRunning, state.isPaused]);

  const value = useMemo(() => ({
    ...state,
    startTracking,
    stopTracking,
    pauseTracking,
    resumeTimer,
    completeTask
  }), [state, startTracking, stopTracking, pauseTracking, resumeTimer, completeTask]);

  return (
    <TimeTrackerContext.Provider value={value}>
      {children}
    </TimeTrackerContext.Provider>
  );
};
